/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { Actionable, Text, View } from '@az/starc-ui';
import styles from './Permission.module.scss';

type PermissionTabType = {
  isActive: boolean;
  value: string;
  label: string;
  onTabChangeHandler: (value: string, label: string) => void;
};

export const PermissionTab = (props: PermissionTabType) => {
  const { t } = useTranslation();
  const { isActive, value, label, onTabChangeHandler } = props;

  return (
    <View
      key={value}
      align="center"
      className={`${styles['permission-view__tabs-item']} ${
        isActive ? styles['permission-view__tabs-item--active'] : ''
      }`}
    >
      <Actionable fullWidth={true} onClick={() => onTabChangeHandler(value, label)}>
        <Text weight="medium">{t(label)}</Text>
      </Actionable>
    </View>
  );
};
