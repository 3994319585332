/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';

import { useEffect } from 'react';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { layoutProductService } from '../layoutProductService';
import { GetLayoutProductsParams } from '@inbound/types/service.type';

export const useGetLayoutsProducts = (options: GetLayoutProductsParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: productsData,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['layouts-products', options],
    queryFn: () => layoutProductService.getProducts(options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isEnabled && isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Zones.Description'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { productsData, isFetching, isError, refetch };
};
