/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';

import { inboundOrderService } from '@inbound/services/inboundOrderService';

export const useGetInboundOrderBySourceOrderNumber = (
  params: { sourceOrderNumber: string },
  isEnabled = true
) => {
  const {
    data: inboundOrderData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['sourceOrderNumber', params],
    queryFn: () => inboundOrderService.getInboundOrderBySourceOrderNumber(params.sourceOrderNumber),
    enabled: isEnabled,
  });

  return { inboundOrderData, isLoading, isError, refetch };
};
