/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import {
  TableStylingVariants,
  NEW_USER_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import { mapNewUserTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { NOTIFICATION_TYPES, PAGE_SIZE } from '@shared/constants/constants.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import { useEffect, useState } from 'react';
import { useGetUsers } from '@mdm/services/hooks/useGetUsers.tsx';
import {
  TableSorting,
  DIRECTION as TABLE_SORT_DIRECTION,
} from '@shared/components/Table/Table.types.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { UserSearchType } from '@mdm/types/schema.type.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import dayjs from 'dayjs';

export const NewUserList = () => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('userId');

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const { userId, userType, primaryDeptId, roleId, fromDate, toDate } = useOutletContext<{
    userId: string;
    primaryDeptId: string;
    roleId: string;
    fromDate: string;
    toDate: string;
    userType: string[];
  }>();

  const { usersData, isFetching: isLoading } = useGetUsers({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    sortBy: sortColumnId,
    direction: sortDirection,
    userId,
    primaryDeptId,
    roleId,
    fromDate: fromDate && toDate ? dayjs(fromDate).format(t('DateFormat.CreateOrder')) : '',
    toDate: fromDate && toDate ? dayjs(toDate).format(t('DateFormat.CreateOrder')) : '',
    userType,
  });

  /* Functions */
  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  const onViewDetails = (user: UserSearchType) => {
    if (user.userId) {
      navigate(PAGE_URLS.USER_DETAILS(user.userId));
    }
  };

  /* Hooks */
  useEffect(() => {
    if (usersData && usersData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
    }
  }, [handleNotification, t, usersData]);

  return (
    <View
      attributes={{
        'data-testid': 'new-user-list',
      }}
    >
      <View padding={[4, 6]}>
        <Table
          columns={NEW_USER_TABLE_COLUMNS}
          rows={mapNewUserTableRows(usersData?.content || [], onViewDetails)}
          isPaginated={true}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={currentPage}
          isCreditItem={false}
          isCheckboxTable={false}
          isLoading={isLoading}
          isApiLoadedData={true}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={usersData?.totalPages || 0}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          onSort={onSort}
        />
      </View>
    </View>
  );
};
