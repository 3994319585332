/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pdf } from '@react-pdf/renderer';

import { saveAs } from 'file-saver';

import {
  Actionable,
  Add,
  Button,
  Checkbox,
  Close,
  Divider,
  Dropdown,
  Icon,
  Link,
  OrderHistory,
  SingleValue,
  Text,
  View,
} from '@az/starc-ui';

import { Download } from '@shared/assets/icons';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { Stat } from '@shared/components/Stat/Stat';
import { Table } from '@shared/components/Table/Table';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { SortRowsParam } from '@shared/components/Table/Table.types';
import {
  COMMODITY_TYPE,
  COMMON,
  DEFAULT_PAGE,
  EMPTY_VALUE,
  INBOUND,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  PO_TYPE,
  RECEIVING,
  INBOUND_ORDER_STATUS,
  PRIORITY,
} from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig';
import { generateDateString } from '@shared/utils/commonUtils';

import { downloadGeneratedCSV } from '@ofm/utils/utils';

import { AddPOModal } from '@inbound/components/AddPOModal/AddPOModal';
import { DropdownSelectMenu } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';
import { ListKanbanToggle } from '@inbound/components/ListKanbanToggle/ListKanbanToggle';
import { POSearch } from '@inbound/components/POSearch/POSearch';
import { PODashBoardExport } from '@inbound/components/PDFs/PODashBoardExport';

import {
  LIST,
  KANBAN,
  POActions,
  DEFAULT_SORT_BY,
  ASC,
  DOMAIN_TYPE_CD,
  FILE_TYPES,
  TRAILER_ORDER_STATUS,
  TRAILER_ORDER_STATUS_CDS,
} from '@inbound/constants/constants';
import { ARRIVAL_TIMES, PO_CATEGORIES, DIVIDER_LABELS } from '@inbound/constants/dataConstants';
import { PO_DASHBOARD_TABLE_COLUMNS } from '@inbound/constants/tableConstants';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import {
  DownloadFileType,
  PODashboardDataRowType,
  PODashboardDividerRowType,
  TrailerOrderListType,
} from '@inbound/types/types';
import {
  calculatePercentageWithSign,
  mapUItoDBField,
  statusToBadgeVariant,
  toUTCString,
} from '@inbound/utils/utils';
import {
  mapPODashboardTableAgeBreakdownRows,
  mapTrailerOrders,
} from '@inbound/utils/table/tableUtils';

import styles from './PODashboard.module.scss';

export const PODashboard = () => {
  /* State variables */
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentView, setCurrentView] = useState<string>(LIST);
  const [showAddPOModal, setShowAddPOModal] = useState(false);
  const [poCategory, setPOCategory] = useState<SingleValue>(undefined);
  const [arrivalTimeFilters, setArrivalTimeFilters] = useState<DropdownOption[]>([]);
  const [poTypes, setPOTypes] = useState<DropdownOption[]>([]);
  const [commodityTypes, setCommodityTypes] = useState<DropdownOption[]>([]);
  const [poStatuses, setPOStatuses] = useState<DropdownOption[]>([]);
  const [poData, setPOData] = useState<PODashboardDividerRowType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('');
  const [direction, setDirection] = useState<string>('ASC');
  const [totalPage, setTotalPage] = useState<number>(0);
  const [downloadWithFilters, setDownloadWithFilters] = useState<boolean>(true);
  const [openExportDropdown, setOpenExportDropdown] = useState<boolean>(false);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [, setIsPDFDownloading] = useState<boolean>(false);
  const [exportType, setExportType] = useState<DownloadFileType>('');

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();

  /* Queries */
  const { configsData: PO_TYPES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: COMMON,
    configCd: PO_TYPE,
  });
  const { configsData: COMMODITY_TYPES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: COMMON,
    configCd: COMMODITY_TYPE,
  });
  const { configsData: PO_STATUES } = useGetFacilityConfig({
    domainCd: INBOUND,
    subDomainCd: RECEIVING,
    configCd: INBOUND_ORDER_STATUS,
  });

  /**
   * searchPage: Current Page, Page Size, SortBy, Direction (ASC, DESC)
   * searchCriteria:
   *   userAssignState: 'BOTH', 'ASSIGNED', 'UNASSIGNED'
   *   arrivalTimestamps: [{ startTime, endTime }]
   *   orderTypes: LTD, PO, ...
   *   commodityTypes: AO, ...
   *   orderStatus: 'NOT_STARTED', 'READY_FOR_SIGNATURE', ...
   * hooks last 2 parameters isEnabled & isDelay (for debounce), so it need to be passed here
   */
  const {
    trailerOrdersData,
    isLoading,
    isFetching: isFetchingTableData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: currentPage - 1,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(sortBy ? sortBy : DEFAULT_SORT_BY),
        direction: direction.toUpperCase(),
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          orderTypeCds: poTypes.map((item) => item.value.toUpperCase()),
          statusCds: poStatuses.map((item) => item.value),
          user: {
            userStatusCds: [!poCategory ? 'BOTH' : poCategory.value],
          },
        },
        trailerOrder: {
          arrivalTimeStamps: arrivalTimeFilters.map(({ value }) => {
            const index = ARRIVAL_TIMES.findIndex((item) => item.value === value);
            const datetime = new Date().setSeconds(0) - index * 86400000;
            return {
              startTime:
                index < ARRIVAL_TIMES.length - 1
                  ? toUTCString(new Date(datetime - 86400000))
                  : toUTCString(new Date(2020, 1, 1)),
              endTime: toUTCString(new Date(datetime)),
            };
          }),
          commodityTypeCds: commodityTypes.map((item) => item.value),
          statusCds:
            poStatuses.findIndex((item) => item.value === TRAILER_ORDER_STATUS.FINALIZED) === -1
              ? [TRAILER_ORDER_STATUS_CDS.OPEN]
              : [TRAILER_ORDER_STATUS_CDS.OPEN, TRAILER_ORDER_STATUS_CDS.CLOSED],
        },
      },
    },
    true,
    true,
    true
  );

  const {
    trailerOrdersData: trailerOrdersSearchData,
    isLoading: isSearchLoading,
    refetch: refetchSearchData,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE - 1,
        pageSize: PAGE_SIZE,
        sortBy: mapUItoDBField(DEFAULT_SORT_BY),
        direction: ASC,
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: searchValue,
        },
        trailerOrder: {
          statusCds: [TRAILER_ORDER_STATUS_CDS.OPEN],
        },
      },
    },
    false
  );

  const {
    trailerOrdersData: downloadTrailerOrders,
    refetch: fetchDownloadOrders,
    isFetching,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: 0,
        pageSize: trailerOrdersData ? trailerOrdersData?.totalElements : 0,
        sortBy: mapUItoDBField(sortBy ? sortBy : DEFAULT_SORT_BY),
        direction: direction.toUpperCase(),
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          orderTypeCds: poTypes.map((item) => item.value.toUpperCase()),
          statusCds: poStatuses.map((item) => item.value),
          user: {
            userStatusCds: [!poCategory ? 'BOTH' : poCategory.value],
          },
        },
        trailerOrder: {
          arrivalTimeStamps: arrivalTimeFilters.map(({ value }) => {
            const index = ARRIVAL_TIMES.findIndex((item) => item.value === value);
            const datetime = new Date().setSeconds(0) - index * 86400000;
            return {
              startTime:
                index < ARRIVAL_TIMES.length - 1
                  ? toUTCString(new Date(datetime - 86400000))
                  : toUTCString(new Date(2020, 1, 1)),
              endTime: toUTCString(new Date(datetime)),
            };
          }),
          commodityTypeCds: commodityTypes.map((item) => item.value),
          statusCds:
            poStatuses.findIndex((item) => item.value === TRAILER_ORDER_STATUS.FINALIZED) === -1
              ? [TRAILER_ORDER_STATUS_CDS.OPEN]
              : [TRAILER_ORDER_STATUS_CDS.OPEN, TRAILER_ORDER_STATUS_CDS.CLOSED],
        },
      },
    },
    false,
    false,
    downloadWithFilters
  );

  /* Functions */
  const handleItemSearch = (value: string) => {
    setSearchValue(value);

    if (value?.length === 8) {
      setTimeout(() => {
        refetchSearchData(); // fake the delay to set state
      }, 1);
    }
  };

  const onListKanbanToggle = (selectedView: string) => {
    setCurrentView(selectedView);
  };

  const onCloseAddPOModal = () => {
    setShowAddPOModal(false);
  };

  const handlePOCategoryFilterChange = (value: SingleValue) => {
    setCurrentPage(1);
    setPOCategory(value);
  };

  const handleArrivalFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setArrivalTimeFilters(values);
  };

  const handlePOTypeFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setPOTypes(values);
  };

  const handleCommodityTypeFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setCommodityTypes(values);
  };

  const handleStatusFilterChange = (values: DropdownOption[]) => {
    setCurrentPage(1);
    setPOStatuses(values);
  };

  const isFiltersApplied = () => {
    return Boolean(
      poCategory ||
        arrivalTimeFilters?.length ||
        poTypes?.length ||
        commodityTypes?.length ||
        poStatuses?.length
    );
  };

  const onClearAll = () => {
    handlePOCategoryFilterChange(undefined);
    handleArrivalFilterChange([]);
    handlePOTypeFilterChange([]);
    handleCommodityTypeFilterChange([]);
    handleStatusFilterChange([]);
  };

  const onRemoveFilter = (
    filters: DropdownOption[],
    handleFilterChange: (values: DropdownOption[]) => void,
    index: number
  ) => {
    const result = [...filters];
    result.splice(index, 1);
    handleFilterChange(result);
  };

  const onActionMenuClick = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    sourceOrderNumber: string,
    receiptId: string,
    action: string
  ) => {
    event.stopPropagation();

    if (action === POActions.VIEW_DETAILS) {
      navigate(PAGE_URLS.PO_DETAILS(sourceOrderNumber, receiptId));
    } else if (action === POActions.EDIT_DETAILS) {
      navigate(PAGE_URLS.PO_EDIT(sourceOrderNumber, receiptId));
    } else if (action === POActions.PUT_ON_HOLD) {
      navigate(PAGE_URLS.PO_PUT_ON_HOLD(sourceOrderNumber, receiptId));
    } else if (action === POActions.REMOVE) {
      navigate(PAGE_URLS.PO_REMOVE(sourceOrderNumber, receiptId));
    }
  };

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    // Split row id to get source order number & trailer in id
    const ids = clickedRow.id.split('-');

    const sourceOrderNumber = ids[0];
    const receiptId = ids[1];

    navigate(PAGE_URLS.PO_DETAILS(sourceOrderNumber, receiptId));
  };

  const addArrivalTimeDividers = useCallback(
    (trailerOrders: PODashboardDataRowType[]) => {
      const data = [...trailerOrders];
      const currentTime = new Date();
      const grouped: Record<string, Array<PODashboardDataRowType>> = {};
      data.forEach((object) => {
        const timeDifference = currentTime.getTime() - object.arrivalTime.getTime();
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        let group;
        if (object.priority > 0) {
          group = 'top';
        } else if (hoursDifference <= 24) {
          group = '24 Hours';
        } else if (hoursDifference <= 48) {
          group = '48 Hours';
        } else if (hoursDifference <= 72) {
          group = '72 Hours';
        } else {
          group = '72+ Hours';
        }
        if (!grouped[group]) {
          grouped[group] = [];
        }
        grouped[group].push(object);
      });
      let dataWithDivider: PODashboardDividerRowType[] = Object.keys(grouped)
        .sort((a, b) => -a.localeCompare(b))
        .map((key) => {
          let items: PODashboardDividerRowType[] = grouped[key].map((item) => ({
            dividerLabel: undefined,
            ...item,
            priority: item.priority ? item.priority > 0 : undefined,
          }));
          items = sortBy || key === 'top' ? [...items] : [{ ...DIVIDER_LABELS[key] }, ...items];
          return items;
        })
        .flat();
      if (sortBy !== '' && sortBy !== 'arrivalTime') {
        dataWithDivider = trailerOrders.map((item) => ({
          dividerLabel: undefined,
          ...item,
          priority: item.priority ? item.priority > 0 : undefined,
        }));
      }
      setPOData(dataWithDivider);
    },
    [sortBy]
  );

  const generateOrdersCSVdata = (inputData: PODashboardDataRowType[]) => {
    const Headers = [
      'Priority',
      'Arrival Time',
      'Vendor',
      'PO',
      'Door',
      'Location',
      'Lines',
      'Pieces',
      'Commodity',
      'PO Type',
      'SKUs withFPS',
      'Status',
    ];

    const CSVData = [];
    CSVData.push(Headers);
    inputData.forEach((order) => {
      CSVData.push([
        order.priority > 0 ? PRIORITY.HIGH_PRIORITY : PRIORITY.LOW_PRIORITY,
        generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
        order.vendorName || EMPTY_VALUE,
        order.poNumber,
        order.door || EMPTY_VALUE,
        order.location || EMPTY_VALUE,
        `${order.lines}/${order.totalLines}`,
        `${order.pieces}/${order.totalPices}`,
        order.commodity,
        order.poType,
        calculatePercentageWithSign(order.skusWithFPS, order.totalSkusWithFPS) || EMPTY_VALUE,
        order.status,
      ]);
    });

    return CSVData;
  };

  const toggleExportDropDown = () => setOpenExportDropdown(!openExportDropdown);

  const toggleDownloadFilter = () => setDownloadWithFilters(!downloadWithFilters);

  const handleDownload = (type: DownloadFileType) => {
    setIsExportInProgress(true);
    setExportType(type);
    fetchDownloadOrders();
    toggleExportDropDown();
  };

  const downloadPdf = async (ordersData: TrailerOrderListType[]) => {
    setIsPDFDownloading(true);
    const currentDate = new Date();
    try {
      const pdfBlob = await pdf(
        <PODashBoardExport data={generateOrdersCSVdata(mapTrailerOrders(ordersData))} />
      ).toBlob();
      handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Export'));
      saveAs(
        pdfBlob,
        `purchase_orders_${generateDateString(
          currentDate,
          t('DateFormat.CreateOrderTime').replace(/ /g, '_')
        )}.pdf`
      );
    } catch (err) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Action.Export', { item: 'orders' }));
    } finally {
      setIsExportInProgress(false);
      setIsPDFDownloading(false);
      setExportType('');
    }
  };

  /*  Hooks */
  useEffect(() => {
    if (trailerOrdersData) {
      addArrivalTimeDividers(mapTrailerOrders(trailerOrdersData.content));
      setTotalPage(Math.ceil(trailerOrdersData.totalElements / PAGE_SIZE));
    }
  }, [trailerOrdersData, isLoading, addArrivalTimeDividers, openExportDropdown]);

  useEffect(() => {
    if (trailerOrdersSearchData) {
      if (searchValue && searchValue?.length === 8) {
        if (!trailerOrdersSearchData.empty) {
          const trailerOrderData = trailerOrdersSearchData.content[0].trailerOrder;

          navigate(
            PAGE_URLS.PO_DETAILS(trailerOrderData.sourceOrderNbr, trailerOrderData.receiptId)
          );
        } else {
          handleNotification(
            NOTIFICATION_TYPES.ERROR,
            t('PODashboard.Notification.SearchTrailerOrder')
          );
        }
      }
    }
  }, [trailerOrdersSearchData, isSearchLoading, searchValue, handleNotification, t, navigate]);

  useEffect(() => {
    if (downloadTrailerOrders && !isFetching) {
      if (exportType == FILE_TYPES.CSV) {
        const CSVData = generateOrdersCSVdata(mapTrailerOrders(downloadTrailerOrders.content));
        let csvContent = '';
        const currentDate = new Date();
        CSVData.forEach((row) => {
          csvContent += row.map((element) => element.toString()).join(',') + '\n';
        });
        downloadGeneratedCSV(
          {
            fileName: `purchase_orders_${generateDateString(
              currentDate,
              t('DateFormat.CreateOrderTime').replace(/ /g, '_')
            )}`,
            data: csvContent,
          },
          'export-order-dashboard'
        );
        setIsExportInProgress(false);
        handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Export'));
        setExportType('');
      } else if (exportType == FILE_TYPES.PDF) {
        downloadPdf(downloadTrailerOrders.content);
      }
    }
  }, [downloadTrailerOrders, exportType, handleNotification, isFetching, t]);

  return (
    <>
      <View direction="column" height="100%" className={styles['po-dashboard']}>
        <MasterTitle
          title={t('PODashboard.Title')}
          // TODO: Add functionality to save page to favorites column
          titleActionProps={{
            label: 'Favorite',
            handleClick: () => {
              return;
            },
          }}
          statusBadgeProps={{
            variant: statusToBadgeVariant('COMPLETED'),
            text: t('PODashboard.Stats.HoursLeft', { count: 4 }),
          }}
          subtitle={
            <View direction="row" gap={4}>
              <View.Item>
                <Text color="600">{t('PODashboard.Stats.Shift', { shift: '2nd' })}</Text>
              </View.Item>
              <View.Item>
                <Text color="600">{t('PODashboard.Stats.Receivers', { count: 10 })}</Text>
              </View.Item>
            </View>
          }
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={5}>
              <POSearch
                isSearchLoading={isSearchLoading}
                label={t('PODashboard.Search.NonFinalizedPOSearch')}
                onItemSearch={handleItemSearch}
              />
            </View.Item>

            <View.Item>
              <Dropdown
                className={styles['po-dashboard__download_content']}
                padding={2}
                variant="ghost"
                open={openExportDropdown}
                width={300}
              >
                <Dropdown.Button
                  className={styles['po-dashboard__download_content__button']}
                  onClick={toggleExportDropDown}
                  disabled={isExportInProgress || isLoading}
                >
                  {isExportInProgress ? (
                    <Button loading={true} variant="ghost" />
                  ) : (
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text size="100" weight="bold">
                        {t('InboundDashboard.Export.Title')}
                      </Text>
                    </View>
                  )}
                </Dropdown.Button>
                <Dropdown.Content>
                  <View gap={2} padding={3}>
                    <View
                      direction="row"
                      align="center"
                      gap={2}
                      className={styles['po-dashboard__download_content__button-details']}
                      as="div"
                      attributes={{
                        onClick: () => handleDownload(FILE_TYPES.CSV),
                      }}
                    >
                      <Icon svg={Download} />
                      <Text size="075" weight="bold">
                        {t('InboundDashboard.Export.CSV')}
                      </Text>
                    </View>
                    <View
                      direction="row"
                      align="center"
                      gap={2}
                      className={styles['po-dashboard__download_content__button-details']}
                      as="div"
                      attributes={{
                        onClick: () => handleDownload(FILE_TYPES.PDF),
                      }}
                    >
                      <Icon svg={Download} />
                      <Text size="075" weight="bold">
                        {t('InboundDashboard.Export.PDF')}
                      </Text>
                    </View>
                    <View>
                      <Divider blank color="300" />
                    </View>
                    <View direction="row" gap={2} align="center">
                      <Checkbox
                        checked={downloadWithFilters}
                        label={t('InboundDashboard.Export.FilterTitle')}
                        value="Exportfilters"
                        name="Exportfilters"
                        onChange={() => toggleDownloadFilter()}
                      />
                    </View>
                  </View>
                </Dropdown.Content>
              </Dropdown>
            </View.Item>

            <View.Item>
              <Button size="large" onClick={() => setShowAddPOModal(true)}>
                <View direction="row" align="center" justify="center" gap={2}>
                  <Icon svg={Add} color="on-primary" />
                  <Text>{t('PODashboard.AddAPurchaseOrder')}</Text>
                </View>
              </Button>
            </View.Item>
          </View>
        </MasterTitle>

        <View
          direction="row"
          justify="center"
          align="center"
          padding={[4, 6]}
          backgroundColor="secondary"
          className={styles['po-dashboard__statistics-section']}
        >
          <View.Item grow>
            <View
              direction="row"
              className={styles['po-dashboard__statistics-section__statistics']}
            >
              <Stat title={t('PODashboard.Stats.TotalRemaining')} primaryText="76" width="160px" />
              <Stat title={t('PODashboard.Stats.RegularPOsRemaining')} primaryText="58" />
              <Stat title={t('PODashboard.Stats.DSDPOsRemaining')} primaryText="18" width="160px" />
              <Stat title={t('PODashboard.Stats.LTDPOsRemaining')} primaryText="0" width="160px" />
            </View>
          </View.Item>
          <View.Item>
            <View direction="row" align="center" gap={2}>
              <Icon svg={OrderHistory} />
              <Link onClick={() => navigate(PAGE_URLS.PO_HISTORY)}>PO History</Link>
            </View>
          </View.Item>
        </View>

        <View
          direction="row"
          padding={[4, 6]}
          backgroundColor="secondary"
          className={styles['po-dashboard__filters-section']}
        >
          <View.Item>
            <View justify="center" align="start">
              <ListKanbanToggle onToggle={onListKanbanToggle} />
            </View>
          </View.Item>
          <View.Item>
            <View width="240px">
              <Dropdown>
                <Dropdown.Button label={poCategory ? poCategory.label : 'All POs'} />
                <Dropdown.Content>
                  <View gap={1}>
                    {PO_CATEGORIES.map((category) => (
                      <Dropdown.Item
                        label={category.label}
                        onClick={() => handlePOCategoryFilterChange(category)}
                      />
                    ))}
                  </View>
                </Dropdown.Content>
              </Dropdown>
            </View>
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={200}
              name="arrivalTimeFilter"
              label="All Arrival Time"
              value={arrivalTimeFilters}
              options={ARRIVAL_TIMES}
              onChange={handleArrivalFilterChange}
              onReset={() => handleArrivalFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="poTypes"
              label="All PO Types"
              value={poTypes}
              options={
                PO_TYPES
                  ? PO_TYPES.map((type) => ({
                      label: `${type.configValue} (${type.description})`,
                      value: type.configValue,
                    }))
                  : []
              }
              onChange={handlePOTypeFilterChange}
              onReset={() => handlePOTypeFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="commodityTypes"
              label="All Commodity Types"
              searchable={true}
              value={commodityTypes}
              options={
                COMMODITY_TYPES
                  ? COMMODITY_TYPES.map((item) => ({
                      label: `${item.configValue} (${item.description})`,
                      value: item.configValue,
                    }))
                  : []
              }
              onChange={handleCommodityTypeFilterChange}
              onReset={() => handleCommodityTypeFilterChange([])}
            />
          </View.Item>
          <View.Item>
            <DropdownSelectMenu
              width={320}
              name="poStatuses"
              label="All Statuses"
              value={poStatuses}
              options={
                PO_STATUES
                  ? PO_STATUES.map((status) => ({
                      label: `${status.description}`,
                      value: status.configValue,
                    }))
                  : []
              }
              onChange={handleStatusFilterChange}
              onReset={() => handleStatusFilterChange([])}
            />
          </View.Item>
          <View.Item
            attributes={{
              style: { height: '100%' },
            }}
          >
            <View height="100%" justify="center">
              <Actionable onClick={onClearAll} disabled={!isFiltersApplied()}>
                <Text
                  className={styles['po-dashboard__actionable-text']}
                  size="087"
                  weight="medium"
                  variant="text-link"
                >
                  {t('PODashboard.ClearAll')}
                </Text>
              </Actionable>
            </View>
          </View.Item>
        </View>

        <View direction="row" padding={[2, 6]} backgroundColor="secondary">
          {poCategory && (
            <View className={styles['po-dashboard__filter-item']}>
              <Text size="087">{poCategory.label}</Text>
              <Actionable onClick={() => handlePOCategoryFilterChange(undefined)}>
                <Icon svg={Close} size={4} />
              </Actionable>
            </View>
          )}
          {arrivalTimeFilters.length > 0 &&
            arrivalTimeFilters.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(arrivalTimeFilters, handleArrivalFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {poTypes.length > 0 &&
            poTypes.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(poTypes, handlePOTypeFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {commodityTypes.length > 0 &&
            commodityTypes.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(commodityTypes, handleCommodityTypeFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
          {poStatuses.length > 0 &&
            poStatuses.map((filter, index) => (
              <View className={styles['po-dashboard__filter-item']}>
                <Text size="087">{filter.label}</Text>
                <Actionable
                  onClick={() => {
                    onRemoveFilter(poStatuses, handleStatusFilterChange, index);
                  }}
                >
                  <Icon svg={Close} size={4} />
                </Actionable>
              </View>
            ))}
        </View>

        {currentView === LIST && (
          <View padding={[4, 6]}>
            <Table
              columns={PO_DASHBOARD_TABLE_COLUMNS}
              // rows={mapPODashboardTableRows(poData, onActionMenuClick)}
              rows={mapPODashboardTableAgeBreakdownRows(poData, onActionMenuClick)}
              isPaginated={true}
              isCheckboxDisabled={false}
              isApiLoadedData={true}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              isLoading={isFetchingTableData}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={totalPage}
              // TODO: implement sorting from the BFF
              onPageChange={(_pageNumber) => {
                setCurrentPage(_pageNumber);
              }}
              onSort={(_sorting, _columnId) => {
                _sorting.forEach((sorting) => {
                  if (sorting.id === _columnId) {
                    setSortBy(_columnId);
                    setDirection(sorting.direction);
                  }
                });
              }}
              onRowAction={(clickedRow) => handleTableRowClick(clickedRow)}
            />
          </View>
        )}

        {currentView === KANBAN && (
          <View padding={[4, 6]} align="center">
            <Text size="250">{t('KanbanView')}</Text>
          </View>
        )}
      </View>

      <AddPOModal isOpen={showAddPOModal} onClose={onCloseAddPOModal} />

      <a id="export-order-dashboard" style={{ display: 'none' }} />
    </>
  );
};
