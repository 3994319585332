/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const FacilityConfigSchema = z.array(
  z.object({
    configKey: z.string(),
    orgId: z.string(),
    facilityId: z.string(),
    domainCd: z.string(),
    subDomainCd: z.string(),
    configCd: z.string(),
    configValue: z.string(),
    configValueDataType: z.string(),
    locale: z.string(),
    description: z.string(),
    type: z.string(),
  })
);
