/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import dayjs from 'dayjs';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  Button,
  Divider,
  FormControl,
  Icon,
  Modal,
  Select,
  Text,
  TextArea,
  TextField,
  View,
  classNames,
} from '@az/starc-ui';
import { Close, HourglassLine, Search } from '@az/starc-ui-icons';

import {
  COMMODITY_TYPE,
  COMMON,
  INBOUND,
  MAX_TEXTAREA_LENGTH,
  NOTIFICATION_TYPES,
} from '@shared/constants/constants';
import { TextAreaCounter } from '@shared/components/TextAreaCounter/TextAreaCounter';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig';

import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';
import { formatErrorLog, getFormInputError, isButtonDisabled } from '@ofm/utils/utils';

import { PriorityToggle } from '@inbound/components/PriorityToggle/PriorityToggle';

import { Lengths, NOT_STARTED, ORDER_LOCATION_TYPE_CD } from '@inbound/constants/constants';
import { LOCATION_TYPES } from '@inbound/constants/dataConstants';

import { useGetInboundOrderBySourceOrderNumber } from '@inbound/services/hooks/useGetInboundOrderBySourceOrderNumber';
import { useCreateTrailerArrival } from '@inbound/services/hooks/useCreateTrailerArrival';

import { TrailerArrivalSchema } from '@inbound/schemas/trailerArrivalSchema';

import { FacilityConfigType, TrailerArrivalType } from '@inbound/types/types';
import {
  getFormAlphaNumericInputError,
  getFormAlphaNumericInputInvalidError,
} from '@inbound/utils/utils';

import * as T from './AddPOModal.types';
import s from './AddPOModal.module.scss';

export const AddPOModal = ({ isOpen, onClose }: T.Props) => {
  /* State variables */
  const [isValidPONumber, setIsValidPONumber] = useState<boolean>(false);
  const [commodityData, setCommodityData] = useState<FacilityConfigType>([]);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<TrailerArrivalType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(TrailerArrivalSchema),
  });

  const sourceOrderNumber = watch('trailerOrders.sourceOrderNbr');

  /* Queries */
  const { isLoading, mutateCreateaTrailerArrival } = useCreateTrailerArrival();
  const {
    inboundOrderData,
    isError: isGetOrderError,
    isLoading: isOrderLoading,
    refetch,
  } = useGetInboundOrderBySourceOrderNumber(
    {
      sourceOrderNumber: sourceOrderNumber,
    },
    false
  );

  const { configsData } = useGetFacilityConfig(
    {
      domainCd: INBOUND,
      subDomainCd: COMMON,
      configCd: COMMODITY_TYPE,
    },
    isOpen
  );

  /* Functions */
  const resetForm = () => {
    reset();
    clearErrors();
    onClose();
  };

  const onModalClose = () => {
    resetForm();
  };

  const onSubmit = async (data: TrailerArrivalType) => {
    const trailerArrivalResult = TrailerArrivalSchema.safeParse(data);

    if (!trailerArrivalResult.success) {
      throw new LogError(formatErrorLog(ErrorLogType.ZOD, trailerArrivalResult.error));
    } else {
      const payload = {
        ...data,
        trailerArrivalTs: dayjs(new Date()).toISOString(),
        contactEmail: null,
        trailerOrders: [
          {
            ...data.trailerOrders,
            inboundOrder: {
              ...data.trailerOrders.inboundOrder,
              statusCd: NOT_STARTED,
              priority: data.priority ? 1 : 0,
              orderLocationId: data.trailerOrders.inboundOrder?.orderLocationId?.toUpperCase(),
              orderLocationTypeCd: ORDER_LOCATION_TYPE_CD,
            },
          },
        ],
      };

      // As we have priority toggle, we used boolean priority to complete UI functionality,
      // which is not required in API call, as API have number type priority, so removed it.
      delete payload.priority;

      mutateCreateaTrailerArrival(
        { trailerArrivalRequest: payload },
        {
          onSuccess: () => {
            resetForm();
            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('PODashboard.Notification.CreateTrilerArrival.Success', {
                poNumber: data.trailerOrders.sourceOrderNbr,
              })
            );
          },
        }
      );
    }
  };

  /* Hooks */
  useEffect(() => {
    if (configsData) {
      setCommodityData(configsData);
    }
  }, [configsData]);

  useEffect(() => {
    if (sourceOrderNumber?.length === Lengths.SOURCE_ORDER_NUMBER) {
      refetch();
    } else {
      setIsValidPONumber(false);
    }
  }, [refetch, sourceOrderNumber]);

  useEffect(() => {
    if (isOrderLoading) {
      setIsValidPONumber(false);
    } else if (isGetOrderError) {
      setIsValidPONumber(true);
    } else {
      if (inboundOrderData) {
        if (inboundOrderData.totalPages) {
          setIsValidPONumber(false);
        } else {
          setIsValidPONumber(true);
        }
      }
    }
  }, [isOrderLoading, isGetOrderError, inboundOrderData]);

  return (
    <Modal
      open={isOpen}
      onClose={() => onModalClose()}
      className={s['add-po-modal']}
      closeByClickAway={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <View
          direction="row"
          justify="space-between"
          padding={6}
          className={s['add-po-modal__header']}
        >
          <View direction="column">
            <Text as="h2" size="175" weight="bold" color="primary">
              {t('PODashboard.AddPurchaseOrder')}
            </Text>
          </View>
          <Button
            variant="ghost"
            onClick={!isLoading ? onModalClose : undefined}
            className={classNames(s['close-icon'], s['add-po-modal__header__close-button'])}
          >
            <Icon svg={Close} />
          </Button>
        </View>

        <View direction="column" className={s['add-po-modal__body']}>
          <View.Item>
            <View direction="column" className={s['add-po-modal__body__section']}>
              <View.Item>
                <Text weight="bold" size="100" className={s['add-po-modal__body__section__title']}>
                  {t('PODashboard.PODetails')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="column" className={s['add-po-modal__body__section__item']}>
                  <Text
                    weight="medium"
                    size="087"
                    className={s['add-po-modal__body__form-field-label--required']}
                  >
                    {t('PODashboard.PONumber')}
                  </Text>
                  <FormControl
                    hasError={!!errors?.trailerOrders?.sourceOrderNbr || isValidPONumber}
                  >
                    <TextField
                      defaultValue=""
                      inputAttributes={{
                        placeholder: t('PODashboard.AddPO.EnterPONumber'),
                        ...register('trailerOrders.sourceOrderNbr'),
                        maxLength: Lengths.SOURCE_ORDER_NUMBER,
                      }}
                      endElement={
                        <Icon
                          svg={isOrderLoading ? HourglassLine : Search}
                          color="gray-500"
                          attributes={{ style: { marginLeft: 'var(--st-unit-3)' } }}
                        />
                      }
                    />

                    <FormControl.Error
                      className={s['add-po-modal__body__form-field-error-messages']}
                    >
                      {t('PODashboard.AddPO.InvalidPONumber')}
                    </FormControl.Error>
                  </FormControl>
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View padding={[5, 0]}>
              <Divider color="300" />
            </View>
          </View.Item>

          <View.Item>
            <View direction="column" className={s['add-po-modal__body__section']}>
              <View.Item>
                <Text weight="bold" size="100" className={s['add-po-modal__body__section__title']}>
                  {t('PODashboard.LocationDetails')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="row" gap={3}>
                  <View.Item columns={6}>
                    <View direction="column" className={s['add-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['add-po-modal__body__form-field-label']}
                      >
                        {t('PODashboard.CarrierName')}
                      </Text>
                      <FormControl hasError={!!errors.trailerCarrierName}>
                        <TextField
                          defaultValue=""
                          inputAttributes={{
                            placeholder: t('PODashboard.AddPO.EnterCarrierName'),
                            ...register('trailerCarrierName'),
                            maxLength: Lengths.MAX_CARRIER_NAME,
                          }}
                        />
                        {errors.trailerCarrierName && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['add-po-modal__body__form-field-error-messages']}
                            >
                              {getFormAlphaNumericInputInvalidError(errors.trailerCarrierName.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>

                  <View.Item columns={6}>
                    <View direction="column" className={s['add-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['add-po-modal__body__form-field-label']}
                      >
                        {t('PODashboard.TrailerNumber')}
                      </Text>
                      <FormControl hasError={!!errors.trailerNbr}>
                        <TextField
                          defaultValue=""
                          inputAttributes={{
                            placeholder: t('PODashboard.AddPO.EnterTrailerNumber'),
                            ...register('trailerNbr'),
                            maxLength: Lengths.MAX_TRAILER_NUMBER,
                          }}
                        />
                        {errors.trailerNbr && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['add-po-modal__body__form-field-error-messages']}
                            >
                              {getFormAlphaNumericInputInvalidError(errors.trailerNbr.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </View>
              </View.Item>

              <View.Item>
                <View direction="row" gap={3}>
                  <View.Item columns={6}>
                    <View direction="column" className={s['add-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['add-po-modal__body__form-field-label--required']}
                      >
                        {t('PODashboard.LocationType')}
                      </Text>
                      <FormControl hasError={!!errors.trailerLocationTypeCd}>
                        <Controller
                          control={control}
                          name="trailerLocationTypeCd"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              label={t('PODashboard.LocationType')}
                              placeholder={t('PODashboard.AddPO.SelectLocationType')}
                              name="trailerLocationTypeCd"
                              variant="no-label"
                              options={LOCATION_TYPES.map((locationOption) => ({
                                label: locationOption.label,
                                value: locationOption.value,
                              }))}
                              required
                              value={{ label: value, value }}
                              inputAttributes={{ onBlur }}
                              onValueChange={(trailerLocationTypeCd) => {
                                setValue('trailerLocationId', '');
                                onChange(trailerLocationTypeCd?.value);
                              }}
                            />
                          )}
                        />
                        {errors.trailerLocationTypeCd && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['add-po-modal__body__form-field-error-messages']}
                            >
                              {getFormInputError(errors.trailerLocationTypeCd.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>

                  <View.Item columns={6}>
                    <View direction="column" className={s['add-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['add-po-modal__body__form-field-label--required']}
                      >
                        {t('PODashboard.Location')}
                      </Text>

                      <FormControl hasError={!!errors.trailerLocationId}>
                        <TextField
                          defaultValue=""
                          inputAttributes={{
                            placeholder: t('PODashboard.AddPO.EnterLocation'),
                            ...register('trailerLocationId'),
                            maxLength: Lengths.MAX_LOCATION,
                          }}
                        />

                        {errors.trailerLocationId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['add-po-modal__body__form-field-error-messages']}
                            >
                              {getFormAlphaNumericInputInvalidError(errors.trailerLocationId.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View padding={[5, 0]}>
              <Divider color="300" />
            </View>
          </View.Item>

          <View.Item>
            <View direction="column" className={s['add-po-modal__body__section']}>
              <View.Item>
                <Text weight="bold" size="100" className={s['add-po-modal__body__section__title']}>
                  {t('PODashboard.CommodityAndReceivingDoor')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="row" gap={3}>
                  <View.Item columns={6}>
                    <View direction="column" className={s['add-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['add-po-modal__body__form-field-label--required']}
                      >
                        {t('PODashboard.Commodity')}
                      </Text>
                      <FormControl hasError={!!errors.trailerOrders?.commodityTypeCd}>
                        <Controller
                          control={control}
                          name="trailerOrders.commodityTypeCd"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              label={t('PODashboard.Commodity')}
                              placeholder={t('PODashboard.AddPO.SelectCommodity')}
                              name="trailerOrders.commodityTypeCd"
                              variant="no-label"
                              options={commodityData.map((commodityOption) => ({
                                label: `${commodityOption.configValue} (${commodityOption.description})`,
                                value: commodityOption.configValue,
                              }))}
                              value={{ label: value, value }}
                              inputAttributes={{ onBlur }}
                              onValueChange={(commodityItem) => onChange(commodityItem?.value)}
                            />
                          )}
                        />
                        {errors.trailerOrders?.commodityTypeCd && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['add-po-modal__body__form-field-error-messages']}
                            >
                              {getFormInputError(errors.trailerOrders.commodityTypeCd.type)}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>

                  <View.Item columns={6}>
                    <View direction="column" className={s['add-po-modal__body__section__item']}>
                      <Text
                        weight="medium"
                        size="087"
                        className={s['add-po-modal__body__form-field-label']}
                      >
                        {t('PODashboard.ReceivingDoor')}
                      </Text>
                      <FormControl hasError={!!errors.trailerOrders?.inboundOrder?.orderLocationId}>
                        <TextField
                          defaultValue=""
                          inputAttributes={{
                            placeholder: t('PODashboard.AddPO.EnterReceivingDoor'),
                            ...register('trailerOrders.inboundOrder.orderLocationId'),
                            maxLength: Lengths.RECEIVING_DOOR,
                          }}
                          inputClassName={s['add-po-modal__body__form-field-uppercase']}
                        />
                        {errors.trailerOrders?.inboundOrder?.orderLocationId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['add-po-modal__body__form-field-error-messages']}
                            >
                              {getFormAlphaNumericInputError(
                                errors.trailerOrders.inboundOrder.orderLocationId.type,
                                Lengths.RECEIVING_DOOR
                              )}
                            </FormControl.Error>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View padding={[5, 0]}>
              <Divider color="300" />
            </View>
          </View.Item>

          <View.Item>
            <View direction="column" className={s['add-po-modal__body__section']}>
              <View.Item>
                <Text weight="bold" size="100" className={s['add-po-modal__body__section__title']}>
                  {t('PODashboard.Priority')}
                </Text>
              </View.Item>

              <View.Item>
                <View direction="column" className={s['add-po-modal__body__section__item']}>
                  <FormControl hasError={!!errors.priority}>
                    <Controller
                      control={control}
                      name="priority"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View direction="row" gap={4}>
                          <PriorityToggle
                            name="priority"
                            checked={value ? true : false}
                            inputAttributes={{ onBlur }}
                            onValueChange={(priorityValue) => onChange(priorityValue)}
                          />
                          <View justify="center" padding={[2, 0]}>
                            <Text weight="medium" size="087">
                              {t('PODashboard.HighPriorityPurchaseOrder')}
                            </Text>
                          </View>
                        </View>
                      )}
                    />

                    {errors.priority && (
                      <View direction="row" justify="space-between">
                        <FormControl.Error>
                          {getFormInputError(errors.priority.type)}
                        </FormControl.Error>
                      </View>
                    )}
                  </FormControl>
                </View>
              </View.Item>

              <View.Item>
                <View direction="column" className={s['add-po-modal__body__section__item']}>
                  <Text
                    weight="medium"
                    size="087"
                    className={s['add-po-modal__body__form-field-label']}
                  >
                    {t('PODashboard.ReasonForAddingPO')}
                  </Text>
                  <FormControl hasError={!!errors.trailerOrders?.comment}>
                    <TextArea
                      variant="alt"
                      resize="vertical"
                      id="add-po-reason"
                      label={t('ConfirmationComment.Placeholder')}
                      defaultValue=""
                      inputAttributes={{
                        ...register('trailerOrders.comment'),
                        maxLength: MAX_TEXTAREA_LENGTH,
                      }}
                    />

                    <View
                      direction="row"
                      justify={errors.trailerOrders?.comment ? 'space-between' : 'end'}
                    >
                      {errors.trailerOrders?.comment && (
                        <FormControl.Error
                          className={s['remove-po-modal__body__form-field-error-messages']}
                        >
                          {getFormInputError(
                            errors.trailerOrders.comment?.type,
                            MAX_TEXTAREA_LENGTH
                          )}
                        </FormControl.Error>
                      )}

                      {watch('trailerOrders.comment')?.length && (
                        <TextAreaCounter
                          count={watch('trailerOrders.comment')?.length}
                          maxCount={MAX_TEXTAREA_LENGTH}
                        />
                      )}
                    </View>
                  </FormControl>
                </View>
              </View.Item>
            </View>
          </View.Item>
        </View>

        <View padding={6} className={s['add-po-modal__footer']}>
          <View
            width="100%"
            direction="row"
            justify="end"
            className={s['add-po-modal__footer__actions']}
          >
            <Button
              variant="secondary"
              attributes={{ style: { width: 'fit-content' } }}
              onClick={onModalClose}
              disabled={isLoading}
            >
              <Text>{t('Cancel')}</Text>
            </Button>

            <Button
              variant="primary"
              attributes={{ style: { width: 'fit-content' } }}
              type="submit"
              disabled={
                isButtonDisabled(watch('trailerOrders.sourceOrderNbr')) ||
                isButtonDisabled(watch('trailerLocationTypeCd')) ||
                isButtonDisabled(watch('trailerLocationId')) ||
                isButtonDisabled(watch('trailerOrders.commodityTypeCd')) ||
                sourceOrderNumber?.length !== Lengths.SOURCE_ORDER_NUMBER ||
                isValidPONumber ||
                isOrderLoading
              }
              loading={isLoading}
            >
              <Text> {t('PODashboard.AddPurchaseOrder')}</Text>
            </Button>
          </View>
        </View>
      </form>
    </Modal>
  );
};
