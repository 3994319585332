/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, ProgressBar } from '@az/starc-ui';
import { AvatarGroup } from '@shared/components/AvatarGroup/AvatarGroup';
import { EMPTY_VALUE } from '@shared/constants/constants';
import {
  InboundShiftGoalDetailsType,
  InboundShiftGoalMappedTableDetailsType,
} from '@taskManagement/types/types';
import dayjs from 'dayjs';
/**
 * @param rows: The rows to populate the table, that are raw data.
 * @returns Rows mapped to the Table STARC format and specific for the Store Details Table
 */

//TODO : Update parameter types during integration process

export const mapShiftHistoryTableRows = (rows: InboundShiftGoalDetailsType[]) => {
  const mapedRows = mergeObjectsByIdAndDate(rows);
  const row = mapedRows.slice(0, 4).map((shift) => ({
    id: shift.shiftIndex.toString(),
    cells: [
      {
        value: (
          <Text>
            {shift.date} - {shift.shiftId}
          </Text>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <ProgressBar
                max={shift.totalGoals}
                value={shift.goalsCompleted}
                label=""
                valueColor="primary"
                attributes={{ style: { height: '8px' } }}
              />
            </View>

            <View>
              <Text>
                {shift.goalsCompleted}/{shift.totalGoals}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.shiftPercentage} %</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift['PO-goalAchieved'] ? shift['PO-goalAchieved'] : 0} /{' '}
                {shift['PO-goalDefined'] ? shift['PO-goalDefined'] : 0}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift['PO-percentage'] ? shift['PO-percentage'] : 0}%</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift['DSD-goalAchieved'] ? shift['DSD-goalAchieved'] : 0} /{' '}
                {shift['DSD-goalDefined'] ? shift['DSD-goalDefined'] : 0}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift['DSD-percentage'] ? shift['DSD-percentage'] : 0}%</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift['LTD-goalAchieved'] ? shift['LTD-goalAchieved'] : 0} /{' '}
                {shift['LTD-goalDefined'] ? shift['LTD-goalDefined'] : 0}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift['LTD-percentage'] ? shift['LTD-percentage'] : 0}%</Text>
            </View>
          </View>
        ),
      },
    ],
  }));

  return row;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapInventoryShiftHistoryTableRows = (rows: any[]) => {
  const row = rows.map((shift) => ({
    id: shift.shiftName,
    cells: [
      {
        value: <Text>{shift.shiftName}</Text>,
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <ProgressBar
                max={shift.totalGoals}
                value={shift.goalsCompleted}
                label=""
                valueColor="primary"
                attributes={{ style: { height: '8px' } }}
              />
            </View>

            <View>
              <Text>
                {shift.goalsCompleted}/{shift.totalGoals}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.shiftPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift.totesPicked}/{shift.totalTotes}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.totesPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift.palletPicked}/{shift.totalPallet}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.palletPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift.dsdTotesPicked}/{shift.totalDsdTotes}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.dsdTotesPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift.dsdPalletPicked}/{shift.totalDsdPallet}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.dsdPalletPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <Text>
                {shift.returnsPicked}/{shift.totalReturns}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.retrunsPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value:
          (shift.assignees.length && (
            <View>
              <AvatarGroup users={shift.assignees} size="small" variant="assignee" maxDisplay={3} />
            </View>
          )) ||
          EMPTY_VALUE,
      },
    ],
  }));

  return row;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapOutboundShiftHistoryTableRows = (rows: any[]) => {
  const row = rows.map((shift) => ({
    id: shift.shiftName,
    cells: [
      {
        value: <Text>{shift.shiftName}</Text>,
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <ProgressBar
                max={shift.totalStores}
                value={shift.storesCompleted}
                label=""
                valueColor="primary"
                attributes={{ style: { height: '8px' } }}
              />
            </View>

            <View>
              <Text>
                {shift.storesCompleted}/{shift.totalStores}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.storesPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value: (
          <View direction="row" align="center" wrap={false} gap={2}>
            <View>
              <ProgressBar
                max={shift.totalPieces}
                value={shift.piecesCompleted}
                label=""
                valueColor="primary"
                attributes={{ style: { height: '8px' } }}
              />
            </View>

            <View>
              <Text>
                {shift.piecesCompleted}/{shift.totalPieces}
              </Text>
            </View>

            <View
              padding={1}
              attributes={{ style: { backgroundColor: '#d6d6d6', borderRadius: '5px' } }}
            >
              <Text>{shift.piecesPercentage}</Text>
            </View>
          </View>
        ),
      },
      {
        value:
          (shift.assignees.length && (
            <View>
              <AvatarGroup users={shift.assignees} size="small" variant="assignee" maxDisplay={3} />
            </View>
          )) ||
          EMPTY_VALUE,
      },
    ],
  }));

  return row;
};

const mergeObjectsByIdAndDate = (
  rows: InboundShiftGoalDetailsType[]
): InboundShiftGoalMappedTableDetailsType[] => {
  const idDateMap = new Map();

  for (const obj of rows) {
    const id = obj.shiftId;
    const date = obj.date;
    const uniqueKey = `${id}_${date}`;

    if (id && date) {
      if (idDateMap.has(uniqueKey)) {
        // Merge properties
        Object.assign(idDateMap.get(uniqueKey), {
          [`${obj.metrics}${'-goalDefined'}`]: obj.goalDefined,
          [`${obj.metrics}${'-goalAchieved'}`]: obj.goalAchieved,
          [`${obj.metrics}${'-percentage'}`]: ((obj.goalAchieved / obj.goalDefined) * 100).toFixed(
            0
          ),
        });
      } else {
        // Add new object to the map

        const today = new Date().getDate();
        const otherDate = dayjs(date, 'DD-MM-YYYY').date();
        const isToday = today === otherDate;
        idDateMap.set(uniqueKey, {
          shiftId: obj.shiftId,
          shiftIndex: obj.shiftIndex,
          date: isToday ? 'Today' : obj.date,
          [`${obj.metrics}${'-goalDefined'}`]: obj.goalDefined ? obj.goalDefined : 0,
          [`${obj.metrics}${'-goalAchieved'}`]: obj.goalAchieved ? obj.goalAchieved : 0,
          [`${obj.metrics}${'-percentage'}`]: (
            ((obj.goalAchieved ? obj.goalAchieved : 0) / (obj.goalDefined ? obj.goalDefined : 0)) *
            100
          ).toFixed(0),
        });
      }
    }
  }

  // Extract merged objects
  const mergedObjects = Array.from(idDateMap.values());
  mergedObjects.forEach((obj) => {
    obj['totalGoals'] =
      (obj['PO-goalDefined'] ? obj['PO-goalDefined'] : 0) +
      (obj['DSD-goalDefined'] ? obj['DSD-goalDefined'] : 0) +
      (obj['LTD-goalDefined'] ? obj['LTD-goalDefined'] : 0);
    obj['goalsCompleted'] =
      (obj['PO-goalAchieved'] ? obj['PO-goalAchieved'] : 0) +
      (obj['DSD-goalAchieved'] ? obj['DSD-goalAchieved'] : 0) +
      (obj['LTD-goalAchieved'] ? obj['LTD-goalAchieved'] : 0);
    obj['shiftPercentage'] = ((obj.goalsCompleted / obj.totalGoals) * 100).toFixed(0);
  });
  return mergedObjects;
};

export const getPreviousShiftDetails = (
  rows: InboundShiftGoalDetailsType[]
): InboundShiftGoalMappedTableDetailsType => {
  const mergedObjects = mergeObjectsByIdAndDate(rows);
  return mergedObjects[0];
};
