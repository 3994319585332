/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Accordion, FormControl, Text, TextField, View } from '@az/starc-ui';
import styles from '@mdm/pages/RDM/ProductManager/Product/Detail/ProductDetail.module.scss';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { useFormContext } from 'react-hook-form';
import { WarehouseProductViewSchema } from '@shared/schemas/warehouseProductSchema.ts';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { getSlotProductQuantity } from '@mdm/utils/utils.ts';
import { SLOT_UNIT } from '@mdm/constants/constants.ts';

export const ProductVolumetrics = () => {
  /* Constants */
  type FormData = z.infer<typeof WarehouseProductViewSchema>;
  const { t } = useTranslation();
  const { register, getValues } = useFormContext<FormData>();

  /* Function */
  const getPhysicalAttributeFieldValue = (type: string, field: never) => {
    const physicalAttributeFields = getValues('physicalAttributes');
    const foundAttribute = (physicalAttributeFields || []).find(
      (field) => field.physicalAttribType?.toLowerCase() === type
    );

    return foundAttribute ? foundAttribute[field] : '0';
  };

  return (
    <View backgroundColor="secondary" padding={[0, 0, 6, 0]}>
      <Accordion
        className={styles['product-detail__accordion-wrapper']}
        headerOptions={{
          headerElement: (
            <View direction="row">
              <View.Item grow>
                <Text color="primary" size="125" weight="bold">
                  {t('ProductDetails.Volumetrics.Title')}
                </Text>
              </View.Item>
            </View>
          ),
        }}
      >
        <View padding={[0, 0, 12, 0]}>
          <View direction="row" gap={4}>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.UnitOfMeasure.Label')} />
              <FormControl>
                <TextField
                  defaultValue=""
                  attributes={{
                    'data-testid': 'product-volumetrics-unit-of-measure',
                  }}
                  inputAttributes={{
                    ...register('physicalPackingUnits.packSizeUomCd'),
                  }}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.Cost.Label')} />
              <FormControl>
                <TextField
                  defaultValue=""
                  attributes={{
                    'data-testid': 'product-volumetrics-cost',
                  }}
                  inputAttributes={{
                    ...register(`productFacilityAttributes.currentBaseCostAmt`),
                  }}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.EachesPerUnit.Label')} />
              <FormControl>
                <TextField
                  defaultValue=""
                  attributes={{
                    'data-testid': 'product-volumetrics-eaches-per-unit',
                  }}
                  inputAttributes={{
                    ...register(`physicalPackingUnits.unitPerCaseQty`),
                  }}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.UnitsPerTier.Label')} />
              <FormControl>
                <TextField
                  defaultValue=""
                  attributes={{
                    'data-testid': 'product-volumetrics-units-per-tier',
                  }}
                  inputAttributes={{
                    ...register(`physicalPackingUnits.unitPerTierQty`),
                  }}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.TierPerPallet.Label')} />
              <FormControl>
                <TextField
                  defaultValue=""
                  attributes={{
                    'data-testid': 'product-volumetrics-tier-per-pallet',
                  }}
                  inputAttributes={{
                    ...register(`physicalPackingUnits.tiersPerPalletQty`),
                  }}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.PalletQty.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-pallet-qty',
                  }}
                  value={getSlotProductQuantity(SLOT_UNIT.PALLET, getValues())?.toString() ?? ''}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
          </View>

          <View direction="row" gap={4} className={[styles['form__field-row']]}>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.EachLength.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-each-length',
                  }}
                  value={getPhysicalAttributeFieldValue('single', 'length' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.EachWidth.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-each-width',
                  }}
                  value={getPhysicalAttributeFieldValue('single', 'width' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.EachHeight.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-each-height',
                  }}
                  value={getPhysicalAttributeFieldValue('single', 'height' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.EachWeight.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-each-weight',
                  }}
                  value={getPhysicalAttributeFieldValue('single', 'weightQty' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
          </View>

          <View direction="row" gap={4} className={[styles['form__field-row']]}>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.InnerLength.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-inner-length',
                  }}
                  value={getPhysicalAttributeFieldValue('inner', 'length' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.InnerWidth.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-inner-width',
                  }}
                  value={getPhysicalAttributeFieldValue('inner', 'width' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.InnerHeight.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-inner-height',
                  }}
                  value={getPhysicalAttributeFieldValue('inner', 'height' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.InnerWeight.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-inner-weight',
                  }}
                  value={getPhysicalAttributeFieldValue('inner', 'weightQty' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.InnerQty.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-inner-qty',
                  }}
                  value={getSlotProductQuantity(SLOT_UNIT.INNER, getValues())?.toString() ?? ''}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
          </View>

          <View direction="row" gap={4} className={[styles['form__field-row']]}>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.OuterLength.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-outer-length',
                  }}
                  value={getPhysicalAttributeFieldValue('outer', 'length' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.OuterWidth.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-outer-width',
                  }}
                  value={getPhysicalAttributeFieldValue('outer', 'width' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.OuterHeight.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-outer-height',
                  }}
                  value={getPhysicalAttributeFieldValue('outer', 'height' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.OuterWeight.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-outer-weight',
                  }}
                  value={getPhysicalAttributeFieldValue('outer', 'weightQty' as never)}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
            <View.Item columns={{ s: 12, l: 2 }}>
              <FormLabel text={t('ProductDetails.OuterQty.Label')} />
              <FormControl>
                <TextField
                  attributes={{
                    'data-testid': 'product-volumetrics-outer-qty',
                  }}
                  value={getSlotProductQuantity(SLOT_UNIT.OUTER, getValues())?.toString() ?? ''}
                  disabled={true}
                />
              </FormControl>
            </View.Item>
          </View>
        </View>
      </Accordion>
    </View>
  );
};
