/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const InventoryAdjustmentsSchema = z.object({
  userId: z.string().optional().nullable(),
  counterUserId: z.string().optional().nullable(),
  recounterUserId: z.string().optional().nullable(),
  adjustmentCd: z.number().optional().nullable(),
  layoutDistinctName: z.string().optional().nullable(),
  layoutName: z.string().optional().nullable(),
  layoutType: z.string().optional().nullable(),
  parentLayoutDistinctName: z.string().optional().nullable(),
  parentLayoutName: z.string().optional().nullable(),
  countTs: z.string().optional().nullable(),
  productId: z.string().optional().nullable(),
  receiptLabel: z.string().optional().nullable(),
  productCategoryName: z.string().optional().nullable(),
  productVendorId: z.string().optional().nullable(),
  currentBaseCostAmt: z.number().optional().nullable(),
  totalInvQtyExpected: z.number().optional().nullable(),
  totalInvQtyCounted: z.number().optional().nullable(),
  statusCd: z.string().optional().nullable(),
});

export const UserSchema = z
  .object({
    userId: z.string().optional().nullable(),
    firstName: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    layoutLocationCount: z.number().optional().nullable(),
    statusCd: z.string().optional().nullable(),
    inventoryAdjustmentType: z.string().optional().nullable(),
  })
  .optional()
  .nullable();

export const CountLayoutLocationSchema = z
  .object({
    layoutDistinctName: z.string().optional().nullable(),
    layoutName: z.string().optional().nullable(),
    productId: z.string().optional().nullable(),
    startTs: z.string().optional().nullable(),
    endTs: z.string().optional().nullable(),
    countStatusCd: z.string().optional().nullable(),
  })
  .optional()
  .nullable();

export const CountLayoutSubzoneSchema = z
  .object({
    parentLayoutDistinctName: z.string().optional().nullable(),
    parentLayoutName: z.string().optional().nullable(),
    parentMapSequence: z.number().optional().nullable(),
    fpsChildCount: z.number().optional().nullable(),
    rsrvChildCount: z.number().optional().nullable(),
    startTs: z.string().optional().nullable(),
    endTs: z.string().optional().nullable(),
  })
  .optional()
  .nullable();

export const CountRequestSchema = z.object({
  taskId: z.string().optional().nullable(),
  countTypeCd: z.string(),
  statusCd: z.string(),
  reasonCode: z.string().optional().nullable(),
  endReason: z.string().optional().nullable(),
  startTs: z.string().optional().nullable(),
  endTs: z.string().optional().nullable(),
});

export const LocationCountSyncSchema = z.array(
  z.object({
    countRequest: CountRequestSchema,
    countLayoutSubzone: CountLayoutSubzoneSchema,
    countLayoutLocation: CountLayoutLocationSchema,
    user: UserSchema,
    inventoryAdjustments: z.array(InventoryAdjustmentsSchema).optional(),
  })
);
