/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { View, Text, Actionable, Icon, ChevronDown, ChevronUp } from '@az/starc-ui';

import { DetailsTableSkeleton } from '@shared/components/Skeletons/DetailsTableSkeleton';
import { InboundTrailerLineType } from '@inbound/types/types';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { DIRECTION } from '@shared/components/Table/Table.types';
import { generateLayoutNameFromDistinctName } from '@shared/utils/commonUtils';

import { headerItems } from './data';

import * as T from './ProductTableSection.types';
import s from './ProductTableSection.module.scss';
import { QUANTITY_UNMATCHED } from '@inbound/constants/constants';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { Box } from '@az/starc-ui-icons';

export const ProductTableSection = ({ data, isLoading, type, searchValue }: T.Props) => {
  /* State variables */
  const [expandedProductRow, setExpandedProductRow] = useState<{ [key: string]: boolean }>({});
  const [sortDirections, setSortDirections] = useState('default');
  const [sortBy, setSortBy] = useState<string>('default');

  /* Constants */
  const { t } = useTranslation();

  const emptySubTitle =
    type === QUANTITY_UNMATCHED
      ? t('PODashboard.Empty.QuantityUnmatched.Subtitle')
      : t('PODashboard.Empty.QuantityMatched.Subtitle');
  const emptyText =
    type === QUANTITY_UNMATCHED
      ? t('PODashboard.Empty.QuantityUnmatched.Text')
      : t('PODashboard.Empty.QuantityMatched.Text');

  /* Functions */
  const toggleExpand = (productId: string) => {
    setExpandedProductRow((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId],
    }));
  };

  const handleSort = (header: { flex: number; text: string; name: string }) => {
    const sortKey = header.name;
    setSortBy(sortKey);
    setSortDirections((prevSortDirection) => {
      if (prevSortDirection === DIRECTION.ASCENDING) {
        return DIRECTION.DESCENDING;
      } else {
        return DIRECTION.ASCENDING;
      }
    });
  };

  /* Hooks */
  const productData = useMemo(() => {
    if (['productLocaleDesc', 'productId'].includes(sortBy)) {
      return data.sort((a, b) => {
        if (sortDirections === DIRECTION.ASCENDING) {
          return (a.product?.productLocaleDesc || '').localeCompare(
            b.product?.productLocaleDesc || ''
          );
        } else {
          return (b.product?.productLocaleDesc || '').localeCompare(
            a.product?.productLocaleDesc || ''
          );
        }
      });
    }

    return data.sort((a, b) => {
      const firstValue = a[sortBy as keyof InboundTrailerLineType];
      const secondValue = b[sortBy as keyof InboundTrailerLineType];

      if (typeof firstValue === 'number' && typeof secondValue === 'number') {
        return sortDirections === DIRECTION.ASCENDING
          ? firstValue - secondValue
          : secondValue - firstValue;
      } else if (typeof firstValue === 'string' && typeof secondValue === 'string') {
        return sortDirections === DIRECTION.ASCENDING
          ? firstValue.localeCompare(secondValue)
          : secondValue.localeCompare(firstValue);
      } else if (firstValue && secondValue) {
        return (
          (firstValue < secondValue ? -1 : 1) * (sortDirections === DIRECTION.ASCENDING ? 1 : -1)
        );
      } else if (firstValue) {
        return 1;
      } else if (secondValue) {
        return 1;
      } else {
        return -1;
      }
    });
  }, [data, sortBy, sortDirections]);

  const productDataSearch = useMemo(() => {
    if (searchValue) {
      return productData.filter(
        (productItem) =>
          productItem.partNbrId &&
          productItem.partNbrId.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return productData;
    }
  }, [productData, searchValue]);

  return isLoading ? (
    <DetailsTableSkeleton />
  ) : (
    <View direction="column" gap={2} padding={[0, 4, 0, 4]}>
      {productDataSearch.length > 0 ? (
        <>
          <View
            padding={[4, 4, 0, 4]}
            direction="row"
            className={s['location-header__location-header']}
          >
            {headerItems.map((header) => (
              <View
                direction="row"
                align="center"
                gap={2}
                attributes={{ style: { flex: header.flex } }}
              >
                <Text className={s['location-header__header-text']}>{header.text}</Text>
                <Actionable
                  onClick={() =>
                    header.name &&
                    handleSort({ flex: header.flex, text: header.text, name: header.name })
                  }
                  attributes={{ style: { cursor: 'pointer' } }}
                >
                  {sortBy !== header.name && (
                    <>
                      <Icon svg={ChevronUp} size={3} color="500" />
                      <Icon svg={ChevronDown} size={3} color="500" />
                    </>
                  )}
                  {sortDirections === DIRECTION.ASCENDING && sortBy === header.name && (
                    <Icon svg={ChevronUp} size={3} color="500" />
                  )}
                  {sortDirections === DIRECTION.DESCENDING && sortBy === header.name && (
                    <Icon svg={ChevronDown} size={3} color="500" />
                  )}
                </Actionable>
              </View>
            ))}
          </View>

          {/* Rows */}
          {productDataSearch.map((productItem) => (
            <View backgroundColor="secondary">
              <View
                className={s['location-wrapper']}
                padding={[4, 4]}
                align="center"
                direction="row"
                backgroundColor="secondary"
              >
                <View attributes={{ style: { flex: 3.5 } }}>
                  <Text weight="bold">{productItem.product?.productLocaleDesc || EMPTY_VALUE}</Text>
                </View>

                <View attributes={{ style: { flex: 4.4 } }}>
                  {productItem.inboundOrderLineLocations &&
                  productItem.inboundOrderLineLocations?.length > 0 ? (
                    <Text>
                      {`${productItem.inboundOrderLineLocations?.[0]?.layoutAttribNameType} - ${
                        productItem.inboundOrderLineLocations?.[0]?.layoutName ||
                        generateLayoutNameFromDistinctName(
                          productItem.inboundOrderLineLocations?.[0]?.layoutDistinctName
                        )
                      }`}
                      {(productItem.inboundOrderLineLocations || []).length > 1 && (
                        <>
                          ,&nbsp;
                          <Actionable onClick={() => toggleExpand(productItem.productId)}>
                            {!expandedProductRow[productItem.productId] ? (
                              <Text weight="bold" decoration="underline">
                                {t('PODashboard.ProductTableSection.ShowMore', {
                                  count: (productItem.inboundOrderLineLocations || []).length,
                                })}
                              </Text>
                            ) : (
                              <Text weight="bold" decoration="underline">
                                {t('PODashboard.ProductTableSection.HideMore', {
                                  count: (productItem.inboundOrderLineLocations || []).length,
                                })}
                              </Text>
                            )}
                          </Actionable>
                        </>
                      )}
                    </Text>
                  ) : (
                    EMPTY_VALUE
                  )}
                </View>

                <View attributes={{ style: { flex: 1 } }}>
                  <Text>{productItem.productId}</Text>
                </View>

                <View attributes={{ style: { flex: 1 } }}>
                  <Text>{productItem.partNbrId}</Text>
                </View>

                <View attributes={{ style: { flex: 1 } }}>
                  <Text weight="bold">
                    {t('PODashboard.PicesData', {
                      count: productItem.totalReceivedQtyAdj
                        ? productItem.totalReceivedQtyAdj
                        : productItem.totalReceivedQty,
                      total: productItem.totalOrderedQty,
                    })}
                  </Text>
                </View>
              </View>
              {expandedProductRow[productItem.productId] &&
                (productItem.inboundOrderLineLocations?.length || 0) > 0 && (
                  <View
                    direction="row"
                    backgroundColor="secondary"
                    className={s['location-wrapper']}
                  >
                    <View attributes={{ style: { flex: 3.5 } }}>&nbsp;</View>
                    <View
                      direction="row"
                      padding={[4, 4, 4, 0]}
                      attributes={{ style: { flex: 7.1 } }}
                      backgroundColor="secondary"
                    >
                      <View.Item grow>
                        <View padding={[0, 0, 2, 0]}>
                          <Text size="087" color="400" weight="bold">
                            {t('PODashboard.ProductTableSection.CountByPutLocation')}
                          </Text>
                        </View>

                        <View>
                          {(productItem.inboundOrderLineLocations || []).map((layout, idx) => (
                            <View
                              direction="row"
                              width="100%"
                              padding={[2, 0]}
                              key={idx}
                              justify="space-between"
                              className={s['location-wrapper__item']}
                            >
                              <View.Item grow>
                                <View>
                                  <Text>
                                    {`${layout.layoutAttribNameType} - ${
                                      layout.layoutName ||
                                      generateLayoutNameFromDistinctName(layout.layoutDistinctName)
                                    }`}
                                  </Text>
                                </View>
                              </View.Item>
                              <View.Item>
                                <View>
                                  <Text>
                                    {t('PODashboard.PicesData', {
                                      count: layout.receivedQtyAdj ?? layout.totalReceivedQty ?? 0,
                                      total: layout.totalReceivedQty || 0,
                                    })}
                                  </Text>
                                </View>
                              </View.Item>
                            </View>
                          ))}
                        </View>
                      </View.Item>
                    </View>
                  </View>
                )}
            </View>
          ))}
        </>
      ) : (
        <View justify="center" align="center">
          <View width="360px">
            <EmptyState svg={Box} subtitle={emptySubTitle} text={emptyText} />
          </View>
        </View>
      )}
    </View>
  );
};
