/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const DashboardVarianceSchema = z.object({
  name: z.string().nullable(),
  value: z.number().nullable(),
});
