/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { useState } from 'react';
import { CycleCountHeader } from './components/CycleCountHeader/CycleCountHeader';
import { CycleCountTabs } from './components/CycleCountTabs/CycleCountTabs';
import { Outlet } from 'react-router-dom';

export const CycleCounts = () => {
  const [isEnableCreateBtn, setIsEnableCreateBtn] = useState(false);
  return (
    <View direction="column" height="100%">
      <CycleCountHeader isEnableCreateBtn={isEnableCreateBtn} />
      <CycleCountTabs />
      <Outlet context={[isEnableCreateBtn, setIsEnableCreateBtn]} />
    </View>
  );
};
