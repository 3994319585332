/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { CapabilityGroupPermissionUpdateRequestType } from '@mdm/pages/RDM/User/Permission/PermissionList.types.ts';
import { capabilityGroupService } from '@shared/services/capabilityGroupService.ts';

export const useMutateCapabilityGroup = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const message = t('Errors.Action.UpdatePermission');

  const {
    mutate: mutateCapabilityGroup,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: {
      capabilityGroupPermissionUpdateRequestType: CapabilityGroupPermissionUpdateRequestType;
    }) => {
      const payload = { ...params.capabilityGroupPermissionUpdateRequestType };
      return capabilityGroupService.updateCapabilityGroupPermission(payload);
    },
    onError: () => {
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateCapabilityGroup, isLoading, isError };
};
