/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { apiService } from '@shared/services/apiService.ts';
import { GetAssetParams } from '@mdm/types/service.type.ts';
import { ASSET_ENDPOINTS } from '@shared/constants/routes.ts';
import { ASSET_TYPE_CODE } from '@mdm/constants/constants.ts';
import { paginatedParseAndLog } from '@mdm/utils/utils.ts';
import { AssetSchema } from '@mdm/schemas/assetSchema.ts';

export const containerService = {
  getContainers: async (options: GetAssetParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        assetTypeCd: ASSET_TYPE_CODE.CONTAINER,
      },
    };

    const response = await apiService.post(ASSET_ENDPOINTS.GET_ASSETS, payload);
    return paginatedParseAndLog(AssetSchema, response.data);
  },
};
