/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { RouteObject } from 'react-router-dom';
import { Inventory } from '@taskManagement/pages/Inventory/Inventory';
import { QualityControlServices } from '@inventory/pages/QualityControlServices';
import { FinalizationDashboard } from '@inventory/pages/FinalizationDashbaord/FinalizationDashboard';
import { POdiscrepancy } from '@inventory/pages/FinalizationDashbaord/Detail/PODiscrepancy';
import { ROUTES } from '@shared/constants/routes';
import { SubzoneDiscrepancy } from '@inventory/pages/FinalizationDashbaord/Detail/SubzoneDiscrepancy';
import { CustomCount } from '@inventory/pages/CycleCounts/components/CustomCount/CustomCount';
import { InventoryCount } from '@inventory/pages/CycleCounts/components/InventoryCount/InventoryCount';
import { RiskBased } from '@inventory/pages/CycleCounts/components/RiskBased/RiskBased';
import { WallToWall } from '@inventory/pages/CycleCounts/components/WallToWall/WallToWall';
import { CycleCounts } from '@inventory/pages/CycleCounts/CycleCounts';
import { CycleCountDetails } from '@inventory/pages/CycleCounts/CycleCountDetails/CycleCountDetails';
import { CycleCountDetailsOverview } from '@inventory/pages/CycleCounts/CycleCountDetails/CycleCountDetailsComponents/CycleCountDetailsOverview/CycleCountDetailsOverview';
import { Subzones } from '@inventory/pages/CycleCounts/components/Subzones/Subzones';
import { InventoryHistory } from '@inventory/pages/InventoryHistory';

export const inventoryRoutes: RouteObject = {
  children: [
    {
      path: ROUTES.FINALIZATION_DASHBOARD,
      children: [
        {
          path: '',
          element: <FinalizationDashboard />,
        },
        {
          path: `:sourceOrderId`,
          element: <POdiscrepancy />,
        },
        {
          path: `sub-zone/:subzone`,
          element: <SubzoneDiscrepancy />,
        },
      ],
    },
    {
      path: ROUTES.INVENTORY_MANAGER,
      element: <Inventory />,
    },
    {
      path: ROUTES.INVENTORY_HISTORY,
      element: <InventoryHistory />,
    },
    {
      path: ROUTES.QCS,
      element: <QualityControlServices />,
    },
    {
      path: ROUTES.CYCLE_COUNTS,
      element: <CycleCounts />,
      children: [
        {
          path: ROUTES.CYCLE_COUNTS_RISK_BASED,
          element: <RiskBased />,
        },
        {
          path: ROUTES.CYCLE_COUNTS_WALL_TO_WALL,
          element: <WallToWall />,
        },
        {
          path: ROUTES.CYCLE_COUNTS_INVENTORY,
          element: <InventoryCount />,
        },
        {
          path: ROUTES.CYCLE_COUNTS_CUSTOM,
          element: <CustomCount />,
        },
      ],
    },
    {
      path: ROUTES.CYCLE_COUNT + '/:countTypeCd' + '/:id',
      element: <CycleCountDetails />,
      children: [
        {
          path: ROUTES.OVERVIEW,
          element: <CycleCountDetailsOverview />,
        },
        {
          path: ROUTES.SKUS_WITH_VARIENCES,
        },
        {
          path: ROUTES.SUBZONES,
          element: <Subzones />,
        },
      ],
    },
  ],
};
