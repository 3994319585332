/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Text, View } from '@az/starc-ui';

import * as T from './UserSignature.types';
import s from './UserSignature.module.scss';

export const UserSignature = ({ userName }: T.Props) => {
  return (
    <View className={s['user-signature__container']}>
      <View className={s['user-signature__content']}>
        <Text className={s['user-signature__content__font']}>{userName}</Text>
      </View>
    </View>
  );
};
