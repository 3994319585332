/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Actionable } from '@az/starc-ui';
import { t } from 'i18next';
import { generateDateString } from '@ofm/utils/utils';
import { getAttributeValueByKey } from '@taskManagement/utils/utils';
import { MAX_VISIBLE_AVATARS_TASK } from '@taskManagement/constants/constants';
import { EMPTY_VALUE, TASK_ATTRIBUTES } from '@shared/constants/constants';
import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { activeUpcomingMasterCardTypes } from './Overview.types';

export const ActiveUpcomingMasterCard = ({
  taskData,
  usersData,
  onClickRemoveTask,
  openActionMenu,
  isUpcomingTask,
}: activeUpcomingMasterCardTypes) => {
  return (
    <MasterCard
      title={
        getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.VENDOR_NAME) ?? EMPTY_VALUE
      }
      priority={taskData.priority ? true : false}
      tagItems={[
        {
          text: getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.ORDER_TYPE_CD),
          variant: 'order',
        },
        {
          text: getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.COMMODITY_TYPE_CD),
          variant: 'order',
        },
      ]}
      label={t('PoTask.Arrival', {
        date: getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS)
          ? generateDateString(
              new Date(
                getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS)
              ),
              t('DateFormat.ShortTime')
            )
          : '',
      })}
      detailsData={{
        data: [
          {
            label: t('PoTask.PO', {
              poNumber: getAttributeValueByKey(
                taskData.attributes,
                TASK_ATTRIBUTES.SOURCE_ORDER_NBR
              ),
            }),
          },
          {
            label: t('PoTask.RC', {
              rcNumber: getAttributeValueByKey(
                taskData.attributes,
                TASK_ATTRIBUTES.ORDER_LOCATION_ID
              ),
            }),
          },
          {
            label: t('PoTask.Loc', {
              locationId: getAttributeValueByKey(
                taskData.attributes,
                TASK_ATTRIBUTES.TRAILER_LOCATION_TYPE_CD
              ),
            }),
          },
        ],
      }}
      {...(taskData && {
        piecesData: {
          count:
            parseInt(
              getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.TOTAL_RECEIVED_QTY)
            ) || 0,
          total:
            parseInt(
              getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.TOTAL_ORDERED_QTY)
            ) || 0,
          label: t('Pieces'),
        },
      })}
      {...(taskData && {
        linesData: {
          count:
            parseInt(
              getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.RECEIVED_LINE_COUNT)
            ) || 0,
          total:
            parseInt(
              getAttributeValueByKey(taskData.attributes, TASK_ATTRIBUTES.TOTAL_LINE_COUNT)
            ) || 0,
          label: t('InboundTaskManager.Lines'),
        },
      })}
      {...(taskData.assignments.length > 0
        ? {
            avatarGroupData: {
              users: taskData.assignments.map((assignment) => {
                const user = usersData?.find((item) => item.userId === assignment.userId);
                return {
                  id: parseInt(assignment.userId),
                  firstName: user?.firstName ?? '',
                  lastName: user?.lastName ?? '',
                };
              }),
              size: 'small',
              variant: 'assignee',
              maxDisplay: MAX_VISIBLE_AVATARS_TASK,
            },
          }
        : {})}
      actionMenu={
        <ActionDropdownMenu isOpen={openActionMenu}>
          <View padding={[1, 0]}>
            {isUpcomingTask && (
              <View.Item>
                <View padding={[3, 4]}>
                  <Actionable fullWidth>
                    <Text>{t('ActiveDropDownList.MoveToTop')}</Text>
                  </Actionable>
                </View>
              </View.Item>
            )}
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable fullWidth>
                  <Text>{t('ActiveDropDownList.ViewDetails')}</Text>
                </Actionable>
              </View>
            </View.Item>
            <View.Item>
              <View padding={[3, 4]}>
                <Actionable fullWidth onClick={() => onClickRemoveTask(taskData.taskNo)}>
                  <Text color="error">{t('ActiveDropDownList.RemoveTask')}</Text>
                </Actionable>
              </View>
            </View.Item>
          </View>
        </ActionDropdownMenu>
      }
    />
  );
};
