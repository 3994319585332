/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Table } from '@shared/components/Table/Table.tsx';
import {
  ZONE_ASSOCIATED_SUBZONE_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';
import { Button, Icon, Text, View } from '@az/starc-ui';
import {
  DIRECTION as TABLE_SORT_DIRECTION,
  TableSorting,
} from '@shared/components/Table/Table.types.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { Props } from '@mdm/pages/RDM/LocationManager/Zone/Detail/ZoneAssociatedSubzoneList.types.ts';
import { LAYOUT, RDM, ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE } from '@shared/constants/constants.ts';
import { mapZoneAssociatedSubzoneTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { useGetSubzonesByZoneId } from '@mdm/services/hooks/useGetSubzonesByZoneId.tsx';
import { useEffect, useState } from 'react';
import { Add, LicensePlateSearch } from '@az/starc-ui-icons';
import { EmptyState } from '@shared/components/EmptyState/EmptyState.tsx';
import { useTranslation } from 'react-i18next';
import { useDeleteSubzone } from '@mdm/services/hooks/useDeleteSubzone.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { ENTITY_ASSOCIATIONS, SUBZONE_FIELD } from '@mdm/constants/constants.ts';
import { LayoutListRowType } from '@mdm/types/schema.type.ts';

export const ZoneAssociatedSubzoneList = ({ zoneLayoutId }: Props) => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('layoutName');
  const [subzoneTypeOptions, setSubzoneTypeOptions] = useState<SelectOption[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateDeleteSubzone, isLoading: isDeleting } = useDeleteSubzone();

  /* Functions */
  const onViewDetails = (subzone: LayoutListRowType) => {
    navigate(PAGE_URLS.SUB_ZONE_DETAILS(String(subzone.layout.layoutDistinctName)));
  };

  const onDeleteSubzone = (subzone: LayoutListRowType) => {
    if (subzone.layout.layoutKey) {
      mutateDeleteSubzone({
        layoutKey: subzone.layout.layoutKey,
      });
    }
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  /* Constants */
  const { subzonesData, isFetching: isLoading } = useGetSubzonesByZoneId(
    {
      currentPage: currentPage - 1,
      pageSize: ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE,
      sortBy: sortColumnId,
      direction: sortDirection,
      parentLayoutDistinctName: zoneLayoutId || '',
      entityAssociations: [
        ENTITY_ASSOCIATIONS.ASSETS,
        ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES,
        ENTITY_ASSOCIATIONS.LAYOUT_PARENT,
        ENTITY_ASSOCIATIONS.CHILDREN,
      ],
    },
    !!zoneLayoutId
  );

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  /* Hooks */
  useEffect(() => {
    const subzoneTypes = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.SUBZONE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setSubzoneTypeOptions(subzoneTypes);
  }, [configsData]);

  return (
    <View gap={4} width="100%" height="100%">
      <View direction="row">
        <View.Item grow>
          <Text size="125" weight="bold">
            {t('ZoneDetails.TotalSubzonesInZone', { count: subzonesData?.totalElements || 0 })}
          </Text>
        </View.Item>
        <View.Item>
          <Button
            variant="secondary"
            size="small"
            disabled={!zoneLayoutId}
            onClick={() => navigate(`${PAGE_URLS.SUB_ZONE_CREATE}/${zoneLayoutId}`)}
          >
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} />
              <Text>{t('CreateSubzone')}</Text>
            </View>
          </Button>
        </View.Item>
      </View>

      {!zoneLayoutId || (subzonesData?.content || []).length === 0 ? (
        <View padding={6}>
          <EmptyState
            svg={LicensePlateSearch}
            subtitle={t('Empty.ZoneSubzones.Subtitle')}
            text={t('Empty.ZoneSubzones.Text')}
          />
        </View>
      ) : (
        <View>
          <Table
            columns={ZONE_ASSOCIATED_SUBZONE_TABLE_COLUMNS}
            rows={mapZoneAssociatedSubzoneTableRows(
              subzonesData?.content || [],
              onViewDetails,
              onDeleteSubzone,
              {
                subzoneTypeOptions,
              },
              isDeleting
            )}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE}
            defaultPage={currentPage}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isLoading}
            isApiLoadedData={true}
            styleVariant={TableStylingVariants.FILLED}
            totalPages={subzonesData?.totalPages || 0}
            onPageChange={(newPage) => {
              setCurrentPage(newPage);
            }}
            onSort={onSort}
          />
        </View>
      )}
    </View>
  );
};
