/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { TaskSearchParams } from '@shared/types/service.type';
import { apiService } from '@shared/services/apiService';
import { TASK_SEARCH_ENDPOINTS } from '@shared/constants/routes.ts';
import { TaskSearchSchema } from '@shared/schemas/TaskSearchSchema';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';

export const TaskSearchService = {
  getTaskSearchData: async (options: TaskSearchParams) => {
    const payload = {
      searchPage: {
        page: options?.searchPage.page,
        size: options?.searchPage.size,
        ...(options?.searchPage.sortBy && { sortBy: options.searchPage.sortBy }),
        ...(options?.searchPage.direction && {
          direction: options.searchPage.direction.toUpperCase(),
        }),
      },
      searchCriteria: {
        ...options?.searchCriteria,
      },
    };

    const response = await apiService.post(TASK_SEARCH_ENDPOINTS.GET_TASK_SEARCH_DATA, payload);
    return paginatedParseAndLog(TaskSearchSchema, response.data);
  },
};
