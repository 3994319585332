/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { locationService } from '@mdm/services/locationService.ts';

export const useGetLocationByLayoutKey = (params: { layoutKey: string }, isEnabled = true) => {
  const {
    data: locationData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['location', params],
    queryFn: () => locationService.getLocationByLayoutKey(params.layoutKey),
    enabled: isEnabled,
    cacheTime: 0,
  });

  return { locationData, isLoading, isError };
};
