/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useMemo } from 'react';
import { MAX_VISIBLE_AVATARS_TASK, TASK_TYPES } from '@taskManagement/constants/constants';
import { TaskSearchSchemaType, UserSearchSchemaType } from '@shared/types/schema.type';
import { getAttributeValueByKey } from '@taskManagement/utils/utils';
import { TASK_ATTRIBUTES } from '@shared/constants/constants';
import { t } from 'i18next';
import { generateDateString } from '@ofm/utils/utils';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

export function useGetTaskTypeCardHook(
  poData: TaskSearchSchemaType,
  usersData: UserSearchSchemaType[],
  taskType: string
) {
  const replenishPriority = poData.priority;

  const createAvatarGroupData = (
    assignments: Array<{ userId: string }> = [],
    usersData: UserSearchSchemaType[]
  ) => {
    return {
      users:
        assignments
          ?.map((assignment) => {
            const user = usersData?.find((usersItem) => usersItem.userId === assignment.userId);
            return user
              ? {
                  id: parseInt(assignment.userId, 10),
                  firstName: user.firstName,
                  lastName: user.lastName,
                }
              : null;
          })
          .filter(Boolean) || [],
      size: 'small',
      variant: 'assignee',
      maxDisplay: MAX_VISIBLE_AVATARS_TASK,
    };
  };

  const setReplenishmentPriorityVariant = useCallback((replenishPriority: number) => {
    const priorityNumber = Number(replenishPriority);
    if (priorityNumber === 7000) return StatusVariants.IN_PROGRESS;
    return priorityNumber > 7000 ? StatusVariants.READY_FOR_ACTION : StatusVariants.IN_PROGRESS;
  }, []);

  return useMemo(() => {
    const getAttribute = (key: string) => getAttributeValueByKey(poData.attributes, key) || '--';

    if (taskType === TASK_TYPES.REPLENISHMENT) {
      const title = getAttribute(TASK_ATTRIBUTES.PRODUCT_NAME);
      const slotQty = parseInt(getAttribute(TASK_ATTRIBUTES.SLOT_QTY));
      const statusBadge = {
        variant: replenishPriority && setReplenishmentPriorityVariant(replenishPriority),
        text: replenishPriority || null,
      };

      const label = {
        data: [
          t('ReplenishmentDashboard.Card.SRC', {
            src: getAttribute(TASK_ATTRIBUTES.SRC),
          }),
          t('ReplenishmentTask.FPS', {
            FPS: getAttribute(TASK_ATTRIBUTES.FPS),
          }),
        ],
      };

      const detailsData = {
        data: [
          {
            label: t('ReplenishmentTask.Pieces', {
              pieces: getAttribute(TASK_ATTRIBUTES.PIECES),
            }),
          },
          {
            label: t('ReplenishmentTask.SlotQty', {
              slotQty: isNaN(slotQty) ? '--' : slotQty,
            }),
          },
          /* if slotQty is 0, display outs  */
          ...(slotQty === 0
            ? [
                {
                  label: t('ReplenishmentDashboard.Card.Outs', {
                    outs: getAttribute(TASK_ATTRIBUTES.OUTS),
                  }),
                },
              ]
            : []),
          {
            label: t('ReplenishmentDashboard.Card.Subzone', {
              subzone: getAttribute(TASK_ATTRIBUTES.REPLENISH_SUBZONE),
            }),
          },
          {
            label: t('ReplenishmentDashboard.Card.Vehicle', {
              vehicle: getAttribute(TASK_ATTRIBUTES.VEHICLE),
            }),
          },
        ],
      };

      const avatarGroupData = createAvatarGroupData(poData?.assignments ?? [], usersData);

      return { title, label, detailsData, avatarGroupData, statusBadge };
    }
    /*================ PO task type ================*/
    if (taskType === TASK_TYPES.PO) {
      const title = getAttribute(TASK_ATTRIBUTES.VENDOR_NAME) || '--';
      const priority = !!poData.priority;

      const tagItems = [
        {
          text: getAttribute(TASK_ATTRIBUTES.ORDER_TYPE_CD),
          variant: 'order',
        },
        {
          text: getAttribute(TASK_ATTRIBUTES.COMMODITY_TYPE_CD),
          variant: 'order',
        },
      ];

      const arrivalDate = t('PoTask.Arrival', {
        date: getAttribute(TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS)
          ? generateDateString(
              new Date(getAttribute(TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS)),
              t('DateFormat.ShortTime')
            )
          : null,
      });

      const detailsData = {
        data: [
          {
            label: t('PoTask.PO', { poNumber: getAttribute(TASK_ATTRIBUTES.SOURCE_ORDER_NBR) }),
          },
          {
            label: t('PoTask.RC', { rcNumber: getAttribute(TASK_ATTRIBUTES.ORDER_LOCATION_ID) }),
          },
          {
            label: t('PoTask.Loc', {
              locationId: getAttribute(TASK_ATTRIBUTES.TRAILER_LOCATION_ID),
            }),
          },
        ],
      };

      const piecesData = {
        total: parseInt(getAttribute(TASK_ATTRIBUTES.TOTAL_ORDERED_QTY)) || 0,
        count: parseInt(getAttribute(TASK_ATTRIBUTES.TOTAL_RECEIVED_QTY)) || 0,
        label: t('Pieces'),
      };

      const linesData = {
        total: parseInt(getAttribute(TASK_ATTRIBUTES.TOTAL_LINE_COUNT)) || 0,
        count: parseInt(getAttribute(TASK_ATTRIBUTES.RECEIVED_LINE_COUNT)) || 0,
        label: t('InboundTaskManager.Lines'),
      };

      const avatarGroupData = createAvatarGroupData(poData?.assignments ?? [], usersData);

      return {
        title,
        priority,
        tagItems,
        arrivalDate,
        detailsData,
        piecesData,
        linesData,
        avatarGroupData,
      };
    }
    return null;
  }, [
    poData.attributes,
    poData.assignments,
    poData.priority,
    replenishPriority,
    setReplenishmentPriorityVariant,
    taskType,
    usersData,
  ]);
}
