/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { GetLayoutParams } from '@mdm/types/service.type.ts';
import { locationService } from '@mdm/services/locationService.ts';

export const useGetLocationsBySubzoneId = (options: GetLayoutParams, isEnabled = true) => {
  const {
    data: locationsData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['subzoneAssociatedLocations', options],
    queryFn: () => locationService.getLocationBySubzoneId(options),
    enabled: isEnabled,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { locationsData, isFetching, isError };
};
