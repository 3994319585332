/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { ACCEPT_LANGUAGE, Language } from '@shared/constants/constants.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';

export const CreateRoleSchema = z.object({
  roleId: z.string().superRefine((data, ctx) => {
    if (!data) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (!isAlphaNumeric(data)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }

    if (data.length > MAX_LENGTH_FIELD.USER_ROLE_ID) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: MAX_LENGTH_FIELD.USER_ROLE_ID,
        type: 'string',
        inclusive: true,
        message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.USER_ROLE_ID }),
      });
    }
  }),
  roleDesc: z.string().superRefine((data, ctx) => {
    if (!data) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (!isAlphaNumeric(data, true)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }

    if (data.length > MAX_LENGTH_FIELD.USER_ROLE_DESCRIPTION) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: MAX_LENGTH_FIELD.USER_ROLE_DESCRIPTION,
        type: 'string',
        inclusive: true,
        message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.USER_ROLE_DESCRIPTION }),
      });
    }
  }),
  locale: z.string().default(ACCEPT_LANGUAGE[Language.ENGLISH]).optional(),
});
