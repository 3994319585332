/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { atom } from 'jotai';

import { DisplayOptionsType } from '@outbound/components/MatrixAccordion/MatrixAccordion.types';
import { defaultDisplayOptions } from '@outbound/pages/ControlDesk/data';

export const displayOptionsAtom = atom<DisplayOptionsType>(defaultDisplayOptions);
