/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMutation } from '@tanstack/react-query';
import { setInboundShiftGoalService } from '../setInboundShiftGoalService';
import { InboundShiftGoalPayloadType } from '@taskManagement/types/types';

export const useMutateInboundShiftGoal = () => {
  const {
    mutate: mutateSetShiftGoal,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (payload: InboundShiftGoalPayloadType) => {
      return setInboundShiftGoalService.setGoals(payload);
    },
  });
  return { mutateSetShiftGoal, isLoading, isError };
};
