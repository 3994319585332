/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { zoneService } from '@mdm/services/zoneService.ts';

export const useGetZoneByLayoutKey = (params: { layoutKey: string }, isEnabled = true) => {
  const {
    data: zoneData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['zone', params],
    queryFn: () => zoneService.getZoneByLayoutKey(params.layoutKey),
    enabled: isEnabled,
    cacheTime: 0,
  });

  return { zoneData, isLoading, isError };
};
