/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { EmptyState } from '@shared/components/EmptyState/EmptyState.tsx';
import { Add, LicensePlateSearch } from '@az/starc-ui-icons';
import { Button, Icon, Text, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  SUBZONE_ASSOCIATED_VEHICLE_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import {
  ASSET,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  RDM,
} from '@shared/constants/constants.ts';

import styles from './SubzoneVehicleList.module.scss';
import { mapSubzoneAssociatedVehicleTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { AttributeType, LayoutAssetType, LayoutViewType } from '@mdm/types/schema.type.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { AddVehicleSubzoneModal } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/AddSubzoneVehicleModal.tsx';
import { useEffect, useState } from 'react';
import { useMutateSubzone } from '@mdm/services/hooks/useMutateSubzone.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { ASSET_TYPE_CODE, VEHICLE_FIELD } from '@mdm/constants/constants.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';

type Props = {
  subzoneId?: string;
  isSubzoneLoading: boolean;
  isSubzoneUpdating: boolean;
  parentLayoutDistinctName: string;
  subzoneData?: LayoutViewType;
  onLayoutMappingAttributeChange: (assetCd: string, attributeKey: string, value: string) => void;
};

export const SubzoneVehicleList = ({
  subzoneId,
  subzoneData,
  parentLayoutDistinctName,
  onLayoutMappingAttributeChange,
  isSubzoneUpdating,
}: Props) => {
  /* State */
  const [isAddSubzoneVehicleModalOpen, setIsAddSubzoneVehicleModalOpen] = useState<boolean>(false);
  const [layoutVehicleAssets, setLayoutVehicleAssets] = useState<LayoutAssetType[]>([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<SelectOption[]>([]);

  /* Constants */
  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: ASSET,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateSubzone: mutateDeleteVehicle, isLoading: isDeletingVehicle } = useMutateSubzone();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  /* Functions */
  const onDeleteVehicle = async (assetCd: string) => {
    if (subzoneData && subzoneData.layoutKey) {
      const layoutAssetMappingData = subzoneData?.assets || [];

      const foundAssetIndex = layoutAssetMappingData.findIndex((asset) => {
        return asset.assetCd === assetCd;
      });

      if (foundAssetIndex !== -1) {
        layoutAssetMappingData[foundAssetIndex].isActive = false;
      }

      let attributes: AttributeType[] = [];
      if (subzoneData.attributes) {
        attributes = subzoneData.attributes
          .filter((attribute) => attribute.value !== '')
          .map((attribute) => {
            return {
              ...attribute,
              value: attribute.value.toString(),
            };
          });
      }

      const payload = {
        ...subzoneData,
        parentLayoutDistinctName,
        attributes,
        assets:
          layoutAssetMappingData.length > 0
            ? layoutAssetMappingData.filter((asset) => !!asset.assetCd)
            : null,
      };

      mutateDeleteVehicle(
        {
          layoutKey: subzoneData.layoutKey,
          layoutRequest: payload,
        },
        {
          onSuccess: (data) => {
            if (data) {
              const successMessage = t('Success.Action.Vehicle.Deleted');

              queryClient.invalidateQueries(['subzones']);
              queryClient.invalidateQueries(['subzone']);
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
          },
        }
      );
    }
  };

  const onViewDetails = (layoutAssetType: LayoutAssetType) => {
    navigate(PAGE_URLS.VEHICLE_DETAILS(layoutAssetType.assetCd));
  };

  const onAttributeChange = (value: string, attributeKey: string, assetCd: string) => {
    onLayoutMappingAttributeChange(assetCd, attributeKey, value);
  };

  /* Hooks */
  useEffect(() => {
    // get the vehicle types from the facility configs
    const vehicleTypes = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.VEHICLE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setVehicleTypeOptions(vehicleTypes);
  }, [configsData]);

  // filter the vehicle assets from the subzone assets
  useEffect(() => {
    if (subzoneData?.assets) {
      const filteredAssets = subzoneData.assets.filter(
        (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE
      );
      setLayoutVehicleAssets([...filteredAssets]);
    }
  }, [subzoneData, isAddSubzoneVehicleModalOpen]);

  return (
    <View
      attributes={{
        'data-testid': 'subzone-vehicle-list',
      }}
      className={styles['subzone-vehicle-list']}
      padding={6}
      gap={4}
      width="100%"
      height="100%"
    >
      <View direction="row">
        <View.Item grow>
          <Text size="125" weight="bold">
            {t('SubzoneDetails.Tabs.Vehicle.Title')}
          </Text>
          <View direction="row" attributes={{ style: { marginTop: 'var(--st-unit-2)' } }}>
            <Text weight="regular">{t('SubzoneDetails.Tabs.Vehicle.Subtitle')}</Text>
          </View>
        </View.Item>
        <View.Item>
          <Button
            variant="secondary"
            size="small"
            disabled={!subzoneId || isSubzoneUpdating}
            onClick={() => setIsAddSubzoneVehicleModalOpen(true)}
          >
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} />
              <Text>{t('AddVehicle')}</Text>
            </View>
          </Button>
        </View.Item>
      </View>

      {subzoneId && layoutVehicleAssets.length > 0 && !isAddSubzoneVehicleModalOpen ? (
        <Table
          columns={SUBZONE_ASSOCIATED_VEHICLE_TABLE_COLUMNS}
          rows={mapSubzoneAssociatedVehicleTableRows(
            (subzoneData?.assets || []).filter(
              (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE
            ) || [],
            onViewDetails,
            onAttributeChange,
            onDeleteVehicle,
            {
              vehicleTypeOptions,
            },
            isDeletingVehicle
          )}
          isPaginated={true}
          isCheckboxDisabled={false}
          isLoading={isAddSubzoneVehicleModalOpen || isSubzoneUpdating}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          styleVariant={TableStylingVariants.FILLED}
          totalPages={Math.ceil(layoutVehicleAssets.length / PAGE_SIZE)}
          onSort={() => ({})}
        />
      ) : (
        <View padding={6}>
          <EmptyState
            svg={LicensePlateSearch}
            subtitle={t('Empty.SubzoneVehicles.Subtitle')}
            text={t('Empty.SubzoneVehicles.Text')}
          />
        </View>
      )}

      <AddVehicleSubzoneModal
        isOpen={isAddSubzoneVehicleModalOpen}
        onClose={() => {
          setIsAddSubzoneVehicleModalOpen(false);
        }}
        subzoneData={subzoneData}
        parentLayoutDistinctName={parentLayoutDistinctName}
        onAttributeChange={onAttributeChange}
        onSubmit={() => ({})}
      />
    </View>
  );
};
