/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import {
  View,
  Text,
  Button,
  Icon,
  Actionable,
  Dropdown,
  Divider,
  Checkbox,
  Loader,
} from '@az/starc-ui';
import { statusToBadgeVariant } from '@ofm/utils/utils';
import { Download } from '@shared/assets/icons';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { useDownload } from '@shared/hooks/useDownload';
import { t } from 'i18next';
import { generateDateString } from '@shared/utils/commonUtils';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { EndCountModal } from '../EndCountModal/EndCountModal';
import { DownloadFileType } from '@inbound/types/types';
import { DEFAULT_PAGE, DESC, FILE_TYPES } from '@inbound/constants/constants';
import styles from './CycleCountDetailsHeader.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDownloadSubzones, useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
  HEADER_COMPONENT,
  PAGE,
  SINGLE_PAGE_SIZE,
} from '@inventory/constants/constants';
import { PAGE_SIZE, SORT_BY, SORT_ORDER } from '@shared/constants/constants';
import { CycleCountDetailsHeaderProps, headerDataType } from './CycleCountDetailsHeader.types';
import { checkCountTypeCd } from '@inventory/utils/utils';

export const CycleCountDetailsHeader = ({
  progressValue,
  countTypeCdValue,
  taskId,
}: CycleCountDetailsHeaderProps) => {
  const navigate = useNavigate();
  const selectedTab = useLocation().pathname.split('/').at(-1);
  const [headerData, setHeaderData] = useState<headerDataType[]>();
  const statusCd = (headerData && headerData[0]?.statusCd) || '';
  const startTs = (headerData && headerData[0]?.startTs) || '';
  const isOverViewTab = selectedTab === ROUTES.OVERVIEW;
  const { downloadContentAsPDF, isDownloading } = useDownload();
  const rootPath = PAGE_URLS.CYCLE_COUNTS_DETAILS(taskId ?? '', countTypeCdValue);

  const [isModalOpen, setModalOpen] = useState(false);
  const [downloadWithFilters, setDownloadWithFilters] = useState<boolean>(true);
  const [openExportDropdown, setOpenExportDropdown] = useState<boolean>(false);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);
  const [exportType, setExportType] = useState<DownloadFileType>('');

  /* Query for get header data using  taskId & countTypeCdValue  */
  const { wallToWallData: getHeaderComponentData } = useCycleCount({
    searchPage: {
      page: PAGE,
      size: SINGLE_PAGE_SIZE,
      sort: [
        {
          sortBy: SORT_BY.END_TS,
          direction: SORT_ORDER.DESC,
        },
      ],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: countTypeCdValue,
        taskId: taskId,
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
      ],
    },
  });

  useEffect(() => {
    if (countTypeCdValue && taskId && Array.isArray(getHeaderComponentData?.content)) {
      getHeaderComponentData?.content.map((data) => {
        const headerComponentData: headerDataType[] = [];
        if (
          data.year != null &&
          data.countTypeCd != null &&
          data.phase != null &&
          data.week != null
        ) {
          const resultCountTypeCd = checkCountTypeCd(data.countTypeCd);
          headerComponentData.push({
            year: data.year,
            countTypeCd: resultCountTypeCd || '',
            phase: data.phase,
            week: data.week,
            statusCd: data.statusCd,
            startTs: data.startTs || '',
          });
        }
        setHeaderData(headerComponentData);
      });
    }
  }, [countTypeCdValue, taskId, getHeaderComponentData?.content]);

  //Queries
  const { refetch: downloadFile } = useDownloadSubzones({
    searchPage: {
      page: DEFAULT_PAGE,
      size: PAGE_SIZE,
      sort: [{ sortBy: SORT_BY.YEAR, direction: DESC }],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: countTypeCdValue,
        taskId: taskId,
        statusCd: [statusCd],
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_LOCATION,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_PERFORM_ACTION,
      ],
    },
    type: exportType === FILE_TYPES.PDF ? 'application/pdf' : 'application/csv',
  });

  const breadcrumbs = {
    data: [
      {
        label: t('CycleCount.Title'),
        onClick: () => navigate(PAGE_URLS.CYCLE_COUNTS),
      },
      {
        label: t('CycleCount.countTypeBreadcrumbs', {
          dynamicBreadcrumbs: checkCountTypeCd(countTypeCdValue),
        }),
        onClick: () => navigate(''),
      },
    ],
  };

  const date: Date | undefined = startTs ? new Date(startTs) : undefined;
  const formattedDate = date ? generateDateString(date, 'DD/MM/YYYY HH:mm:ss') : '';

  const toggleExportDropDown = () => setOpenExportDropdown(!openExportDropdown);
  const toggleDownloadFilter = () => setDownloadWithFilters(!downloadWithFilters);

  const handleDownload = (type: DownloadFileType) => {
    setIsExportInProgress(true);
    setExportType(type);
    toggleExportDropDown();
  };

  useEffect(() => {
    if (exportType !== '') {
      downloadFile()
        .then(() => {
          setIsExportInProgress(false);
          setExportType('');
          return;
        })
        .catch((_) => {
          setExportType('');
          setIsExportInProgress(false);
        });
    }
  }, [downloadFile, exportType]);

  return (
    <>
      {headerData ? (
        <MasterTitle
          breadcrumbProps={breadcrumbs}
          title={`${headerData[0].countTypeCd + ' - '} ${headerData[0].year}  
                  ${
                    headerData[0].countTypeCd === HEADER_COMPONENT.WALL_TO_WALL
                      ? HEADER_COMPONENT.PHASE + headerData[0].phase
                      : headerData[0].countTypeCd === HEADER_COMPONENT.RISK_BASED
                      ? HEADER_COMPONENT.PERIOD +
                        headerData[0].phase +
                        ' ' +
                        HEADER_COMPONENT.WEEK +
                        ' ' +
                        headerData[0].week
                      : ''
                  } `}
          titleActionProps={{
            label: 'Favorite',
            handleClick: () => {
              return;
            },
          }}
          statusBadgeProps={{
            variant: statusToBadgeVariant(statusCd ?? ''),
            text: statusCd ?? '',
          }}
          subtitle={
            <View direction="row" gap={4}>
              <View.Item>
                <Text color="600">{`${t('CycleCount.Started', { date: formattedDate })}`}</Text>
              </View.Item>
            </View>
          }
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item>
              <Dropdown
                className={styles['cycle-count-details-header__download_content']}
                padding={2}
                variant="ghost"
                width={300}
              >
                <Dropdown.Button
                  className={styles['cycle-count-details-header__download_content__button']}
                  onClick={toggleExportDropDown}
                  disabled={isExportInProgress}
                >
                  {isExportInProgress || isDownloading ? (
                    <Button loading={true} variant="ghost" />
                  ) : (
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text size="100" weight="bold">
                        {t('InboundDashboard.Export.Title')}
                      </Text>
                    </View>
                  )}
                </Dropdown.Button>
                <Dropdown.Content>
                  <View gap={2} padding={3}>
                    {!isOverViewTab && (
                      <View
                        direction="row"
                        align="center"
                        gap={2}
                        className={
                          styles['cycle-count-details-header__download_content__button-details']
                        }
                        as="div"
                        attributes={{
                          onClick: () => handleDownload(FILE_TYPES.CSV),
                        }}
                      >
                        <Icon svg={Download} />
                        <Text size="075" weight="bold">
                          {t('InboundDashboard.Export.CSV')}
                        </Text>
                      </View>
                    )}
                    <View
                      direction="row"
                      align="center"
                      gap={2}
                      className={
                        styles['cycle-count-details-header__download_content__button-details']
                      }
                      as="div"
                      attributes={{
                        onClick: () =>
                          isOverViewTab
                            ? downloadContentAsPDF('OverView')
                            : handleDownload(FILE_TYPES.PDF),
                      }}
                    >
                      <Icon svg={Download} />
                      <Text size="075" weight="bold">
                        {t('InboundDashboard.Export.PDF')}
                      </Text>
                    </View>
                    {!isOverViewTab && (
                      <>
                        <View>
                          <Divider blank color="300" />
                        </View>
                        <View direction="row" gap={2} align="center">
                          <Checkbox
                            checked={downloadWithFilters}
                            label={t('InboundDashboard.Export.FilterTitle')}
                            value="Exportfilters"
                            name="Exportfilters"
                            onChange={() => toggleDownloadFilter()}
                          />
                        </View>
                      </>
                    )}
                  </View>
                </Dropdown.Content>
              </Dropdown>
            </View.Item>
            <ActionDropdownMenu>
              <View padding={[1, 0]}>
                <View.Item>
                  <View padding={[3, 4]}>
                    <Actionable
                      fullWidth
                      onClick={() => navigate(`${rootPath}/${ROUTES.SUBZONES}`)}
                    >
                      <Text>{t('CycleCount.ManageAssignees')}</Text>
                    </Actionable>
                  </View>
                </View.Item>
                <View.Item>
                  <View padding={[3, 4]}>
                    <Actionable
                      fullWidth
                      onClick={() => setModalOpen(true)}
                      disabled={statusCd === CYCLE_COUNT_STATUS_CD.COMPLETE}
                    >
                      <Text color="error">{t('CycleCount.EndCount')}</Text>
                    </Actionable>
                  </View>
                </View.Item>
              </View>
            </ActionDropdownMenu>
          </View>
        </MasterTitle>
      ) : (
        <Loader color="primary" />
      )}
      {isModalOpen && headerData && (
        <EndCountModal
          progressValue={progressValue}
          isOpen={isModalOpen}
          countTypeCdValue={countTypeCdValue}
          taskId={taskId}
          year={headerData[0]?.year || Number('')}
          phase={headerData[0]?.phase || Number('')}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};
