/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const SHIPMENT_TIME_TYPES = [
  {
    label: 'Dispatch Time(Earliest)',
    value: 'dsd',
    fullForm: '(Direct Store Delivery)',
  },
  {
    label: 'Dispatch Time(Latest)',
    value: 'po',
    fullForm: '(Standard Purchase Orders)',
  },
  {
    label: 'Amount Loaded(Highest)',
    value: 'ltl',
    fullForm: '(Less-Than-Truckload)',
  },
  {
    label: 'Amount Loaded(Lowest)',
    value: 'ltd',
    fullForm: '(Long Tail Distribution)',
  },
];
export const TRAILER_MASTERCARD_ROWS = [
  {
    trailer: null,
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 12:50:00'),
    dispatchTime: '',
    hazmat: false,
    numberLoaded: 0,
    totalLoaded: 26,
    linesCount: 0,
    totalLines: 45,
    status: 'Not Started',
    priority: false,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'PQR',
        lastName: 'XYZ',
      },
      {
        id: 2,
        firstName: 'LMN',
        lastName: 'STY',
      },
    ],
  },
  {
    trailer: null,
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 22:30:00'),
    dispatchTime: '',
    numberLoaded: 0,
    totalLoaded: 35,
    linesCount: 0,
    totalLines: 63,
    status: 'Not Started',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    trailer: null,
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 12:30:00'),
    dispatchTime: '',
    numberLoaded: 0,
    totalLoaded: 35,
    linesCount: 0,
    totalLines: 25,
    status: 'Not Started',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    trailer: null,
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 10:30:00'),
    dispatchTime: '',
    numberLoaded: 0,
    totalLoaded: 40,
    linesCount: 0,
    totalLines: 56,
    status: 'Not Started',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    trailer: null,
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 12:30:00'),
    dispatchTime: '',
    numberLoaded: 0,
    totalLoaded: 50,
    linesCount: 0,
    totalLines: 77,
    status: 'Not Started',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    trailer: 'Trailer 675432',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 1:30:00'),
    DispatchTime: '',
    numberLoaded: 15,
    totalLoaded: 40,
    linesCount: 12,
    totalLines: 45,
    hazmat: true,
    status: 'In Progress',
    priority: false,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'MNP',
        lastName: 'XYZ',
      },
      {
        id: 2,
        firstName: 'PQR',
        lastName: 'LMN',
      },
    ],
  },
  {
    trailer: 'Trailer 825629',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 2:30:00'),
    DispatchTime: '',
    numberLoaded: 24,
    totalLoaded: 40,
    linesCount: 34,
    totalLines: 56,
    hazmat: false,
    status: 'In Progress',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'KIU',
        lastName: 'LPY',
      },
      {
        id: 2,
        firstName: 'HYR',
        lastName: 'LUT',
      },
    ],
  },
  {
    trailer: 'Trailer 646382',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 00:30:00'),
    DispatchTime: '',
    numberLoaded: 24,
    totalLoaded: 40,
    linesCount: 38,
    totalLines: 75,
    hazmat: false,
    status: 'In Progress',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'PGY',
        lastName: 'JJU',
      },
      {
        id: 2,
        firstName: 'RTY',
        lastName: 'LPY',
      },
    ],
  },
  {
    trailer: 'Trailer 062574',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 15:30:00'),
    DispatchTime: '',
    numberLoaded: 12,
    totalLoaded: 20,
    linesCount: 59,
    totalLines: 82,
    hazmat: true,
    status: 'In Progress',
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'LUY',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'ALP',
        lastName: 'VBY',
      },
    ],
  },
  {
    trailer: 'Trailer 675432',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 22:30:00'),
    hazmat: true,
    DispatchTime: '',
    numberLoaded: 48,
    totalLoaded: 48,
    linesCount: 82,
    totalLines: 82,
    status: 'Shipped',
    priority: false,
  },
  {
    trailer: 'Trailer 825629',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 5:30:00'),
    DispatchTime: '',
    numberLoaded: 30,
    totalLoaded: 30,
    linesCount: 76,
    totalLines: 76,
    status: 'Shipped',
  },
  {
    trailer: 'Trailer 646382',
    route: 'Route #123456',
    hazmat: true,
    trailerDispatchTime: new Date('01/15/24 4:30:00'),
    DispatchTime: '',
    numberLoaded: 40,
    totalLoaded: 40,
    linesCount: 46,
    totalLines: 46,
    status: 'Shipped',
  },
  {
    trailer: 'Trailer 062574',
    route: 'Route #123456',
    hazmat: true,
    trailerDispatchTime: new Date('01/15/24 23:30:00'),
    DispatchTime: '',
    numberLoaded: 20,
    totalLoaded: 20,
    linesCount: 89,
    totalLines: 89,
    status: 'Shipped',
  },
  {
    trailer: 'Trailer 458912',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 12:30:00'),
    DispatchTime: '',
    numberLoaded: 40,
    totalLoaded: 40,
    linesCount: 63,
    totalLines: 63,
    status: 'CT Dock Lane Closed',
    hazmat: false,
    priority: false,
  },
  {
    trailer: 'Trailer 093487',
    route: 'Route #123456',
    trailerDispatchTime: new Date('01/15/24 12:45:00'),
    DispatchTime: '',
    numberLoaded: 30,
    totalLoaded: 30,
    linesCount: 85,
    totalLines: 85,
    hazmat: false,
    status: 'CT Dock Lane Closed',
  },
];
