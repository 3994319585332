/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Accordion, FormControl, Text, TextField, View } from '@az/starc-ui';
import styles from '@mdm/pages/RDM/ProductManager/Product/Detail/ProductDetail.module.scss';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { useFormContext } from 'react-hook-form';
import { WarehouseProductViewSchema } from '@shared/schemas/warehouseProductSchema.ts';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const ProductAlias = () => {
  /* Constants */
  type FormData = z.infer<typeof WarehouseProductViewSchema>;
  const { t } = useTranslation();
  const { register, getValues } = useFormContext<FormData>();

  return (
    <View padding={[4, 0]} backgroundColor="secondary">
      <Accordion
        className={styles['product-detail__accordion-wrapper']}
        headerOptions={{
          headerElement: (
            <View direction="row">
              <View.Item grow>
                <Text color="primary" size="125" weight="bold">
                  {t('ProductDetails.Aliases.Title')}
                </Text>
              </View.Item>
            </View>
          ),
        }}
      >
        <View padding={[0, 0, 6, 0]} direction="row" gap={4}>
          {(getValues('codes') || []).map((_code, index) => {
            return (
              <>
                <View.Item columns={{ s: 12, l: 4 }}>
                  <FormLabel text={t('Type')} isRequired={true} />
                  <FormControl>
                    <TextField
                      defaultValue=""
                      attributes={{
                        'data-testid': 'product-alias-type',
                      }}
                      inputAttributes={{
                        ...register(`codes.${index}.productCodeType`),
                      }}
                      disabled={true}
                    />
                  </FormControl>
                </View.Item>
                <View.Item columns={{ s: 12, l: 4 }}>
                  <FormLabel text={t('Alias')} isRequired={true} />
                  <FormControl>
                    <TextField
                      defaultValue=""
                      attributes={{
                        'data-testid': 'product-alias-valie',
                      }}
                      inputAttributes={{
                        ...register(`codes.${index}.productCodeValue`),
                      }}
                      disabled={true}
                    />
                  </FormControl>
                </View.Item>
                <View.Item columns={{ s: 12, l: 4 }}>
                  <FormLabel text={t('Source')} isRequired={true} />
                  <FormControl>
                    <TextField
                      defaultValue=""
                      attributes={{
                        'data-testid': 'product-alias-source',
                      }}
                      inputAttributes={{
                        ...register(`codes.${index}.productCodeSource`),
                      }}
                      disabled={true}
                    />
                  </FormControl>
                </View.Item>
              </>
            );
          })}
        </View>
      </Accordion>
    </View>
  );
};
