/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { consolidationLocationService } from '@mdm/services/consolidationLocationService.ts';
import { useEffect } from 'react';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

export const useGetConsolidationLocationById = (
  params: { layoutKey: string },
  isEnabled = true
) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: locationData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['consolidationLocation', params],
    queryFn: () => consolidationLocationService.getLocationById(params.layoutKey),
    enabled: isEnabled,
    cacheTime: 0,
  });

  useEffect(() => {
    if (isEnabled && isError) {
      handleNotification(
        NOTIFICATION_TYPES.ERROR,
        t('Errors.Detail.ConsolidationLocation.Description')
      );
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { locationData, isLoading, isError };
};
