/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { isNumeric } from '@inbound/utils/utils';

export const PurchaseOrderFinalizeSchema = z.object({
  autoZoneId: z
    .string()
    .min(1)
    .refine((autoZoneId) => isNumeric(autoZoneId)),
  pincode: z.string().min(1).optional(),
});
