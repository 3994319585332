/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { View, Text, Skeleton, Box } from '@az/starc-ui';

import { EmptyState } from '@shared/components/EmptyState/EmptyState';

import { INBOUND_ORDER_STATUS } from '@inbound/constants/constants';

import { DISCREPANCY_STATUS, headersData } from '@inventory/constants/constants';

import { PODiscrepancyTabsProps } from './PODiscripancy.types';
import { OrderLinesCard } from './OrderLinesCard';

import styles from './PODiscrepancy.module.scss';

export const CheckedDiscrepancy = ({
  inboundOrderData,
  isOrderLoading,
  isInboundOrderFeatching,
  setIsEditOrderLinesCount,
}: PODiscrepancyTabsProps) => {
  /* Constants */
  const { t } = useTranslation();

  /* Hooks */
  const inboundOrderLinesData = useMemo(() => {
    const inboundOrderLines = inboundOrderData?.inboundOrder?.inboundOrderLines?.filter(
      (line) => line.statusCd === INBOUND_ORDER_STATUS.IC_REVIEW_COMPLETED
    );

    return inboundOrderLines;
  }, [inboundOrderData]);

  return inboundOrderLinesData?.length || isOrderLoading ? (
    <View direction="row" padding={6} className={styles['po-discrepancy']}>
      <View
        padding={[3, 4]}
        width="100%"
        direction="row"
        align="center"
        className={styles['po-discrepancy__header']}
      >
        {headersData.map((header) => (
          <View key={header.label} width={header.width}>
            <Text weight="medium">{t(header.label)}</Text>
          </View>
        ))}
      </View>
      <View direction="row" gap={2} width="100%">
        {!isOrderLoading ? (
          inboundOrderLinesData?.map((order) => {
            return (
              <OrderLinesCard
                key={order.productId}
                orderLines={order}
                inboundOrderData={inboundOrderData}
                isInboundOrderFeatching={isInboundOrderFeatching}
                status={DISCREPANCY_STATUS.CHECKED}
                setIsEditOrderLinesCount={setIsEditOrderLinesCount}
              />
            );
          })
        ) : (
          <View direction="row" gap={2} width="100%">
            <Skeleton width="100%" height="20vh" />
            <Skeleton width="100%" height="20vh" />
            <Skeleton width="100%" height="20vh" />
          </View>
        )}
      </View>
    </View>
  ) : (
    <View justify="center" align="center" className={styles['po-discrepancy__empty']} height="60vh">
      <View width="360px">
        <EmptyState
          svg={Box}
          subtitle={t('FinalizationDashboard.Empty.Checked.Subtitle')}
          text={t('FinalizationDashboard.Empty.Checked.Text')}
        />
      </View>
    </View>
  );
};
