/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Button, Divider, Icon, Skeleton } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import styles from './Inventory.module.scss';
import { useEffect, useState } from 'react';
import { ShiftGoalInventoryModal } from '@taskManagement/components/Modals/ShiftGoalInventoryModal/ShiftGoalInventoryModal';
import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';
import { useTranslation } from 'react-i18next';
import { Default_PIECES, MAX_SIZE, PAGE } from '@taskManagement/constants/constants';
import { ActionViewAll, Checkmark } from '@az/starc-ui-icons';
import { UserSearch } from '@taskManagement/components/UserSearch/UserSearch';
import { UserSearchSchemaType } from '@shared/types/schema.type';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { FIRST_NAME } from '@shared/constants/constants';
import { ASC } from '@inbound/constants/constants';

export const Inventory = () => {
  /* Constants */
  const { t } = useTranslation();

  /* State */
  const [isShiftGoalsModalOpen, setIsShiftGoalsModalOpen] = useState(false);
  const [isShiftGoal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [suggestedUsers, setSuggestedUsers] = useState<UserSearchSchemaType[]>([]);
  const [isFilteringUsers, setIsFilteringUsers] = useState(false);

  /* Queries */
  const { usersSearchData: usersData, isLoading: usersDataLoading } = useUsersSearch({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: FIRST_NAME,
      direction: ASC,
    },
    searchCriteria: {},
  });

  /* Functions */
  const onClickViewShiftGoals = () => {
    setIsShiftGoalsModalOpen(true);
  };

  const onShiftGoalModalClose = () => {
    setIsShiftGoalsModalOpen(false);
  };

  const handleItemSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSearchItemClick = (item: UserSearchSchemaType | string) => {
    // TODO - Add logic when upcoming ticket is created
    // eslint-disable-next-line no-console
    console.log(item);
  };

  /* Hooks */
  useEffect(() => {
    if (searchValue.trim().length > 0 && usersData?.content && usersData.content.length > 0) {
      setIsFilteringUsers(true);
      const filteredUsers = usersData.content.filter((user) => {
        return (
          user.employeeId.includes(searchValue) ||
          user.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setIsFilteringUsers(false);
      setSuggestedUsers(filteredUsers);
    } else {
      setSuggestedUsers([]);
    }
  }, [searchValue, usersData]);

  return (
    <>
      <ShiftGoalInventoryModal
        isOpen={isShiftGoalsModalOpen}
        onClose={onShiftGoalModalClose}
        // eslint-disable-next-line no-console
        onSave={(data) => console.log('Shift Goal Data:', data)}
        shiftName="1st Shift"
        ValidatorCount={5}
        timeLeftInHour={8}
      />

      <View direction="row">
        <MasterTitle
          title="Inventory Control Task Manager"
          subtitle={t(`InventoryTaskManager.Subtitle_one`, { count: 5 })}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          titleActionProps={{ label: 'Favorite', handleClick: () => {} }}
        >
          <View direction="row" justify="end">
            <View.Item columns={4}>
              {usersDataLoading ? (
                <>
                  <Skeleton borderRadius="small" />
                  <Skeleton borderRadius="small" />
                </>
              ) : (
                <UserSearch
                  options={suggestedUsers}
                  isSearchLoading={isFilteringUsers}
                  label={t('InboundTaskManager.Search.Placeholder')}
                  onItemSearch={handleItemSearch}
                  onUserItemClick={handleSearchItemClick}
                />
              )}
            </View.Item>
          </View>
        </MasterTitle>
      </View>

      <View
        gap={6}
        direction="row"
        backgroundColor="secondary"
        padding={[6, 6]}
        attributes={{ style: { margin: '0px' } }}
      >
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle="Shift Progress"
              widgetSubtitle="2nd Shift | 10 Inventory Control AutoZoners"
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                // Add your logic to handle the click event of the icon button
              }}
            />
            <WidgetWithChildren.Body>
              <View gap={2}>
                <View direction="row">
                  <Text size="100" variant="subtitle-bold">
                    {t(`ShiftProgress.NewShiftStarted`)}
                  </Text>
                </View>
                <View direction="row" align="center" justify="space-between" gap={2}>
                  <View.Item grow>
                    <Text size="087" color="500" className={styles['widget_subtext']}>
                      {t('ShiftGoalHistory.PreviousInventoryStatus', {
                        completed: 5950,
                        total: 6000,
                        difference: 50,
                      })}
                    </Text>
                  </View.Item>
                  <View.Item>
                    <Button size="small" onClick={onClickViewShiftGoals}>
                      {t(`ShiftProgress.SetShiftGoalBtn`)}
                    </Button>
                  </View.Item>
                </View>

                <Divider color="300" />

                <View gap={2} direction="row" align="center" justify="space-between">
                  <View direction="row" align="baseline" gap={1}>
                    <View>
                      <Text variant="display-3">{0}</Text>
                    </View>
                    <View>
                      <Text variant="subtitle-bold">
                        {t(`ShiftGoalHistory.InventoryColumns.TotalPieces`)}
                      </Text>
                    </View>
                  </View>
                  {!isShiftGoal && (
                    <View align="center">
                      <Text
                        size="087"
                        variant="subtitle-regular"
                        className={styles['widget_no_shift']}
                      >
                        {t(`ShiftProgress.NoShiftGoal`)}
                      </Text>
                    </View>
                  )}
                </View>

                <View gap={0} direction="row" align="center" width="100%">
                  <Text
                    size="075"
                    variant="subtitle-bold"
                    textCase="uppercase"
                    className={styles['widget_breakdown_text']}
                  >
                    {t(`ShiftGoalHistory.InventoryBreakDown`)}
                  </Text>
                </View>
                {Default_PIECES?.map((piecesTypes, index) => (
                  <View>
                    <View
                      padding={[2, 2]}
                      direction="row"
                      align="center"
                      justify="space-between"
                      width="100%"
                    >
                      <View gap={0} direction="row" align="center">
                        <Text>
                          <Icon svg={Checkmark} />
                        </Text>

                        <Text
                          size="087"
                          variant="subtitle-regular"
                          className={styles['widget_regular_po']}
                        >
                          {t(piecesTypes?.label)}
                        </Text>
                      </View>
                      <View>
                        <Text size="087" variant="subtitle-regular">
                          {piecesTypes?.value}
                        </Text>
                      </View>
                    </View>
                    {index < Default_PIECES.length - 1 && (
                      <View>
                        <Divider color="500" />
                      </View>
                    )}
                  </View>
                ))}
              </View>
            </WidgetWithChildren.Body>
          </WidgetWithChildren>
        </View.Item>
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle="Cycle Count Overview"
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                // Add your logic to handle the click event of the icon button
              }}
            />
          </WidgetWithChildren>
        </View.Item>
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle="Slot Book"
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                // Add your logic to handle the click event of the icon button
              }}
            />
          </WidgetWithChildren>
        </View.Item>
      </View>
    </>
  );
};
