/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useOutletContext } from 'react-router-dom';

import { useAtom } from 'jotai';

import { View, Text } from '@az/starc-ui';

import { OrderReleaseAccordion } from '@outbound/components/OrderReleaseAccordion/OrderReleaseAccordion';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import { OrderSection } from '@outbound/components/OrderSection/OrderSection';

import { releaseOrders, allLaneOptions, crossDockOrders } from '../data';

import styles from '../OrderTabs.module.scss';

export const CrossDock = () => {
  /*Atoms*/
  const [laneConfirmationItem] = useAtom(laneConfirmationItemAtom);
  const [inLaneConfirmation] = useAtom(inLaneConfirmationAtom);

  /* Constants */
  const [getSelectionStatusLabel]: [() => string] = useOutletContext();

  return (
    <View direction="column" padding={6} className={styles['order-release']}>
      {!inLaneConfirmation && <Text>{getSelectionStatusLabel()}</Text>}
      <View direction="column" className={styles['order-release__content']}>
        <OrderSection>
          {(!inLaneConfirmation || laneConfirmationItem) &&
            crossDockOrders.map((dc) => (
              <OrderReleaseAccordion
                item={dc}
                order={releaseOrders.crossDockOrders}
                laneOptions={allLaneOptions}
              />
            ))}
        </OrderSection>
      </View>
    </View>
  );
};
