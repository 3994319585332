/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Actionable, Tooltip } from '@az/starc-ui';

import { t } from 'i18next';

import { MatrixHeaderConst } from '@outbound/constants/constants';

import { MatrixHeaderProps } from './MatrixAccordion.types';
import styles from './MatrixAccordion.module.scss';
import classNames from 'classnames';

export const MatrixHeader = ({ data, displayOptions, onExpand, onCollapse }: MatrixHeaderProps) => {
  let storeIndex = data.length
    ? data.map((item) => item.stores.length).reduce((a, b) => a + b) + 1
    : 0;

  return (
    <View direction="row" wrap={false} align="stretch" className={styles['matrix-header']}>
      <View padding={[4, 6, 2, 6]} direction="column" className={styles['matrix__location']}>
        <Text size="100" weight="bold" className={styles[`line-height24`]}>
          {t('OutboundMatrix.Locations')}
        </Text>
        <View direction="row" gap={4} wrap={false}>
          <Actionable onClick={onExpand}>
            <Text variant="text-link" color="400" decoration="underline">
              {t('OutboundMatrix.Expand')}
            </Text>
          </Actionable>
          <Actionable onClick={onCollapse}>
            <Text variant="text-link" color="400" decoration="underline">
              {t('OutboundMatrix.Collapse')}
            </Text>
          </Actionable>
        </View>
      </View>
      {data.map((group, index) => {
        const storesCount = group.stores.length ?? 0;
        const groupDetailsDirection =
          storesCount < MatrixHeaderConst.MinWrapDetailsCount ? 'column' : 'row';

        return (
          <View.Item key={index} className={styles['matrix__tiles']}>
            <View gap={2} padding={[4, 0]} height="100%" direction="column" justify="space-between">
              {displayOptions?.waveDetails && (
                <View
                  direction={groupDetailsDirection}
                  padding={[0, 2]}
                  align="center"
                  justify="space-between"
                  attributes={{ 'data-testid': 'wave-details' }}
                  {...(storesCount < MatrixHeaderConst.MinWrapDetailsCount
                    ? { height: 'var(--st-unit-19)' }
                    : {})}
                >
                  {group.id || group.groupBy ? (
                    <Text
                      size="087"
                      weight="medium"
                      align="center"
                      className={styles[`line-height22`]}
                    >
                      {group.groupBy ?? ''} {group.id ?? ''}
                    </Text>
                  ) : (
                    '--'
                  )}
                  <View
                    direction={groupDetailsDirection}
                    {...(storesCount < MatrixHeaderConst.MinWrapDetailsCount ? {} : { gap: 6 })}
                  >
                    {group?.pallets && (
                      <Text
                        size="087"
                        weight="medium"
                        align="center"
                        className={styles[`line-height22`]}
                      >
                        {t('OutboundMatrix.OrderRelease.Pallet', { count: group.pallets })}
                      </Text>
                    )}
                    {group.pieces && (
                      <Text
                        size="087"
                        weight="medium"
                        align="center"
                        className={styles[`line-height22`]}
                      >
                        {group.pieces}
                      </Text>
                    )}
                  </View>
                </View>
              )}
              <View direction="row" gap={2} padding={[0, 1]}>
                {group.stores.map((store, index) => {
                  storeIndex--;
                  return (
                    <View
                      key={index}
                      align="center"
                      direction="column"
                      attributes={{ 'data-testid': 'matrix-header-storeid' }}
                      className={styles['matrix-header__cell']}
                    >
                      <Text
                        size="075"
                        weight="bold"
                        align="center"
                        color="400"
                        textCase="uppercase"
                        className={styles[`line-height20`]}
                      >
                        {storeIndex}
                      </Text>
                      {store.length > MatrixHeaderConst.ShowTooltipCount ? (
                        <Tooltip
                          variant="alt"
                          placement="bottom"
                          triggerType="hover"
                          buttonTitle=""
                          svg={
                            <Text
                              size="100"
                              weight="medium"
                              align="center"
                              className={classNames(
                                styles[`line-height22`],
                                styles['matrix-header__store-number']
                              )}
                            >
                              {store}
                            </Text>
                          }
                        >
                          <View padding={[1, 4, 4, 4]}>
                            <Text>{store}</Text>
                          </View>
                        </Tooltip>
                      ) : (
                        <Text
                          size="100"
                          weight="medium"
                          align="center"
                          className={styles[`line-height22`]}
                        >
                          {store}
                        </Text>
                      )}
                    </View>
                  );
                })}
              </View>
            </View>
          </View.Item>
        );
      })}
    </View>
  );
};
