/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Ref, useEffect, useRef, useState } from 'react';
import { SearchBar, View, classNames } from '@az/starc-ui';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants';
import { UserSearchSchemaType } from '@shared/types/schema.type';
import { UserSuggestion } from '@taskManagement/components/UserSearch/UserSuggestion/UserSuggestion';
import { UserSuggestionSkeleton } from '@taskManagement/components/UserSearch/UserSuggestion/UserSuggestionSkeleton';

import * as T from './UserSearch.types';
import styles from './UserSearch.module.scss';

export const UserSearch = ({
  id,
  className,
  searchValue,
  options,
  label,
  isSearchLoading,
  onItemSearch,
  onUserItemClick,
}: T.Props) => {
  /* State variables */
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(searchValue);
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<UserSearchSchemaType | string>();
  const [isHideSuggestions, setIsHideSuggestions] = useState<boolean>(false);

  /* Functions */
  const handleItemSearch = (search: string) => {
    if (onItemSearch) {
      onItemSearch(search);
    }

    setValue(search);
    setIsHideSuggestions(false);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      setSearchInputValue((e.currentTarget as HTMLInputElement).value);
    }
  };

  const handleItemSelect = (item: UserSearchSchemaType | string) => {
    setSelectedItem(item);

    if (item !== searchValue) {
      setValue('');
      ref.current?.focus();
    }
  };

  const handleSearchSuggestions = () => {
    if (!isHideSuggestions) {
      if (isSearchLoading) {
        return <UserSuggestionSkeleton />;
      } else if (options && options.length > 0) {
        return (
          <UserSuggestion
            onItemClick={handleItemSelect}
            data={options}
            maxEntries={5}
            searchValue={value}
          />
        );
      } else {
        return <EmptySuggestions />;
      }
    }
  };

  /* Hooks */
  useEffect(() => {
    if (selectedItem) {
      onUserItemClick(selectedItem);
      setSelectedItem('');
    }
    //else navigate() on page
  }, [onUserItemClick, searchInputValue, selectedItem]);

  return (
    <View className={styles['user-search']}>
      <SearchBar
        id={id}
        label={label}
        value={value}
        hideSuggestions={!value}
        onValueChange={handleItemSearch}
        inputAttributes={{
          onKeyDown: onKeyDown,
        }}
        ref={ref as Ref<HTMLInputElement>}
        className={classNames(styles['user-search__search-bar'], className)}
        suggestions={handleSearchSuggestions()}
      />
    </View>
  );
};
