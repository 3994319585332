/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  InfoCircle,
  Modal,
  Text,
  View,
  classNames,
  Notification,
} from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import { useMutateReplenishment } from '@inbound/services/hooks/useMutateReplenishment';
import { useGetVendors } from '@inbound/services/hooks/useGetVendor';

import { EMPTY_VALUE, NOTIFICATION_TYPES, PRODUCT_ID_TYPE } from '@shared/constants/constants';
import { UserSearchSchemaType } from '@shared/types/schema.type';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton';
import { DetailsSection } from '@shared/components/DetailsSection/DetailsSection';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useGetWarehouseProducts } from '@shared/services/hooks/useGetWarehouseProducts';
import { useGetInventory } from '@shared/services/hooks/useGetInventory';
import { padWithZeros } from '@shared/utils/commonUtils';

import { LocationSelect } from './LocationSelect/LocationSelect';
import { DropdownOption } from './LocationSelect/LocationSelect.types';
import { AssigneeSelect } from './AssigneeSelect/AssigneeSelect';

import * as T from './AddManualReplenishmentModal.types';
import s from './AddManualReplenishmentModal.module.scss';

export const AddManualReplenishmentModal = ({ isOpen, onClose }: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  /* State variables */
  const [selectedLocation, setSelectedLocation] = useState<DropdownOption | null>(null);
  const [selectedAssignee, setSelectedAssignee] = useState<UserSearchSchemaType | null>(null);

  const [allocatedQty, setAllocatedQty] = useState<number>(0);
  const [availableQty, setAvailableQty] = useState<number>(0);
  const [suggestedQty, setSuggestedQty] = useState<number>();
  const [vendorId, setVendorId] = useState<string>('');
  const [lessSKUModal, setLessSKUModal] = useState<boolean>(false);

  const [mannualReplenishmentData] = useState([
    { label: t('ReplenishmentDashboard.ReplenishmentDetails.VendorName'), text: EMPTY_VALUE },
    { label: t('ReplenishmentDashboard.ReplenishmentDetails.PartNumber'), text: EMPTY_VALUE },
    { label: t('ReplenishmentDashboard.ReplenishmentDetails.SKU'), text: EMPTY_VALUE },
    {
      label: t('ReplenishmentDashboard.ReplenishmentDetails.SlotMinMax'),
      text: EMPTY_VALUE,
    },
    { label: t('ReplenishmentDashboard.ReplenishmentDetails.SlotQuantity'), text: EMPTY_VALUE },
    {
      label: t('ReplenishmentDashboard.ReplenishmentDetails.SuggestedQuantity'),
      text: EMPTY_VALUE,
    },
    {
      label: t('ReplenishmentDashboard.ReplenishmentDetails.TotalPiecesToReplenish'),
      text: EMPTY_VALUE,
    },
  ]);

  /* Queries */
  const { refetch: getProductDetail, isFetching: isProductLoading } = useGetWarehouseProducts(
    {
      productIdType: PRODUCT_ID_TYPE.PRODUCT_ID,
      products: [padWithZeros(selectedLocation?.value, 16) || ''],
      currentPage: 0,
      pageSize: 1,
      sortBy: 'productId',
      direction: 'ASC',
      entityAssociations: [
        'CATEGORY',
        'PRODUCT_CODES',
        'VENDORS',
        'WHSE_ATTRIBS',
        'WHSE_UOM_CONVERSION',
      ],
    },
    !!selectedLocation?.value
  );

  const { refetch: getInventoryLocation, isFetching: isInventoryLocationLoading } = useGetInventory(
    {
      scopeOfInventory: ['LOCATION'],
      locations: [selectedLocation?.layoutName || ''],
    },
    !!selectedLocation?.value
  );

  const { refetch: getInventoryProduct, isFetching: isInventoryProductLoading } = useGetInventory(
    {
      scopeOfInventory: ['FACILITY'],
      products: [padWithZeros(selectedLocation?.value, 16) || ''],
    },
    !!selectedLocation?.value
  );

  const { refetch: getVendors, isFetching: isVendorLoading } = useGetVendors(
    [vendorId],
    vendorId.length > 1
  );

  const { mutateReplenishment, isLoading: isCreatingReplenishment } = useMutateReplenishment();

  /* Functions */
  const onLocationSelect = (option: DropdownOption) => {
    setSelectedLocation(option);
  };

  const onAssigneeSelect = (assignee: UserSearchSchemaType) => {
    setSelectedAssignee(assignee);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnSubmit = () => {
    if (suggestedQty && suggestedQty <= 0) {
      setLessSKUModal(true);
    } else {
      setLessSKUModal(false);
      mutateReplenishment(
        {
          manualReplenishmentRequest: {
            productId: padWithZeros(selectedLocation?.value, 16),
            layoutDistinctName: selectedLocation?.layoutDistinctName || '',
            availableQty: availableQty,
            allocatedQty: allocatedQty,
            eventType: 'MANUAL_REPL',
            ...(selectedAssignee && {
              userId: selectedAssignee?.userId || '',
              firstName: selectedAssignee?.firstName || '',
              lastName: selectedAssignee?.lastName || '',
            }),
          },
        },
        {
          onSuccess: () => {
            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('ReplenishmentDashboard.AddReplenishment.MannualReplenishmentSuccess')
            );
            onClose();
          },
          onError: () => {
            handleNotification(
              NOTIFICATION_TYPES.ERROR,
              t('ReplenishmentDashboard.AddReplenishment.MannualReplenishmentError')
            );
          },
        }
      );
    }
  };

  /* Hooks */
  useEffect(() => {
    if (selectedLocation) {
      const loadProduct = async () => {
        let slotQuantity = 0;
        let totalAllocated = 0;
        const product = await getProductDetail();
        const inventoryLocation = await getInventoryLocation();
        const inventoryProducts = await getInventoryProduct();
        if (inventoryLocation.data) {
          inventoryLocation.data.forEach((location) => {
            if (location.buckets) {
              slotQuantity += location.buckets.AVAILABLE;
              totalAllocated += location.buckets.ALLOCATED;
            }
          });
          setAllocatedQty(totalAllocated);
          setAvailableQty(slotQuantity);
        }
        const suggestedQty = selectedLocation.max - slotQuantity;
        setSuggestedQty(suggestedQty >= 0 ? suggestedQty : 0);
        if (product.data && product.data.content.length > 0) {
          if (product.data.content[0].productVendors.length > 0) {
            setVendorId(product.data.content[0].productVendors[0].vendorId);
          }
          mannualReplenishmentData[1].text = product.data.content[0].partNumber || EMPTY_VALUE;
          mannualReplenishmentData[2].text = product.data.content[0].productId || EMPTY_VALUE;
          mannualReplenishmentData[3].text =
            `${selectedLocation?.min}/${selectedLocation?.max}` || EMPTY_VALUE;
          mannualReplenishmentData[4].text = slotQuantity.toString() || EMPTY_VALUE;
          mannualReplenishmentData[5].text = suggestedQty >= 0 ? suggestedQty.toString() : '0';
          if (
            inventoryProducts.data &&
            inventoryProducts.data.length > 0 &&
            inventoryProducts.data[0].buckets
          ) {
            mannualReplenishmentData[6].text =
              inventoryProducts.data[0].buckets.AVAILABLE.toString();
          } else {
            mannualReplenishmentData[6].text = EMPTY_VALUE;
          }
        }
      };
      loadProduct();
    }
  }, [
    getInventoryLocation,
    getInventoryProduct,
    getProductDetail,
    mannualReplenishmentData,
    selectedLocation,
  ]);

  useEffect(() => {
    if (vendorId) {
      const loadVendor = async () => {
        const vendor = await getVendors();
        if (vendor.data) {
          mannualReplenishmentData[0].text = vendor.data[0].name;
        } else {
          mannualReplenishmentData[0].text = EMPTY_VALUE;
        }
      };
      loadVendor();
    }
  }, [getVendors, mannualReplenishmentData, vendorId]);

  return (
    <Modal
      open={isOpen}
      onClose={() => handleOnClose()}
      className={s['add-manual-replenishment-modal']}
      closeByClickAway={false}
    >
      <View className={s['add-manual-replenishment-modal__header']}>
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {lessSKUModal
              ? t('ReplenishmentDashboard.AddReplenishment.CannotReplenishLocation')
              : t('ReplenishmentDashboard.AddReplenishment.Title')}
          </Text>
        </View>
        {lessSKUModal ? undefined : (
          <Button
            variant="ghost"
            onClick={handleOnClose}
            className={classNames(
              s['close-icon'],
              s['add-manual-replenishment-modal__header__close-button']
            )}
          >
            <Icon svg={Close} />
          </Button>
        )}
      </View>
      {lessSKUModal ? (
        <View direction="column" gap={4} className={s['add-manual-replenishment-modal__body']}>
          <View direction="column">
            <Text size="100" weight="medium" color="primary">
              {t('ReplenishmentDashboard.AddReplenishment.CannotReplenishLocationMessage')}
            </Text>
          </View>
        </View>
      ) : (
        <View direction="column" gap={4} className={s['add-manual-replenishment-modal__body']}>
          <View.Item>
            <Notification
              type="custom"
              className={s['add-manual-replenishment-modal__body__notification_info']}
              customNotification={{
                svg: InfoCircle,
                barColor: 'info',
                iconColor: 'info',
              }}
              text={t('ReplenishmentDashboard.AddReplenishment.Info')}
            />
          </View.Item>
          <View.Item>
            <LocationSelect
              name="location"
              selectedLocation={selectedLocation}
              searchable
              onSelect={onLocationSelect}
            />
          </View.Item>
          {selectedLocation && (
            <View.Item>
              {isProductLoading ||
              isInventoryLocationLoading ||
              isInventoryProductLoading ||
              isVendorLoading ? (
                <DetailsSectionSkeleton items={6} />
              ) : (
                <DetailsSection
                  header=""
                  className={s['add-manual-replenishment-modal__details-section']}
                  rows={mannualReplenishmentData}
                />
              )}
            </View.Item>
          )}

          <View.Item>
            <AssigneeSelect
              name="location"
              label={selectedLocation ? selectedLocation.label : 'Select SRC Location'}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              onSelect={onAssigneeSelect}
            />
          </View.Item>
        </View>
      )}

      <View className={s['add-manual-replenishment-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={s['add-manual-replenishment-modal__footer__actions']}
        >
          <Button
            variant="secondary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnClose}
            disabled={isCreatingReplenishment}
          >
            <Text>{t('Cancel')}</Text>
          </Button>
          {lessSKUModal ? undefined : (
            <Button
              variant="primary"
              attributes={{ style: { width: 'fit-content' } }}
              onClick={handleOnSubmit}
              loading={isCreatingReplenishment}
              disabled={!selectedLocation}
            >
              <Text>{t('ReplenishmentDashboard.AddReplenishment.Add')}</Text>
            </Button>
          )}
        </View>
      </View>
    </Modal>
  );
};
