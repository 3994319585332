/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Checkbox, Divider, Text, View } from '@az/starc-ui';

import styles from './SubzonesSuggestion.module.scss';
import { Props } from './SubzonesSuggestion.types';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { SUBZONE_FIELD, VEHICLE_FIELD } from '@mdm/constants/constants.ts';
import { AssetLayoutMappingType } from '@mdm/types/schema.type.ts';
import { useEffect, useState } from 'react';
import style from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/AddVehicleSubzoneModal.module.scss';
import { useTranslation } from 'react-i18next';

export const SubzonesSuggestion = ({
  data,
  subzonesData,
  existingSubzones,
  onItemClick,
  onAttributeClick,
  configData,
}: Props) => {
  /* State */
  const [selectedSubzones, setSelectedSubzones] = useState<AssetLayoutMappingType[]>([]);

  /* Constant */
  const { t } = useTranslation();

  /* Functions */
  const isSelected = (layoutDistinctName: string) => {
    if (existingSubzones) {
      return (
        existingSubzones.filter((item) => item.layoutDistinctName === layoutDistinctName).length > 0
      );
    }

    return false;
  };

  const isAttributeSelected = (layoutDistinctName: string, attributeName: string) => {
    if (existingSubzones) {
      const foundLayout = existingSubzones.find(
        (item) => item.layoutDistinctName === layoutDistinctName
      );
      if (foundLayout) {
        const foundAttribute = foundLayout.assetLayoutMappingAttributes?.find(
          (item) => item.name === attributeName
        );

        return foundAttribute?.value === 'true';
      }
    }

    return false;
  };

  const getSubzoneName = (layoutDistinctName: string) => {
    const subzone = subzonesData?.find(
      (item) => item.layout.layoutDistinctName === layoutDistinctName
    );
    return subzone ? subzone.layout.layoutName : '';
  };

  const getSubzoneDescription = (layoutDistinctName: string) => {
    const subzone = subzonesData?.find(
      (item) => item.layout.layoutDistinctName === layoutDistinctName
    );
    return subzone ? subzone.layout.layoutNameDesc : '';
  };

  const handleSuggestionClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    subzone: AssetLayoutMappingType
  ) => {
    onItemClick?.(subzone, e.target.checked);
  };

  const handleAttributeClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    subzone: AssetLayoutMappingType,
    attributeName: string
  ) => {
    onAttributeClick?.(subzone, e.target.checked, attributeName);
  };

  /* Hooks */
  useEffect(() => {
    setSelectedSubzones(existingSubzones || []);
  }, [existingSubzones]);

  return (
    <View direction="column" padding={[2, 0]} className={styles['subzones-suggestion__container']}>
      <View.Item>
        <View direction="column" className={styles['subzones-suggestion__section']}>
          <View.Item>
            <View className={styles['subzones-suggestion__section__title']}>
              <Text>
                {t('VehicleDetails.AddSubzoneModal.Subzones.Selected')} ({selectedSubzones.length})
              </Text>
            </View>
          </View.Item>
          <View.Item>
            <View className={styles['subzones-suggestion__section__items']} direction="column">
              {data?.map((subzone) => {
                return (
                  <View
                    key={subzone.layoutDistinctName}
                    direction="column"
                    className={styles['subzones-suggestion__item']}
                  >
                    <View direction="row" justify="center" align="center">
                      <View.Item grow>
                        <View direction="row">
                          <Checkbox
                            name="selected-subzones"
                            label={
                              <>
                                <View.Item>
                                  <Text weight="bold">
                                    {getSubzoneName(subzone.layoutDistinctName)}
                                  </Text>
                                </View.Item>
                                <Divider
                                  vertical
                                  color="600"
                                  attributes={{
                                    style: {
                                      height: 'var(--st-unit-5)',
                                      margin: '0 var(--st-unit-2)',
                                    },
                                  }}
                                />
                                <View.Item>
                                  <Text>
                                    {configData.subzoneTypeOptions.find(
                                      (option) =>
                                        option.value ===
                                        (subzone.layoutAttributes
                                          ? subzone.layoutAttributes[
                                              `${getFormFieldIndexByKey(
                                                subzone.layoutAttributes,
                                                SUBZONE_FIELD.SUBZONE_TYPE
                                              )}`
                                            ]?.value
                                          : '')
                                    )?.label || '-'}
                                  </Text>
                                </View.Item>
                              </>
                            }
                            value="all"
                            defaultChecked={isSelected(subzone.layoutDistinctName)}
                            onChange={(e) => handleSuggestionClick(e, subzone)}
                            className={styles[`subzones-suggestion__item__checkbox`]}
                          />
                        </View>
                        <View>
                          <View.Item
                            attributes={{
                              style: {
                                marginTop: 'var(--st-unit-2)',
                                marginLeft: 'var(--st-unit-9)',
                              },
                            }}
                          >
                            <Text color="500">
                              {getSubzoneDescription(subzone.layoutDistinctName)}
                            </Text>
                          </View.Item>
                        </View>
                      </View.Item>
                      <View.Item>
                        <View direction="row" align="center">
                          <View.Item
                            attributes={{
                              style: {
                                marginRight: 'var(--st-unit-2)',
                              },
                            }}
                          >
                            <Checkbox
                              className={style['checkbox-field']}
                              name={`assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                                subzone.assetLayoutMappingAttributes,
                                VEHICLE_FIELD.PICKING_ALLOWED
                              )}.value`}
                              label={t('Table.VehicleList.PickingAllowed')}
                              value="false"
                              defaultChecked={isAttributeSelected(
                                subzone.layoutDistinctName,
                                VEHICLE_FIELD.PICKING_ALLOWED
                              )}
                              onChange={(e) =>
                                handleAttributeClick(e, subzone, VEHICLE_FIELD.PICKING_ALLOWED)
                              }
                            />
                          </View.Item>
                          <View.Item>
                            <Checkbox
                              className={style['checkbox-field']}
                              name={`assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                                subzone.assetLayoutMappingAttributes,
                                VEHICLE_FIELD.PUTAWAY_ALLOWED
                              )}.value`}
                              label={t('Table.VehicleList.PutawayAllowed')}
                              value="false"
                              defaultChecked={isAttributeSelected(
                                subzone.layoutDistinctName,
                                VEHICLE_FIELD.PUTAWAY_ALLOWED
                              )}
                              onChange={(e) =>
                                handleAttributeClick(e, subzone, VEHICLE_FIELD.PUTAWAY_ALLOWED)
                              }
                            />
                          </View.Item>
                        </View>
                      </View.Item>
                    </View>
                  </View>
                );
              })}
            </View>
          </View.Item>
        </View>
      </View.Item>
    </View>
  );
};
