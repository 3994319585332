/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { View, Store, Pagination } from '@az/starc-ui';
import { CheckBoxCard } from '@shared/components/CheckBoxCard/CheckBoxCard';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { SEARCH_PROPERTIES } from '@ofm/constants/dataConstants';
import { locationManager } from '@ofm/services/locationManager';
import { useStoreSearch } from '@ofm/services/useStoreSearch';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { CardGridSkeleton } from '@shared/components/Skeletons/CardGridSkeleton';
import { SetChildErrorType } from '@ofm/types/types';
import { usePageErrorHandler } from '@shared/hooks/usePageErrorHandler';
import { PAGE_ERRORS } from '@shared/constants/constants';

import s from './StoreSearch.module.scss';
import { useEffect, useState } from 'react';

export const StoreSearch = () => {
  const { t } = useTranslation();
  const { setChildError } = useOutletContext<{
    setChildError?: SetChildErrorType;
  }>();

  /* State variables */
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  /* Constants */
  const navigate = useNavigate();
  const storeParam = locationManager.getParameter(SEARCH_PROPERTIES.STORE.queryParam);

  /* Queries */

  const {
    stores,
    isError: isStoreError,
    invalidId,
    storesData,
    isStoreInitialLoading,
  } = useStoreSearch({ storeId: storeParam, currentPage: currentPage, pageSize: 30 });

  /* Hooks */
  usePageErrorHandler([{ name: PAGE_ERRORS.STORES, value: isStoreError }], setChildError);

  useEffect(() => {
    if (storesData) {
      setTotalPages(storesData?.totalPages);
    }
  }, [storesData]);

  useEffect(() => {
    setCurrentPage(0);
    setTotalPages(0);
  }, [storeParam]);

  return (
    <View padding={6} width="100%" height="100%" className={s['stores__container']}>
      {isStoreInitialLoading ? (
        <View height="100%" justify="start" padding={6}>
          <CardGridSkeleton items={6} />
        </View>
      ) : stores.length < 1 ? (
        <View padding={6} width="100%" height="100%" className={s['stores__container']}>
          <EmptyState
            svg={Store}
            subtitle={t('Empty.Search.Subtitle')}
            text={t('Empty.Search.Text', {
              value: invalidId,
            })}
          />
        </View>
      ) : (
        <View as="ul" gap={4} className={s['stores__list']}>
          {stores.map((store, index) => (
            <View.Item as="li" key={`${store}-${index}`} className={s['stores__item']}>
              <CheckBoxCard
                title={store}
                shouldHideCheckbox
                onClick={() =>
                  navigate(
                    PAGE_URLS.GENERAL_PAGE(
                      ROUTES.OUTBOUND,
                      ROUTES.ORDER_REQUEST_BILLING,
                      ROUTES.STORES,
                      store
                    )
                  )
                }
              />
            </View.Item>
          ))}
        </View>
      )}
      {totalPages > 1 && storeParam && storeParam.trim().length > 0 && (
        <View align="center">
          <Pagination
            initialPage={currentPage + 1}
            totalPages={totalPages}
            onPageChange={(currentPage) => {
              setCurrentPage(currentPage - 1);
            }}
            attributes={{ style: { visibility: isStoreInitialLoading ? 'hidden' : 'unset' } }}
          />
        </View>
      )}
    </View>
  );
};
