/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import html2canvas from 'html2canvas';
import { pdf, Document, Page, Image as PDFImage } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ActionNotificationType } from '@ofm/types/types';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';

export const downloadAsPDF = async (
  elementRef: React.RefObject<HTMLDivElement>,
  filename = 'downloaded-page.pdf',
  handleNotification: (type: ActionNotificationType, msg: string) => void,
  t: (msg: string) => string
): Promise<void> => {
  if (!elementRef.current) {
    // eslint-disable-next-line no-console
    console.error('The reference to the element to capture is invalid.');
    return;
  }

  try {
    const canvas = await html2canvas(elementRef.current);
    const imgData = canvas.toDataURL('image/png');

    // Create a PDF document using @react-pdf/renderer
    const pdfDoc = pdf();
    pdfDoc.updateContainer(
      <Document>
        <Page size="A4" orientation="portrait">
          <PDFImage src={imgData} />
        </Page>
      </Document>
    );

    // Generate a blob from the PDF and save it with file-saver
    const pdfBlob = await pdfDoc.toBlob();
    if (pdfBlob) {
      saveAs(pdfBlob, filename);
    } else {
      // eslint-disable-next-line no-console
      console.error('Failed to generate PDF blob.');
      handleNotification(NOTIFICATION_TYPES.ERROR, t('CycleCount.Notification.PDF.Error'));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error creating PDF:', error);
    handleNotification(NOTIFICATION_TYPES.ERROR, t('CycleCount.Notification.PDF.Error'));
  }
};
