/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const headerItems = [
  {
    width: 'calc(var(--st-unit-28)*2 -  var(--st-unit-1))',
    text: 'Product Description',
    name: 'productLocaleDesc',
    sortable: true,
  },
  { width: 'calc(var(--st-unit-32)*2 - 1px)', text: 'Put Locations', sortable: true },
  { width: 'calc(var(--st-unit-28) - 2px)', text: 'SKU', name: 'productId', sortable: true },
  { width: 'calc(var(--st-unit-28) - 2px)', text: 'Part#', name: 'partNbr', sortable: true },
  { width: 'calc(var(--st-unit-23) - 2px)', text: 'Ordered', name: 'ordered', sortable: true },
  { width: 'var(--st-unit-25)', text: 'Expected', name: 'expected', sortable: true },
  { width: 'calc(var(--st-unit-24) - 1px)', text: 'Received', name: 'received', sortable: true },
  { width: 'var(--st-unit-30)', text: 'Assignees', name: 'asignee', sortable: true },
  { width: 'var(--st-unit-30)', text: 'Discrepancy', name: 'discrepancy', sortable: true },
  {
    width: 'calc(var(--st-unit-20) * 2)',
    text: 'Additional Details',
    name: 'details',
    sortable: true,
  },
  { width: 'auto', text: '', name: 'action', sortable: false },
];
