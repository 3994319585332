/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { t } from 'i18next';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';
// eslint-disable-next-line import/no-cycle
import { isPositiveNumeric } from '@mdm/utils/form/validationUtils.tsx';

export const SubzoneReservePrioritySchema = z.object({
  layoutName: z.string().optional(),
  layoutDistinctName: z.string().min(1).nullable(),
  toDistinctName: z.string().min(1),
  priority: z
    .string()
    .superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.RequiredField'),
        });
      }

      if (val.length > MAX_LENGTH_FIELD.SUBZONE_RESERVE_PRIORITY) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: MAX_LENGTH_FIELD.SUBZONE_RESERVE_PRIORITY,
          type: 'string',
          inclusive: true,
          message: t('Form.MaxCharacterAmount', {
            count: MAX_LENGTH_FIELD.SUBZONE_RESERVE_PRIORITY,
          }),
        });
      }

      if (!isPositiveNumeric(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.PositiveNumericError'),
        });
      }
    })
    .nullable()
    .default('')
    .optional(),
});
