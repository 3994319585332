/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FC, useEffect } from 'react';
import { CreateRoleModalProps } from '@mdm/pages/RDM/Role/CreateRoleModal/CreateRoleModal.types.ts';
import { Button, Close, FormControl, Icon, Modal, Text, TextField, View } from '@az/starc-ui';
import styles from '@mdm/pages/RDM/Role/CreateRoleModal/CreateRoleModal.module.scss';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';
import { z } from 'zod';
import { CreateRoleSchema } from '@shared/schemas/userRoleSchema.ts';
import { useForm } from 'react-hook-form';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { ACCEPT_LANGUAGE, Language, NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useMutateUserRole } from '@shared/services/hooks/useMutateUserRole.tsx';
import { useQueryClient } from '@tanstack/react-query';

export const CreateRoleModal: FC<CreateRoleModalProps> = ({ showModal, onClose }) => {
  /* Constants */
  type FormData = z.infer<typeof CreateRoleSchema>;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const { mutateUserRole, isLoading: isUserRoleCreating } = useMutateUserRole();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    clearErrors,
    reset,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: getFormDefaults(CreateRoleSchema),
    resolver: zodResolver(CreateRoleSchema),
  });

  /* Functions */
  const onSubmit = () => {
    mutateUserRole(
      {
        userRequestType: [
          {
            roleId: getValues().roleId,
            desc: getValues().roleDesc,
            locale: ACCEPT_LANGUAGE[Language.ENGLISH],
          },
        ],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['userRoles']);
          handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.UserRole.Created'));
          onClose();
        },
      }
    );
  };

  const handleClose = () => {
    const formData = getValues();
    if (formData.roleId || formData.roleDesc) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('RecordCanceled'));
    }

    onClose();
  };

  useEffect(() => {
    if (showModal) {
      // reset field value and clear errors when modal is open
      reset();
      clearErrors();
    }
  }, [clearErrors, reset, showModal]);

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      closeByClickAway={true}
      className={styles['create-role__modal']}
      attributes={{ style: { width: '750px' } }}
    >
      <View direction="row" justify="space-between" className={styles['create-role-modal__header']}>
        <View direction="column">
          <Text
            className={styles['create-role__modal__header__title']}
            as="h2"
            size="175"
            weight="bold"
            color="primary"
          >
            {t('AddRole')}
          </Text>
        </View>
        <Button variant="ghost" onClick={handleClose}>
          <Icon svg={Close} />
        </Button>
      </View>
      <View className={styles['create-role__modal__form__container']}>
        <form>
          <View direction="row" gap={4}>
            <View.Item columns={{ s: 12, l: 6 }}>
              <View gap={2}>
                <FormLabel text={t('CreateRoleModal.RoleName')} isRequired={true} />
                <FormControl hasError={!!errors.roleId}>
                  <TextField
                    attributes={{
                      'data-testid': 'name',
                    }}
                    inputAttributes={{
                      placeholder: t('CreateRoleModal.RoleNamePlaceholder'),
                      ...register('roleId'),
                      maxLength: MAX_LENGTH_FIELD.USER_ROLE_ID,
                    }}
                    defaultValue=""
                  />

                  {errors.roleId && (
                    <View direction="row" justify="space-between">
                      <FormControl.Error>{errors.roleId.message}</FormControl.Error>
                    </View>
                  )}
                </FormControl>
              </View>
            </View.Item>
            <View.Item columns={{ s: 12, l: 6 }}>
              <View gap={2}>
                <FormLabel text={t('CreateRoleModal.RoleDescription')} isRequired={true} />
                <FormControl hasError={!!errors.roleDesc}>
                  <TextField
                    attributes={{
                      'data-testid': 'description',
                    }}
                    inputAttributes={{
                      placeholder: t('CreateRoleModal.RoleDescriptionPlaceholder'),
                      ...register('roleDesc'),
                      maxLength: MAX_LENGTH_FIELD.USER_ROLE_DESCRIPTION,
                    }}
                    defaultValue=""
                  />

                  {errors.roleDesc && (
                    <View direction="row" justify="space-between">
                      <FormControl.Error>{errors.roleDesc.message}</FormControl.Error>
                    </View>
                  )}
                </FormControl>
              </View>
            </View.Item>
          </View>
        </form>
      </View>
      <View className={styles['create-role__modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={styles['create-role__modal__footer__actions']}
        >
          <Button
            variant="secondary"
            size="large"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleClose}
          >
            <Text>{t('Cancel')}</Text>
          </Button>

          <Button
            variant="primary"
            size="large"
            loading={isUserRoleCreating}
            onClick={() => handleSubmit(onSubmit)()}
            attributes={{ style: { width: 'fit-content' } }}
          >
            <Text>{t('AddRole')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
