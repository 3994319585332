/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Checkbox, FormControl, Link, Radio, Select, Text, TextField, View } from '@az/starc-ui';
import styles from '@mdm/pages/RDM/ProductManager/Product/Detail/ProductDetail.module.scss';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { Controller, useFormContext } from 'react-hook-form';
import { WarehouseProductViewSchema } from '@shared/schemas/warehouseProductSchema.ts';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { TOTE_COMPARTMENT_SIZE_OPTIONS } from '@inbound/constants/constants.ts';
import { useSessionStorage } from '@shared/hooks/useStorage.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';

export const ProductDetailForm = () => {
  /* Constants */
  type FormData = z.infer<typeof WarehouseProductViewSchema>;
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext<FormData>();

  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const productDescription = watch('prodDesc.0.prodDesc');

  return (
    <>
      <View direction="row" gap={4}>
        <View.Item columns={{ s: 12, l: 3 }}>
          <View gap={2}>
            <Text
              className={styles['product-detail__form-field-label--required']}
              weight="medium"
              size="087"
            >
              {t('Warehouse')}
            </Text>
            <FormControl>
              <Select
                attributes={{
                  'data-testid': 'warehouse-id',
                  style: { width: 'var(--st-unit-5)' },
                }}
                label={t('Warehouse')}
                name="warehouse"
                variant="no-label"
                multiSelect={false}
                defaultValue={{
                  label: 'DC ' + selectedDC?.toString(),
                  value: 'DC ' + selectedDC?.toString(),
                }}
                options={[]}
                disabled
              />
            </FormControl>
            <Text weight="regular" color="600" size="087">
              {t('ProductDetails.WarehouseInstruction')}
            </Text>
          </View>
        </View.Item>
        <View.Item>
          <View gap={2}>
            <FormLabel text={t('ProductDetails.Sku.Label')} isRequired={true} />
            <FormControl>
              <TextField
                defaultValue=""
                attributes={{
                  'data-testid': 'productId',
                }}
                disabled={true}
                inputAttributes={{
                  ...register('productId'),
                }}
              />
            </FormControl>
          </View>
        </View.Item>
        <View.Item>
          <View gap={2}>
            <FormLabel text={t('ProductDetails.Description.Label')} isRequired={true} />
            <FormControl>
              <TextField
                attributes={{
                  'data-testid': 'description',
                }}
                disabled={true}
                value={productDescription || ''}
              />
            </FormControl>
          </View>
        </View.Item>
        <View.Item>
          <View gap={2}>
            <FormLabel text={t('ProductDetails.Status.Label')} isRequired={true} />
            <FormControl>
              <TextField
                defaultValue=""
                attributes={{
                  'data-testid': 'status',
                }}
                disabled={true}
                inputAttributes={{
                  ...register('productFacilityAttributes.whseProductStatusCd'),
                }}
              />
            </FormControl>
          </View>
        </View.Item>
      </View>
      <View direction="row" gap={4} className={[styles['form__field-row']]}>
        <FormControl
          hasError={
            !!(errors.productFacilityAttributes && errors.productFacilityAttributes.isCubescannedFl)
          }
        >
          <Controller
            control={control}
            name="productFacilityAttributes.isCubescannedFl"
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                name={name}
                value={value === 'true' ? 'true' : 'false'}
                checked={value === 'true'}
                inputAttributes={{ ref }}
                label={t('ProductDetails.Cubiscanned.Label')}
                onChange={(event) => onChange(event.target.checked.toString())}
              />
            )}
          />
          {errors.productFacilityAttributes && errors.productFacilityAttributes.isCubescannedFl && (
            <View direction="row" justify="space-between">
              <FormControl.Error>
                {errors.productFacilityAttributes &&
                  errors.productFacilityAttributes.isCubescannedFl?.message}
              </FormControl.Error>
            </View>
          )}
        </FormControl>
      </View>
      <View direction="row" gap={4} className={[styles['form__field-row']]}>
        <View
          attributes={{
            'data-testid': 'tote-compartment-sizeCd',
          }}
          gap={2}
        >
          <FormLabel text={`${t('ProductDetails.ToteCompartmentSize.Label')} (${t('Optional')})`} />
          <FormControl
            hasError={
              !!(
                errors.productFacilityAttributes &&
                errors.productFacilityAttributes.toteCompartmentSizeCd
              )
            }
          >
            <Controller
              control={control}
              name="productFacilityAttributes.toteCompartmentSizeCd"
              render={({ field: { onChange, value } }) => (
                <View direction="row" gap={4}>
                  {TOTE_COMPARTMENT_SIZE_OPTIONS.map((option) => {
                    return (
                      <Radio
                        value={option.value}
                        label={option.label}
                        checked={option.value === value}
                        onChange={(toteCompartmentSizeCd) => onChange(toteCompartmentSizeCd)}
                      />
                    );
                  })}
                </View>
              )}
            />

            <FormControl.Error>
              {errors.productFacilityAttributes &&
                errors.productFacilityAttributes.toteCompartmentSizeCd &&
                errors.productFacilityAttributes.toteCompartmentSizeCd.message}
            </FormControl.Error>
          </FormControl>
        </View>
      </View>
      <View direction="row" align="center" className={[styles['form__field-row']]} gap={2}>
        <View.Item>
          <View gap={2}>
            <FormLabel text={`${t('ProductDetails.LoadUnitCapacity.Label')} (${t('Optional')})`} />
            <FormControl
              hasError={
                !!(
                  errors.productFacilityAttributes &&
                  errors.productFacilityAttributes.loadUnitCapacityQty
                )
              }
            >
              <TextField
                defaultValue=""
                attributes={{
                  'data-testid': 'load-unit-capacity',
                }}
                inputAttributes={{
                  ...register('productFacilityAttributes.loadUnitCapacityQty'),
                  maxLength: MAX_LENGTH_FIELD.PRODUCT_LOAD_UNIT_CAPACITY,
                }}
                onChange={(event) => {
                  setValue(
                    'productFacilityAttributes.loadUnitCapacityQty',
                    event.target.value ? event.target.value : null
                  );
                  trigger('productFacilityAttributes.loadUnitCapacityQty');
                }}
              />
              <FormControl.Error>
                {errors.productFacilityAttributes &&
                  errors.productFacilityAttributes.loadUnitCapacityQty &&
                  errors.productFacilityAttributes.loadUnitCapacityQty.message}
              </FormControl.Error>
            </FormControl>
          </View>
        </View.Item>
        <View.Item>
          <Link
            onClick={() => {
              setValue('productFacilityAttributes.toteCompartmentSizeCd', '');
              setValue('productFacilityAttributes.loadUnitCapacityQty', null);
              trigger('productFacilityAttributes.toteCompartmentSizeCd');
              trigger('productFacilityAttributes.loadUnitCapacityQty');
            }}
          >
            {t('Erase')}
          </Link>
        </View.Item>
      </View>
    </>
  );
};
