/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { shiftService } from '../shiftService';
import { shiftSearchParams } from '@shared/types/service.type';

export const useShiftSearch = (options?: shiftSearchParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: shiftsSearchData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['shiftsSearch', options],
    queryFn: () => shiftService.shiftsSearch(options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Notification.ShiftsSearch'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { shiftsSearchData, isLoading, isFetching, isError, refetch };
};
