/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { apiService } from '@shared/services/apiService.ts';
import { LAYOUT_ENDPOINTS } from '@shared/constants/routes.ts';
import { SEARCH_TYPE } from '@mdm/constants/constants';
import { GetLayoutProductsParams } from '@inbound/types/service.type';
import { LayoutProductListSchema } from '@inbound/schemas/layoutProductSchema';
import { parseAndLog } from '@ofm/utils/utils';

export const layoutProductService = {
  getProducts: async (options: GetLayoutProductsParams) => {
    const payload = {
      productId: options.productId,
      layoutName: options.layoutName,
      searchType: SEARCH_TYPE.PARTIAL,
      entityAssociations: options?.entityAssociations,
    };

    const response = await apiService.post(LAYOUT_ENDPOINTS.GET_PRODUCT_BY_LAYOUT, payload);
    return parseAndLog(LayoutProductListSchema, response.data);
  },
};
