/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Table } from '@shared/components/Table/Table.tsx';
import {
  LOCATION_ASSOCIATED_SKU_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';
import { Button, Icon, Text, View } from '@az/starc-ui';
import { Props } from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationAssociatedSkuList.types.ts';
import {
  DEFAULT_PAGE,
  LOCATION_ASSOCIATED_SKUS_PAGE_SIZE,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';
import { useState } from 'react';
import { Add, LicensePlateSearch } from '@az/starc-ui-icons';
import { EmptyState } from '@shared/components/EmptyState/EmptyState.tsx';
import { useTranslation } from 'react-i18next';
import { mapLocationAssociatedSkuTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { LOCATION_ASSOCIATED_SKU_ROWS } from '@shared/components/Table/tableMockData.ts';

export const LocationAssociatedSkuList = ({ locationId }: Props) => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);

  /* Constants */
  const { t } = useTranslation();

  return (
    <View gap={4} width="100%" height="100%">
      <View direction="row">
        <View.Item grow>
          <Text size="125" weight="bold">
            {t('LocationDetails.TotalSkusInLocation', {
              count: LOCATION_ASSOCIATED_SKU_ROWS.length,
            })}
          </Text>
        </View.Item>
        <View.Item>
          <Button variant="secondary" size="small" disabled={!locationId} onClick={() => ({})}>
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} />
              <Text>{t('AddSku')}</Text>
            </View>
          </Button>
        </View.Item>
      </View>

      {!locationId ? (
        <View padding={6}>
          <EmptyState
            svg={LicensePlateSearch}
            subtitle={t('Empty.LocationSkus.Subtitle')}
            text={t('Empty.LocationSkus.Text')}
          />
        </View>
      ) : (
        <View>
          <Table
            columns={LOCATION_ASSOCIATED_SKU_TABLE_COLUMNS}
            rows={mapLocationAssociatedSkuTableRows(LOCATION_ASSOCIATED_SKU_ROWS)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={LOCATION_ASSOCIATED_SKUS_PAGE_SIZE}
            defaultPage={currentPage}
            isCreditItem={false}
            isCheckboxTable={false}
            styleVariant={TableStylingVariants.FILLED}
            totalPages={Math.ceil(
              (LOCATION_ASSOCIATED_SKU_ROWS.length || DEFAULT_PAGE - 1) / PAGE_SIZE
            )}
            onPageChange={(newPage) => {
              setCurrentPage(newPage);
            }}
            onSort={() => ({})}
          />
        </View>
      )}
    </View>
  );
};
