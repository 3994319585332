/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
  RefObject,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import { downloadAsPDF } from '@shared/utils/downloadUtils';
import { generateDateString } from '@shared/utils/commonUtils';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

type DownloadContextType = {
  contentRef: RefObject<HTMLDivElement>;
  downloadContentAsPDF: (filePrefix: string) => Promise<void>;
  isDownloading: boolean;
};

type DownloadProviderProps = {
  children: ReactNode;
};

const DownloadContext = createContext<DownloadContextType | undefined>(undefined);

export const DownloadProvider = ({ children }: DownloadProviderProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const downloadContentAsPDF = useCallback(
    async (filePrefix: string) => {
      if (!contentRef.current || isDownloading) {
        return;
      }
      try {
        setIsDownloading(true);
        const currentDate = new Date();
        const name = `${filePrefix}_${generateDateString(
          currentDate,
          t('DateFormat.CreateOrderTime').replace(/ /g, '_')
        )}.pdf`;

        await downloadAsPDF(contentRef, name, handleNotification, t);
      } finally {
        setIsDownloading(false);
      }
    },
    [handleNotification, isDownloading, t]
  );

  return (
    <DownloadContext.Provider value={{ contentRef, downloadContentAsPDF, isDownloading }}>
      {children}
    </DownloadContext.Provider>
  );
};

export const useDownload = (): DownloadContextType => {
  const context = useContext(DownloadContext);
  if (!context) {
    throw new Error('useDownload must be used within a DownloadProvider');
  }
  return context;
};
