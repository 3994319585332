/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { lazy, startTransition, useCallback, useMemo, useState } from 'react';

import { t } from 'i18next';

import { EMPTY_VALUE, USER_STATUS_CD } from '@shared/constants/constants';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

import { generateDateString } from '@shared/utils/commonUtils';

import { MasterCard } from '@inbound/components/MasterCard/MasterCard';

const LazyLoadDetailDrawer = lazy(() =>
  import('@inbound/components/ReplenishmentDetailDrawer/ReplenishmentDetailDrawer').then(
    (module) => ({
      default: module.ReplenishmentDetailDrawer,
    })
  )
);

import * as T from './ReplenishmentDashboard.types';
import { Actionable } from '@az/starc-ui';
import { MAX_VISIBLE_AVATARS_REPLENISHMENT_DASHBOARD } from '@inbound/constants/constants';

export const ReplenishmentDashboardCard: React.FC<T.ReplenishmentCardTypes> = ({
  data,
  statusVariant,
}) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const openDrawer = useCallback(() => {
    startTransition(() => setShowDrawer(true));
  }, []);

  const getUserData = useMemo(() => {
    if (data?.replnAssignedUsers && data.replnAssignedUsers.length > 0) {
      const assignedUsers = data.replnAssignedUsers?.filter(
        (user) =>
          user.userStatusCd === USER_STATUS_CD.ASSIGNED ||
          (user.userStatusCd === USER_STATUS_CD.INPROGRESS &&
            user.firstName !== null &&
            user.lastName !== null)
      );

      return assignedUsers.map((user) => {
        return {
          id: parseInt(user.userId),
          firstName: user.firstName || '',
          lastName: user.lastName || '',
        };
      });
    }
    return [];
  }, [data]);

  const isAssignedUsers =
    data.replnAssignedUsers &&
    data.replnAssignedUsers.length > 0 &&
    data.replnAssignedUsers?.some((user) => user.userStatusCd == USER_STATUS_CD.ASSIGNED);

  return (
    <>
      <Actionable fullWidth={true} onClick={() => openDrawer()}>
        <MasterCard
          title={data.replnRequest.productLongDesc ?? EMPTY_VALUE}
          replenPlanMoveKey={data.replnPlanMoveKey}
          statusBadgeProps={{
            variant: statusVariant,
            text: String(data.replnRequest.priority),
          }}
          label={t('ReplenishmentDashboard.Card.DateCreated', {
            date: data.replnRequest.srcRequestedTs
              ? generateDateString(
                  new Date(data.replnRequest.srcRequestedTs),
                  t('DateFormat.ShortTime')
                )
              : EMPTY_VALUE,
          })}
          detailsData={{
            data: [
              {
                label: t('ReplenishmentDashboard.Card.Pieces', {
                  pieces: data.totalReplnQty ?? EMPTY_VALUE,
                }),
              },
              {
                label: t('ReplenishmentDashboard.Card.SlotQty', {
                  slotQty: data.replnRequest.slotQty ? data.replnRequest.slotQty : EMPTY_VALUE,
                }),
                color:
                  data.replnRequest.slotQty && +data.replnRequest.slotQty <= 0
                    ? 'error'
                    : undefined,
              },
              ...(statusVariant !== StatusVariants.NOT_STARTED
                ? [
                    {
                      label: t('ReplenishmentDashboard.Card.Outs', {
                        outs:
                          data.replnRequest.currentBaseCostAmt && data.replnRequest.slotQty
                            ? data.replnRequest.currentBaseCostAmt * data.replnRequest.slotQty
                            : EMPTY_VALUE,
                      }),
                    },
                  ]
                : []),
              {
                label: t('ReplenishmentDashboard.Card.Subzone', {
                  subzone: data.replnRequest.parentLayoutName || EMPTY_VALUE,
                }),
              },
              {
                label: t('ReplenishmentDashboard.Card.Vehicle', {
                  vehicle: (data.replnAssets && data.replnAssets[0]?.assetCd) || EMPTY_VALUE,
                }),
              },
              {
                label: `${t('ReplenishmentDashboard.Card.SRC', {
                  src: data?.fromLayoutName || EMPTY_VALUE,
                })} | ${data.replnRequest.layoutType}: ${data?.toLayoutName || EMPTY_VALUE}`,
              },
            ],
          }}
          {...(getUserData.length > 0
            ? {
                avatarGroupData: {
                  users: getUserData,
                  size: 'small',
                  variant: 'assignee',
                  maxDisplay: MAX_VISIBLE_AVATARS_REPLENISHMENT_DASHBOARD,
                },
              }
            : {})}
          topSectionDirection="column"
        />
      </Actionable>

      {showDrawer && (
        <LazyLoadDetailDrawer
          showDrawer={showDrawer}
          data={data}
          isAssigned={isAssignedUsers}
          statusVariant={statusVariant}
          onDrawerClose={() => setShowDrawer(!showDrawer)}
        />
      )}
    </>
  );
};
