/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { orderService } from '@ofm/services/orderService';
import { OrderStatus, OrderType } from '@ofm/constants/constants';

export const useGetOrderActivity = (
  params: { orderId: string; orderType: OrderType; orderStatus: OrderStatus | undefined },
  isEnabled = false
) => {
  const {
    data: orderActivity,
    isLoading: activityLoading,
    isError,
    refetch: refetchOrderActivity,
  } = useQuery({
    queryKey: ['orderActivity', params.orderId],
    queryFn: () =>
      orderService.getOrderActivityById(params.orderId, params.orderType, params.orderStatus),
    enabled: isEnabled,
  });

  return { orderActivity, refetchOrderActivity, activityLoading, isError };
};
