/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import { ProductStatus } from '@ofm/constants/constants';
import { MOCK_WILL_CALL_ITEM_OPTIONS } from '@ofm/constants/dataConstants';
import { WillCallRowTypes } from './Table.types';
import { ORDER_TYPE_FIELD } from '@mdm/constants/constants.ts';

const STORE_ERROR_ROWS = [
  {
    storeId: 94794,
    errorStatus: 'Store_Connnect_Failed',
    attempts: 3,
    created: new Date('06/20/23 08:22:13'),
    lastAttempted: new Date('06/25/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94794,
    errorStatus: 'Store_Connnect_Failed',
    attempts: 7,
    created: new Date('06/21/23 08:22:13'),
    lastAttempted: new Date('06/24/23 10:30:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94794,
    errorStatus: 'Store_Connnect_Failed',
    attempts: 5,
    created: new Date('06/07/23 08:22:13'),
    lastAttempted: new Date('06/23/23 08:59:40'),
    link: 'View Order Details',
  },
];

const CSR_ERROR_ROWS = [
  {
    storeId: 94781,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94782,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 10:30:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94783,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94784,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
  {
    storeId: 94785,
    errorStatus: 'CSR_No_Order_Created',
    attempts: 2,
    created: new Date('06/21/23 08:48:52'),
    lastAttempted: new Date('06/21/23 08:59:40'),
    link: 'View Order Details',
  },
];

const ORDER_DETAILS_ROWS = [
  {
    sku: '000991131',
    description: 'Polyurethane Wheels Lorem',
    partNumber: 'TMK-2.5',
    status: ProductStatus.CLEARANCE,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
      {
        warehouseId: '600',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
      {
        warehouseId: '900',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '111',
  },
  {
    sku: '000991132',
    description: 'Polyurethane Wheels X',
    partNumber: 'TMK-2.5',
    status: ProductStatus.SELL_THROUGH,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '222',
  },
  {
    sku: '000991133',
    description: 'Polyurethane Wheels',
    partNumber: 'TMK-2.5',
    status: ProductStatus.SELL_THROUGH,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '333',
  },
  {
    sku: '000991134',
    description: 'Polyurethane Wheels',
    partNumber: 'TMK-2.5',
    status: ProductStatus.NEW,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '444',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '555',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '666',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '777',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '888',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '999',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '123',
  },
  {
    sku: '000991135',
    description: 'Polyurethane Pads',
    partNumber: 'TMK-2.5',
    status: ProductStatus.DISCONTINUED,
    pack: 10,
    upc: '123',
    planogramId: '123',
    warehouseDetails: [
      {
        warehouseId: '1',
        warehouseName: 'abc',
        quantityOnHand: 800,
        minimumPack: 2,
      },
    ],
    quantity: 500,
    credited: false,
    inProgress: false,
    id: '321',
  },
];

const STORE_DETAILS_ROWS = [
  {
    orderId: '1',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '2',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '3',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '4',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '5',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '6',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '7',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '8',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '9',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '10',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '11',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '12',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '13',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '14',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '15',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '16',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '17',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '18',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '19',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '20',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '21',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '22',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '23',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '24',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '25',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '26',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '27',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '28',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '29',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '40',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '30',
    invoiceId: '',
    requestBy: new Date('09/21/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Request',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:20:44'),
    lines: 500,
    pieces: 600,
    hasComments: false,
  },
  {
    orderId: '31',
    invoiceId: '',
    requestBy: new Date('09/21/23 07:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Ready to Bill',
    badgeVariant: StatusVariants.IN_PROGRESS,
    requestedAt: new Date('06/20/23 03:39:16'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '32',
    invoiceId: '',
    requestBy: new Date('06/18/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Error: Store',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/20/23 03:39:16'),
    billedAt: new Date('06/18/23 03:42:09'),
    lines: 480,
    pieces: 605,
    hasComments: true,
  },
  {
    orderId: '33',
    invoiceId: '900937',
    requestBy: new Date('06/15/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Not Started',
    badgeVariant: StatusVariants.NOT_STARTED,
    requestedAt: new Date('06/15/23 03:05:09'),
    billedAt: new Date('06/15/23 03:10:09'),
    lines: 900,
    pieces: 1623,
    hasComments: false,
  },
  {
    orderId: '34',
    invoiceId: '903819',
    orderType: 'Will Call',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/15/23 03:27:20'),
    billedAt: new Date('06/15/23 03:27:20'),
    lines: 1,
    pieces: 100,
    hasComments: true,
  },
  {
    orderId: '35',
    invoiceId: '923060',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/13/23 03:20:14'),
    billedAt: new Date('06/13/23 03:26:14'),
    lines: 734,
    pieces: 924,
    hasComments: false,
  },
  {
    orderId: '36',
    invoiceId: '943271',
    orderType: 'Will Call',
    badgeText: 'Cancelled',
    badgeVariant: StatusVariants.CANCELLED,
    requestedAt: new Date('06/11/23 03:20:14'),
    lines: 420,
    pieces: 1287,
    hasComments: true,
  },
  {
    orderId: '37',
    invoiceId: '948661',
    requestBy: new Date('06/13/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/08/23 02:40:36'),
    billedAt: new Date('06/08/23 02:50:36'),
    lines: 800,
    pieces: 1203,
    hasComments: false,
  },
  {
    orderId: '38',
    invoiceId: '958665',
    requestBy: new Date('06/06/23 04:00:00'),
    orderType: 'Replenishment',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/06/23 03:56:30'),
    billedAt: new Date('06/06/23 03:59:21'),
    lines: 820,
    pieces: 1000,
    hasComments: false,
  },
  {
    orderId: '39',
    invoiceId: '958123',
    requestBy: new Date('06/04/23 04:00:00'),
    orderType: 'Cross Dock',
    badgeText: 'Sent to Outbound',
    badgeVariant: StatusVariants.COMPLETED,
    requestedAt: new Date('06/04/23 03:46:30'),
    billedAt: new Date('06/04/23 03:49:21'),
    lines: 231,
    pieces: 1000,
    hasComments: false,
  },
];

const WILL_CALL_ROW_ADDITIONAL_DATA = {
  pack: 10,
  upc: '111',
  planogramId: '123',
  selectedWarehouse: {
    value: 'DC 20 - Primary',
    label: 'DC 20 - Primary',
    warehouseId: '20',
    warehouseName: 'abc',
    quantityOnHand: 800,
    minimumPack: 2,
  },
  warehouseDetails: [
    {
      value: 'DC 20 - Primary',
      label: 'DC 20 - Primary',
      warehouseId: '20',
      warehouseName: 'abc',
      quantityOnHand: 800,
      minimumPack: 2,
    },
    {
      value: 'DC 40',
      label: 'DC 40',
      warehouseId: '40',
      warehouseName: 'abc',
      quantityOnHand: 800,
      minimumPack: 1,
    },
    {
      value: 'DC 48',
      label: 'DC 48',
      warehouseId: '48',
      warehouseName: 'abc',
      quantityOnHand: 800,
      minimumPack: 10,
      disabled: true,
    },
  ],
  quantityCurrent: 20,
  numberOfPacksCurrent: 2,
  isRowBeingEdited: false,
};

const WILL_CALL_TABLE_ROWS: WillCallRowTypes[] = MOCK_WILL_CALL_ITEM_OPTIONS.map((item) => {
  return {
    sku: item.sku,
    partNumber: item.partNumber,
    description: item.name,
    status: ProductStatus.SELL_THROUGH,
    ...WILL_CALL_ROW_ADDITIONAL_DATA,
  };
});

const WAREHOUSE_CONFIGURATION_TABLE_ROWS = [
  {
    id: 1,
    dcNumber: 10,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 2,
    dcNumber: 15,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 3,
    dcNumber: 19,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '',
  },
  {
    id: 4,
    dcNumber: 22,
    address: '77 Rush St, Lexington, TN 38351, USA',
    description: 'Lexington Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 5,
    dcNumber: 25,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '',
  },
];

const STORE_ORDER_RELEASE_ROWS = [
  {
    storeNumber: '00859',
    pallets: 4,
    pieces: 121,
    comments: '',
  },
  {
    storeNumber: '00144',
    pallets: 2,
    pieces: 105,
    comments: 'DSD',
  },
  {
    storeNumber: '00668',
    pallets: 3,
    pieces: 150,
    comments: 'Mega/Area 6',
  },
  {
    storeNumber: '001854',
    pallets: 1,
    pieces: 100,
    comments: '',
  },
  {
    storeNumber: '00859',
    pallets: 4,
    pieces: 121,
    comments: '',
  },
  {
    storeNumber: '00144',
    pallets: 2,
    pieces: 105,
    comments: 'DSD',
  },
  {
    storeNumber: '00668',
    pallets: 3,
    pieces: 150,
    comments: 'Mega/Area 6',
  },
  {
    storeNumber: '001854',
    pallets: 1,
    pieces: 100,
    comments: '',
  },
];

const ALL_USER_ROWS = {
  totalPages: 1,
  totalElements: 1,
  size: 1,
  content: [
    {
      userKey: '01dd7aa9950b86da479700ba0829c2c7',
      userId: '11216564',
      employeeId: '1018',
      firstName: 'Silvi',
      middleName: 'Silvi',
      secondLastName: 'Silvi',
      lastName: 'Gupta',
      statusCd: 'ACTIVE',
      countryCd: 'US',
      stateProvinceCd: 'string',
      city: 'string',
      addressLine1: 'string',
      addressLine2: 'string',
      postalCd: '44551',
      hireDate: '2000-10-31',
      jobCd: 'Advisor',
      jobTitle: 'Advisor',
      preferedLanguage: 'en_US',
      multilingualFl: true,
      temporaryFl: true,
      clockedInFl: true,
      facilities: [
        {
          facilityUserKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
          facilityId: '000000',
          activeFromDate: '2000-10-31',
          activeToDate: '2030-10-31',
          wageCd: 'HOURLY',
          statusCd: 'ACTIVE',
          primaryFl: true,
          departments: [
            {
              userDeptKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
              deptId: 'FIXTURES',
              statusCd: 'ACTIVE',
              primaryFl: true,
              locale: 'en_US',
              deptDesc: 'Inbound-Receiving department',
            },
          ],
          shifts: [
            {
              shiftId: 'FIRST',
              shiftDesc: 'First',
              shiftIndex: 1,
              locale: 'en_US',
              fromTime: '07:00',
              toTime: '15:00',
              dayOfWeek: 'MON',
            },
          ],
          roles: [
            {
              roleId: 'INBOUND_CLERK',
              locale: 'en-US',
              roleDesc: 'Inbound Clerk',
            },
          ],
        },
        {
          facilityUserKey: 'dasasdakdjashjdfajfb9i3ueasaiuu3enn',
          facilityId: '20',
          activeFromDate: '2000-10-31',
          activeToDate: '2030-10-31',
          wageCd: 'HOURLY',
          statusCd: 'ACTIVE',
          primaryFl: false,
          departments: [
            {
              userDeptKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
              deptId: 'MAINTENANCE',
              statusCd: 'ACTIVE',
              primaryFl: true,
              locale: 'en_US',
              deptDesc: 'Inbound-Receiving department',
            },
          ],
          shifts: [
            {
              shiftId: 'FIRST',
              shiftDesc: 'First',
              shiftIndex: 1,
              locale: 'en_US',
              fromTime: '07:00',
              toTime: '15:00',
              dayOfWeek: 'MON',
            },
          ],
          roles: [
            {
              roleId: 'INBOUND_CLERK',
              locale: 'en-US',
              roleDesc: 'Inbound Clerk',
            },
          ],
        },
      ],
    },
  ],
  number: 1,
  first: true,
  last: true,
  pageable: {
    offset: 0,
    sort: {
      sortyBy: 'string',
      direction: 'ASC',
    },
    page: 1,
    pageSize: 10,
    paged: true,
  },
  numberOfElements: 1,
  empty: true,
};

const USER_PERMISSION_ROWS = [
  {
    id: '1',
    permission: 'RF Material Handler',
    canView: false,
    canEdit: false,
  },
  {
    id: '2',
    permission: 'RF Packing',
    canView: false,
    canEdit: false,
  },
  {
    id: '3',
    permission: 'RF Inventory',
    canView: false,
    canEdit: false,
  },
  {
    id: '4',
    permission: 'RF Location Admin',
    canView: false,
    canEdit: false,
  },
  {
    id: '5',
    permission: 'RF Putaway Prep',
    canView: false,
    canEdit: false,
  },
  {
    id: '6',
    permission: 'RF Shop',
    canView: false,
    canEdit: false,
  },
  {
    id: '7',
    permission: 'RF Kitting',
    canView: false,
    canEdit: false,
  },
  {
    id: '8',
    permission: 'RF Pick by Order',
    canView: false,
    canEdit: false,
  },
  {
    id: '9',
    permission: 'RF Inventory Admin',
    canView: false,
    canEdit: false,
  },
];

const SUBZONE_MIN_MAX_ROWS = [
  {
    id: '1',
    sku: 'RESLOTTED',
    subzone: 'AR1',
    description: 'Reslot',
    min: '1',
    max: '100',
  },
  {
    id: '2',
    sku: '000000000',
    subzone: 'AR2',
    description: 'slot',
    min: '5',
    max: '500',
  },
];

const ORDER_TYPE_ROWS = [
  {
    id: '1',
    orderTypeCode: 'A',
    description: 'Automatic Billing',
    attributes: [
      {
        name: ORDER_TYPE_FIELD.PRIORITY,
        value: '55',
      },
      {
        name: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
        value: '55',
      },
      {
        name: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
        value: '',
      },
      {
        name: ORDER_TYPE_FIELD.ALLOW_MERGE,
        value: 'false',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_LIGHT,
        value: 'false',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE,
        vvalue: 'false',
      },
    ],
  },
  {
    id: '2',
    orderTypeCode: 'C',
    description: 'Cross Dock',
    attributes: [
      {
        name: ORDER_TYPE_FIELD.PRIORITY,
        value: '50',
      },
      {
        name: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
        value: '50',
      },
      {
        name: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
        value: '',
      },
      {
        name: ORDER_TYPE_FIELD.ALLOW_MERGE,
        value: 'true',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_LIGHT,
        value: 'false',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE,
        value: 'false',
      },
    ],
  },
  {
    id: '3',
    orderTypeCode: 'N',
    description: 'New Store Billing',
    attributes: [
      {
        name: ORDER_TYPE_FIELD.PRIORITY,
        value: '50',
      },
      {
        name: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
        value: '50',
      },
      {
        name: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
        value: '',
      },
      {
        name: ORDER_TYPE_FIELD.ALLOW_MERGE,
        value: 'true',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_LIGHT,
        value: 'false',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE,
        value: 'false',
      },
    ],
  },
  {
    id: '4',
    orderTypeCode: 'P',
    description: 'Commercial Orders',
    attributes: [
      {
        name: ORDER_TYPE_FIELD.PRIORITY,
        value: '75',
      },
      {
        name: ORDER_TYPE_FIELD.SHUTTLE_PRIORITY,
        value: '75',
      },
      {
        name: ORDER_TYPE_FIELD.PALLET_THRESHOLD,
        value: '0.55',
      },
      {
        name: ORDER_TYPE_FIELD.ALLOW_MERGE,
        value: 'true',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_LIGHT,
        value: 'false',
      },
      {
        name: ORDER_TYPE_FIELD.RELEASE_TO_SHUTTLE,
        value: 'false',
      },
    ],
  },
];

const LOCATION_ASSOCIATED_SKU_ROWS = [
  {
    sku: '000000367',
    description: 'Shop Pro AF FS',
    partNumber: 'AXA0B3',
    quantity: '0',
  },
  {
    sku: '000000368',
    description: 'Shop Pro AF FS',
    partNumber: 'AXA0B4',
    quantity: '1',
  },
];

const ROLE_ROWS = [
  {
    roleId: 'ABHISHEKGROUP',
    roleDesc: 'ABHISHEKGROUP',
  },
  {
    roleId: 'DEVGROUP',
    roleDesc: 'Devyani grouaa',
  },
  {
    roleId: 'SAMEER',
    roleDesc: 'SAMEER_TEST',
  },
];

const CAPABILITY_GROUP_ROWS = [
  {
    groupId: 'RF_DEVICE',
    groupDescription: 'RF Device',
    locale: 'en-US',
    capabilities: [
      {
        capabilityCode: 'RF_DEVICE_HANDLER',
        capabilityDescription: 'RF Material Handler',
        capabilityType: 'RF_DEVICE',
        locale: 'en-US',
        permissions: [
          {
            roleId: 'ABHISHEKGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'Y',
            canDelete: 'Y',
          },
          {
            roleId: 'DEVGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'N',
            canDelete: 'N',
          },
        ],
      },
    ],
  },
  {
    groupId: 'RDM',
    groupDescription: 'RDM',
    locale: 'en-US',
    capabilities: [
      {
        capabilityCode: 'ZONE',
        capabilityDescription: 'Zone',
        capabilityType: 'RDM',
        locale: 'en-US',
        permissions: [
          {
            roleId: 'ABHISHEKGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'Y',
            canDelete: 'Y',
          },
          {
            roleId: 'DEVGROUP',
            canAdd: 'Y',
            canEdit: 'N',
            canView: 'N',
            canDelete: 'Y',
          },
        ],
      },
      {
        capabilityCode: 'SUBZONE',
        capabilityDescription: 'Subzone',
        capabilityType: 'RDM',
        locale: 'en-US',
        permissions: [
          {
            roleId: 'ABHISHEKGROUP',
            canAdd: 'Y',
            canEdit: 'N',
            canView: 'Y',
            canDelete: 'N',
          },
          {
            roleId: 'DEVGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'Y',
            canDelete: 'Y',
          },
        ],
      },
    ],
  },
  {
    groupId: 'TASK_MANAGER',
    groupDescription: 'Task Manager',
    locale: 'en-US',
    capabilities: [
      {
        capabilityCode: 'RF_MATERIAL_HANDLER',
        capabilityDescription: 'RF Material Handler',
        capabilityType: 'TASK_TYPE',
        locale: 'en-US',
        permissions: [
          {
            roleId: 'ABHISHEKGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'Y',
            canDelete: 'Y',
          },
          {
            roleId: 'DEVGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'N',
            canDelete: 'N',
          },
        ],
      },
      {
        capabilityCode: 'RF_DEVICE',
        capabilityDescription: 'Radio Frequency Device',
        capabilityType: 'TASK_TYPE',
        locale: 'en-US',
        permissions: [
          {
            roleId: 'ABHISHEKGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'Y',
            canDelete: 'Y',
          },
          {
            roleId: 'DEVGROUP',
            canAdd: 'Y',
            canEdit: 'Y',
            canView: 'N',
            canDelete: 'Y',
          },
        ],
      },
    ],
  },
];

export {
  CSR_ERROR_ROWS,
  STORE_ERROR_ROWS,
  ORDER_DETAILS_ROWS,
  STORE_DETAILS_ROWS,
  WILL_CALL_TABLE_ROWS,
  WAREHOUSE_CONFIGURATION_TABLE_ROWS,
  STORE_ORDER_RELEASE_ROWS,
  ALL_USER_ROWS,
  USER_PERMISSION_ROWS,
  SUBZONE_MIN_MAX_ROWS,
  ORDER_TYPE_ROWS,
  LOCATION_ASSOCIATED_SKU_ROWS,
  ROLE_ROWS,
  CAPABILITY_GROUP_ROWS,
};
