/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Button, Divider, ProgressBar, Icon } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import styles from './Outbound.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';
import { ActionViewAll } from '@az/starc-ui-icons';
import { ShiftGoalOutboundModal } from '@taskManagement/components/Modals/ShiftGoalOutboundModal/ShiftGoalOutboundModal';

export const Outbound = () => {
  /* Constants */
  const { t } = useTranslation();

  /* State */
  const [isShiftGoalsModalOpen, setIsShiftGoalsModalOpen] = useState(false);

  /* Functions */
  const onClickViewShiftGoals = () => {
    setIsShiftGoalsModalOpen(true);
  };

  const onShiftGoalModalClose = () => {
    setIsShiftGoalsModalOpen(false);
  };

  return (
    <>
      <ShiftGoalOutboundModal
        isOpen={isShiftGoalsModalOpen}
        onClose={onShiftGoalModalClose}
        shiftName="1st Shift"
        ValidatorCount={5}
        timeLeftInHour={8}
        storesPicked={10}
        storesCompleted={100}
        storesPercentage={10}
        piecesPicked={10}
        piecesCompleted={100}
        piecesPercentage={10}
      />

      <View direction="row">
        <MasterTitle
          title="Outbound Task Manager"
          subtitle="Last updated 5 min ago"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          titleActionProps={{ label: 'Favorite', handleClick: () => {} }}
        />
      </View>

      <View
        gap={6}
        direction="row"
        backgroundColor="secondary"
        padding={[6, 6]}
        className={styles['outbound-manager_body']}
      >
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle="Shift Progress"
              widgetSubtitle="2nd Shift | 10 Outbound Autozoners"
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                // Add your logic to handle the click event of the icon button
              }}
            />
            <WidgetWithChildren.Body>
              <View gap={2}>
                <View gap={2}>
                  <View direction="row">
                    <Text size="100" variant="subtitle-bold">
                      {t(`ShiftProgress.NewShiftStarted`)}
                    </Text>
                  </View>
                  <View direction="row" align="center" justify="space-between" gap={2}>
                    <View.Item grow>
                      <Text size="087" color="500" className={styles['widget_subtext']}>
                        {t('ShiftGoalHistory.PreviousOutboundStatus', {
                          completed: 87,
                          total: 85,
                          difference: 2,
                        })}
                      </Text>
                    </View.Item>
                    <View.Item>
                      <Button size="small" onClick={onClickViewShiftGoals}>
                        {t(`ShiftProgress.SetShiftGoalBtn`)}
                      </Button>
                    </View.Item>
                  </View>

                  <Divider color="300" />

                  <View gap={2}>
                    <View direction="row" align="center" justify="space-between">
                      <View direction="row" gap={2} align="baseline">
                        <View>
                          <Text variant="display-4">0 / 83</Text>
                        </View>
                        <View>
                          <Text variant="subtitle-bold">Stores</Text>
                        </View>
                      </View>
                      <View>
                        <Text color="300">0 %</Text>
                      </View>
                    </View>
                    <View>
                      <ProgressBar
                        label=""
                        value={10}
                        valueColor="500"
                        attributes={{ style: { width: '100%' } }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </WidgetWithChildren.Body>
            <WidgetWithChildren.Footer>
              <View gap={2}>
                <Divider color="300" />

                <View direction="row" align="center" justify="space-between">
                  <View direction="row" gap={2} align="baseline">
                    <View>
                      <Text variant="display-4">0 / 66,726</Text>
                    </View>
                    <View>
                      <Text variant="subtitle-bold">Pieces</Text>
                    </View>
                  </View>
                  <View>
                    <Text color="300">0 %</Text>
                  </View>
                </View>
                <View>
                  <ProgressBar
                    label=""
                    value={10}
                    valueColor="500"
                    attributes={{ style: { width: '100%' } }}
                  />
                </View>
              </View>
            </WidgetWithChildren.Footer>
          </WidgetWithChildren>
        </View.Item>
      </View>
    </>
  );
};
