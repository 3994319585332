/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { setInboundShiftGoalService } from '../setInboundShiftGoalService';
import { getInboundShiftGoalOptions } from '@taskManagement/types/types';

export const useGetShiftDetails = (options: getInboundShiftGoalOptions, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: shiftsSearchData,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['ShiftsSearchAPI', options],
    queryFn: () => setInboundShiftGoalService.getGoals(options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('ShiftGoalHistory.RetrieveError'));
    }
  }, [isError, handleNotification, t]);
  return { shiftsSearchData, isFetching, isError, refetch };
};
