/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { AssignUserToRoleRequest } from '@mdm/types/service.type.ts';
import { userRoleService } from '@shared/services/userRoleService.ts';

export const useMutateAssignUserToRole = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const message = t('Errors.Action.UpdateUserRole');

  const {
    mutate: mutateAssignUserToRole,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { assignUserToRoleRequest: AssignUserToRoleRequest[] }) => {
      return userRoleService.assignUsers(params.assignUserToRoleRequest);
    },
    onError: () => {
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateAssignUserToRole, isLoading, isError };
};
