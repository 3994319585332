/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Skeleton } from '@az/starc-ui';
import styles from './TopVariancesSection.module.scss';

export const ListSkeleton = () => {
  return Array(5)
    .fill(null)
    .map((_, index) => (
      <View
        key={index}
        className={styles['list-component-item']}
        direction="row"
        align="center"
        justify="space-between"
        width="100%"
        height="54px"
        padding={4}
      >
        <View width="10%">
          <Skeleton width="15px" />
        </View>
        <View width="90%" direction="row" justify="space-between">
          <View>
            <Skeleton width={`${Math.random() * (120 - 80) + 80}px`} />
          </View>
          <View>
            <Skeleton width="50px" />
          </View>
        </View>
      </View>
    ));
};
