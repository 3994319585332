/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const VendorLocaleSchema = z.object({
  vendorId: z.string(),
  localeCd: z.string(),
  vendorName: z.string().optional().nullable(),
});
