/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Icon, Text, View } from '@az/starc-ui';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  ROLE_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '@mdm/pages/RDM/Role/RoleList.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { Add } from '@az/starc-ui-icons';
import { mapRoleTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { DIRECTION, TableSorting } from '@shared/components/Table/Table.types.ts';
import { RoleType } from '@shared/types/schema.type.ts';
import { CreateRoleModal } from '@mdm/pages/RDM/Role/CreateRoleModal/CreateRoleModal.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { useUserRoleSearch } from '@shared/services/hooks/useUserRoleSearch.ts';

export const RoleList = () => {
  /* State */
  const [showCreateRoleModal, setShowCreateRoleModal] = useState<boolean>(false);
  const [normalizeRoleData, setNormalizeRoleData] = useState<RoleType[]>([]);
  /* Constants */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userRolesData, isFetching: isLoading } = useUserRoleSearch({});

  /* Function */
  const onViewDetails = (role: RoleType) => {
    navigate(PAGE_URLS.USER_ROLE_DETAILS(role.roleId));
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);

    if (foundColumn) {
      const sortedRolesData = [...userRolesData].sort(
        (firstItem: RoleType, secondItem: RoleType) => {
          const firstValue = firstItem[foundColumn.id as keyof RoleType];
          const secondValue = secondItem[foundColumn.id as keyof RoleType];

          if (firstValue && secondValue) {
            return (
              (firstValue < secondValue ? -1 : 1) *
              (foundColumn.direction === DIRECTION.ASCENDING ? 1 : -1)
            );
          } else if (firstValue) {
            return 1;
          } else if (secondValue) {
            return 1;
          } else {
            return -1;
          }
        }
      );

      setNormalizeRoleData(sortedRolesData);
    }
  };

  /* Hooks */
  useEffect(() => {
    if (userRolesData) {
      setNormalizeRoleData(userRolesData);
    }
  }, [userRolesData]);

  return (
    <View>
      <View className={styles['role__list']}>
        <MasterTitle title={t('Roles')}>
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button size="large" onClick={() => setShowCreateRoleModal(true)}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Add} color="secondary" />
                      <Text>{t('AddRole')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={[4, 6]}>
          <Table
            columns={ROLE_TABLE_COLUMNS}
            rows={mapRoleTableRows(normalizeRoleData, onViewDetails)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isLoading}
            styleVariant={TableStylingVariants.DETAILS}
            totalPages={Math.ceil((normalizeRoleData.length || DEFAULT_PAGE - 1) / PAGE_SIZE)}
            onSort={onSort}
          />
        </View>
      </View>

      <CreateRoleModal
        showModal={showCreateRoleModal}
        onClose={() => setShowCreateRoleModal(false)}
      />
    </View>
  );
};
