/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text } from '@az/starc-ui';
import styles from './PODiscrepancy.module.scss';
import { Accordion } from '@shared/components/Accordion/Accordion';
import { InboundOrderLineLocationType } from '@inventory/types/types';
import { generateSubzoneNameFromDistinctName } from '@shared/utils/commonUtils';
import { t } from 'i18next';
import { generateDateString } from '@ofm/utils/utils';

export const General = ({ locationDetails }: { locationDetails: InboundOrderLineLocationType }) => {
  return (
    <View direction="row" padding={6} className={styles['po-discrepancy']} height="57vH">
      <View direction="row" gap={4} width="100%">
        <View
          direction="column"
          backgroundColor="secondary"
          attributes={{ style: { width: '100%', minWidth: 'fit-content' } }}
        >
          <View className={styles['po-discrepancy__accordion']}>
            <Accordion
              header={{
                label: (
                  <View>
                    <Text size="100" weight="bold">
                      {t('LocationSearchModal.LocationInformation')}
                    </Text>
                  </View>
                ),
                auxiliaryLabel: '',
              }}
            >
              <View direction="row" padding={[2, 4]}>
                <View.Item columns={4}>
                  <Text size="087" weight="bold">
                    {t('LocationSearchModal.Location')}
                  </Text>
                </View.Item>
                <View.Item columns={4}>
                  <Text size="087">{locationDetails.layoutName}</Text>
                </View.Item>
              </View>
              <View direction="row" padding={[2, 4]}>
                <View.Item columns={4}>
                  <Text size="087" weight="bold">
                    {t('LocationSearchModal.Stockroom')}
                  </Text>
                </View.Item>
                <View.Item columns={4}>
                  <Text size="087"> {t('LocationSearchModal.LXDC')}</Text>
                </View.Item>
              </View>

              <View direction="row" padding={[2, 4]}>
                <View.Item columns={4}>
                  <Text size="087" weight="bold">
                    {t('LocationSearchModal.Subzone')}
                  </Text>
                </View.Item>
                <View.Item columns={4}>
                  <Text>
                    {generateSubzoneNameFromDistinctName(locationDetails.layoutDistinctName)}
                  </Text>
                </View.Item>
              </View>
              <View direction="row" padding={[2, 4]}>
                <View.Item columns={4}>
                  <Text size="087" weight="bold">
                    {t('LocationSearchModal.Type')}
                  </Text>
                </View.Item>
                <View.Item columns={4}>
                  <Text size="087">{locationDetails.layoutAttribNameType}</Text>
                </View.Item>
              </View>
              <View direction="row" padding={[2, 2, 4, 4]}>
                <View.Item columns={4}>
                  <Text size="087" weight="bold">
                    {t('LocationSearchModal.VendorDateCode')}
                  </Text>
                </View.Item>
                <View.Item columns={4}>
                  <Text size="087">{generateDateString(new Date(), t('DateFormat.Short'))}</Text>
                </View.Item>
              </View>
            </Accordion>
          </View>
        </View>
        <View
          direction="column"
          backgroundColor="secondary"
          attributes={{ style: { width: '100%', minWidth: 'fit-content' } }}
        >
          <View className={styles['po-discrepancy__accordion']}>
            <Accordion
              header={{
                label: (
                  <View>
                    <Text size="100" weight="bold">
                      {t('LocationSearchModal.ProductInformation')}
                    </Text>
                  </View>
                ),
                auxiliaryLabel: '',
              }}
            >
              <></>
            </Accordion>
          </View>
        </View>
        <View
          direction="column"
          backgroundColor="secondary"
          attributes={{ style: { width: '100%', minWidth: 'fit-content' } }}
        >
          <View className={styles['po-discrepancy__accordion']}>
            <Accordion
              header={{
                label: (
                  <View>
                    <Text size="100" weight="bold">
                      {t('LocationSearchModal.CycleCountInformation')}
                    </Text>
                  </View>
                ),
                auxiliaryLabel: '',
              }}
            >
              <></>
            </Accordion>
          </View>
        </View>
      </View>
    </View>
  );
};
