/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { subzoneService } from '@mdm/services/subzoneService.ts';
import { GetLayoutParams } from '@mdm/types/service.type.ts';
import { useEffect } from 'react';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

export const useGetSubzones = (options: GetLayoutParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: subzonesData,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['subzones', options],
    queryFn: () => subzoneService.getSubzones(options),
    enabled: isEnabled,
    cacheTime: 0,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Subzones.Description'));
    }
  }, [isError, handleNotification, t]);

  return { subzonesData, isFetching, isError, refetch };
};
