/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Actionable, Text, Icon, KebabMenu } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { TaskManagerCardProps } from '@taskManagement/components/TaskManagerCard/TaskManagerCard.types';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { ProgressBar } from '@inbound/components/ProgressBar/ProgressBar';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import { NOT_ASSIGNED, PROGRESS_LIMIT, USER_STATUS } from '@taskManagement/constants/constants';
import { ActionDropdownMenu } from '@shared/components/ActionDropdownMenu/ActionDropdownMenu';
import { Tag } from '@shared/components/Tag/Tag';
import { capitalizeFirstLetter } from '@shared/utils/commonUtils';
import styles from './TaskManagerCard.module.scss';
import { DEPARTMENTS } from '@shared/constants/constants';

export const TaskManagerCard = ({
  userId,
  firstName,
  lastName,
  clockedInFl,
  lastTransactionTs,
  assignmentStatus,
  taskStatus,
  vendorName,
  poNumber,
  subZone,
  priority,
  orderTypeCd,
  commodityTypeCd,
  totalTaskPercentage,
  completedPoints,
  totalPoints,
  executionPriority,
  tempDeptId,
  onClickAssignTask,
  onClickMoveUserToAnotherDept,
  onClickMoveUserBackToDept,
  setOpenActionMenu,
  totalReceivedQty,
  totalOrderedQty,
}: TaskManagerCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <View
        padding={4}
        attributes={{ style: { opacity: !clockedInFl ? '0.5' : '1' } }}
        backgroundColor="secondary"
        width="100%"
        className={styles['task-manager-card_list-item']}
        justify="space-between"
        direction="row"
        align="center"
        key={userId}
      >
        <View direction="row" gap={2} backgroundColor="secondary" align="center" justify="start">
          <View align="center" justify="center">
            <Avatar
              key={'avatar' + userId}
              name={firstName && lastName ? `${firstName}  ${lastName}` : ''}
              size="large"
            />
          </View>
          <Text size="100" weight="medium">
            {firstName}
          </Text>
        </View>

        <View direction="row" align="center">
          <View direction="row" gap={2} align="center" justify="center">
            {taskStatus === USER_STATUS.NOT_ACTIVE && (
              <>
                {tempDeptId && tempDeptId !== null && tempDeptId !== DEPARTMENTS.INBOUND ? (
                  <Text size="087" variant="subtitle-regular">
                    <Tag text={'Working in' + ' ' + `${capitalizeFirstLetter(tempDeptId)}`} />
                  </Text>
                ) : (
                  ''
                )}
                {assignmentStatus === NOT_ASSIGNED ? (
                  <Text size="087" variant="subtitle-regular">
                    {t(`InboundTaskManager.Text.NotAssigned`)}
                  </Text>
                ) : (
                  <Text size="087" variant="subtitle-regular">
                    {t(`InboundTaskManager.Text.Assigned`)}
                  </Text>
                )}

                {lastTransactionTs && lastTransactionTs !== null ? (
                  <Text size="087" variant="subtitle-regular" color="error">
                    {lastTransactionTs == 0 || isNaN(lastTransactionTs) ? (
                      <Text size="087" variant="subtitle-regular" color="primary">
                        {t('InboundTaskManager.NoTransaction')}
                      </Text>
                    ) : (
                      t(`InboundTaskManager.LastTransactionTime`, {
                        lastTransactionTs: lastTransactionTs,
                      })
                    )}
                  </Text>
                ) : (
                  <Text size="087" variant="subtitle-regular" color="primary">
                    {t('InboundTaskManager.NoTransaction')}
                  </Text>
                )}

                {clockedInFl && (
                  <ActionDropdownMenu isOpen={setOpenActionMenu}>
                    <View>
                      <View.Item>
                        <Actionable
                          fullWidth
                          onClick={(
                            event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                          ) => {
                            event.stopPropagation();
                            if (tempDeptId && tempDeptId !== DEPARTMENTS.INBOUND) {
                              onClickMoveUserBackToDept?.(userId);
                            } else {
                              onClickMoveUserToAnotherDept?.(userId);
                            }
                          }}
                        >
                          <View padding={[3, 4]}>
                            <Text>
                              {tempDeptId && tempDeptId !== DEPARTMENTS.INBOUND
                                ? t('NotActiveDropDownList.MoveBackToOriginalDepartment', {
                                    OriginalDept: capitalizeFirstLetter(DEPARTMENTS.INBOUND),
                                  })
                                : t('NotActiveDropDownList.MoveToAnotherDepartment')}
                            </Text>
                          </View>
                        </Actionable>
                      </View.Item>

                      <View.Item>
                        <Actionable
                          fullWidth
                          onClick={(
                            event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                          ) => {
                            event.stopPropagation();
                            onClickAssignTask?.(userId);
                          }}
                        >
                          <View padding={[3, 4]}>
                            <Text>{t('NotActiveDropDownList.AssignTasks')} </Text>
                          </View>
                        </Actionable>
                      </View.Item>
                      <View.Item>
                        <Actionable
                          fullWidth
                          onClick={(
                            event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                          ) => {
                            event.stopPropagation();
                          }}
                        >
                          <View padding={[3, 4]}>
                            <Text color="error">{t('NotActiveDropDownList.RemoveFromShift')}</Text>
                          </View>
                        </Actionable>
                      </View.Item>
                    </View>
                  </ActionDropdownMenu>
                )}
                {!clockedInFl && <Icon svg={KebabMenu} />}
              </>
            )}
            {poNumber && (
              <>
                <View gap={2}>
                  <Text size="087" variant="subtitle-regular" color="primary">
                    {t('PoTask.PO', { poNumber })}
                  </Text>
                </View>
              </>
            )}
            {subZone && (
              <>
                <View gap={2}>
                  <Text size="087" variant="subtitle-regular" color="primary">
                    {subZone}
                  </Text>
                </View>
              </>
            )}
          </View>
          {taskStatus !== USER_STATUS.NOT_ACTIVE && (
            <ActionDropdownMenu isOpen={setOpenActionMenu}>
              <View>
                <View.Item>
                  <Actionable
                    fullWidth
                    onClick={(
                      event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                    ) => {
                      event.stopPropagation();
                      onClickMoveUserToAnotherDept?.(userId);
                    }}
                  >
                    <View padding={[3, 4]}>
                      <Text>{t('NotActiveDropDownList.MoveToAnotherDepartment')}</Text>
                    </View>
                  </Actionable>
                </View.Item>
                <View.Item>
                  <Actionable
                    fullWidth
                    onClick={(
                      event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                    ) => {
                      event.stopPropagation();
                      onClickAssignTask?.(userId);
                    }}
                  >
                    <View padding={[3, 4]}>
                      <Text>{t('ActiveDropDownList.AssignTasks')}</Text>
                    </View>
                  </Actionable>
                </View.Item>
              </View>
            </ActionDropdownMenu>
          )}
        </View>

        <View direction="row" align="center" width="100%">
          <View direction="row" gap={2} padding={[2, 0, 0, 0]} align="center" width="100%">
            {vendorName && <Text>{vendorName}</Text>}
            {priority && <PriorityTag />}
            {orderTypeCd && <Tag text={orderTypeCd} variant="order" />}
            {commodityTypeCd && <Tag text={commodityTypeCd} variant="order" />}

            {executionPriority}
            {totalTaskPercentage && taskStatus === USER_STATUS.ACTIVE && (
              <View align="center" width="100%">
                {totalReceivedQty && totalOrderedQty && completedPoints && totalPoints && (
                  <ProgressBar
                    label={t('InboundTaskManager.PiecesData', {
                      count: parseInt(totalReceivedQty),
                      total: parseInt(totalOrderedQty),
                      label: 'Done',
                    })}
                    value={completedPoints}
                    max={totalPoints}
                  />
                )}
              </View>
            )}
            {totalTaskPercentage &&
              taskStatus === USER_STATUS.ALMOST_DONE &&
              totalTaskPercentage > PROGRESS_LIMIT && (
                <View align="center" width="100%">
                  {totalReceivedQty && totalOrderedQty && completedPoints && totalPoints && (
                    <ProgressBar
                      label={t('InboundTaskManager.PiecesData', {
                        count: parseInt(totalReceivedQty),
                        total: parseInt(totalOrderedQty),
                        label: 'Done',
                      })}
                      value={completedPoints}
                      max={totalPoints}
                    />
                  )}
                </View>
              )}
          </View>
        </View>
      </View>
    </>
  );
};
