/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

export const LayoutAssetSchema = z.object({
  assetTypeCd: z.string(),
  assetCd: z.string(),
  assetDesc: z.string().optional(),
  assetAttributes: z.array(AttributeSchema).nullable(),
  assetLayoutMappingAttrib: z.array(AttributeSchema).nullable(),
  isActive: z.boolean().optional(),
});

export const LayoutProductSchema = z.object({
  productId: z.string(),
  min: z.string().or(z.number()).nullable(),
  max: z.string().or(z.number()).nullable(),
  isActive: z.boolean().optional(),
});

export const LayoutParentSchema = z.object({
  layoutName: z.string().nullable(),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().nullable().optional(),
  mapSequence: z.number().nullable().optional(),
  attributes: z.array(AttributeSchema).nullable(),
});

export const LayoutXrefSchema = z.object({
  toDistinctName: z.string(),
  toEntityTypeCd: z.string().optional(),
  isActive: z.boolean().optional(),
  priority: z.number(),
});

export const LayoutChildrenSchema = z.object({
  layoutName: z.string(),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string(),
});

export const LayoutListSchema = z.object({
  layout: z.object({
    layoutKey: z.string(),
    layoutName: z.string(),
    layoutNameDesc: z.string().nullable().default(''),
    layoutDistinctName: z.string(),
    entityCd: z.string(),
    entityTypeCd: z.string(),
    mapSequence: z.number().nullable().optional(),
    active: z.boolean(),
    attributes: z.array(AttributeSchema).nullable(),
  }),
  assets: z.array(LayoutAssetSchema).nullable(),
  products: z.array(LayoutProductSchema).nullable(),
  parent: LayoutParentSchema.nullable().optional(),
  xrefs: z.array(LayoutXrefSchema).nullable(),
  children: z.array(LayoutChildrenSchema).nullable().optional(),
});

export const LayoutSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z.string(),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  parentLayoutDistinctName: z.string().nullable().default(''),
  entityCd: z.string(),
  entityTypeCd: z.string(),
  active: z.boolean().default(true),
  attributes: z.array(AttributeSchema).nullable(),
  assets: z.array(LayoutAssetSchema).nullable(),
  products: z.array(LayoutProductSchema).nullable(),
});

export const LayoutViewSchema = z.object({
  layoutKey: z.string().optional(),
  layoutName: z.string(),
  layoutNameDesc: z.string().nullable().default(''),
  layoutDistinctName: z.string().optional(),
  entityCd: z.string(),
  entityTypeCd: z.string(),
  active: z.boolean().default(true),
  attributes: z.array(AttributeSchema).nullable(),
  assets: z.array(LayoutAssetSchema).nullable(),
  products: z.array(LayoutProductSchema).nullable(),
});
