/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Button, Text } from '@az/starc-ui';
import { Tag } from '@shared/components/Tag/Tag';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import { TrailerOrderListType } from '@inbound/types/types';
import {
  FinalizationDashboardDataRowType,
  FinalizationDashboardDividerRowType,
  FinalizationDashboardLocationRowType,
  TopVarianceProductCategoryType,
} from '@inventory/types/types';
import { generateDateString } from '@ofm/utils/utils';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { t } from 'i18next';
import { INBOUND_ORDER_STATUS } from '@inbound/constants/constants';
import { EventLogPitType, EventLogListType, EventLogType } from '@shared/types/service.type';

export const mapTrailerOrders = (poOrders: TrailerOrderListType[]) => {
  return poOrders.map(({ trailerOrder }) => ({
    receiptId: trailerOrder.receiptId,
    priority: trailerOrder.inboundOrder.priority,
    arrivalTime: new Date(trailerOrder.trailerArrival.trailerArrivalTs),
    vendorName: trailerOrder.inboundOrder.vendorLocale
      ? trailerOrder.inboundOrder.vendorLocale.vendorName || ''
      : '',
    poNumber: trailerOrder.sourceOrderNbr,
    location:
      trailerOrder.trailerArrival.trailerLocationTypeCd &&
      trailerOrder.trailerArrival.trailerLocationId
        ? trailerOrder.trailerArrival.trailerLocationTypeCd +
          ' ' +
          trailerOrder.trailerArrival.trailerLocationId
        : '',
    commodity: trailerOrder.commodityTypeCd,
    poType: trailerOrder.inboundOrder.orderTypeCd || '',
    shippedPcs: trailerOrder.inboundOrder.totalOrderedQty,
    linesWithError: trailerOrder.inboundOrder.inboundOrderLines?.filter(
      (line) => line.statusCd == INBOUND_ORDER_STATUS.IC_REVIEW_IN_PROGRESS
    ).length,
    sentFromInbound:
      trailerOrder.inboundOrder.inventoryControlTs &&
      new Date(trailerOrder.inboundOrder.inventoryControlTs),
    inboundOrderLines: trailerOrder.inboundOrder.inboundOrderLines,
  }));
};
const generateFinalizeDashboardTableCells = (
  order: FinalizationDashboardDividerRowType | FinalizationDashboardDataRowType,
  onBeginChecking: (poNumber: string) => void
) => {
  return [
    {
      value: order.priority && <PriorityTag />,
      sortValue: order.priority,
    },
    {
      value: order.vendorName || EMPTY_VALUE,
      sortValue: order.vendorName,
    },
    { value: order.poNumber },
    {
      value: order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
      sortValue:
        order.arrivalTime && generateDateString(order.arrivalTime, t('DateFormat.ShortTime')),
    },

    {
      value: order.location || EMPTY_VALUE,
    },
    {
      value: order.shippedPcs || EMPTY_VALUE,
      sortValue: order.shippedPcs,
    },
    {
      value: order.linesWithError || EMPTY_VALUE,
      sortValue: order.linesWithError,
    },
    {
      value: order.poType && <Tag variant="order" text={order.poType} />,
    },
    {
      value: order.commodity && <Tag variant="order" text={order.commodity} />,
    },
    {
      value:
        (order.sentFromInbound &&
          generateDateString(order.sentFromInbound, t('DateFormat.ShortTime'))) ||
        EMPTY_VALUE,
      sortValue:
        order.sentFromInbound &&
        generateDateString(order.sentFromInbound, t('DateFormat.ShortTime')),
    },
    {
      value: (
        <View>
          <Button variant="secondary" onClick={() => onBeginChecking(order.poNumber)}>
            {t('FinalizationDashboard.BeginChecking')}
          </Button>
        </View>
      ),
    },
  ];
};
export const mapFinalizationDashboardRows = (
  poOrders: FinalizationDashboardDividerRowType[],
  onBeginChecking: (poNumber: string) => void
) => {
  return poOrders.map((order) => ({
    id: `${order.poNumber.toString()}-${order.receiptId}`,
    dividerLabel: order.dividerLabel ? order.dividerLabel : '',
    cells: order.dividerLabel ? [] : generateFinalizeDashboardTableCells(order, onBeginChecking),
  }));
};

export const mapFinalizationDashboardLocationsRows = (
  orders: FinalizationDashboardLocationRowType[],
  onBeginChecking: (subzone: string) => void
) => {
  return orders.map((order) => ({
    id: `${order.productId?.toString() || ''}`,
    cells: [
      {
        value: order.subzoneName || EMPTY_VALUE,
        sortValue: order.subzoneName,
      },
      {
        value: order.location || EMPTY_VALUE,
      },
      {
        value: order.poWithError || EMPTY_VALUE,
        sortValue: order.poWithError,
      },
      {
        value:
          order.linesWithError >= 10 ? (
            <Text color="error">{order.linesWithError}</Text>
          ) : (
            order.linesWithError || EMPTY_VALUE
          ),
        sortValue: order.linesWithError,
      },

      {
        value: (
          <View>
            <Button variant="secondary" onClick={() => onBeginChecking(order.subzoneName)}>
              {t('FinalizationDashboard.BeginChecking')}
            </Button>
          </View>
        ),
      },
    ],
  }));
};

export const mapProductCategoryTableCells = (data: TopVarianceProductCategoryType[]) => {
  const rows = data?.map(
    (product: TopVarianceProductCategoryType, index: { toString: () => string }) => ({
      id: index.toString(),
      cells: [
        {
          value: (
            <View direction="row" gap={4} width="max-content">
              <Text size="087" weight="bold" color="gray-500">
                {+index + 1}
              </Text>
              <Text size="087" weight="medium" color="primary">
                {product.name}
              </Text>
            </View>
          ),
        },
        {
          value: product.value || EMPTY_VALUE,
          sortvalue: product.value,
        },
      ],
    })
  );
  return rows;
};

export const generateInventoryHistoryTableCells = (item: EventLogType) => {
  return [
    {
      value: <Text> {item.productId || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.layoutName || EMPTY_VALUE}</Text>,
    },
    {
      value:
        item.createTs && typeof item.createTs !== 'string'
          ? generateDateString(item.createTs, t('DateFormat.ShortTime'))
          : EMPTY_VALUE,
    },
    {
      value: <Text> {item.layoutDistinctName || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.receiptLabel || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.eventTypeCd?.replace(new RegExp('_', 'g'), ' ') || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.reasonCd || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.uomCd || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.documentId || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.documentType?.replace(new RegExp('_', 'g'), ' ') || EMPTY_VALUE}</Text>,
    },
    {
      value:
        item.documentDate && typeof item.documentDate !== 'string'
          ? generateDateString(item.documentDate, t('DateFormat.ShortTime'))
          : EMPTY_VALUE,
    },
    {
      value:
        item.expiryDate && typeof item.expiryDate !== 'string'
          ? generateDateString(item.expiryDate, t('DateFormat.ShortTime'))
          : EMPTY_VALUE,
    },
  ];
};

export const generateInventoryEventLogTableCells = (item: EventLogPitType) => {
  return [
    {
      value: <Text> {item.scope || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.bucket || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.operation || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.qty || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.finalQty || EMPTY_VALUE}</Text>,
    },
    {
      value: <Text> {item.receiptLabelSeqenceId || EMPTY_VALUE}</Text>,
    },
    {
      value: item.createTs
        ? generateDateString(new Date(item.createTs), t('DateFormat.ShortTime'))
        : EMPTY_VALUE,
    },
    {
      value: <Text> {item.description || EMPTY_VALUE}</Text>,
    },
  ];
};

export const mapInventoryHistory = (history: EventLogListType) => {
  return history.map((item) => ({
    productId: item.productId,
    layoutName: item.layoutName,
    layoutDistinctName: item.layoutDistinctName,
    receiptLabel: item.receiptLabel,
    eventTypeCd: item.eventTypeCd,
    reasonCd: item.reasonCd,
    uomCd: item.uomCd,
    documentId: item.documentId,
    documentType: item.documentType,
    documentDate: item.documentDate ? new Date(item.documentDate) : EMPTY_VALUE,
    expiryDate: item.expiryDate ? new Date(item.expiryDate) : EMPTY_VALUE,
    createTs: item.createTs ? new Date(item.createTs) : EMPTY_VALUE,
    eventLogPits: item.eventLogPits,
  }));
};

export const mapInventoryEventLogTableRows = (log: EventLogPitType[], key: string) => {
  return log.map((item, index) => ({
    id: `${index}-${key}-${item.scope}-${item.receiptLabelSeqenceId}-${item.operation}-${item.description}`,
    cells: generateInventoryEventLogTableCells(item),
  }));
};

export const mapInventoryHistoryTableRows = (log: EventLogType[]) => {
  return log.map((item, index) => ({
    id: `${index}-${item.productId}-${item.layoutName}-${item.eventTypeCd}`,
    cells: generateInventoryHistoryTableCells(item),
  }));
};
