/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';
import { TrailerArrivalUpdateRequestType } from '@inbound/types/types';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useTranslation } from 'react-i18next';

interface UpdateTrailerArrivalParams {
  payload: TrailerArrivalUpdateRequestType;
  receiptId: string | undefined;
  sourceOrderId: string | undefined;
  notificationText?: string;
  successCallBack?: () => void;
  errorCallBack?: () => void;
}

export const useTrailerArrivalUpdater = () => {
  const { isLoading: isLoadingUpdate, mutateUpdateTrailerArrival } = useUpdateTrailerArrival();
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const updateTrailerArrival = ({
    payload,
    receiptId,
    sourceOrderId,
    notificationText,
    successCallBack,
    errorCallBack,
  }: UpdateTrailerArrivalParams) => {
    mutateUpdateTrailerArrival(
      { receiptId: receiptId || '', trailerArrivalUpdateRequest: payload },
      {
        onSuccess: () => {
          const message = notificationText
            ? notificationText
            : t('PODashboard.Notification.UpdateTrilerArrival.Success', {
                poNumber: sourceOrderId,
              });

          handleNotification(NOTIFICATION_TYPES.SUCCESS, message);

          if (successCallBack) {
            successCallBack();
          }
        },
        onError: () => {
          if (errorCallBack) {
            errorCallBack();
          }
        },
      }
    );
  };

  return { updateTrailerArrival, isLoadingUpdate };
};
