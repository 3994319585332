/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { UserSearchSchemaType } from '@shared/types/schema.type';

export const useGetUserDetails = (userDetailsData: UserSearchSchemaType | undefined) => {
  const { t } = useTranslation();

  const userDetails = [
    {
      label: t('UserDetailsTab.UserDetailItems.FullName'),
      text: `${userDetailsData?.firstName} ${userDetailsData?.lastName}` || EMPTY_VALUE,
    },
    {
      label: t('UserDetailsTab.UserDetailItems.HiringDate'),
      text: userDetailsData?.hireDate || EMPTY_VALUE,
    },
    {
      label: t('UserDetailsTab.UserDetailItems.EmpId'),
      text: userDetailsData?.employeeId || EMPTY_VALUE,
    },
    {
      label: t('UserDetailsTab.UserDetailItems.RIMSID'),
      text: '1234' || EMPTY_VALUE, // This should be changed once data comes from the API
    },
    {
      label: t('UserDetailsTab.UserDetailItems.Lang'),
      text: userDetailsData?.preferedLanguage || EMPTY_VALUE,
    },
    {
      label: t('UserDetailsTab.UserDetailItems.Address'),
      text:
        `${userDetailsData?.addressLine1} ${userDetailsData?.city}, ${userDetailsData?.stateProvinceCd}, ${userDetailsData?.countryCd}, ${userDetailsData?.postalCd}` ||
        EMPTY_VALUE,
    },
    {
      label: t('UserDetailsTab.UserDetailItems.WageCode'),
      text: userDetailsData?.facilities[0]?.wageCd || EMPTY_VALUE,
    },
  ];

  return userDetails;
};
