/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { vendorService } from '../vendorService';

export const useGetVendors = (options: string[], isEnabled = true) => {
  const {
    data: replenishmentData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['vendors', JSON.stringify(options)],
    queryFn: () => vendorService.getVendors(options),
    enabled: isEnabled,
  });

  return { replenishmentData, isLoading, isFetching, isError, refetch };
};
