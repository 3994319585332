/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const UpdateTaskAssignmentSchema = z.object({
  userId: z.string(),
  status: z.string(),
  startTs: z.string().nullable(),
  endTs: z.string().nullable(),
  progress: z.number().optional().nullable(),
  executionPriority: z.number().nullable(),
  lastModifiedTs: z.string().optional().nullable(),
  typeCd: z.number().nullable(),
});

export const UpdateTaskAttributeSchema = z.object({
  name: z.string(),
  value: z.string().nullable().optional(),
});

export const UpdateTaskSchema = z.object({
  taskNo: z.string().optional(),
  departmentId: z.string().optional(),
  domainType: z.string().optional(),
  statusCd: z.string().optional(),
  taskTypeCd: z.string().optional(),
  priority: z.number().optional().nullable(),
  attributes: z.array(UpdateTaskAttributeSchema).nullable(),
  assignments: z.array(UpdateTaskAssignmentSchema).nullable(),
});

export const UpdateTaskResponseSchema = z.array(UpdateTaskSchema);
