/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INVENTORY_ENDPOINTS } from './../constants/routes';

import { apiService } from '@shared/services/apiService.ts';
import { parseAndLog } from '@shared/utils/service/serviceUtils';
import { GetInventoryHistoryParams, GetInventoryParams } from '@shared/types/service.type.ts';
import { InventoryProductSchema } from '@shared/schemas/inventorySchema';
import { EventLogListSchema } from '@shared/schemas/eventLogSchema';

export const inventoryService = {
  getInventoryDetails: async (payload: GetInventoryParams) => {
    const response = await apiService.post(INVENTORY_ENDPOINTS.GET_INVENTORY, payload);
    return parseAndLog(InventoryProductSchema, response.data);
  },

  getEventLogs: async (payload: GetInventoryHistoryParams) => {
    const response = await apiService.post(INVENTORY_ENDPOINTS.EVENT_LOG_SEARCH, payload);
    return parseAndLog(EventLogListSchema, response.data);
  },
};
