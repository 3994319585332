/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

const dateStringSchema = z.string().refine((value) => !isNaN(Date.parse(value)), {
  message: 'Invalid date format',
});

export const EventLogPitSchema = z.object({
  scope: z.string(),
  bucket: z.string(),
  operation: z.string(),
  qty: z.number(),
  finalQty: z.number(),
  receiptLabelSeqenceId: z.number(),
  description: z.string(),
  createTs: z.union([dateStringSchema, z.date()]).nullable(),
});

export const EventLogSchema = z.object({
  productId: z.string(),
  layoutName: z.string().nullable(),
  layoutDistinctName: z.string().nullable(),
  receiptLabel: z.string().nullable(),
  eventTypeCd: z.string().nullable(),
  reasonCd: z.string().nullable(),
  uomCd: z.string().nullable(),
  documentId: z.string().nullable(),
  documentType: z.string().nullable(),
  documentDate: z.union([dateStringSchema, z.date()]).nullable(),
  expiryDate: z.union([dateStringSchema, z.date()]).nullable(),
  createTs: z.union([dateStringSchema, z.date()]).nullable(),
  eventLogPits: z.array(EventLogPitSchema).nullable(),
});

export const EventLogListSchema = z.array(EventLogSchema);
