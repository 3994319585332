/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, Icon, ChevronLeft, Actionable, Link, Tag } from '@az/starc-ui';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Drawer } from '@shared/components/Drawer/Drawer';
import { Avatar } from '@shared/components/Avatar/Avatar';
import { AssignTaskDrawerProps } from '@taskManagement/components/AssignTaskDrawer/AssignTaskDrawer.types';
import { CombinedPillTabs } from '@taskManagement/components/CombinedPillTabs/CombinedPillTabs';
import {
  InboundTaskTypeFilter,
  USER_DETAILS_TAB,
  defaultItems,
} from '@taskManagement/constants/constants';
import { Tabs } from '@mdm/components/Tabs/Tabs';
import { Tab } from '@mdm/components/Tabs/Tabs.types';
import { Overview } from './UserTabs/Overview/Overview';
import { TransactionHistory } from './UserTabs/TransactionHistory';
import { UserDetails } from './UserTabs/UserDetails/UserDetails';
import { capitalizeFirstLetter } from '@shared/utils/commonUtils';
import styles from './AssignTaskDrawer.module.scss';

export const AssignTaskDrawer = ({
  userId,
  firstName,
  lastName,
  deptId,
  shiftId,
  onDrawerClose,
  OpenDrawer,
  IsAssignTaskList,
  userStatus,
  usersData,
  userDetailsData,
  onClickMoveUserToAnotherDept,
  onClickMoveUserBackToDept,
}: AssignTaskDrawerProps) => {
  const [currentDept, setCurrentDept] = useState<string>('');
  const [otherDept, setOtherDept] = useState<string>('');

  useEffect(() => {
    if (!deptId || !usersData) return;

    if (usersData && deptId) {
      setOtherDept(deptId);

      const CurrentUserData = usersData.find((user) => user.userId === userId);

      if (CurrentUserData) {
        const originDept = CurrentUserData.facilities.reduce((acc, facility) => {
          const originDepartment =
            facility.departments?.filter((dept) => dept.primaryFl).map((dept) => dept.deptId) ?? [];
          return acc.concat(originDepartment);
        }, [] as string[]);
        setCurrentDept(originDept.length > 0 ? originDept[0] : '');
      }
    }
  }, [deptId, usersData, userId]);

  const tabs: Array<Tab> = [
    {
      name: t(USER_DETAILS_TAB.OVERVIEW),
      value: 'Overview',
      numberOfItems: defaultItems,
      content: (
        <Overview
          userStatus={userStatus}
          userId={userId}
          firstName={firstName}
          lastName={lastName}
          usersData={usersData}
        />
      ),
    },
    {
      name: t(USER_DETAILS_TAB.TRANSACTION_HISTORY),
      value: 'Transaction',
      numberOfItems: defaultItems,
      content: <TransactionHistory />,
    },
    {
      name: t(USER_DETAILS_TAB.USER_DETAILS),
      value: 'UserDetails',
      numberOfItems: defaultItems,
      content: <UserDetails userDetailsData={userDetailsData} />,
    },
  ];
  return (
    <>
      <Drawer
        show={OpenDrawer}
        handleClose={() => onDrawerClose(false)}
        closeByClickAway={false}
        className={styles['assign-task-drawer']}
        size="small"
        position="right"
        CustomHeader={
          userId && (
            <>
              <View gap={4} direction="row" align="center" backgroundColor="secondary">
                <View
                  align="center"
                  justify="center"
                  className={styles['assign-task-drawer__profile-icon']}
                  padding={[2, 2]}
                  borderRadius="round"
                  width="var(--st-unit-14)"
                  height="var(--st-unit-14)"
                  gap={2}
                >
                  <Avatar
                    key={'avatar' + userId}
                    name={firstName && lastName ? `${firstName} ${lastName}` : ''}
                    size="extraLarge"
                  />
                </View>

                <View.Item gapBefore={6}>
                  <View direction="row" align="center">
                    <Text
                      weight="bold"
                      size="175"
                      className={styles['assign-task-drawer__username']}
                    >
                      {firstName && lastName ? `${firstName} ${lastName}` : ''}
                    </Text>
                    <Text size="087" variant="subtitle-regular">
                      {otherDept !== currentDept && (
                        <Tag>{`Working in ${capitalizeFirstLetter(otherDept)}`}</Tag>
                      )}
                    </Text>
                  </View>
                  <View direction="row" align="start" width="100%" justify="start">
                    <Text size="100">
                      {userId} |
                      {t(`MoveUserBackToDept.WorkingDept`, {
                        WorkingDept: currentDept && `${capitalizeFirstLetter(currentDept)}`,
                      })}
                      {otherDept !== currentDept ? (
                        <Link
                          onClick={(
                            event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                          ) => {
                            event.stopPropagation();
                            onClickMoveUserBackToDept?.(userId);
                          }}
                        >
                          {t(`NotActiveDropDownList.MoveBackToOriginalDepartment`, {
                            OriginalDept: currentDept && `${capitalizeFirstLetter(currentDept)}`,
                          })}
                        </Link>
                      ) : (
                        <Link
                          onClick={(
                            event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
                          ) => {
                            event.stopPropagation();
                            onClickMoveUserToAnotherDept?.(userId);
                          }}
                        >
                          {t(`NotActiveDropDownList.MoveToAnotherDepartment`)}
                        </Link>
                      )}
                    </Text>
                  </View>
                </View.Item>
              </View>
            </>
          )
        }
      >
        {IsAssignTaskList && (
          <>
            <View
              gap={1}
              padding={[0, 6]}
              direction="row"
              align="center"
              width="100%"
              justify="start"
              className={styles['available-tasks']}
            >
              <View gap={4} align="center" direction="row">
                <Actionable onClick={() => onDrawerClose(false)}>
                  <Icon svg={ChevronLeft} />
                </Actionable>
              </View>
              <View gap={2} align="center" direction="row">
                <Text size="125" variant="subtitle-bold">
                  {t(`AvailableTasks`)}
                </Text>
              </View>
            </View>
            <View padding={6} gap={4} align="center" width="100%" direction="row">
              {/* Available task list - PO,  RECOMMENDED , Replenishments TAsk Type Pill Tab */}
              <CombinedPillTabs
                TabsFilterData={InboundTaskTypeFilter}
                userId={userId}
                firstName={firstName}
                lastName={lastName}
                shiftId={shiftId}
                usersData={usersData}
              />
            </View>
          </>
        )}

        {/* User Search Drawer Profile Screen - Overview Transaction History etc.. */}
        {!IsAssignTaskList && (
          <View width="100%" className={styles['assign-task-drawer__user__tabs']}>
            <Tabs tabs={tabs} />
          </View>
        )}
      </Drawer>
    </>
  );
};
