/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GetOrderTypeParams, OrderTypeRequest } from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { ORDER_TYPE_ENDPOINTS } from '@shared/constants/routes.ts';

export const orderTypeService = {
  createOrderType: async (orderTypeRequest: OrderTypeRequest) => {
    const payload = { ...orderTypeRequest };
    const resp = await apiService.post(ORDER_TYPE_ENDPOINTS.CREATE_BULK_ORDER_TYPE, [payload]);
    return resp.data;
  },
  getOrderTypes: async (options: GetOrderTypeParams) => {
    const payload =
      options.orderTypeCodes && options.orderTypeCodes.length ? [options.orderTypeCodes] : [];

    const response = await apiService.post(ORDER_TYPE_ENDPOINTS.GET_ORDER_TYPES, payload);
    return response.data;
  },
  updateOrderType: async (orderTypeCode: string, orderTypeRequest: OrderTypeRequest) => {
    const payload = { ...orderTypeRequest };
    const resp = await apiService.put(
      ORDER_TYPE_ENDPOINTS.UPDATE_ORDER_TYPE(orderTypeCode),
      payload
    );
    return resp.data;
  },
};
