/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Button,
  Modal,
  View,
  Text,
  Icon,
  Divider,
  TextField,
  classNames,
  FormControl,
  Skeleton,
} from '@az/starc-ui';
import { ShiftGoalModalProps } from './ShiftGoalModal.types';
import { Checkmark, Close } from '@az/starc-ui-icons';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import {
  PAST_SHIFTS_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants';
import {
  getPreviousShiftDetails,
  mapShiftHistoryTableRows,
} from '@taskManagement/utils/table/tableUtils';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants';
import { Table } from '@shared/components/Table/Table';
import styles from './ShiftGoalModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  InboundShiftDetailsType,
  InboundShiftGoalMappedTableDetailsType,
  InboundShiftGoalOptions,
} from '@taskManagement/types/types';
import {
  calculateHoursBetweenTimes,
  getOrdinal,
  getPrevious4Shifts,
} from '@shared/utils/validationUtils';
import { asyncShiftsAtom } from '@shared/atoms/shifts/shiftAtom';
import { useAtom } from 'jotai';
import { useGetShiftDetails } from '@taskManagement/services/hooks/useGetShiftDetails';

export const ShiftGoalModal = ({
  isOpen,
  onClose,
  onSave,
  submitInProgress = false,
  currentShift,
  usersCount,
}: ShiftGoalModalProps) => {
  /* Constants */
  const { t } = useTranslation();
  const [allShifts] = useAtom(asyncShiftsAtom);

  /* State variables */
  const [currentPOs, setPOs] = useState<InboundShiftGoalOptions>({
    regularPO: '',
    DSD: '',
    LTD: '',
  });
  const [errors, setErrors] = useState({
    regularPO: false,
    DSD: false,
    LTD: false,
  });
  const [previousShiftDetails, setPreviousShiftDetails] =
    useState<InboundShiftGoalMappedTableDetailsType>();

  const [previousShifts, setPreviousShifts] = useState<InboundShiftDetailsType>([]);

  /* Queries */
  const {
    shiftsSearchData,
    isFetching: isPreviousShiftsLoading,
    refetch: shiftSearchRefetch,
  } = useGetShiftDetails(
    {
      pageSize: 12,
      shiftId: previousShifts.map((shift) => shift.shiftId),
      metrics: ['PO', 'LTD', 'DSD'],
      activity: ['PO_RECEIVING'],
    },
    false
  );

  /* Functions */
  const handleCancel = () => {
    setPOs({ regularPO: '', DSD: '', LTD: '' });
  };

  useEffect(() => {
    if (allShifts && currentShift?.length && isOpen) {
      setPreviousShifts(getPrevious4Shifts(allShifts, currentShift));
    }
  }, [allShifts, currentShift, isOpen]);

  useEffect(() => {
    if (previousShifts.length && isOpen) {
      shiftSearchRefetch();
    }
  }, [previousShifts, isOpen, shiftSearchRefetch]);

  useEffect(() => {
    if (shiftsSearchData && isOpen && currentShift?.length) {
      setPreviousShiftDetails(getPreviousShiftDetails(shiftsSearchData?.content));
    }
  }, [shiftsSearchData, isOpen, currentShift]);

  const handleGoalSubmit = () => {
    if (currentPOs.DSD && currentPOs.LTD && currentPOs.regularPO) {
      onSave(currentPOs);
      setPOs({ regularPO: '', DSD: '', LTD: '' });
    } else {
      if (!currentPOs.regularPO) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          regularPO: true,
        }));
      }
      if (!currentPOs.DSD) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          DSD: true,
        }));
      }
      if (!currentPOs.LTD) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          LTD: true,
        }));
      }
    }
  };

  const resetModal = () => {
    setPOs({ regularPO: '', DSD: '', LTD: '' });
    setErrors({ regularPO: false, DSD: false, LTD: false });
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      size="large"
      closeByClickAway={false}
      className={styles['task-manager__modal']}
    >
      <View
        direction="row"
        justify="space-between"
        padding={4}
        className={styles['task-manager__header']}
      >
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {t('ShiftGoalHistory.Title')}
          </Text>
        </View>
        <Button
          variant="ghost"
          className={classNames(styles['close-icon'], styles['task-manager__header__close-button'])}
          onClick={handleClose}
        >
          <Icon svg={Close} />
        </Button>
      </View>

      <View className={styles['task-manager__body']} padding={4}>
        <View className={styles['task-manager__current_shift']} padding={4} gap={2}>
          <View direction="row" justify="space-between" align="center">
            <View direction="column" gap={2}>
              <Text weight="bold" size="137" color="primary">
                {t('ShiftGoalHistory.CurrentShift')}
              </Text>
              <Text size="087" color="500">
                {getOrdinal(
                  (currentShift && currentShift.length && currentShift[0].shiftIndex) || 0
                )}{' '}
                {t(`ShiftGoalHistory.Receiver`, { receiver: usersCount })}
              </Text>
            </View>
            <StatusBadge
              variant={StatusVariants.COMPLETED}
              text={t('ShiftGoalHistory.TimeBreak', {
                hour: calculateHoursBetweenTimes(
                  new Date().getHours() + ':' + new Date().getMinutes(),
                  (currentShift && currentShift.length && currentShift[0].toTime) || ''
                ),
              })}
            />
          </View>

          <View className={styles['task-manager__current_shift-details']} padding={4} gap={3}>
            <View direction="row" justify="space-between" align="center">
              <View direction="column" gap={2}>
                <Text weight="bold" size="100" color="primary">
                  {t('ShiftGoalHistory.newShiftTitle')}
                </Text>
                <Text size="087" color="500" className={styles['widget_subtext']}>
                  {t(`ShiftGoalHistory.PreviousStatus`)}&nbsp;
                  <u>
                    {t(`ShiftGoalHistory.ShiftCount`, {
                      completed: (previousShiftDetails && previousShiftDetails.goalsCompleted) || 0,
                      total: (previousShiftDetails && previousShiftDetails.totalGoals) || 0,
                    })}
                  </u>
                  &nbsp;
                  {t(`ShiftGoalHistory.LastShiftText`)}&nbsp;
                  <u>
                    {t(`ShiftGoalHistory.difference`, {
                      difference:
                        (previousShiftDetails &&
                          previousShiftDetails.totalGoals - previousShiftDetails.goalsCompleted) ||
                        0,
                    })}
                  </u>
                  &nbsp;
                  {t(`ShiftGoalHistory.PoGoalText`)}
                </Text>
              </View>
              <View direction="row" gap={3}>
                <Button variant="secondary" onClick={handleCancel} disabled={submitInProgress}>
                  {t('Cancel')}
                </Button>
                <Button variant="primary" onClick={handleGoalSubmit} loading={submitInProgress}>
                  {t('Save')}
                </Button>
              </View>
            </View>

            <Divider color="300" />

            <View direction="row" gap={3} align="stretch">
              <View className={styles['task-manager__current_shift-entires']}>
                <View direction="row" justify="space-between" align="center">
                  <View>
                    <Text weight="bold" size="100" color="primary">
                      {Number(currentPOs.regularPO) +
                        Number(currentPOs.DSD) +
                        Number(currentPOs.LTD)}{' '}
                      POs
                    </Text>
                  </View>
                  <View>
                    <Text size="100" color="500">
                      {t('ShiftGoalHistory.NoGoal')}
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <Divider vertical color="300" />
              </View>

              <View className={styles['task-manager__current_shift-entires']} gap={2}>
                <View direction="row" align="center">
                  <Text weight="bold" size="100" color="500">
                    {t('ShiftGoalHistory.OrderBreakDown')}
                  </Text>
                </View>

                <View gap={2}>
                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        Regular PO
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <FormControl hasError={errors.regularPO}>
                          <TextField
                            label={t('ShiftGoalHistory.InputLabel')}
                            value={currentPOs.regularPO.toString()}
                            size="small"
                            onChange={(e) => {
                              setPOs({ ...currentPOs, regularPO: e.target.value });
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                regularPO: false,
                              }));
                            }}
                          />
                        </FormControl>
                      </View>
                    </View>
                  </View>

                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        DSD
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <FormControl hasError={errors.DSD}>
                          <TextField
                            label={t('ShiftGoalHistory.InputLabel')}
                            value={currentPOs.DSD.toString()}
                            size="small"
                            onChange={(e) => {
                              setPOs({ ...currentPOs, DSD: e.target.value });
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                DSD: false,
                              }));
                            }}
                          />
                        </FormControl>
                      </View>
                    </View>
                  </View>
                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        LTD
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <FormControl hasError={errors.LTD}>
                          <TextField
                            label={t('ShiftGoalHistory.InputLabel')}
                            value={currentPOs.LTD.toString()}
                            size="small"
                            onChange={(e) => {
                              setPOs({ ...currentPOs, LTD: e.target.value });
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                LTD: false,
                              }));
                            }}
                          />
                        </FormControl>
                      </View>
                    </View>
                  </View>
                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        Total
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <Text>
                          {' '}
                          {!(
                            currentPOs.regularPO.length ||
                            currentPOs.DSD.length ||
                            currentPOs.LTD.length
                          )
                            ? 0
                            : Number(currentPOs.regularPO) +
                              Number(currentPOs.DSD) +
                              Number(currentPOs.LTD)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View className={styles['task-manager__past_shifts']} gap={2} padding={[4, 0, 0, 0]}>
          <View>
            <Text weight="bold" size="137" color="primary">
              {t('ShiftGoalHistory.PastShifts')}
            </Text>
          </View>

          <View>
            {isPreviousShiftsLoading ? (
              <View gap={4}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </View>
            ) : shiftsSearchData?.content.length ? (
              <Table
                columns={PAST_SHIFTS_TABLE_COLUMNS}
                rows={mapShiftHistoryTableRows(shiftsSearchData?.content || [])}
                isPaginated={false}
                pageSize={PAGE_SIZE}
                defaultPage={DEFAULT_PAGE}
                isCreditItem={false}
                isCheckboxTable={false}
                styleVariant={TableStylingVariants.DETAILS}
                totalPages={0}
                onSort={() => ({})}
              />
            ) : (
              <View padding={4} gap={2} direction="row" justify="center" align="center">
                <Text>{t('ShiftGoalHistory.NoPastShifts')}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};
