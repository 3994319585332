/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS } from '@shared/constants/routes';
import { apiService } from '@shared/services/apiService';
import { parseAndLog } from '@shared/utils/service/serviceUtils';
import {
  CreateLocationCountSyncParams,
  StartCountParams,
  SubZoneAssigneesLocCountSyncParams,
} from '@inventory/types/service.types';
import { LocationCountSyncSchema } from '@inventory/schemas/LocationCountSyncSchema';

export const createLocationCountService = {
  createLocationCount: async (options: CreateLocationCountSyncParams) => {
    const response = await apiService.post(
      LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS.PERFORM_ACTION_SYNC,
      options
    );
    return parseAndLog(LocationCountSyncSchema, response.data);
  },
  UpdateSubZoneAssigneesLocCount: async (options: SubZoneAssigneesLocCountSyncParams) => {
    const response = await apiService.post(
      LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS.PERFORM_ACTION_SYNC,
      options
    );
    return parseAndLog(LocationCountSyncSchema, response.data);
  },
  UpdateStartCount: async (options: StartCountParams) => {
    const response = await apiService.post(
      LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS.PERFORM_ACTION_SYNC,
      options
    );
    return parseAndLog(LocationCountSyncSchema, response.data);
  },
};
