/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text } from '@az/starc-ui';
import { t } from 'i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes';
import { CycleCountTable } from '../CycleCountTable/CycleCount.table';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import { CycleCountRowType } from '@inventory/types/types';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_PAGE, SORT_BY, SORT_ORDER } from '@shared/constants/constants';
import { mapCycleCountAPIData } from '@inventory/utils/table/cycleCountTableUtils';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  CYCLE_COUNT_STATUS_CD,
  PAGE,
} from '@inventory/constants/constants';
import { SortRowsParam } from '@shared/components/Table/Table.types';

type ActiveCountTableProps = {
  countTypeCd: string;
};

export const ActiveCountTable: React.FC<ActiveCountTableProps> = ({ countTypeCd }) => {
  const navigate = useNavigate();
  const [activeData, setActiveData] = useState<CycleCountRowType[]>();

  const [, setIsEnableCreateBtn] =
    useOutletContext<[unknown, React.Dispatch<React.SetStateAction<boolean>>]>();

  /* Query for Active Count data */
  const { wallToWallData: activeCycleCountData, isLoading } = useCycleCount({
    searchPage: {
      page: PAGE,
      size: DEFAULT_PAGE,
      sort: [
        {
          sortBy: SORT_BY.END_TS,
          direction: SORT_ORDER.DESC,
        },
      ],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: countTypeCd,
        statusCd: [CYCLE_COUNT_STATUS_CD.NEW, CYCLE_COUNT_STATUS_CD.INPROGRESS],
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
      ],
    },
  });

  // Mapped currentActiveData using : Location count search API response
  const currentActiveData: CycleCountRowType[] = useMemo(() => {
    return mapCycleCountAPIData(activeCycleCountData?.content, [
      CYCLE_COUNT_STATUS_CD.NEW,
      CYCLE_COUNT_STATUS_CD.INPROGRESS,
    ]);
  }, [activeCycleCountData]);

  const handleTableRowClick = (clickedRow: SortRowsParam) => {
    const { id } = clickedRow;
    navigate(PAGE_URLS.CYCLE_COUNTS_DETAILS(id, countTypeCd));
  };

  const isActiveItem = activeData?.some((item) => item.status === CYCLE_COUNT_STATUS_CD.NEW);

  useEffect(() => {
    if (currentActiveData) {
      setActiveData(currentActiveData);

      //if there is no active item with NEW Status, then enable create button & if Active item with NEW status then disable create button
      const hasNewStatusItem = currentActiveData?.some(
        (item) =>
          item.status === CYCLE_COUNT_STATUS_CD.NEW ||
          item.status === CYCLE_COUNT_STATUS_CD.INPROGRESS
      );
      setIsEnableCreateBtn(!hasNewStatusItem);
    }
  }, [currentActiveData, setIsEnableCreateBtn]);

  return (
    <>
      <View.Item>
        <CycleCountTable
          data={activeData}
          isLoading={isLoading}
          noRecordsLabel="CycleCount.ActiveTable.NoRecords"
          label="CycleCount.ActiveTable.Title"
          isPaginated={false}
          handleTableRowClick={(clickedRow) => handleTableRowClick(clickedRow)}
        />
        {isActiveItem && <Text color="error">{t('CycleCount.ActiveTable.IncompleTePhase')}</Text>}
      </View.Item>
    </>
  );
};
