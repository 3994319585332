/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { GetLayoutSearchParams } from '@inventory/types/service.types';
import { layoutSearchService } from '../layoutSearchService';

export const useSubzoneLayoutSearch = (options: GetLayoutSearchParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const {
    data: subzoneLayoutSearchData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['subzoneList', JSON.stringify(options)],
    queryFn: () => layoutSearchService.getLayoutSearchData(options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('CycleCount.Notification.Error'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { subzoneLayoutSearchData, isLoading, isFetching, isError, refetch };
};
