/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';

import { View, Text, Card } from '@az/starc-ui';

import { useTranslation } from 'react-i18next';

import { DISCREPANCY_STATUS, headersData } from '@inventory/constants/constants';

import { OrderLinesCard } from '../PODiscrepancy/OrderLinesCard';

import styles from './index.module.scss';
import { DiscrepancyTabsProps } from './Discripancy.types';

export const UnCheckedDiscrepancy = ({
  inboundOrderData,
  isInboundOrderFeatching,
  setMarkedAsCheckPO,
  setIsRequiredCommentsAdded,
  inboundOrderDataBySubzone,
}: DiscrepancyTabsProps) => {
  /* Constants */
  const { t } = useTranslation();

  // UseEffect added to set the isRequiredCommentsAdded state based on the receivedQtyAdj and comment values of the inboundOrderLineLocations and comment values of the inboundOrderLine
  useEffect(() => {
    inboundOrderData?.forEach((data) => {
      data.inboundOrder.inboundOrderLines?.forEach((line) => {
        if (
          line.inboundOrderLineLocations?.some((location) => location.receivedQtyAdj !== null) &&
          (line.comment === null || line.comment?.trim() === '')
        ) {
          setIsRequiredCommentsAdded?.(false);
        } else {
          setIsRequiredCommentsAdded?.(true);
        }
      });
    });
  }, [inboundOrderData, setIsRequiredCommentsAdded]);

  return inboundOrderDataBySubzone?.map((data) => {
    const trailer = data.inboundOrder.trailerOrders.slice(-1)[0].trailerArrival;
    const totalDiscrepancy = data.inboundOrder.totalReceivedQty - data.inboundOrder.totalOrderedQty;

    return (
      <View padding={6} direction="column" className={styles['subzone-discrepancy']}>
        <View>
          <Card hasHoverEffect={false}>
            <View direction="row" align="center" justify="space-between" padding={4}>
              <View.Item grow>
                <View direction="row" gap={8}>
                  <View>
                    <Text variant="display-5">
                      {t('FinalizationDashboard.Subzone.Vendor', {
                        vendor: data.inboundOrder.vendorLocale?.vendorName,
                      })}
                    </Text>
                  </View>
                  <View>
                    <Text variant="display-5">
                      {t('FinalizationDashboard.Subzone.PO', {
                        poNumber: data.inboundOrder.sourceOrderNbr,
                      })}
                    </Text>
                  </View>
                  <View>
                    <Text variant="display-5">
                      {t('FinalizationDashboard.Subzone.Location', {
                        location: `${trailer?.trailerLocationTypeCd} ${trailer?.trailerLocationId}`,
                      })}
                    </Text>
                  </View>
                  <View>
                    <Text variant="display-5">{data.inboundOrder.orderLocationId}</Text>
                  </View>
                </View>
              </View.Item>
              <View.Item>
                <View direction="row" gap={1}>
                  <Text variant="display-5">
                    {t('FinalizationDashboard.Subzone.TotalPODiscrepancy')}
                  </Text>
                  {totalDiscrepancy > 0 ? (
                    <Text variant="display-5" color="success">
                      {'+' + totalDiscrepancy}
                    </Text>
                  ) : (
                    <Text variant="display-5" color="error">
                      {totalDiscrepancy}
                    </Text>
                  )}
                </View>
              </View.Item>
            </View>
          </Card>
        </View>
        <View>
          <View padding={4} className={styles['subzone-discrepancy__card']}>
            <View
              padding={[3, 4]}
              width="100%"
              direction="row"
              align="start"
              className={styles['subzone-discrepancy__header']}
            >
              {headersData.map((header) => (
                <View key={header.label} width={header.width}>
                  <Text weight="medium">{t(header.label)}</Text>
                </View>
              ))}
            </View>

            <View direction="row" gap={2} width="100%">
              {data.inboundOrder.inboundOrderLines?.map((order) => {
                return (
                  <OrderLinesCard
                    key={order.productId}
                    orderLines={order}
                    inboundOrderData={data}
                    isInboundOrderFeatching={isInboundOrderFeatching}
                    setMarkedAsCheckPO={setMarkedAsCheckPO}
                    status={DISCREPANCY_STATUS.UNCHECKED}
                  />
                );
              })}
            </View>
          </View>
        </View>
      </View>
    );
  });
};
