/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  View,
  Text,
  Actionable,
  Icon,
  ChevronDown,
  ChevronUp,
  Tooltip,
  Button,
} from '@az/starc-ui';

import { Checkmark } from '@az/starc-ui-icons';

import { AvatarGroup } from '@shared/components/AvatarGroup/AvatarGroup';
import { DIRECTION } from '@shared/components/Table/Table.types';
import { EMPTY_VALUE } from '@shared/constants/constants';
import { generateLayoutNameFromDistinctName } from '@shared/utils/commonUtils';

import {
  INBOUND_ORDER_STATUS,
  MAX_VISIBLE_AVATARS_PO_DASHBOARD,
  TRAILER_ORDER_STATUS,
} from '@inbound/constants/constants';
import { headerItems } from '@inbound/pages/PODashboard/Detail/PODiscrepancyTable/data';
import { InboundTrailerLineType } from '@inbound/types/types';

import * as T from './PODiscrepancy.types';
import s from './PODiscrepancy.module.scss';
import styles from '../../Detail/PODetail.module.scss';

export const PODiscrepancyTable = ({
  productData,
  acceptedRows,
  handleIndividualAccept,
  users,
  status,
}: T.Props) => {
  /* State variables */
  const [expandedProductRow, setExpandedProductRow] = useState<{ [key: string]: boolean }>({});
  const [sortDirections, setSortDirections] = useState('default');
  const [sortBy, setSortBy] = useState<string>('default');

  /* Constants */
  const { t } = useTranslation();

  /* Functions */
  const toggleExpand = (productId: string) => {
    setExpandedProductRow((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId],
    }));
  };

  const handleSort = (header: { width: string; text: string; name: string }) => {
    const sortKey = header.name;
    setSortBy(sortKey);
    setSortDirections((prevSortDirection) => {
      if (prevSortDirection === DIRECTION.ASCENDING) {
        return DIRECTION.DESCENDING;
      } else {
        return DIRECTION.ASCENDING;
      }
    });
  };

  const formatValue = (totalOrderedQty: number, totalReceivedQty: number) => {
    let color: React.ReactNode = EMPTY_VALUE;

    if (totalReceivedQty > 0) {
      const value = totalReceivedQty - totalOrderedQty;
      const formattedValue = value > 0 ? `+${value}` : value.toString();

      color =
        value > 0 ? (
          <Text color="success">{formattedValue}</Text>
        ) : value < 0 ? (
          <Text color="error">{formattedValue}</Text>
        ) : (
          EMPTY_VALUE
        );
    }

    return color;
  };

  const calculateDescrepancies = (productItem: InboundTrailerLineType) => {
    const { totalOrderedQty, totalReceivedQtyAdj, totalReceivedQty } = productItem;

    if (totalReceivedQtyAdj && totalReceivedQtyAdj > 0) {
      return formatValue(totalOrderedQty, totalReceivedQtyAdj);
    } else if (totalReceivedQty > 0) {
      return formatValue(totalOrderedQty, totalReceivedQty);
    }
  };

  /* Hooks */
  useMemo(() => {
    if (['productLocaleDesc', 'productId'].includes(sortBy)) {
      return productData.sort((a, b) => {
        if (sortDirections === DIRECTION.ASCENDING) {
          return (a.product?.productLocaleDesc || '').localeCompare(
            b.product?.productLocaleDesc || ''
          );
        } else {
          return (b.product?.productLocaleDesc || '').localeCompare(
            a.product?.productLocaleDesc || ''
          );
        }
      });
    }

    return productData.sort((a, b) => {
      const firstValue = a[sortBy as keyof InboundTrailerLineType];
      const secondValue = b[sortBy as keyof InboundTrailerLineType];

      if (typeof firstValue === 'number' && typeof secondValue === 'number') {
        return sortDirections === DIRECTION.ASCENDING
          ? firstValue - secondValue
          : secondValue - firstValue;
      } else if (typeof firstValue === 'string' && typeof secondValue === 'string') {
        return sortDirections === DIRECTION.ASCENDING
          ? firstValue.localeCompare(secondValue)
          : secondValue.localeCompare(firstValue);
      } else if (firstValue && secondValue) {
        return (
          (firstValue < secondValue ? -1 : 1) * (sortDirections === DIRECTION.ASCENDING ? 1 : -1)
        );
      } else if (firstValue) {
        return 1;
      } else if (secondValue) {
        return 1;
      } else {
        return -1;
      }
    });
  }, [productData, sortBy, sortDirections]);

  return (
    <View direction="column" gap={2} width="100%" padding={[0, 0]}>
      {productData.length > 0 && (
        <>
          <View
            padding={[0, 2, 0, 2]}
            direction="row"
            width="100%"
            className={s['line-header']}
            attributes={{ style: { minWidth: '1552px' } }}
          >
            {headerItems.map((header) => (
              <View
                direction="row"
                align="center"
                width={header.width}
                padding={[0, 2]}
                className={s['line-header__header-item']}
              >
                <Text className={s['line-header__header-text']} maxLines={1}>
                  {header.text}
                </Text>
                {header.sortable && (
                  <Actionable
                    onClick={() =>
                      header.name &&
                      handleSort({ width: header.width, text: header.text, name: header.name })
                    }
                    attributes={{ style: { cursor: 'pointer' } }}
                  >
                    {sortBy !== header.name && (
                      <>
                        <Icon svg={ChevronUp} size={3} color="500" />
                        <Icon svg={ChevronDown} size={3} color="500" />
                      </>
                    )}
                    {sortDirections === DIRECTION.ASCENDING && sortBy === header.name && (
                      <Icon svg={ChevronUp} size={3} color="500" />
                    )}
                    {sortDirections === DIRECTION.DESCENDING && sortBy === header.name && (
                      <Icon svg={ChevronDown} size={3} color="500" />
                    )}
                  </Actionable>
                )}
              </View>
            ))}
          </View>

          {/* Rows */}
          {productData.map((productItem: InboundTrailerLineType) => (
            <View
              backgroundColor="secondary"
              className={s['line-wrapper']}
              attributes={{ style: { minWidth: '1552px' } }}
            >
              <View
                align="center"
                direction="row"
                wrap={false}
                width="100%"
                backgroundColor="secondary"
                padding={[0, 2]}
              >
                <View
                  className={s['line-wrapper__item']}
                  width="calc(var(--st-unit-28)*2 -  var(--st-unit-1))"
                >
                  <Text weight="bold">{productItem.product?.productLocaleDesc || EMPTY_VALUE}</Text>
                </View>

                <View className={s['line-wrapper__item']} width="calc(var(--st-unit-32)*2 - 1px)">
                  {productItem.inboundOrderLineLocations &&
                  productItem.inboundOrderLineLocations?.length > 0 ? (
                    <Text>
                      {`${productItem.inboundOrderLineLocations?.[0]?.layoutAttribNameType} - ${
                        productItem.inboundOrderLineLocations?.[0]?.layoutName ||
                        generateLayoutNameFromDistinctName(
                          productItem.inboundOrderLineLocations?.[0]?.layoutDistinctName
                        )
                      }`}
                      {(productItem.inboundOrderLineLocations || []).length > 1 && (
                        <>
                          ,&nbsp;
                          <Actionable onClick={() => toggleExpand(productItem.productId)}>
                            {!expandedProductRow[productItem.productId] ? (
                              <Text size="087" weight="bold" decoration="underline">
                                {t('PODashboard.ProductTableSection.ShowMore', {
                                  count: (productItem.inboundOrderLineLocations || []).length,
                                })}
                              </Text>
                            ) : (
                              <Text size="087" weight="bold" decoration="underline">
                                {t('PODashboard.ProductTableSection.HideMore', {
                                  count: (productItem.inboundOrderLineLocations || []).length,
                                })}
                              </Text>
                            )}
                          </Actionable>
                        </>
                      )}
                    </Text>
                  ) : (
                    EMPTY_VALUE
                  )}
                </View>

                <View className={s['line-wrapper__item']} width="calc(var(--st-unit-28) - 2px)">
                  <Text>{productItem.productId || EMPTY_VALUE}</Text>
                </View>

                <View className={s['line-wrapper__item']} width="calc(var(--st-unit-28) - 2px)">
                  <Text>{productItem.partNbrId || EMPTY_VALUE}</Text>
                </View>

                <View className={s['line-wrapper__item']} width="calc(var(--st-unit-23) - 2px)">
                  <Text weight="bold">{productItem.totalOrderedQty || EMPTY_VALUE}</Text>
                </View>
                <View className={s['line-wrapper__item']} width="var(--st-unit-25)">
                  <Text weight="bold">{productItem.totalOrderedQty || EMPTY_VALUE}</Text>
                </View>
                <View className={s['line-wrapper__item']} width="calc(var(--st-unit-24) - 1px)">
                  <Text weight="bold">
                    {productItem.totalReceivedQtyAdj
                      ? productItem.totalReceivedQtyAdj
                      : productItem.totalReceivedQty || EMPTY_VALUE}
                  </Text>
                </View>
                <View className={s['line-wrapper__item']} width="var(--st-unit-30)">
                  <View>
                    <AvatarGroup
                      users={users.map((user) => ({
                        id: parseInt(user.assignedUserId),
                        firstName: user.assignedUserName.split(' ')[0],
                        lastName: user.assignedUserName.split(' ')[1],
                      }))}
                      size="small"
                      variant="assignee"
                      maxDisplay={MAX_VISIBLE_AVATARS_PO_DASHBOARD}
                    />
                  </View>
                </View>
                <View className={s['line-wrapper__item']} width="var(--st-unit-30)">
                  <Text weight="bold">{calculateDescrepancies(productItem)}</Text>
                </View>
                <View className={s['line-wrapper__item']} width="calc(var(--st-unit-20) * 2)">
                  <Text weight="bold">{EMPTY_VALUE}</Text>
                </View>
                <View.Item grow>
                  <View
                    className={s['line-wrapper__item']}
                    //width="calc(var(--st-unit-15)*3)"
                    align="end"
                  >
                    {productItem.productId &&
                    acceptedRows[productItem.productId] &&
                    status === TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION ? (
                      <View className={styles['button-tooltip']}>
                        <Tooltip
                          variant="alt"
                          placement="left"
                          triggerType="hover"
                          buttonTitle=""
                          svg={
                            <View>
                              <Button
                                attributes={{
                                  style: {
                                    width: 'fit-content',
                                    color: 'var(--additional-green-700)',
                                    backgroundColor: 'var(--additional-green-300)',
                                  },
                                }}
                                onClick={() => {
                                  handleIndividualAccept(productItem.productId);
                                }}
                                variant="secondary"
                                size="small"
                              >
                                <View direction="row" justify="center" align="center" gap={2}>
                                  <Icon svg={Checkmark} />
                                  <Text>{t('PODashboard.Accepted')}</Text>
                                </View>
                              </Button>
                            </View>
                          }
                        >
                          <View padding={[0, 4, 4, 4]} textAlign="start">
                            <Text color="500">{t('PODashboard.AcceptedTooltipText')}</Text>
                          </View>
                        </Tooltip>
                      </View>
                    ) : status === TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION ? (
                      <Button
                        onClick={() => {
                          handleIndividualAccept(productItem.productId);
                        }}
                        attributes={{
                          style: { width: 'fit-content' },
                        }}
                        variant="secondary"
                        size="small"
                      >
                        {t('PODashboard.Accept')}
                      </Button>
                    ) : productItem.statusCd == INBOUND_ORDER_STATUS.ACCEPTED ? (
                      <Button
                        onClick={() => {
                          handleIndividualAccept(productItem.productId);
                        }}
                        variant="secondary"
                        size="small"
                        attributes={{
                          style: {
                            width: 'fit-content',
                          },
                        }}
                        disabled={true}
                      >
                        <View direction="row" justify="center" align="center" gap={2}>
                          <Icon color="400" svg={Checkmark} />
                          <Text>{t('PODashboard.Accepted')}</Text>
                        </View>
                      </Button>
                    ) : (
                      <View direction="row" justify="start" align="start">
                        <Text weight="bold">{t('PODashboard.IcReviewInProgress')}</Text>
                      </View>
                    )}
                  </View>
                </View.Item>
              </View>

              {expandedProductRow[productItem.productId] &&
                (productItem.inboundOrderLineLocations?.length || 0) > 0 && (
                  <View
                    direction="row"
                    backgroundColor="secondary"
                    className={s['location-wrapper']}
                    attributes={{ style: { minWidth: '1552px' } }}
                  >
                    <View width="calc(var(--st-unit-28)*2">&nbsp;</View>
                    <View
                      direction="row"
                      padding={[4, 4, 4, 3]}
                      attributes={{ style: { flex: 7.1 } }}
                      backgroundColor="secondary"
                    >
                      <View.Item grow>
                        <View padding={[0, 0, 2, 0]}>
                          <Text size="087" color="400" weight="bold">
                            {t('PODashboard.ProductTableSection.CountByPutLocation')}
                          </Text>
                        </View>

                        <View>
                          {(productItem.inboundOrderLineLocations || []).map((layout, idx) => (
                            <View
                              direction="row"
                              width="100%"
                              padding={[2, 0]}
                              key={idx}
                              justify="space-between"
                              className={s['location-wrapper__item']}
                            >
                              <View.Item grow>
                                <View>
                                  <Text>
                                    {`${layout.layoutAttribNameType} - ${
                                      layout.layoutName ||
                                      generateLayoutNameFromDistinctName(layout.layoutDistinctName)
                                    }`}
                                  </Text>
                                </View>
                              </View.Item>
                              <View.Item>
                                <View>
                                  <Text>
                                    {t('PODashboard.PicesData', {
                                      count: layout.receivedQtyAdj ?? layout.totalReceivedQty ?? 0,
                                      total: layout.totalReceivedQty || 0,
                                    })}
                                  </Text>
                                </View>
                              </View.Item>
                            </View>
                          ))}
                        </View>
                      </View.Item>
                    </View>
                  </View>
                )}
            </View>
          ))}
        </>
      )}
    </View>
  );
};
