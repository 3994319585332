/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ROUTES } from '@shared/constants/routes';

type CycleCountTabsData = {
  label: string;
  value: string;
};

export const CycleCountTabsData: CycleCountTabsData[] = [
  {
    label: 'CycleCount.Tabs.RiskBased',
    value: ROUTES.CYCLE_COUNTS_RISK_BASED,
  },
  {
    label: 'CycleCount.Tabs.WallToWall',
    value: ROUTES.CYCLE_COUNTS_WALL_TO_WALL,
  },
  {
    label: 'CycleCount.Tabs.Inventory',
    value: ROUTES.CYCLE_COUNTS_INVENTORY,
  },
  {
    label: 'CycleCount.Tabs.Custom',
    value: ROUTES.CYCLE_COUNTS_CUSTOM,
  },
];
