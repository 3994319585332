/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const ORGANIZATION_ID = 'AZ';
export const FACILITY_ID = '000000';

export const PAGE = 0;
export const SIZE = 40;
export const MAX_SIZE = 2147483647;
export const defaultItems = 0;

export const MAX_VISIBLE_AVATARS = 2;
export const TRANSACTION_TIME_MIN = 10;
export const TRANSACTION_TIME_SEC = 600;
export const PROGRESS_LIMIT = 80;
export const DEFAULT_SHIFT = 'ALL_WEEK_MORNING';

export const OTHER = 'Other';
export const MAX_VISIBLE_AVATARS_TASK = 2;
export const PO_RECEIVING = 'PO_RECEIVING';
export const DOMAIN_TYPE_INBOUND = 'Inbound';
export const DEPARTMENT_ID_PO = 'PO';
export const NOT_ASSIGNED = 'NOT_ASSIGNED';
export const OTHER_DEPT = 'OTHER_DEPT';

export enum TASK_STATUS {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  INVALID = 'INVALID',
  CANCELLED = 'CANCELLED',
}

export enum USER_STATUS {
  NOT_ACTIVE = 'NOT_ACTIVE',
  ACTIVE = 'ACTIVE',
  ALMOST_DONE = 'ALMOST_DONE',
}
export enum USER_OVERVIEW_TASK_STATUS {
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
}

export enum WidgetTypes {
  SHIFT_PROGRESS = 'ShiftProgress',
  PO_NOT_RECEIVED = 'POsNotReceived',
  Unassign_Replenishments = 'UnassignedReplenishments',
}

export enum ShiftTypes {
  INVENTORY = 'INVENTORY',
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
}

export enum PO_TYPES {
  REGULAR_PO = `ShiftProgress.POType.RegularPo`,
  DSD = `ShiftProgress.POType.DSD`,
  LTD = `ShiftProgress.POType.LTD`,
}

export enum PIECES_TYPES {
  TOTES = `ShiftGoalHistory.InventoryPieceTypes.Totes`,
  PALLETS = `ShiftGoalHistory.InventoryPieceTypes.Pallets`,
  DSD_TOTES = `ShiftGoalHistory.InventoryPieceTypes.DSD_Totes`,
  DSD_PALLETS = `ShiftGoalHistory.InventoryPieceTypes.DSD_Pallets`,
  RETURNS = `ShiftGoalHistory.InventoryPieceTypes.Returns`,
}

export enum TASK_TYPE_TAB {
  RECOMMENDED = `Recommended`,
  POS = `POs`,
  REPLENISHMENTS = `Replenishments`,
  INBOUND = `Inbound`,
  OUTBOUND = `Outbound`,
  INVENTORY_CONTROL = `InventoryControl`,
  RETURNS_RECALLS = `ReturnsRecalls`,
  GENERAL_TASKS = `GeneralTasks`,
}

export enum DEPARTMENT_IDS {
  PO = `PO`,
  PO_RECEIVING = 'PO_RECEIVING',
}

export enum USER_DETAILS_TAB {
  OVERVIEW = `UserDetailsTab.Overview`,
  TRANSACTION_HISTORY = `UserDetailsTab.TransactionHistory`,
  USER_DETAILS = `UserDetailsTab.UserDetails`,
}

export const tabs = [
  {
    name: `UserDetailsTab.Overview`,
    value: `UserDetailsTab.Overview`,
    numberOfItems: 0,
    content: `UserDetailsTab.Overview`,
  },
  {
    name: `UserDetailsTab.TransactionHistory`,
    value: `UserDetailsTab.TransactionHistory`,
    numberOfItems: 0,
    content: `UserDetailsTab.TransactionHistory`,
  },
  {
    name: `UserDetailsTab.UserDetails`,
    value: `UserDetailsTab.UserDetails`,
    numberOfItems: 0,
    content: `UserDetailsTab.UserDetails`,
  },
];

export const InboundTaskTypeFilter = [
  {
    value: 'Recommended',
    label: `TaskType.Recommended`,
  },
  {
    value: 'POs',
    label: `TaskType.POs`,
  },
  {
    value: 'Replenishments',
    label: `TaskType.Replenishments`,
  },
  {
    value: 'Outbound',
    label: `TaskType.Outbound`,
  },
  {
    value: 'InventoryControl',
    label: `TaskType.InventoryControl`,
  },
  {
    value: 'GeneralTasks',
    label: `TaskType.GeneralTasks`,
  },
];

export const SHIFT_LIST = [
  {
    label: '1st-Shift',
    value: 'FIRST',
    fullForm: '1st-Shift',
  },
  {
    label: '2nd-Shift',
    value: 'SECOND',
    fullForm: '2nd-Shift',
  },
  {
    label: '3rd-Shift',
    value: 'THIRD',
    fullForm: '3rd-Shift',
  },
  {
    label: 'Weekend 1st-Shift',
    value: 'WEEKEND_FIRST',
    fullForm: 'Weekend shift',
  },
  {
    label: 'Weekend 2nd-Shift',
    value: 'WEEKEND_SECOND',
    fullForm: 'Weekend 2nd-shift',
  },
  {
    label: 'Weekend 3rd-Shift',
    value: 'WEEKEND_THIRD',
    fullForm: 'Weekend 3rd-Shift',
  },
];

export const INBOUND_TASK_SORT_BY_OPTIONS = [
  {
    label: 'InboundTaskManager.SortByUserNameAtoZ',
    value: 'A-Z',
  },
  {
    label: 'InboundTaskManager.SortByUserNameZtoA',
    value: 'Z-A',
  },
  {
    label: 'InboundTaskManager.SortByTask',
    value: 'Task Type',
  },
];

export const Default_PO_Types = [
  {
    label: PO_TYPES.REGULAR_PO,
    value: 0,
  },
  {
    label: PO_TYPES.DSD,
    value: 0,
  },
  {
    label: PO_TYPES.LTD,
    value: 0,
  },
];

export const Default_PIECES = [
  {
    label: PIECES_TYPES.TOTES,
    value: 0,
  },
  {
    label: PIECES_TYPES.PALLETS,
    value: 0,
  },
  {
    label: PIECES_TYPES.DSD_TOTES,
    value: 0,
  },
  {
    label: PIECES_TYPES.DSD_PALLETS,
    value: 0,
  },
  {
    label: PIECES_TYPES.RETURNS,
    value: 0,
  },
];

export const REFETCH_INTERVAL_1_MIN = 60000; // 1 minute in milliseconds

export enum USER_DEPT {
  INBOUND = `Inbound`,
  OUTBOUND = `Outbound`,
}

export enum TASK_TYPES {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  INVENTORY = 'INVENTORY',
  RECOMMENDED = 'RECOMMENDED',
  PO = 'PO',
  REPLENISHMENT = 'REPLENISHMENT',
}
