/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { View, Skeleton, Box } from '@az/starc-ui';

import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { PAGE_URLS } from '@shared/constants/routes';

import { INBOUND_ORDER_STATUS } from '@inbound/constants/constants';

import { URLQuery } from '@inventory/constants/constants';
import { getInboundOrderDataBySubzone } from '@inventory/utils/utils';

import { UnCheckedDiscrepancy } from './SubzoneUncheck';
import { DiscrepancyTabsProps } from './Discripancy.types';

import styles from './index.module.scss';

export const UncheckedDiscrepancy = ({
  inboundOrderData,
  isOrderLoading,
  isInboundOrderFeatching,
  setIsAllDiscrepsChecked,
  setIsRequiredCommentsAdded,
}: DiscrepancyTabsProps) => {
  /* State */
  const [markAsCheckedPO, setMarkedAsCheckPO] = useState<string | null>(null);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subzone } = useParams();

  /* Hooks */
  const inboundOrderDataBySubzone = useMemo(() => {
    return subzone
      ? getInboundOrderDataBySubzone(inboundOrderData ?? [], subzone)
      : inboundOrderData;
  }, [inboundOrderData, subzone]);

  useEffect(() => {
    if (markAsCheckedPO && markAsCheckedPO !== '' && !isOrderLoading) {
      let isAllDiscrepanciesForPOResolved = true;
      const markAsCheckedPOData = inboundOrderData?.find(
        (data) => data.inboundOrder.sourceOrderNbr === markAsCheckedPO
      );

      markAsCheckedPOData?.inboundOrder.inboundOrderLines?.forEach((line) => {
        if (line.statusCd === INBOUND_ORDER_STATUS.IC_REVIEW_IN_PROGRESS) {
          isAllDiscrepanciesForPOResolved = false;
        }
      });

      if (isAllDiscrepanciesForPOResolved) {
        markAsCheckedPO && navigate(PAGE_URLS.PO_DESCRIPANCY(markAsCheckedPO));
      } else if (inboundOrderDataBySubzone?.length === 0) {
        navigate({
          pathname: PAGE_URLS.FINALIZATION_DASHBOARD,
          search: `?${URLQuery.GROUP_BY}=${URLQuery.LOCATIONS}`,
        });
      }

      setMarkedAsCheckPO(null);
    }

    if (inboundOrderData?.length === 0 && !isOrderLoading) {
      setIsAllDiscrepsChecked?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundOrderData, isOrderLoading, setIsAllDiscrepsChecked]);

  return !inboundOrderDataBySubzone || inboundOrderDataBySubzone?.length === 0 ? (
    <View width="100%" padding={6} className={styles['subzone-discrepancy']}>
      {isOrderLoading ? (
        <View direction="row" gap={2}>
          <Skeleton width="100%" height="20vh" />
          <Skeleton width="100%" height="20vh" />
          <Skeleton width="100%" height="20vh" />
        </View>
      ) : (
        <View
          justify="center"
          align="center"
          className={styles['subzone-discrepancy__empty']}
          height="60vh"
        >
          <View width="360px">
            <EmptyState
              svg={Box}
              subtitle={t('FinalizationDashboard.Empty.UnChecked.Subtitle')}
              text={t('FinalizationDashboard.Empty.UnChecked.Text')}
            />
          </View>
        </View>
      )}
    </View>
  ) : (
    <UnCheckedDiscrepancy
      inboundOrderData={inboundOrderData}
      isOrderLoading={isOrderLoading || false}
      isInboundOrderFeatching={isInboundOrderFeatching || false}
      setIsAllDiscrepsChecked={setIsAllDiscrepsChecked}
      setMarkedAsCheckPO={setMarkedAsCheckPO}
      setIsRequiredCommentsAdded={setIsRequiredCommentsAdded}
      inboundOrderDataBySubzone={inboundOrderDataBySubzone}
    />
  );
};
