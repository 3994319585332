/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { Text, View } from '@az/starc-ui';
import { RoleType, UserSearchSchemaType } from '@shared/types/schema.type.ts';
import { useEffect, useState } from 'react';
import styles from './UserAssignedRole.module.scss';

type Props = {
  userData?: UserSearchSchemaType;
};

export const UserAssignedRole = ({ userData }: Props) => {
  /* State */
  const [roleData, setRoleData] = useState<RoleType[]>([]);

  /* Constants */
  const { t } = useTranslation();

  /* Hooks */
  useEffect(() => {
    if (userData) {
      const primaryFacility = userData.facilities.find((facility) => facility.primaryFl);
      if (primaryFacility && primaryFacility.roles) {
        setRoleData(primaryFacility.roles);
      }
    }
  }, [userData]);

  return (
    <View className={styles['role__container']}>
      <Text className={styles['role__title']} size="125" weight="bold">
        {t('Roles')}
      </Text>
      <View direction="row" gap={2}>
        {roleData.map((role) => {
          return (
            <View
              direction="row"
              justify="center"
              align="center"
              min-height="var(--st-unit-6)"
              width="fit-content"
              borderRadius="small"
              data-testid="user-role-badge"
              className={styles['role__badge']}
            >
              <Text size="100">{role.roleId}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};
