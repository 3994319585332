/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { MIN_FIELD_LENGTH } from '@shared/constants/constants';
import { MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND } from '@inventory/constants/constants';

export const ReasonSchema = z.object({
  reason: z
    .string()
    .min(1)
    .max(MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND)
    .refine((reason) => reason.trim().length >= MIN_FIELD_LENGTH),
});
