/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text } from '@az/starc-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CYCLE_COUNTS_TABLE_COLUMNS } from '@inventory/constants/tableColumns/cycleCounts';
import { TableStylingVariants } from '@shared/components/Table/tableConstants';
import { PAGE_SIZE } from '@shared/constants/constants';
import { Table } from '@shared/components/Table/Table';
import { TableSorting } from '@shared/components/Table/Table.types';

import styles from './CycleCount.module.scss';
import { mapCycleCountRows } from '@inventory/utils/table/cycleCountTableUtils';
import { CycleCountTableProps } from './CycleCountTable.types';
import { DEFAULT_PAGE } from '@inbound/constants/constants';

export const CycleCountTable: FC<CycleCountTableProps> = ({
  data,
  isLoading,
  isPaginated,
  label,
  noRecordsLabel,
  totalPage,
  currentPage,
  setOnPageChange,
  setSortBy,
  handleTableRowClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <View gap={2} className={styles['cycle-count__table-view']}>
        <Text variant="subtitle-bold" size="125">
          {t(label)}
        </Text>
        {data?.length === 0 && !isLoading ? (
          <Text>{t(noRecordsLabel)}</Text>
        ) : (
          <Table
            columns={CYCLE_COUNTS_TABLE_COLUMNS}
            rows={mapCycleCountRows(data ?? [])}
            isPaginated={isPaginated}
            isCheckboxDisabled={false}
            isApiLoadedData={true}
            pageSize={PAGE_SIZE}
            defaultPage={currentPage ? currentPage : DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isLoading}
            totalPages={totalPage ? totalPage : 0}
            styleVariant={TableStylingVariants.DETAILS}
            onSort={(sorting: TableSorting[], columnID: string) => setSortBy?.(sorting, columnID)}
            onPageChange={setOnPageChange}
            onRowAction={handleTableRowClick}
          />
        )}
      </View>
    </>
  );
};
