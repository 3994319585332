/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  View,
  Text,
  Divider,
  Badge,
  Button,
  ActionDelete,
  Icon,
  Add,
  Notification,
  Skeleton,
} from '@az/starc-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stat } from '@shared/components/Stat/Stat';
import {
  MAX_SIZE,
  PAGE,
  TRANSACTION_TIME_MIN,
  USER_OVERVIEW_TASK_STATUS,
  USER_STATUS,
} from '@taskManagement/constants/constants';
import {
  attributeKeys,
  ENTITY_ASSOCIATIONS,
  NOTIFICATION_TYPES,
  SORT_BY,
  SORT_ORDER,
  TASK_ASSIGNMENT_STATUS,
  TASK_STATUS,
} from '@shared/constants/constants';
import { getAttributeValueByKey, updateTaskAttributes } from '@taskManagement/utils/utils';
import { TaskSearchSchemaType } from '@shared/types/schema.type';
import * as T from '@taskManagement/components/AssignTaskDrawer/UserTabs/Overview/Overview.types';
import { ActiveUpcomingTaskType } from '@taskManagement/components/AssignTaskDrawer/UserTabs/Overview/Overview.types';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { ConfirmationModal } from '@outbound/components/Modal/ConfirmationModal';
import { useMutateUpdateTask } from '@taskManagement/services/hooks/useMutateUpdateTask';
import { useGetTaskSearchData } from '@shared/services/hooks/useGetTaskSearchData';
import { ActiveUpcomingMasterCard } from '@taskManagement/components/AssignTaskDrawer/UserTabs/Overview/ActiveUpcomingMasterCard';
import styles from './Overview.module.scss';
import { WMSInlineNotification } from '@shared/components/Notification/Notification';

export const Overview = ({
  userStatus,
  userId,
  firstName,
  lastName,
  usersData,
}: T.OverviewProps) => {
  /* State variables */
  const [removeTaskModal, setRemoveTaskModal] = useState(false);
  const [openActionMenu, setOpenActionMenu] = useState<boolean | null>(false);
  const [activeUpcomingData, setActiveUpcomingData] = useState<ActiveUpcomingTaskType[]>();
  const [taskNo, setTaskNo] = useState<string>();
  const [ShowNotification, setShowNotification] = useState<boolean>();
  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const activeTaskCount = activeUpcomingData?.filter(
    (user) => user.taskStatus === USER_OVERVIEW_TASK_STATUS.ACTIVE
  ).length;
  const upcomingCount = activeUpcomingData?.filter(
    (user) => user.taskStatus === USER_OVERVIEW_TASK_STATUS.UPCOMING
  ).length;

  /* Query */
  const { mutateUpdateTask } = useMutateUpdateTask();

  const { isFetching: isLoading, taskSearchData } = useGetTaskSearchData({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: SORT_BY.PRIORITY,
      direction: SORT_ORDER.DESC,
    },
    searchCriteria: {
      userId: userId,
      taskStatusCd: [
        TASK_STATUS.ASSIGNED,
        TASK_STATUS.INPROGRESS,
        TASK_STATUS.NEW,
        TASK_STATUS.INACTIVE,
      ],
      entityAssociations: [
        ENTITY_ASSOCIATIONS.ATTRIBUTES,
        ENTITY_ASSOCIATIONS.ASSIGNMENTS,
        ENTITY_ASSOCIATIONS.ACTIVITIES,
      ],
    },
  });

  /* Functions */

  const onClickRemoveTask = (taskNo: string) => {
    setTaskNo(taskNo);
    setRemoveTaskModal(true);
    setOpenActionMenu(null);
  };
  //Checking the last transaction time of active | upcoming | Completed Tasks
  const [latestTransactionTs, setLatestTransactionTs] = useState<number | null>(null);
  const findLowestTransactionTs = (
    activeUpcomingData: T.ActiveUpcomingTaskType[] | undefined,
    userId: string
  ): ActiveUpcomingTaskType | null => {
    let latestTransactionTask: ActiveUpcomingTaskType | null = null;
    activeUpcomingData &&
      activeUpcomingData.forEach((task) => {
        if (
          task.userId === userId &&
          task.lastTransactionTs !== null &&
          task.lastTransactionTs > 0
        ) {
          if (
            latestTransactionTask === null ||
            (task.lastTransactionTs &&
              task.lastTransactionTs < (latestTransactionTask?.lastTransactionTs ?? 0))
          ) {
            latestTransactionTask = task;
          }
        }
      });

    return latestTransactionTask;
  };

  useEffect(() => {
    // checking tasks with userID and finding latest transaction time
    const latestTransactionTask = findLowestTransactionTs(activeUpcomingData, userId);
    if (latestTransactionTask) {
      setLatestTransactionTs(latestTransactionTask.lastTransactionTs);
      setShowNotification(true);
    } else {
      setLatestTransactionTs(null);
      setShowNotification(true);
    }
  }, [activeUpcomingData, latestTransactionTs, userId]);

  useEffect(() => {
    const updatedTasksData: ActiveUpcomingTaskType[] = [];
    if (userId) {
      taskSearchData?.content?.forEach((taskSearchItem: TaskSearchSchemaType) => {
        const rcValue = getAttributeValueByKey(taskSearchItem.attributes, 'orderLocationId');
        taskSearchItem?.assignments
          ?.filter((item) => item.userId === userId)
          .forEach((taskAssignmentItem) => {
            const currentTime = new Date();
            let lastModifiedTs = new Date();
            if (
              taskAssignmentItem.status !== TASK_ASSIGNMENT_STATUS.COMPLETED &&
              taskAssignmentItem.status !== TASK_ASSIGNMENT_STATUS.REJECTED
            ) {
              // Sort activities based on endTs or startTs and get the latest one
              const latestActivity = taskAssignmentItem?.activities?.sort((a, b) => {
                const aTime = new Date(a.endTs ?? a.startTs ?? 0).getTime();
                const bTime = new Date(b.endTs ?? b.startTs ?? 0).getTime();
                return bTime - aTime; // Descending order
              })[0];

              // Determine the last modified timestamp
              if ((latestActivity && latestActivity.endTs) || latestActivity?.startTs) {
                lastModifiedTs = new Date(latestActivity.endTs ?? latestActivity.startTs ?? 0);
              }

              const lastModifiedTime = new Date(lastModifiedTs);
              const timeDifference = currentTime.getTime() - lastModifiedTime.getTime();
              const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
              const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
              const totalMinutes = hoursDifference * 60 + minutesDifference;
              const transactionHourMinutes = `${hoursDifference}:${minutesDifference}`;

              const transactionTimeObj = {
                userId: taskAssignmentItem.userId,
                lastModifiedTime: lastModifiedTime,
                lastTransactionTs: totalMinutes,
                transactionHourMinutes: transactionHourMinutes,
                taskStatus: '',
                rcValue: rcValue,
                ...taskSearchItem,
              };
              if (totalMinutes > 0) {
                transactionTimeObj.taskStatus = USER_OVERVIEW_TASK_STATUS.ACTIVE;
              } else {
                transactionTimeObj.taskStatus = USER_OVERVIEW_TASK_STATUS.UPCOMING;
              }
              updatedTasksData.push(transactionTimeObj as unknown as ActiveUpcomingTaskType);
            }
          });
      });
      setActiveUpcomingData(updatedTasksData);
    }
  }, [userId, taskSearchData]);

  /* Onclick remove task below function will execute and call task update API */
  const RemoveTask = (userId: string, taskNo: string) => {
    const taskToBeRemove: TaskSearchSchemaType | undefined = taskSearchData?.content
      .filter((taskItem) => taskItem.taskNo === taskNo)
      .find((item) => item.assignments?.some((assignmentItem) => assignmentItem.userId === userId));

    if (taskToBeRemove) {
      const userAssignment = taskToBeRemove.assignments?.find(
        (assignment) => assignment.userId === userId
      );
      const attributesPayload = updateTaskAttributes(taskToBeRemove.attributes, attributeKeys);

      setRemoveTaskModal(false);

      mutateUpdateTask(
        {
          updateTaskRequest: [
            {
              taskNo: taskNo,
              departmentId: taskToBeRemove.departmentId,
              domainType: taskToBeRemove.domainType || null,
              statusCd: taskToBeRemove.statusCd || null,
              taskTypeCd: taskToBeRemove.taskTypeCd,
              startTs: taskToBeRemove.startTs || null,
              endTs: taskToBeRemove.endTs || null,
              priority: taskToBeRemove.priority || null,
              nextTask: taskToBeRemove?.nextTask || null,
              attributes: attributesPayload,
              assignments: [
                {
                  userId: userId,
                  status: TASK_ASSIGNMENT_STATUS.COMPLETED,
                  startTs: userAssignment?.startTs || null,
                  endTs: userAssignment?.endTs || null,
                  progress: userAssignment?.completedPoints || null,
                  executionPriority: userAssignment?.executionPriority || null,
                  typeCd: userAssignment?.typeCd || 0,
                },
              ],
              completedPoints: taskToBeRemove.completedPoints || 0,
              totalPoints: taskToBeRemove.totalPoints || 0,
            },
          ],
        },
        {
          onSuccess: () => {
            handleNotification(NOTIFICATION_TYPES.SUCCESS, t(`RemoveTaskConfirmation.OnSuccess`));
          },
        }
      );
    }
  };

  return (
    <View className={styles['overview-content']}>
      {/* if user hasn't made a transaction in last 10 minutes or having null activity then (warning display only) */}
      {ShowNotification && latestTransactionTs && latestTransactionTs >= TRANSACTION_TIME_MIN && (
        <View gap={4}>
          <Notification
            {...WMSInlineNotification.warning}
            title={t(`UserOverviewNotification.title`)}
            text={t(`UserOverviewNotification.text`)}
            hasCloseButton={ShowNotification}
            onClose={() => setShowNotification(false)}
          />
        </View>
      )}
      {ShowNotification && latestTransactionTs === null && (
        <View gap={4}>
          <Notification
            {...WMSInlineNotification.warning}
            title={t(`UserOverviewNotification.title`)}
            text={t(`UserOverviewNotification.text`)}
            hasCloseButton={ShowNotification}
            onClose={() => setShowNotification(false)}
          />
        </View>
      )}
      <View direction="row" justify="space-between" className={styles['overview-content__status']}>
        <View>
          <Stat
            title={t('OverviewUserTab.CompletionRate')}
            primaryText={t('OverviewUserTab.CompletionRateValue', {
              value: 97.6,
            })}
            secondaryTextProps={{ secondaryText: 'test' }}
          />
        </View>
        <View>
          <Stat
            title={t('OverviewUserTab.TransactionTime')}
            primaryText={String(latestTransactionTs ?? '--')}
            secondaryTextProps={{ secondaryText: t(`UserDetailsTab.MinAgo`) }}
          />
        </View>
        <View>
          <Stat
            title={t('OverviewUserTab.LoadedLabor')}
            primaryText={t('OverviewUserTab.LoadedLaborValue', { value: 6.04 })}
            secondaryTextProps={{ secondaryText: '30 sec' }}
          />
        </View>
        <View>
          <Stat
            title={t('OverviewUserTab.CurrentVehicle')}
            primaryText="FMD2"
            secondaryTextProps={{ secondaryText: '' }}
          />
        </View>
      </View>
      {/* Display Remove user from shift link by checking Current user is comes from Not Active state  */}
      {userStatus === USER_STATUS.NOT_ACTIVE ? (
        <View
          gap={2}
          direction="row"
          width="100%"
          align="center"
          justify="start"
          className={styles['overview-content__remove-user-link']}
        >
          <Icon svg={ActionDelete} size={6} color="error" />
          <Text
            size="087"
            weight="bold"
            color="error"
            className={styles['overview-content__remove-active-text']}
          >
            {t('OverviewUserTab.RemoveUserFromShift')}
          </Text>
        </View>
      ) : (
        <View
          direction="row"
          gap={4}
          width="100%"
          align="center"
          justify="start"
          attributes={{
            style: { cursor: 'not-allowed', opacity: '0.5' },
          }}
        >
          <Icon svg={ActionDelete} />
          <Text
            size="100"
            weight="bold"
            color="primary"
            className={styles['overview-content__remove-inactive-text_']}
          >
            {t('OverviewUserTab.RemoveUserFromShift')}
          </Text>
        </View>
      )}
      <Divider className={styles['overview-content__bottom-divider']} />
      {/* Active Task section */}
      <View gap={4} direction="row" justify="space-between">
        <Text size="100" weight="bold" color="primary">
          {t('OverviewUserTab.ActiveTask')}
        </Text>

        {activeTaskCount && activeTaskCount > 0 ? (
          <Text size="100" weight="bold" color="primary" textCase="uppercase">
            <Badge
              variant="status"
              text={t('OverviewUserTab.AlmostDone')}
              variantOptions={{
                backgroundColor: 'blue-300',
                borderColor: 'blue-700',
                textColor: 'blue-700',
              }}
            />
          </Text>
        ) : (
          ''
        )}

        {activeUpcomingData &&
          activeUpcomingData
            ?.filter((data) => data.taskStatus == USER_OVERVIEW_TASK_STATUS.ACTIVE)
            .map((activeData) => (
              <>
                <ActiveUpcomingMasterCard
                  taskData={activeData}
                  usersData={usersData}
                  onClickRemoveTask={onClickRemoveTask}
                  openActionMenu={openActionMenu}
                  isUpcomingTask={false}
                />
              </>
            ))}
      </View>
      <Divider className={styles['overview-content__bottom-divider']} />
      {/* Upcoming Task Section */}
      <View gap={6} direction="row" justify="space-between" align="center">
        <Text size="100" weight="bold" color="primary">
          {t('OverviewUserTab.UpcomingTasks', {
            count: upcomingCount,
          })}
        </Text>
        <Button
          variant="pill"
          attributes={{ style: { width: 'fit-content' } }}
          startIcon={<Icon svg={Add} color="secondary" size={5} />}
        >
          {t('OverviewUserTab.AddTasks')}
        </Button>
      </View>

      <View width="100%" gap={4} className={styles['overview-content__upcoming-tasks-list']}>
        {isLoading ? (
          <Skeleton
            borderRadius="small"
            width="calc(var(--st-unit-32) * 4 + var(--st-unit-2))"
            height="var(--st-unit-32)"
          />
        ) : (
          activeUpcomingData &&
          activeUpcomingData
            ?.filter((data) => data.taskStatus === USER_OVERVIEW_TASK_STATUS.UPCOMING)
            .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
            .map((upcomingTaskData) => (
              <>
                <ActiveUpcomingMasterCard
                  taskData={upcomingTaskData}
                  usersData={usersData}
                  onClickRemoveTask={onClickRemoveTask}
                  openActionMenu={openActionMenu}
                  isUpcomingTask={true}
                />
              </>
            ))
        )}
      </View>
      {upcomingCount == 0 ? (
        <View gap={4} className={styles['overview-content__no-tasks']}>
          <Text>{t('OverviewUserTab.NoTaskMessage')} </Text>
        </View>
      ) : (
        ''
      )}
      {removeTaskModal && (
        <ConfirmationModal
          title={t('ConfirmationComment.RemoveTaskTitle', {
            username: `${firstName} ${lastName}`,
          })}
          isCancelBtn={true}
          open={removeTaskModal}
          onClose={() => setRemoveTaskModal(false)}
          primaryBtnText={t('ConfirmationComment.Remove')}
          onSuccess={() => taskNo && RemoveTask(userId, taskNo)}
        >
          <Text>{t('ConfirmationComment.RemoveTaskSubtitle')}</Text>
        </ConfirmationModal>
      )}
    </View>
  );
};
