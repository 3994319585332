/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const UserSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
});

export const InboundUserSchema = z.object({
  inboundOrderUserKey: z.string().optional(),
  assignedUserId: z.string(),
  assignedUserName: z.string(),
  assignedTs: z.string().optional().nullable(),
  userStatusCd: z.string(),
});
