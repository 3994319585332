/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAtom } from 'jotai';
import { t } from 'i18next';

import { View } from '@az/starc-ui';

import { CombinedTabs } from '@shared/components/CombinedTabs/CombinedTabs';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
  replenishedAtom,
  selectedStoresAtom,
  selectedStoresLaneAtom,
  selectedSubzonesAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import { displayOptionsAtom } from '@outbound/atoms/outboundMatrix/outboundMatrixAtoms';
import { MatrixSortOptions, OrderReleaseTabTitles } from '@outbound/constants/constants';

export const OrderTabs = () => {
  /*Atoms*/
  const [selectedStores, setSelectedStores] = useAtom(selectedStoresAtom);
  const [selectedSubzones, setSelectedSubzones] = useAtom(selectedSubzonesAtom);
  const [inLaneConfirmation, setInLaneConfirmation] = useAtom(inLaneConfirmationAtom);
  const [, setLaneConfirmationItem] = useAtom(laneConfirmationItemAtom);
  const [, setSelectedStoresLane] = useAtom(selectedStoresLaneAtom);
  const [, setReplenishedStores] = useAtom(replenishedAtom);
  const [, setDisplayOptions] = useAtom(displayOptionsAtom);

  /* Constants */
  const location = useLocation();
  const isTransferOrderTab = location.pathname.includes(ROUTES.Transfer);
  const isDifOrderTab = location.pathname.includes(ROUTES.DIF);
  const isOrderReleasePage = location.pathname.includes(ROUTES.ORDER_RELEASE);

  const tabs = [
    {
      name: t(OrderReleaseTabTitles.REPLENISHMENT),
      numberOfItems: 156,
      value: 'replenishment',
    },
    {
      name: t(OrderReleaseTabTitles.WILL_CALL),
      numberOfItems: 3,
      value: 'will-call',
    },
    {
      name: t(OrderReleaseTabTitles.NEW_STORE),
      numberOfItems: 1,
      value: 'new-store-and-backup',
    },
    {
      name: t(OrderReleaseTabTitles.LTD),
      numberOfItems: 24,
      value: 'long-tail-distribution',
    },
    {
      name: t(OrderReleaseTabTitles.TRANSFER),
      numberOfItems: 16,
      value: 'transfer',
    },
    {
      name: t(OrderReleaseTabTitles.CROSS_DOCK),
      numberOfItems: 12,
      value: 'cross-dock',
    },
    {
      name: t(OrderReleaseTabTitles.DIF),
      numberOfItems: 8,
      value: 'dif',
    },
  ];

  const getSelectionStatusLabel = () => {
    let label = 'OutboundMatrix.OrderRelease.StoreSelected';
    let count = 0;

    if (isTransferOrderTab || isDifOrderTab) {
      label = 'OutboundMatrix.OrderRelease.OrderSelected';
      count = selectedStores.length;
    } else if (selectedStores.length) {
      label = 'OutboundMatrix.OrderRelease.StoreSelected';
      count = selectedStores.length;
    } else if (selectedSubzones.length) {
      label = 'OutboundMatrix.OrderRelease.SubzoneSelected';
      count = selectedSubzones.length;
    }

    return t(label, {
      count: count,
    });
  };

  // useEffect added to clear order release states
  useEffect(() => {
    setInLaneConfirmation(false);
    setSelectedStores([]);
    setSelectedSubzones([]);
    setLaneConfirmationItem(null);
    setSelectedStoresLane([]);
    setReplenishedStores([]);
    setDisplayOptions((options) => ({
      ...options,
      sortBy: MatrixSortOptions.ROUTE,
    }));
  }, [
    location.pathname,
    setDisplayOptions,
    setInLaneConfirmation,
    setLaneConfirmationItem,
    setReplenishedStores,
    setSelectedStores,
    setSelectedStoresLane,
    setSelectedSubzones,
  ]);

  return (
    <View
      direction="column"
      height="100%"
      attributes={{ style: { width: '100%', minWidth: 'fit-content' } }}
    >
      {!inLaneConfirmation && (
        <CombinedTabs
          tabs={tabs}
          rootPath={isOrderReleasePage ? PAGE_URLS.ORDER_RELEASE : PAGE_URLS.OUTBOUND_CONTROL_DESK}
        />
      )}
      <Outlet context={[getSelectionStatusLabel]} />
    </View>
  );
};
