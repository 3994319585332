/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { Text, View } from '@az/starc-ui';

import { Tag } from '@shared/components/Tag/Tag';

import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';

import * as T from './PODetailsSection.types';
import styles from '../PODetail.module.scss';

export const RenderMaterTitleSubTitle = ({
  inboundOrderData,
  isHighPriority,
  sourceOrderId,
}: T.PODetailsMaterialTileProps) => {
  const { t } = useTranslation();

  return (
    <View align="start">
      <View.Item>
        <View
          direction="row"
          align="center"
          justify="center"
          className={styles['po-detail__master-title__sub-title']}
        >
          <View.Item>
            <View
              direction="row"
              align="center"
              justify="center"
              className={styles['po-detail__master-title__sub-title__right-items']}
            >
              {isHighPriority && (
                <View.Item>
                  <PriorityTag />
                </View.Item>
              )}

              {inboundOrderData.trailerOrder.inboundOrder.orderTypeCd && (
                <View.Item>
                  <View justify="center">
                    <Tag
                      variant="order"
                      text={inboundOrderData.trailerOrder.inboundOrder.orderTypeCd}
                      className={styles['po-detail__master-title__sub-title__tag']}
                    />
                  </View>
                </View.Item>
              )}
            </View>
          </View.Item>

          <View.Item>
            <Text color="600">
              {t('PODashboard.PoWithNumber', {
                poNumber: sourceOrderId,
              })}
            </Text>
          </View.Item>

          {inboundOrderData.trailerOrder.inboundOrder.orderLocationId && (
            <View.Item>
              <Text color="600">
                {t('PODashboard.DoorWithName', {
                  door: inboundOrderData.trailerOrder.inboundOrder.orderLocationId,
                })}
              </Text>
            </View.Item>
          )}
        </View>
      </View.Item>
    </View>
  );
};
