/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const SEARCH_PROPERTIES = {
  USER: {
    queryParam: 'user',
    search: 'search',
  },
};

export { SEARCH_PROPERTIES };

// TASK Mock Data as per API response - remove after getting actual API response data
export const TASK_SEARCH_MOCK_Data = [
  {
    taskNo: '19881TASK000000006068',
    departmentId: 'INBOUND',
    domainType: 'RECEIVING',
    statusCd: 'INPROGRESS',
    taskTypeCd: 'PO_RECEIVING',
    startTs: null,
    endTs: null,
    priority: 1,
    totalPoints: 400,
    completedPoints: 350,
    nextTask: null,
    createUserId: 'SYSTEM',
    createTs: '2024-08-02T13:31:08.663684Z',
    lastModifiedUserId: 'SYSTEM',
    lastModifiedTs: '2024-08-02T13:31:08.663687Z',
    attributes: [
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'orderTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'ee14bc0a6c57cc0bfa6a60d182ceb0d4',
        key: 'priority',
        value: '1',
      },
      {
        attributeKey: 'f6c3d256c7aa5af0493b1df9d999f5d5',
        key: 'commodityTypeCd',
        value: 'NEW',
      },
      {
        attributeKey: 'f7678a3c7a2cb57bfd2382e020f63396',
        key: 'vendorName',
        value: 'TASK-1 Shift Solutions Auto LLC',
      },
      {
        attributeKey: 'd8a61e6ad2935f35a3e3982cfeb42d12',
        key: 'orderLocationId',
        value: 'RC02',
      },
      {
        attributeKey: 'a775c464aca2c9a0079fa17fef0ad3b7',
        key: 'trailerArrivalTs',
        value: '2024-06-12T06:55:46.134Z',
      },
      {
        attributeKey: '9bfcef7709eb19d3ce3f731e028a9c5c',
        key: 'receivedLineCount',
        value: '9',
      },
      {
        attributeKey: 'aff810ec0bca066ebb0970875530df9b',
        key: 'totalReceivedQty',
        value: '150',
      },
      {
        attributeKey: '47672033d22fd67bfa0fc31ada72cdda',
        key: 'sourceOrderNbr',
        value: '00000003',
      },
      {
        attributeKey: '9f84164831cbeb1cf838c5db01379de6',
        key: 'domainTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'bb3db1d7f14a852a02e4e7c1c04219de',
        key: 'trailerLocationTypeCd',
        value: 'Aisle',
      },
      {
        attributeKey: 'ff94d1e3c82c0a1246feed02c6b55792',
        key: 'trailerLocationId',
        value: '825',
      },
      {
        attributeKey: '96163547d39049cc4be2914c07686583',
        key: 'totalOrderedQty',
        value: '400',
      },
      {
        attributeKey: '3e21e475addf56a117c2ef52d7c2ca3f',
        key: 'totalLineCount',
        value: '2',
      },
    ],
    assignments: [
      {
        userId: '112165611', //shoaib // first shift
        status: 'INPROGRESS',
        startTs: '2024-05-09T11:09:01.972949Z',
        endTs: null,
        completedPoints: 150,
        progress: 75,
        executionPriority: null,
        lastModifiedTs: '2024-06-13T11:47:09.226362Z',
        typeCd: 0,
        createUserId: null,
        createTs: null,
        lastModifiedUserId: null,
        activities: [
          {
            userId: '112165611',
            status: 'COMPLETED',
            startTs: '2024-07-01T14:09:11.972849Z',
            endTs: '2024-07-04T09:00:11.972849Z',
            completedPoints: 150,
            activityAttributes: [
              {
                attributeKey: 'f8e593477551d488b4645d675cf3ff31',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
      {
        userId: '112165622',
        status: 'INPROGRESS',
        startTs: '2024-05-09T11:09:01.972949Z',
        endTs: null,
        completedPoints: 150,
        progress: 75,
        executionPriority: null,
        lastModifiedTs: '2024-06-13T11:47:09.226362Z',
        typeCd: 0,
        createUserId: null,
        createTs: null,
        lastModifiedUserId: null,
        activities: [
          {
            userId: '112165622',
            status: 'COMPLETED',
            startTs: '2024-07-01T14:09:11.972849Z',
            endTs: '2024-07-03T23:23:11.972849Z', // 11:23 UTC
            completedPoints: 150,
            activityAttributes: [
              {
                attributeKey: 'f8e593477551d488b4645d675cf3ff31',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
    ],
    comments: [],
  },
  {
    taskNo: '19901TASK000000018617',
    departmentId: 'INBOUND',
    domainType: 'RECEIVING',
    statusCd: 'INPROGRESS',
    taskTypeCd: 'PO_RECEIVING',
    startTs: null,
    endTs: null,
    priority: null,
    totalPoints: 200,
    completedPoints: 80,
    nextTask: null,
    createUserId: 'SYSTEM',
    createTs: '2024-08-02T13:31:08.663684Z',
    lastModifiedUserId: 'SYSTEM',
    lastModifiedTs: '2024-08-02T13:31:08.663687Z',
    attributes: [
      {
        attributeKey: '4f11fcc40f225f15880a2a49066bbdbd',
        key: 'trailerArrivalTs',
        value: '2024-05-27T16:50:00.691Z',
      },
      {
        attributeKey: '8371d380c43750ee8edbbdb5fec5cc09',
        key: 'receivedLineCount',
        value: '2',
      },
      {
        attributeKey: '36d504a8629bb844073c2c870faab3ad',
        key: 'trailerLocationTypeCd',
        value: 'LOCATION',
      },
      {
        attributeKey: '6caf5768c5bf0de9a436060cec7bca08',
        key: 'totalReceivedQty',
        value: '180',
      },
      {
        attributeKey: '66b88d0b54a9dce33b826e6bd0d5ae84',
        key: 'totalLineCount',
        value: '2',
      },
      {
        attributeKey: '57ac59b7a0dc8963b6fffd66cf2e944f',
        key: 'trailerLocationId',
        value: '3lgxt',
      },
      {
        attributeKey: '740d0e3cfc31234d9e3b9d736ffd5f53',
        key: 'sourceOrderNbr',
        value: '91703733',
      },
      {
        attributeKey: '55b36b7c3d005c5cfe4af677648a7aa8',
        key: 'commodityTypeCd',
        value: 'OIL',
      },
      {
        attributeKey: 'f9cd7f50908e6d109a3c3824ef5d94e7',
        key: 'priority',
        value: '0',
      },
      {
        attributeKey: '2a1dedcc855450cbd951ebfeaaebabd9',
        key: 'orderLocationId',
        value: 'RC01',
      },
      {
        attributeKey: '40e5db7412c9f4db5f31f9042391b7f7',
        key: 'totalOrderedQty',
        value: '200',
      },
      {
        attributeKey: '6adfda83a8ea51d68d8c474edd6d654d',
        key: 'orderTypeCd',
        value: 'LTD',
      },
      {
        attributeKey: '6153fde543f6e8b8742e3953b3183fe6',
        key: 'vendorName',
        value: 'TASK-2 Shift Solutions Auto LLC',
      },
    ],
    assignments: [
      {
        userId: '112165622', //Joe // 2nd shift
        status: 'INPROGRESS',
        startTs: null,
        endTs: null,
        completedPoints: 30,
        progress: null,
        executionPriority: null,
        lastModifiedTs: '2024-06-28T18:24:40.967618Z',
        typeCd: 0,
        createUserId: null,
        createTs: null,
        lastModifiedUserId: null,
        activities: [
          {
            userId: '112165622',
            status: 'COMPLETED',
            startTs: '2024-07-03T18:30:11.972949Z',
            endTs: '2024-07-03T15:32:31.972949Z', // keep always UTC actual time it will show IST with increase 5:30 min
            completedPoints: 1,
            activityAttributes: [
              {
                attributeKey: '2b80ce9e499e628edf4f94699c68354f',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
    ],
    comments: null,
  },
  {
    taskNo: '19865TASK000000003510',
    departmentId: 'INBOUND',
    domainType: 'RECEIVING',
    statusCd: 'INPROGRESS',
    taskTypeCd: 'PO_RECEIVING',
    startTs: null,
    endTs: null,
    priority: 1,
    totalPoints: 600,
    completedPoints: 550,
    nextTask: null,
    createUserId: 'SYSTEM',
    createTs: '2024-08-02T13:31:08.663684Z',
    lastModifiedUserId: 'SYSTEM',
    lastModifiedTs: '2024-08-02T13:31:08.663687Z',
    attributes: [
      {
        attributeKey: 'b92759f7ae48c121c91f6eb705170d00',
        key: 'orderLocationId',
        value: 'RC02',
      },
      {
        attributeKey: '6ed0934fdafccfbf1b76a3e81118c8b6',
        key: 'receivedLineCount',
        value: '0',
      },
      {
        attributeKey: 'db3dad82db7d79925f6d5d04995c0a24',
        key: 'totalReceivedQty',
        value: '450',
      },
      {
        attributeKey: '53de451b5e6564b883b72bbb666dff9f',
        key: 'totalOrderedQty',
        value: '600',
      },
      {
        attributeKey: '07167bebac5aec639fe2c1a6fd1cef46',
        key: 'trailerArrivalTs',
        value: '2024-05-09T03:18:08.418Z',
      },
      {
        attributeKey: 'c9c9102f30af4377c31805a51ea8ea08',
        key: 'sourceOrderNbr',
        value: '00000001',
      },
      {
        attributeKey: 'ebf0034010989b6d2efda8ced7974840',
        key: 'priority',
        value: '1',
      },
      {
        attributeKey: 'b4651ef78a964d74bdaf043d0e2ba73d',
        key: 'orderTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'a2c8c88544330a7bcb7a85097f94ec5b',
        key: 'vendorName',
        value: 'TASK-3 Solutions Auto LLC',
      },
      {
        attributeKey: 'ce3f1b9d78bc2fcecfe40205879a49c9',
        key: 'commodityTypeCd',
        value: 'DSD',
      },
      {
        attributeKey: '7e265deaeb3f1213bec6217f670d5c8a',
        key: 'totalLineCount',
        value: '2',
      },
    ],
    assignments: [
      {
        userId: '112165622',
        status: 'INPROGRESS',
        startTs: null,
        endTs: null,
        completedPoints: 10,
        progress: null,
        executionPriority: null,
        lastModifiedTs: '2024-06-14T20:09:11.972849Z',
        typeCd: 0,
        activities: [
          {
            userId: '112165622',
            status: 'COMPLETED',
            startTs: '2024-06-28T10:02:11.972949Z',
            endTs: '2024-07-04T10:57:11.972949Z',
            completedPoints: 1,
            activityAttributes: [
              {
                attributeKey: 'dcf250e53b90e634335f79702603f46d',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
      {
        userId: '112165611', //shoaib // first shift
        status: 'INPROGRESS',
        startTs: '2024-05-09T11:09:01.972949Z',
        endTs: null,
        completedPoints: 50,
        progress: 75,
        executionPriority: null,
        lastModifiedTs: '2024-06-13T11:47:09.226362Z',
        typeCd: 0,
        createUserId: null,
        createTs: null,
        lastModifiedUserId: null,
        activities: [
          {
            userId: '112165611',
            status: 'COMPLETED',
            startTs: '2024-07-01T14:09:11.972849Z',
            endTs: '2024-07-04T09:52:11.972849Z',
            completedPoints: 50,
            activityAttributes: [
              {
                attributeKey: 'f8e593477551d488b4645d675cf3ff31',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
    ],
    comments: [],
  },
  {
    taskNo: '19881TASK000000006070',
    departmentId: 'INBOUND',
    domainType: 'RECEIVING',
    statusCd: 'INPROGRESS',
    taskTypeCd: 'REPLENISHMENT',
    startTs: null,
    endTs: null,
    priority: 1,
    totalPoints: 250,
    completedPoints: 220,
    nextTask: null,
    createUserId: 'SYSTEM',
    createTs: '2024-04-02T13:31:08.663684Z',
    lastModifiedUserId: 'SYSTEM',
    lastModifiedTs: '2024-08-02T13:31:08.663687Z',
    attributes: [
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'SRC',
        value: 'PAG2405D',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'FPS',
        value: 'FMQ300A',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'pieces',
        value: '14',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'slotQty',
        value: '5',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'outs', // it will display if slotQty = 0
        value: '5.96', // pieces * price = outs value
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'vehicle',
        value: 'FANY',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'SubZone',
        value: 'LMR1',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'orderTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'ee14bc0a6c57cc0bfa6a60d182ceb0d4',
        key: 'priority',
        value: '7005', // 7005 TASK-1 New
      },
      {
        attributeKey: 'f6c3d256c7aa5af0493b1df9d999f5d5',
        key: 'commodityTypeCd',
        value: 'NEW',
      },
      {
        attributeKey: 'f7678a3c7a2cb57bfd2382e020f63396',
        key: 'vendorName',
        value: 'TASK-1 Replenishments Auto LLC',
      },
      {
        attributeKey: 'd8a61e6ad2935f35a3e3982cfeb42d12',
        key: 'orderLocationId',
        value: 'RC02',
      },
      {
        attributeKey: 'a775c464aca2c9a0079fa17fef0ad3b7',
        key: 'trailerArrivalTs',
        value: '2024-06-12T06:55:46.134Z',
      },
      {
        attributeKey: '9bfcef7709eb19d3ce3f731e028a9c5c',
        key: 'receivedLineCount',
        value: '9',
      },
      {
        attributeKey: 'aff810ec0bca066ebb0970875530df9b',
        key: 'totalReceivedQty',
        value: '50',
      },
      {
        attributeKey: '47672033d22fd67bfa0fc31ada72cdda',
        key: 'sourceOrderNbr',
        value: '00000033',
      },
      {
        attributeKey: '9f84164831cbeb1cf838c5db01379de6',
        key: 'domainTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'bb3db1d7f14a852a02e4e7c1c04219de',
        key: 'trailerLocationTypeCd',
        value: 'Aisle',
      },
      {
        attributeKey: 'ff94d1e3c82c0a1246feed02c6b55792',
        key: 'trailerLocationId',
        value: '825',
      },
      {
        attributeKey: '96163547d39049cc4be2914c07686583',
        key: 'totalOrderedQty',
        value: '250',
      },
      {
        attributeKey: '3e21e475addf56a117c2ef52d7c2ca3f',
        key: 'totalLineCount',
        value: '2',
      },
    ],
    assignments: [
      {
        userId: '112165611', //shoaib // first shift
        status: 'INPROGRESS',
        startTs: '2024-05-09T11:09:01.972949Z',
        endTs: null,
        completedPoints: 50,
        progress: 75,
        executionPriority: null,
        lastModifiedTs: '2024-06-13T11:47:09.226362Z',
        typeCd: 0,
        createUserId: null,
        createTs: null,
        lastModifiedUserId: null,
        activities: [
          {
            userId: '112165611',
            status: 'COMPLETED',
            startTs: '2024-07-01T14:09:11.972849Z',
            endTs: '2024-07-04T09:03:11.972849Z',
            completedPoints: 50,
            activityAttributes: [
              {
                attributeKey: 'f8e593477551d488b4645d675cf3ff31',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    taskNo: '19881TASK000000006071',
    departmentId: 'INBOUND',
    domainType: 'RECEIVING',
    statusCd: 'INPROGRESS',
    taskTypeCd: 'REPLENISHMENT',
    startTs: null,
    endTs: null,
    priority: 1,
    totalPoints: 251,
    completedPoints: 221,
    nextTask: null,
    createUserId: 'SYSTEM',
    createTs: '2024-07-01T13:31:08.663684Z',
    lastModifiedUserId: 'SYSTEM',
    lastModifiedTs: '2024-08-02T13:31:08.663687Z',
    attributes: [
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'SRC',
        value: 'PAG2405E',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'FPS',
        value: 'FMQ300B',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'pieces',
        value: '15',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'slotQty',
        value: '0',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'outs', // it will display if slotQty = 0
        value: '5.96', // pieces * price = outs value
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'vehicle',
        value: 'FANY',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'SubZone',
        value: 'LMR2',
      },
      {
        attributeKey: 'f7bbeed5430f7a57b39b18b12aab560d',
        key: 'orderTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'ee14bc0a6c57cc0bfa6a60d182ceb0d4',
        key: 'priority',
        value: '7000', // last task-2 7000
      },
      {
        attributeKey: 'f6c3d256c7aa5af0493b1df9d999f5d5',
        key: 'commodityTypeCd',
        value: 'NEW',
      },
      {
        attributeKey: 'f7678a3c7a2cb57bfd2382e020f63396',
        key: 'vendorName',
        value: 'Delphi Fuel Pump LLC',
      },
      {
        attributeKey: 'd8a61e6ad2935f35a3e3982cfeb42d12',
        key: 'orderLocationId',
        value: 'RC02',
      },
      {
        attributeKey: 'a775c464aca2c9a0079fa17fef0ad3b7',
        key: 'trailerArrivalTs',
        value: '2024-07-12T06:55:46.134Z',
      },
      {
        attributeKey: '9bfcef7709eb19d3ce3f731e028a9c5c',
        key: 'receivedLineCount',
        value: '9',
      },
      {
        attributeKey: 'aff810ec0bca066ebb0970875530df9b',
        key: 'totalReceivedQty',
        value: '51',
      },
      {
        attributeKey: '47672033d22fd67bfa0fc31ada72cdda',
        key: 'sourceOrderNbr',
        value: '00000034',
      },
      {
        attributeKey: '9f84164831cbeb1cf838c5db01379de6',
        key: 'domainTypeCd',
        value: 'PO',
      },
      {
        attributeKey: 'bb3db1d7f14a852a02e4e7c1c04219de',
        key: 'trailerLocationTypeCd',
        value: 'Aisle',
      },
      {
        attributeKey: 'ff94d1e3c82c0a1246feed02c6b55792',
        key: 'trailerLocationId',
        value: '825',
      },
      {
        attributeKey: '96163547d39049cc4be2914c07686583',
        key: 'totalOrderedQty',
        value: '251',
      },
      {
        attributeKey: '3e21e475addf56a117c2ef52d7c2ca3f',
        key: 'totalLineCount',
        value: '2',
      },
    ],
    assignments: [
      {
        userId: '112165611', //shoaib // first shift
        status: 'INPROGRESS',
        startTs: '2024-05-09T11:09:01.972949Z',
        endTs: null,
        completedPoints: 50,
        progress: 75,
        executionPriority: null,
        lastModifiedTs: '2024-06-13T11:47:09.226362Z',
        typeCd: 0,
        createUserId: null,
        createTs: null,
        lastModifiedUserId: null,
        activities: [
          {
            userId: '112165611',
            status: 'COMPLETED',
            startTs: '2024-07-01T14:09:11.972849Z',
            endTs: '2024-07-04T09:03:11.972849Z',
            completedPoints: 50,
            activityAttributes: [
              {
                attributeKey: 'f8e593477551d488b4645d675cf3ff31',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
    ],
  },
];

//USER Mock Data as per API response - remove after getting actual API response data
export const USER_SEARCH_MOCK_Data = [
  {
    userKey: '961744a753d1f95c7ee6d236dad0f9c5',
    userId: '112165611',
    employeeId: '1014',
    firstName: 'Shoaib',
    middleName: 'Khan',
    secondLastName: 'Khan',
    lastName: 'Khan',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Wanwori IND',
    postalCd: '44000',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'Tech Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND',
            // tempDeptId: null, // added by only UI side
            statusCd: 'ACTIVE',
            primaryFl: true,
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'FIRST',
            shiftDesc: 'First',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
  {
    userKey: '961744a753d1f95c7ee6d236dad0f8c6',
    userId: '112165622',
    employeeId: '1017',
    firstName: 'Joe',
    middleName: 'Bell',
    secondLastName: 'Thomsan',
    lastName: 'Bell',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Wanwori IND',
    postalCd: '44000',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'Tech Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND',
            // tempDeptId: null, // added by only UI side
            statusCd: 'ACTIVE',
            primaryFl: true,
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'SECOND',
            shiftDesc: 'SECOND',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
  // THIRD Shift User - Not assigned any task
  {
    userKey: '961744a753d1f95c7ee6d236dad0f8c6',
    userId: '112165633',
    employeeId: '1018',
    firstName: 'Ram',
    middleName: 'Narsing',
    secondLastName: 'Ghodke',
    lastName: 'Ghodke',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Pune IND',
    postalCd: '411033',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'UI Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND',
            statusCd: 'ACTIVE',
            primaryFl: true,
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'THIRD',
            shiftDesc: 'THIRD',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
  // THIRD Shift User - Other Dept user
  {
    userKey: '961744a753d1f95c7ee6d236dad0f8c6',
    userId: '112165644',
    employeeId: '1019',
    firstName: 'Jonthan',
    middleName: 'S',
    secondLastName: 'Martin',
    lastName: 'Martin',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Pune IND',
    postalCd: '411033',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'UI Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND', // current dept name
            statusCd: 'INACTIVE', // this dept status should be INACTIVE if its working in ANOTHER department
            primaryFl: true, // this key should be true for the current department
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'OUTBOUND', // other deptId name should be specific other dept name
            statusCd: 'ACTIVE', // it should have active here
            primaryFl: false, // this key should be false
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'THIRD',
            shiftDesc: 'THIRD',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
];

export const SHIFT_GOAL_MOCK_DATA = [
  {
    shiftName: 'Today - 1st shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    regularPO: 3,
    regularPO_Percentage: '3%',
    DSD: 3,
    dsdPercentage: '3%',
    LTD: 4,
    ltdPercentage: '4%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: '9/23/23 - 3rd shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    regularPO: 3,
    regularPO_Percentage: '3%',
    DSD: 3,
    dsdPercentage: '3%',
    LTD: 4,
    ltdPercentage: '4%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: '9/23/23 - 2nd shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    regularPO: 3,
    regularPO_Percentage: '3%',
    DSD: 3,
    dsdPercentage: '3%',
    LTD: 4,
    ltdPercentage: '4%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: '9/23/23 - 3rd shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    regularPO: 3,
    regularPO_Percentage: '3%',
    DSD: 3,
    dsdPercentage: '3%',
    LTD: 4,
    ltdPercentage: '4%',
    assignees: [],
  },
];

export const INVENTORY_SHIFT_GOAL_MOCK_DATA = [
  {
    shiftName: 'Today - 1st shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    totesPicked: 3,
    totalTotes: 3,
    totesPercentage: '100%',
    palletPicked: 3,
    totalPallet: 3,
    palletPercentage: '100%',
    dsdTotesPicked: 3,
    totalDsdTotes: 3,
    dsdTotesPercentage: '100%',
    dsdPalletPicked: 3,
    totalDsdPallet: 3,
    dsdPalletPercentage: '100%',
    returnsPicked: 3,
    totalReturns: 3,
    retrunsPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: 'Today - 1st shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    totesPicked: 3,
    totalTotes: 3,
    totesPercentage: '100%',
    palletPicked: 3,
    totalPallet: 3,
    palletPercentage: '100%',
    dsdTotesPicked: 3,
    totalDsdTotes: 3,
    dsdTotesPercentage: '100%',
    dsdPalletPicked: 3,
    totalDsdPallet: 3,
    dsdPalletPercentage: '100%',
    returnsPicked: 3,
    totalReturns: 3,
    retrunsPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: 'Today - 1st shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    totesPicked: 3,
    totalTotes: 3,
    totesPercentage: '100%',
    palletPicked: 3,
    totalPallet: 3,
    palletPercentage: '100%',
    dsdTotesPicked: 3,
    totalDsdTotes: 3,
    dsdTotesPercentage: '100%',
    dsdPalletPicked: 3,
    totalDsdPallet: 3,
    dsdPalletPercentage: '100%',
    returnsPicked: 3,
    totalReturns: 3,
    retrunsPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: 'Today - 1st shift',
    goalsCompleted: 10,
    totalGoals: 100,
    shiftPercentage: '10%',
    totesPicked: 3,
    totalTotes: 3,
    totesPercentage: '100%',
    palletPicked: 3,
    totalPallet: 3,
    palletPercentage: '100%',
    dsdTotesPicked: 3,
    totalDsdTotes: 3,
    dsdTotesPercentage: '100%',
    dsdPalletPicked: 3,
    totalDsdPallet: 3,
    dsdPalletPercentage: '100%',
    returnsPicked: 3,
    totalReturns: 3,
    retrunsPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
];

export const OUTBOUND_SHIFT_GOAL_MOCK_DATA = [
  {
    shiftName: 'Today - 1st shift',
    storesCompleted: 10,
    totalStores: 100,
    storesPercentage: '100%',
    piecesCompleted: 10,
    totalPieces: 100,
    piecesPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: 'Today - 1st shift',
    storesCompleted: 10,
    totalStores: 100,
    storesPercentage: '100%',
    piecesCompleted: 10,
    totalPieces: 100,
    piecesPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: 'Today - 1st shift',
    storesCompleted: 10,
    totalStores: 100,
    storesPercentage: '100%',
    piecesCompleted: 10,
    totalPieces: 100,
    piecesPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
  {
    shiftName: 'Today - 1st shift',
    storesCompleted: 10,
    totalStores: 100,
    storesPercentage: '100%',
    piecesCompleted: 10,
    totalPieces: 100,
    piecesPercentage: '100%',
    assignees: [
      { firstName: 'Test', lastName: 'Test' },
      { firstName: 'Kiran', lastName: 'Kumar' },
      { firstName: 'Nisha', lastName: 'Sharma' },
      { firstName: 'Test', lastName: 'Test' },
    ],
  },
];

export const INBOUND_TASK_TYPES = [
  {
    label: 'Inbound Clerk',
    value: 'Inbound clerk',
  },
  {
    label: 'Unloader',
    value: 'Unloader',
  },
  {
    label: 'Dock Runner',
    value: 'Dock Runner',
  },
  {
    label: 'OIL Room',
    value: 'OIL Room - Replenishments/Receivals',
  },
  {
    label: 'Aerosol Room',
    value: 'Aerosol Room - Replenishments/Receivals',
  },
  {
    label: 'Battery Aisle',
    value: 'Battery Aisle - Replenishments/Receivals',
  },
  {
    label: 'DSD',
    value: 'DSD Receivals',
  },
  {
    label: 'LTD',
    value: 'LTD Receivals',
  },
];
