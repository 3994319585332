/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const setGoalsPayloadOptions = z.object({
  shiftId: z.string(),
  shiftIndex: z.number(),
  date: z.string(),
  activity: z.string(),
  metrics: z.string(),
  goalDefined: z.number(),
});

const ShiftGoalsDetails = setGoalsPayloadOptions.extend({
  dailyShiftGoalKey: z.string(),
  goalAchieved: z.number(),
});

export const mappedShiftGoalstoTable = setGoalsPayloadOptions.extend({
  totalGoals: z.number(),
  goalsCompleted: z.number(),
  shiftPercentage: z.number(),
  'PO-goalDefined': z.number(),
  'PO-goalAchieved': z.number(),
  'PO-percentage': z.number(),
  'DSD-goalDefined': z.number(),
  'DSD-goalAchieved': z.number(),
  'DSD-percentage': z.number(),
  'LTD-goalDefined': z.number(),
  'LTD-goalAchieved': z.number(),
  'LTD-percentage': z.number(),
});

export const InboundShiftGoalPayloadSchema = z.array(setGoalsPayloadOptions);

export const InboundShiftGoalDetailsSchema = ShiftGoalsDetails;
