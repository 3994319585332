/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const InboundTaskSchema = z.object({
  inboundOrderTaskKey: z.string(),
  taskNbr: z.string(),
  statusCd: z.string(),
});

export const TrailerInboundTaskSchema = z.object({
  inboundOrderTaskKey: z.string(),
  statusCd: z.string(),
});
