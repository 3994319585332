/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Modal, View, Text, Icon, Divider, TextField, classNames } from '@az/starc-ui';
import { ShiftGoalInventoryModalProps } from './ShiftGoalInventoryModal.types';
import { Checkmark, Close } from '@az/starc-ui-icons';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import {
  INVENTORY_PAST_SHIFTS_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants';
import { mapInventoryShiftHistoryTableRows } from '@taskManagement/utils/table/tableUtils';
import { INVENTORY_SHIFT_GOAL_MOCK_DATA } from '@taskManagement/constants/dataConstants';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants';
import { Table } from '@shared/components/Table/Table';
import styles from './ShiftGoalInventory.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const ShiftGoalInventoryModal = ({
  isOpen,
  onClose,
  onSave,
  shiftName,
  ValidatorCount,
  timeLeftInHour,
}: ShiftGoalInventoryModalProps) => {
  /* Constants */
  const { t } = useTranslation();

  /* State variables */
  const [currentPieces, setcurrentPieces] = useState({
    totes: '',
    pallets: '',
    dsdTotes: '',
    dsdPallets: '',
    returns: '',
  });

  /* Functions */
  const handleCancel = () => {
    setcurrentPieces({
      totes: '',
      pallets: '',
      dsdTotes: '',
      dsdPallets: '',
      returns: '',
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="large"
      closeByClickAway={false}
      className={styles['inventory-goals__modal']}
    >
      <View
        direction="row"
        justify="space-between"
        padding={3}
        className={styles['inventory-goals__header']}
      >
        <View direction="column">
          <Text as="h2" size="150" weight="bold" color="primary">
            {t('ShiftGoalHistory.Title')}
          </Text>
        </View>
        <Button
          variant="ghost"
          className={classNames(
            styles['close-icon'],
            styles['inventory-goals__header__close-button']
          )}
          onClick={onClose}
        >
          <Icon svg={Close} />
        </Button>
      </View>

      <View className={styles['inventory-goals__body']} padding={2}>
        <View className={styles['inventory-goals__current_shift']} padding={3} gap={1}>
          <View direction="row" justify="space-between" align="center">
            <View direction="column" gap={1}>
              <Text weight="bold" size="125" color="primary">
                {t('ShiftGoalHistory.CurrentShift')}
              </Text>
              <Text size="087" color="500">
                {shiftName} | {ValidatorCount} Checkers
              </Text>
            </View>
            <StatusBadge
              variant={StatusVariants.COMPLETED}
              text={t('ShiftGoalHistory.TimeBreak', { hour: timeLeftInHour })}
            />
          </View>

          <View className={styles['inventory-goals__current_shift-details']} padding={4} gap={3}>
            <View direction="row" justify="space-between" align="center">
              <View direction="column" gap={2}>
                <Text weight="bold" size="100" color="primary">
                  {t('ShiftGoalHistory.newShiftTitle')}
                </Text>
                <Text size="087" color="500">
                  {t('ShiftGoalHistory.PreviousInventoryStatus', {
                    completed: 5950,
                    total: 6000,
                    difference: 50,
                  })}
                </Text>
              </View>
              <View direction="row" gap={3}>
                <Button variant="secondary" onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
                <Button variant="primary" onClick={() => onSave(currentPieces)}>
                  {t('Save')}
                </Button>
              </View>
            </View>

            <Divider color="300" />

            <View direction="row" gap={3} align="stretch">
              <View className={styles['inventory-goals__current_shift-entires']}>
                <View direction="row" justify="space-between" align="center">
                  <View>
                    <Text weight="bold" size="100" color="primary">
                      0 Total Pieces
                    </Text>
                  </View>
                  <View>
                    <Text size="100" color="500">
                      {t('ShiftGoalHistory.NoGoal')}
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <Divider vertical color="300" />
              </View>

              <View className={styles['inventory-goals__current_shift-entires']} gap={2}>
                <View direction="row" align="center">
                  <Text weight="bold" size="100" color="500">
                    {t('ShiftGoalHistory.InventoryBreakDown')}
                  </Text>
                </View>

                <View gap={1}>
                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        {t('ShiftGoalHistory.InventoryPieceTypes.Totes')}
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <TextField
                          label={t('ShiftGoalHistory.InputLabel')}
                          value={currentPieces.totes.toString()}
                          size="small"
                          onChange={(e) =>
                            setcurrentPieces({ ...currentPieces, totes: e.target.value })
                          }
                        />
                      </View>
                      <View>
                        <Text>Pcs</Text>
                      </View>
                    </View>
                  </View>

                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        {t('ShiftGoalHistory.InventoryPieceTypes.Pallets')}
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <TextField
                          label={t('ShiftGoalHistory.InputLabel')}
                          value={currentPieces.pallets.toString()}
                          size="small"
                          onChange={(e) =>
                            setcurrentPieces({ ...currentPieces, pallets: e.target.value })
                          }
                        />
                      </View>
                      <View>
                        <Text>Pcs</Text>
                      </View>
                    </View>
                  </View>
                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        {t('ShiftGoalHistory.InventoryPieceTypes.DSD_Totes')}
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <TextField
                          label={t('ShiftGoalHistory.InputLabel')}
                          value={currentPieces.dsdTotes.toString()}
                          size="small"
                          onChange={(e) =>
                            setcurrentPieces({ ...currentPieces, dsdTotes: e.target.value })
                          }
                        />
                      </View>
                      <View>
                        <Text>Pcs</Text>
                      </View>
                    </View>
                  </View>
                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        {t('ShiftGoalHistory.InventoryPieceTypes.DSD_Pallets')}
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <TextField
                          label={t('ShiftGoalHistory.InputLabel')}
                          value={currentPieces.dsdPallets.toString()}
                          size="small"
                          onChange={(e) =>
                            setcurrentPieces({ ...currentPieces, dsdPallets: e.target.value })
                          }
                        />
                      </View>
                      <View>
                        <Text>Pcs</Text>
                      </View>
                    </View>
                  </View>
                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        {t('ShiftGoalHistory.InventoryPieceTypes.Returns')}
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <TextField
                          label={t('ShiftGoalHistory.InputLabel')}
                          value={currentPieces.returns.toString()}
                          size="small"
                          onChange={(e) =>
                            setcurrentPieces({ ...currentPieces, returns: e.target.value })
                          }
                        />
                      </View>
                      <View>
                        <Text>Pcs</Text>
                      </View>
                    </View>
                  </View>
                  <Divider color="300" />

                  <View direction="row" justify="space-between" align="center">
                    <View direction="row" align="center" gap={2}>
                      <Icon svg={Checkmark} color="primary" />
                      <Text size="100" weight="bold" color="primary">
                        {t('ShiftGoalHistory.Total')}
                      </Text>
                    </View>
                    <View direction="row" align="center" gap={2}>
                      <View>
                        <Text color="500">0 /</Text>
                      </View>
                      <View>
                        <Text>
                          {' '}
                          {!(
                            currentPieces.totes.length ||
                            currentPieces.pallets.length ||
                            currentPieces.dsdTotes.length ||
                            currentPieces.dsdPallets.length ||
                            currentPieces.returns.length
                          )
                            ? '0 Pcs'
                            : Number(currentPieces.totes) +
                              Number(currentPieces.pallets) +
                              Number(currentPieces.dsdTotes) +
                              Number(currentPieces.dsdPallets) +
                              Number(currentPieces.returns) +
                              ' Pcs'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View className={styles['inventory-goals__past_shifts']} gap={2} padding={[3, 0, 0, 0]}>
          <View>
            <Text weight="bold" size="125" color="primary">
              {t('ShiftGoalHistory.PastShifts')}
            </Text>
          </View>

          <View>
            <Table
              columns={INVENTORY_PAST_SHIFTS_TABLE_COLUMNS}
              rows={mapInventoryShiftHistoryTableRows(INVENTORY_SHIFT_GOAL_MOCK_DATA)}
              isPaginated={false}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={0}
              onSort={() => ({})}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};
