/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { ManualReplenishmentRequest } from '@inbound/types/service.type';
import { replenishmentService } from '../replenishmentService';

export const useMutateReplenishment = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const message = t('Errors.Action.UpdatePermission');

  const {
    mutate: mutateReplenishment,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { manualReplenishmentRequest: ManualReplenishmentRequest }) => {
      const payload = { ...params.manualReplenishmentRequest };
      return replenishmentService.createManualReplenishment(payload);
    },
    onError: () => {
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateReplenishment, isLoading, isError };
};
