/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const getEncryptedStorageItem = (key: string): string => {
  const content = localStorage.getItem(key) || '';
  return atob(content);
};

export const setEncryptedStorageItem = (key: string, item: string): void => {
  localStorage.setItem(key, btoa(item));
};
