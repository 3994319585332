/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Icon, View, Text, Dropdown, Add, Loader } from '@az/starc-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { lazy, startTransition, useCallback, useEffect, useState } from 'react';
import { CreateDropdownContent, CreateCountType } from './CycleCountsCreateButton.constants';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import {
  CHOOSE_ASSIGNEE_CONTENT_TYPE,
  CYCLE_COUNT_STATUS_CD,
} from '@inventory/constants/constants';
import { useMutateCreateLocationCount } from '@inventory/services/hooks/mutateCreateLocationCount';
import styles from './CycleCountsCreateButton.module.scss';
import { checkCountTypeCd } from '@inventory/utils/utils';

const LazyLoadChooseAssigneesModal = lazy(() =>
  import('@inventory/components/CycleCountsModal/ChooseAssigneesModal/ChooseAssigneesModal').then(
    (module) => ({ default: module.ChooseAssigneesModal })
  )
);

export interface CreateButtonProps {
  isEnableCreateBtn: boolean;
}

export const CycleCountsCreateButton = ({ isEnableCreateBtn }: CreateButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.pathname.split('/').at(-1);
  const [countTypeCdValue, setCountTypeCdValue] = useState<string>();
  const currentCountTypeCdValue = checkCountTypeCd(countTypeCdValue ? countTypeCdValue : '');

  const [selectedCountType, setCreateCountType] = useState<CreateCountType | null>(null);

  const { isLoading: isCreatePhaseLoading, mutateLocationCount } = useMutateCreateLocationCount();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  /* Query Create Location count Synch API */
  useEffect(() => {
    if (selectedCountType === CreateCountType.WALL_TO_WALL_COUNT) {
      mutateLocationCount(
        {
          CreateLocationCountRequest: [
            {
              countRequest: {
                countTypeCd: currentCountTypeCdValue ? currentCountTypeCdValue : '',
                statusCd: CYCLE_COUNT_STATUS_CD.NEW,
                startTs: new Date().toISOString(),
              },
            },
          ],
        },
        {
          onSuccess: (data) => {
            if (data) {
              queryClient.invalidateQueries(['subzoneList']);
              const successMessage = t('CycleCount.WallToWall.ActivePhaseSuccess', {
                countTypeCd: checkCountTypeCd(currentCountTypeCdValue ?? ''),
              });
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
              if (Array.isArray(data) && data[0]?.countRequest) {
                const id = data[0].countRequest.taskId;
                navigate(
                  PAGE_URLS.CREATE_CYCLE_COUNTS(
                    id ?? '',
                    currentCountTypeCdValue ?? '',
                    ROUTES.SUBZONES
                  )
                );
              }
            }
          },
          onError: (error) => {
            const errorMessage = t('Errors.Action.CreateLocationCount');
            error ?? handleNotification(NOTIFICATION_TYPES.ERROR, errorMessage);
          },
        }
      );
    }
  }, [
    currentCountTypeCdValue,
    handleNotification,
    mutateLocationCount,
    navigate,
    queryClient,
    selectedCountType,
    t,
  ]);

  useEffect(() => {
    if (currentTab) {
      setCountTypeCdValue(currentTab);
    }
  }, [currentTab]);

  const openModal = useCallback((type: CreateCountType) => {
    startTransition(() => setCreateCountType(type));
  }, []);

  function OnClickStartCount(): void {
    throw new Error('Function not implemented.');
  }
  return (
    <>
      <View>
        <Dropdown padding={0} className={styles['create-cycle-count__create_dropdown']}>
          <Dropdown.Button className={styles['create-cycle-count__create_dropdown__button']}>
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} color="on-primary" />
              {isCreatePhaseLoading ? (
                <Loader color="secondary" />
              ) : (
                <Text color="on-primary" size="100" textCase="uppercase">
                  {t('Create')}
                </Text>
              )}
            </View>
          </Dropdown.Button>
          <Dropdown.Content>
            {CreateDropdownContent.map((link, index) => (
              <Dropdown.Item
                key={link.label}
                label={t(link.label)}
                onClick={() => openModal(link.type)}
                disabled={index === 0 ? !isEnableCreateBtn : false}
              />
            ))}
          </Dropdown.Content>
        </Dropdown>
      </View>
      {selectedCountType === CreateCountType.RISK_BASED_COUNT && (
        <LazyLoadChooseAssigneesModal
          showModal
          contentType={CHOOSE_ASSIGNEE_CONTENT_TYPE.SINGLE_ASSIGNEE}
          onClose={() => setCreateCountType(null)}
          OnClickStartCount={OnClickStartCount}
          setAssigneeListPayloadData={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      )}
    </>
  );
};
