/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { apiService } from '@shared/services/apiService';
import { TASK_UPDATE_ENDPOINTS } from '@shared/constants/routes.ts';
import { parseAndLog } from '@taskManagement/utils/utils.ts';
import { UpdateTaskResponseSchema } from '@taskManagement/schemas/UpdateTaskSchema';
import { UpdateTaskParams } from '@taskManagement/types/types';

export const updateTaskService = {
  updateTask: async (layoutRequest: UpdateTaskParams) => {
    const payload = layoutRequest;

    const resp = await apiService.post(TASK_UPDATE_ENDPOINTS.UPDATE_TASK, payload);
    return parseAndLog(UpdateTaskResponseSchema, resp.data);
  },
};
