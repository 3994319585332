/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { apiService } from '@shared/services/apiService.ts';
import { VENDOR_SEARCH_ENDPOINTS } from '@shared/constants/routes.ts';
import { parseAndLog } from '@ofm/utils/utils';
import { VendorListSchema } from '@inbound/schemas/vendorSchema';

export const vendorService = {
  getVendors: async (payload: string[]) => {
    const response = await apiService.post(VENDOR_SEARCH_ENDPOINTS.GET_VENDORS, payload);
    return parseAndLog(VendorListSchema, response.data);
  },
};
