/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GetAssetParams } from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { ASSET_ENDPOINTS } from '@shared/constants/routes.ts';
import {
  ASSET_DEFAULT_SORT_FIELD,
  ASSET_TYPE_CODE,
  ENTITY_ASSOCIATIONS,
} from '@mdm/constants/constants.ts';
import { paginatedParseAndLog } from '@mdm/utils/utils.ts';
import { AssetSchema } from '@mdm/schemas/assetSchema.ts';

export const vehicleService = {
  getVehicles: async (options: GetAssetParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        assetTypeCd: ASSET_TYPE_CODE.VEHICLE,
        entityAssociations: options?.entityAssociations || [ENTITY_ASSOCIATIONS.ATTRIBUTES],
        ...(options?.assetCd && { assetCd: options.assetCd }),
      },
    };

    const response = await apiService.post(ASSET_ENDPOINTS.GET_ASSETS, payload);
    return paginatedParseAndLog(AssetSchema, response.data);
  },
  getVehicleById: async (assetCd: string) => {
    const payload = {
      searchCriteria: {
        assetTypeCd: ASSET_TYPE_CODE.VEHICLE,
        assetCd: assetCd,
        entityAssociations: [ENTITY_ASSOCIATIONS.ATTRIBUTES, ENTITY_ASSOCIATIONS.LAYOUTS],
      },
      searchPage: {
        sortBy: ASSET_DEFAULT_SORT_FIELD,
      },
    };

    const response = await apiService.post(ASSET_ENDPOINTS.GET_ASSETS, payload);
    return paginatedParseAndLog(AssetSchema, response.data);
  },
};
