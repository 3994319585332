/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { atom } from 'jotai';
import { ShiftsAtomType } from './shiftAtom.types';
import { shiftService } from '@shared/services/shiftService';

export const shiftsAtom = atom<ShiftsAtomType>(undefined);

export const asyncShiftsAtom = atom(
  (get) => {
    return get(shiftsAtom);
  },
  async (_get, set) => {
    const response = await shiftService.shiftsSearch({});
    if (response) {
      set(shiftsAtom, response);
      return true;
    }

    set(shiftsAtom, undefined);
    return false;
  }
);
