/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import axios from 'axios';

import { LogError } from '@ofm/classes/LogError.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';
import {
  ACCEPT_LANGUAGE,
  FACILITY_ID,
  Language,
  ORGANISATION_ID,
} from '@shared/constants/constants.ts';
import { LOCAL_ACCESS_TOKEN, LOCAL_LANGUAGE } from '@shared/constants/storageConstants.ts';
import { getEncryptedStorageItem } from '@shared/utils/token/tokenUtils';

const apiService = axios.create({
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  (config) => {
    config.headers['Org-Id'] = ORGANISATION_ID;
    if (!config.headers['Facility-Id']) {
      config.headers['Facility-Id'] = FACILITY_ID;
    }

    config.headers['Accept-Language'] =
      ACCEPT_LANGUAGE[
        (localStorage.getItem(LOCAL_LANGUAGE) as Language) || ACCEPT_LANGUAGE[Language.ENGLISH]
      ] || localStorage.getItem(LOCAL_LANGUAGE);
    config.headers['Authorization'] = `Bearer ${getEncryptedStorageItem(LOCAL_ACCESS_TOKEN)}`;
    config.headers['client_id'] = window.env.APIGEE_CLIENT_ID;
    return config;
  },
  (error) => {
    throw new LogError(formatErrorLog(ErrorLogType.AXIOS, error, error?.response?.status));
  }
);

apiService.interceptors.response.use(
  (response) => response,
  (e) => {
    const error = e?.response?.data?.error
      ? e.response.data.error
      : { message: ErrorLogType.AXIOS };

    if (error.details && Array.isArray(error.details) && error.details.length > 0) {
      error.message = error.details.map((detail: { message: string }) => detail.message).join(', ');
    }

    return Promise.reject(error);
  }
);

export { apiService };
