/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { View } from '@az/starc-ui';

import { Stat } from '@shared/components/Stat/Stat';

import { calculatePercentageWithSign } from '@inbound/utils/utils';

import * as T from './PODetailsSection.types';
import styles from '../PODetail.module.scss';

export const PODetailStatatics = ({ inboundOrderData }: T.PODetailsStataticsProps) => {
  const { t } = useTranslation();

  const renderPOStatistic = (title: string, primaryText: string, secondaryText?: string) => {
    return (
      <View.Item grow backgroundColor="secondary">
        {secondaryText ? (
          <Stat
            title={title}
            primaryText={primaryText}
            width="100%"
            secondaryTextProps={{
              secondaryText: secondaryText,
            }}
          />
        ) : (
          <Stat title={title} primaryText={primaryText} width="100%" />
        )}
      </View.Item>
    );
  };

  return (
    <View
      direction="row"
      padding={[4, 6]}
      className={styles['po-detail__right-column__statistics']}
    >
      {renderPOStatistic(
        t('PODashboard.Stats.Received'),
        calculatePercentageWithSign(
          inboundOrderData.trailerOrder.inboundOrder.receivedLineCount,
          inboundOrderData.trailerOrder.inboundOrder.totalLineCount
        ),
        t('PODashboard.LinesData', {
          count: inboundOrderData.trailerOrder.inboundOrder.receivedLineCount,
          total: inboundOrderData.trailerOrder.inboundOrder.totalLineCount,
        })
      )}
      {renderPOStatistic(
        t('PODashboard.Stats.PiecesFitInFPS'),
        calculatePercentageWithSign(
          inboundOrderData.trailerOrder.inboundOrder.receivedLineCount,
          inboundOrderData.trailerOrder.inboundOrder.totalLineCount
        ),
        t('PODashboard.PicesData', {
          count: inboundOrderData.trailerOrder.inboundOrder.receivedLineCount,
          total: inboundOrderData.trailerOrder.inboundOrder.totalLineCount,
        })
      )}
      {renderPOStatistic(
        t('PODashboard.Stats.SKUsWithFPS'),
        calculatePercentageWithSign(
          inboundOrderData.trailerOrder.inboundOrder.totalFpsLineCount,
          inboundOrderData.trailerOrder.inboundOrder.totalLineCount
        ),
        t('PODashboard.SkuWithFPSData', {
          count: inboundOrderData.trailerOrder.inboundOrder.totalFpsLineCount ?? 0,
          total: inboundOrderData.trailerOrder.inboundOrder.totalLineCount,
        })
      )}
      {renderPOStatistic(
        t('PODashboard.Stats.ReceivingLaborHoursRemaining'),
        t('PODashboard.Stats.Hours', { count: 0.53 })
      )}
    </View>
  );
};
