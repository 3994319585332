/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { t } from 'i18next';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';
import { isNumeric } from '@shared/utils/validationUtils.ts';

export const SubzoneMinMaxSchema = z.object({
  layoutName: z.string(),
  layoutDistinctName: z.string().min(1).nullable(),
  layoutNameDesc: z.string().nullable(),
  productId: z.string(),
  min: z.string(),
  max: z.string(),
});

export const SubzoneMinMaxViewSchema = z
  .object({
    productId: z.string().superRefine((val, ctx) => {
      if (val == '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('Form.RequiredField'),
        });
      }

      if (val.length > MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_SKU) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_SKU,
          type: 'string',
          inclusive: true,
          message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_SKU }),
        });
      }
    }),
    layoutDistinctName: z
      .string()
      .default('')
      .superRefine((val, ctx) => {
        if (val == '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('Form.RequiredField'),
          });
        }
      }),
    min: z
      .string()
      .default('')
      .superRefine((val, ctx) => {
        if (val == '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('Form.RequiredField'),
          });
        }

        if (val.length > MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MIN) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_big,
            maximum: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MIN,
            type: 'string',
            inclusive: true,
            message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MIN }),
          });
        }

        if (!isNumeric(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('Form.NumericError'),
          });
        }
      }),
    max: z
      .string()
      .default('')
      .superRefine((val, ctx) => {
        if (val == '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('Form.RequiredField'),
          });
        }

        if (val.length > MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MAX) {
          ctx.addIssue({
            code: z.ZodIssueCode.too_big,
            maximum: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MAX,
            type: 'string',
            inclusive: true,
            message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.SUBZONE_MIN_MAX_MAX }),
          });
        }

        if (!isNumeric(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('Form.NumericError'),
          });
        }
      }),
  })
  .superRefine((values, ctx) => {
    const min = parseInt(<string>values.min, 10);
    const max = parseInt(<string>values.max, 10);
    if (values && min >= max) {
      if (min > max) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['min'],
          message: t('ValidationError.MIN_GREATER_THAN_MAX'),
        });
      }

      if (min > 0 && max > 0 && min == max) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['min'],
          message: t('ValidationError.MIN_MAX_EQUAL'),
        });
      }
    }

    if (values.layoutDistinctName == '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }
  })
  .default({
    productId: '',
    layoutDistinctName: '',
    min: '',
    max: '',
  });
