/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const headerItems = [
  { flex: 3.2, text: 'Product Description', name: 'productLocaleDesc' },
  { flex: 4, text: 'Put Locations' },
  { flex: 1, text: 'SKU', name: 'productId' },
  { flex: 1, text: 'Part', name: 'partNbrId' },
  { flex: 1, text: 'Pieces', name: 'totalOrderedQty,' },
];
