/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Text, View, classNames } from '@az/starc-ui';
import { KEY } from '@shared/constants/keyConstants';
import { UserSearchSchemaType } from '@shared/types/schema.type';
import * as T from './UserSuggestion.types';
import styles from './UserSuggestion.module.scss';

export const UserSuggestion = ({
  data,
  maxEntries,
  searchValue,
  onItemClick,
  setSuggestionWasHidden,
}: T.Props) => {
  data = data?.slice(0, maxEntries);
  /* State variables */
  const [selectedItem, setSelectedItem] = useState<UserSearchSchemaType>();

  /* Functions */
  const handleSuggestionClick = (
    e: MouseEvent | KeyboardEvent,
    suggestion: UserSearchSchemaType
  ) => {
    e.preventDefault();

    onItemClick?.(suggestion);
    setSuggestionWasHidden?.(true);
  };

  const handleKeyEvent = useCallback(
    (e: KeyboardEvent) => {
      if (data) {
        let currentIndex = data?.findIndex((el) => el === selectedItem);
        const totalSuggestions = data?.length || 0;
        switch (e.key) {
          case KEY.ARROW_DOWN:
            currentIndex = ((currentIndex as number) + 1) % totalSuggestions;
            setSelectedItem(data?.[currentIndex]);
            break;
          case KEY.ARROW_UP:
            currentIndex = ((currentIndex as number) - 1 + totalSuggestions) % totalSuggestions;
            setSelectedItem(data?.[currentIndex]);
            break;
          case KEY.ENTER:
            if (data && data[currentIndex] && onItemClick) {
              onItemClick(data[currentIndex]);
              setSuggestionWasHidden?.(true);
            }
            break;
          case KEY.ESCAPE:
            setSuggestionWasHidden?.(true);
            break;
          default:
            break;
        }
      }
    },
    [data, onItemClick, selectedItem, setSuggestionWasHidden]
  );

  const renderSuggestions = (item: UserSearchSchemaType) => {
    const userSearchName = `${item.firstName} ${item.lastName}`;
    const userSearchId = item.userId.toString();

    if (searchValue) {
      // Split on highlight term and include term into parts, ignore case
      const userNameParts = userSearchName.split(new RegExp(`(${searchValue})`, 'gi'));
      const userSearchIdParts = userSearchId.split(new RegExp(`(${searchValue})`, 'gi'));

      if (userNameParts.length > 1) {
        return (
          <>
            <View.Item>
              {userNameParts.map((part, index) => {
                return (
                  <Text
                    key={index}
                    as="span"
                    fontFamily="body"
                    size="100"
                    attributes={{ 'aria-label': part, 'data-testid': part }}
                    weight={part.toLowerCase() === searchValue.toLowerCase() ? 'regular' : 'bold'}
                    color="primary"
                  >
                    {part}
                  </Text>
                );
              })}
            </View.Item>
          </>
        );
      }

      if (userSearchIdParts.length > 1) {
        return (
          <>
            <View.Item>
              {userSearchIdParts.map((searchIdPart, index) => {
                return (
                  <Text
                    key={index}
                    as="span"
                    fontFamily="body"
                    size="100"
                    attributes={{ 'aria-label': searchIdPart, 'data-testid': searchIdPart }}
                    weight={
                      searchIdPart.toLowerCase() === searchValue.toLowerCase() ? 'regular' : 'bold'
                    }
                    color="primary"
                  >
                    {searchIdPart}
                  </Text>
                );
              })}
            </View.Item>
          </>
        );
      }
    } else {
      return (
        <Text fontFamily="body" size="100" weight="bold">
          {userSearchName}
        </Text>
      );
    }
  };

  /* Hooks */
  useEffect(() => {
    document.addEventListener('keydown', handleKeyEvent);
    return () => document.removeEventListener('keydown', handleKeyEvent);
  }, [handleKeyEvent]);

  return (
    <View className={styles['user-suggestion__container']} direction="column">
      {data?.map((el) => (
        <View
          key={el.userId}
          attributes={{
            onMouseDown: (e: MouseEvent) => handleSuggestionClick(e, el),
            role: 'option',
            'aria-selected': el === selectedItem,
          }}
          className={classNames(
            styles['user-suggestion'],
            el === selectedItem && styles['user-suggestion--active']
          )}
        >
          <View direction="row" align="center">
            {renderSuggestions(el)}
          </View>
        </View>
      ))}
    </View>
  );
};
