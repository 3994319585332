/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

export const AssetLayoutMappingSchema = z.object({
  assetLayoutMapKey: z.string().optional(),
  assetLayoutMappingAttributes: z.array(AttributeSchema).nullable(),
  entityTypeCd: z.string().nullable().optional(),
  isActive: z.boolean().optional(),
  layoutName: z.string().nullable().optional(),
  layoutDesc: z.string().nullable().optional(),
  layoutDistinctName: z.any(),
  layoutAttributes: z.array(AttributeSchema).nullable().optional(),
});

export const AssetSchema = z.object({
  assetKey: z.string().optional(),
  assetTypeCd: z.string(),
  assetCd: z.string(),
  assetDesc: z.string().default(''),
  attributes: z.array(AttributeSchema).nullable(),
  layouts: z.array(AssetLayoutMappingSchema).nullable().optional(),
});
