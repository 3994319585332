/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { atom } from 'jotai';

export const releaseToPaperSubzonesAtom = atom<string[]>([]);
export const releaseToPaperLaneConfirmationAtom = atom<boolean>(false);
export const printAndReleaseAtom = atom<boolean>(false);
export const closingPaperAtom = atom<boolean>(false);
