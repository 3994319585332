/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Skeleton, Text, View } from '@az/starc-ui';

import s from './EmptySuggestions.module.scss';
import { useTranslation } from 'react-i18next';
import * as T from './EmptySuggestions.types';

export const EmptySuggestions = ({ isLoading }: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  return (
    <View className={s['empty-suggestions']}>
      {isLoading ? (
        <View gap={2}>
          <Skeleton borderRadius="small" />
          <Skeleton borderRadius="small" />
          <Skeleton borderRadius="small" />
        </View>
      ) : (
        <Text className={s['empty-suggestions__text']}>{t('Empty.Search.NoSuggestions')}</Text>
      )}
    </View>
  );
};
