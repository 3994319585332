/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Pagination, SearchBar, Skeleton, Store, Text, View, useDebounce } from '@az/starc-ui';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { CheckBoxCard } from '@shared/components/CheckBoxCard/CheckBoxCard';
import { Drawer } from '@shared/components/Drawer/Drawer';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants';

import * as T from './StoreSearchDrawer.types';
import s from './StoreSearchDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import { useStoreSearch } from '@ofm/services/useStoreSearch';
import { DEBOUNCE_TIMER } from '@shared/constants/constants';
import { useEffect, useState } from 'react';

export const StoreSearchDrawer = ({
  title,
  label,
  showDrawer,
  setShowDrawer,
  storeId,
  setStoreId,
  onClick,
}: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  const debouncedStoreValue = useDebounce(storeId, DEBOUNCE_TIMER);

  /* State variables */
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const {
    stores: filteredStores,
    invalidId,
    hasNoResults,
    storesData,
    isStoreInitialLoading,
  } = useStoreSearch({
    storeId: debouncedStoreValue,
    currentPage: currentPage,
    pageSize: 10,
  });

  /* Functions */
  const handleStoreClick = (value: string) => {
    setStoreId(value);
    onClick();
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      if (inputValue) {
        setStoreId(inputValue);
      }
    }
  };

  const onClearSearch = () => {
    setStoreId('');
  };

  /* Hooks */
  useEffect(() => {
    if (storesData) {
      setTotalPages(storesData?.totalPages);
    }
  }, [storesData]);

  useEffect(() => {
    setCurrentPage(0);
    setTotalPages(0);
  }, [storeId]);

  return (
    <Drawer
      show={showDrawer}
      handleClose={() => {
        setStoreId('');
        setShowDrawer(false);
      }}
      size="small"
      position="right"
      headerTitle={title}
      contentClassName={s['store-search-drawer__content']}
    >
      <Text weight="bold">{label}</Text>
      <SearchBar
        variant="pro"
        value={storeId}
        onValueChange={setStoreId}
        className={s['store-search-drawer__search-bar']}
        label={t('Search.StoreNumber')}
        onValueClear={onClearSearch}
        inputAttributes={{ onKeyDown: onKeyDown }}
        maxMenuHeight={300}
      />

      {isStoreInitialLoading ? (
        <View gap={2} padding={2} align="center" backgroundColor="secondary" width="100%">
          <Skeleton width="100%" borderRadius="small" />
          <Skeleton width="100%" borderRadius="small" />
          <Skeleton width="100%" borderRadius="small" />
          <Skeleton width="100%" borderRadius="small" />
          <Skeleton width="100%" borderRadius="small" />
        </View>
      ) : hasNoResults ? (
        <EmptyState
          svg={Store}
          subtitle={t('Empty.Search.Subtitle')}
          text={t('Empty.Search.Text', {
            value: invalidId,
          })}
        />
      ) : (
        <View as="ul" className={s['store-search-drawer__store-list']}>
          {filteredStores.map((store, index) => (
            <View.Item
              as="li"
              key={`${store}-${index}`}
              className={s['store-search-drawer__store-item']}
            >
              <CheckBoxCard
                title={store}
                shouldHideCheckbox
                onClick={() => handleStoreClick(store)}
              />
            </View.Item>
          ))}
        </View>
      )}

      {totalPages > 1 && storeId && storeId.trim().length > 0 && (
        <View align="center" width="100%">
          <Pagination
            initialPage={currentPage + 1}
            totalPages={totalPages}
            onPageChange={(currentPage) => {
              setCurrentPage(currentPage - 1);
            }}
            attributes={{
              style: { visibility: isStoreInitialLoading ? 'hidden' : 'unset' },
            }}
          />
        </View>
      )}
    </Drawer>
  );
};
