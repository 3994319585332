/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GetShiftParams } from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { SHIFT_ENDPOINTS } from '@shared/constants/routes.ts';
import { parseAndLog } from '@mdm/utils/utils.ts';
import { ShiftListSchema } from '@shared/schemas/userSchema.ts';

export const shiftService = {
  getShifts: async (options: GetShiftParams) => {
    const payload = {
      ...(options?.shiftDate && { shiftDate: options.shiftDate }),
      ...(options?.shiftTime && { shiftTime: options.shiftTime }),
      ...(options?.shiftId && { shiftId: options.shiftId }),
    };

    const response = await apiService.post(SHIFT_ENDPOINTS.GET_SHIFTS, payload);
    return parseAndLog(ShiftListSchema, response.data);
  },
};
