/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { AssetRequest } from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { ASSET_ENDPOINTS } from '@shared/constants/routes.ts';
import { parseAndLog } from '@mdm/utils/utils.ts';
import { AssetSchema } from '@mdm/schemas/assetSchema.ts';

export const assetService = {
  createAsset: async (assetRequest: AssetRequest) => {
    const resp = await apiService.post(ASSET_ENDPOINTS.CREATE_ASSET, assetRequest);
    return parseAndLog(AssetSchema, resp.data);
  },
  deleteAsset: async (assetKey: string) => {
    const resp = await apiService.put(ASSET_ENDPOINTS.UPDATE_ASSET(assetKey), {
      isActive: false,
    });
    return parseAndLog(AssetSchema, resp.data);
  },
  updateAsset: async (assetKey: string, assetRequest: AssetRequest) => {
    const resp = await apiService.put(ASSET_ENDPOINTS.UPDATE_ASSET(assetKey), assetRequest);
    return parseAndLog(AssetSchema, resp.data);
  },
};
