/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useRef, useState } from 'react';

import { Dropdown, View, Checkbox, Divider, Text, Actionable, SearchBar } from '@az/starc-ui';

import * as T from './DropdownSelectMenu.types';
import styles from './DropdownSelectMenu.module.scss';
import { useTranslation } from 'react-i18next';
import { KEY } from '@shared/constants/keyConstants';

export const DropdownSelectMenu = ({
  width,
  name,
  label,
  searchable,
  value: values,
  options,
  onChange,
  onReset,
}: T.Props) => {
  /* State variables */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  /* Constants */
  const { t } = useTranslation();

  /* Functions */
  const toggleDropdown = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onOptionsChange = (value: string[]) => {
    const objValue = value
      .map((item) => options.find((option) => option.value === item))
      .filter((item) => item !== undefined) as T.DropdownOption[];
    onChange(objValue);
  };

  const onResetAll = () => {
    onReset();
  };

  /* Hooks */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target: Node = ((event as MouseEvent).target ||
        (event as TouchEvent).targetTouches[0]) as Node;
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(target) &&
        !buttonRef.current.contains(target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = (event: Event) => {
      const target: Node = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEY.ESCAPE && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const filteredOptions = options.filter((option) => option.label.includes(searchValue));

  return (
    <View width="240px">
      <Dropdown
        width={Math.max(width, 240)}
        open={isOpen}
        className={styles['dropdown-select-menu']}
      >
        <View attributes={{ ref: buttonRef }}>
          <Dropdown.Button label={label} onClick={toggleDropdown} />
        </View>
        <Dropdown.Content>
          <View
            attributes={{
              ref: dropdownRef,
            }}
            gap={1}
            padding={[2, 0]}
            width="auto"
          >
            {searchable && (
              <View.Item>
                <View padding={[0, 2, 2, 2]}>
                  <SearchBar
                    className={styles['dropdown-select-menu__search-bar']}
                    suggestions={[]}
                    label="Search"
                    value={searchValue}
                    onValueChange={setSearchValue}
                  />
                </View>
                <Divider />
              </View.Item>
            )}
            <Checkbox.Group
              name={name}
              onValueChange={onOptionsChange}
              value={values.map((item) => item.value)}
            >
              <View gap={4} className={styles['dropdown-select-menu__scrollable-section']}>
                {filteredOptions.length === 0 ? (
                  <Text size="087" weight="medium" variant="text-link" align="center">
                    {t('PODashboard.NoMatch')}
                  </Text>
                ) : (
                  filteredOptions.map((option: T.DropdownOption, index: number) => (
                    <Checkbox key={index} label={option.label} value={option.value} />
                  ))
                )}
              </View>
            </Checkbox.Group>
            <Divider color="300" />
            <View padding={4}>
              <Actionable onClick={onResetAll}>
                <Text
                  className={styles['dropdown-select-menu__actionable-text']}
                  size="087"
                  weight="medium"
                  variant="text-link"
                >
                  {t('PODashboard.ResetAll')}
                </Text>
              </Actionable>
            </View>
          </View>
        </Dropdown.Content>
      </Dropdown>
    </View>
  );
};
