/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { useAtom } from 'jotai';

import {
  Actionable,
  Button,
  FormControl,
  Icon,
  Loader,
  Modal,
  Text,
  TextField,
  View,
  classNames,
} from '@az/starc-ui';
import { Close, PasswordHidden, PasswordShow } from '@az/starc-ui-icons';

import { asyncUserAtom } from '@shared/atoms/user/userAtom';
import { Tag } from '@shared/components/Tag/Tag';
import { EMPTY_VALUE, INPUT_TYPES, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';
import { formatErrorLog, generateDateString, isButtonDisabled } from '@ofm/utils/utils';

import { PriorityToggle } from '@inbound/components/PriorityToggle/PriorityToggle';
import { UserSignature } from '@inbound/components/UserSignature/UserSignature';

import { POActions, TRAILER_ORDER_STATUS } from '@inbound/constants/constants';
import { PurchaseOrderFinalizeSchema } from '@inbound/schemas/purchaseOrderFinalizeSchema';
import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';
import { PurchaseOrderFinalizeType } from '@inbound/types/types';
import { getFormNumericInputError } from '@inbound/utils/utils';

import * as T from './FinalizePOModal.types';
import s from './FinalizePOModal.module.scss';

export const FinalizePOModal = ({ isOpen, onClose, trailerOrder, trailerArrivalData }: T.Props) => {
  /* Atoms */
  const [user] = useAtom(asyncUserAtom);

  /* State variables */
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [isValidAutoZoneId, setIsValidAutoZoneId] = useState<boolean>(true);
  const [isValidPincode, setIsValidPincode] = useState<boolean>(true);
  const [showSignature, setShowSignature] = useState<boolean>(false);
  const [pincodeFieldType, setPincodeFieldType] = useState<string>(INPUT_TYPES.PASSWORD);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    reset,
    watch,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<PurchaseOrderFinalizeType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(PurchaseOrderFinalizeSchema),
  });

  const autoZoneId = watch('autoZoneId');
  const pinCode = watch('pincode');

  /* Queries */
  const { isLoading, mutateUpdateTrailerArrival } = useUpdateTrailerArrival();

  /* Functions */
  const togglePincodeFieldType = () => {
    if (pincodeFieldType === INPUT_TYPES.PASSWORD) {
      setPincodeFieldType(INPUT_TYPES.TEXT);
    } else {
      setPincodeFieldType(INPUT_TYPES.PASSWORD);
    }
  };

  const resetForm = () => {
    reset();
    clearErrors();
    setShowSignature(false);
    onClose();
  };

  const onModalClose = () => {
    resetForm();
  };

  const onSubmit = async (data: PurchaseOrderFinalizeType) => {
    const finalizeResult = PurchaseOrderFinalizeSchema.safeParse(data);

    if (!finalizeResult.success) {
      throw new LogError(formatErrorLog(ErrorLogType.ZOD, finalizeResult.error));
    } else {
      const payload = {
        ...trailerArrivalData,
        trailerOrders: [
          {
            ...trailerArrivalData.trailerOrders,
            inboundOrder: {
              statusCd: TRAILER_ORDER_STATUS.FINALIZED,
              finalizedByUserName: user ? `${user.name} ${user.lastName}` : '',
            },
          },
        ],
      };

      mutateUpdateTrailerArrival(
        {
          receiptId: trailerOrder.receiptId,
          operation: POActions.FINALIZE,
          trailerArrivalUpdateRequest: payload,
        },
        {
          onSuccess: () => {
            resetForm();

            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('PODashboard.Notification.FinalizePO.Success')
            );
          },
        }
      );
    }
  };

  useEffect(() => {
    if (autoZoneId && pinCode) {
      // todo: Need to replace when integrating AutoZoneID & pin code validation
      setIsLoadingUser(true);

      setTimeout(() => {
        setIsValidAutoZoneId(true);
        setIsValidPincode(true);
        setShowSignature(true);
        setIsLoadingUser(false);
      }, 750);
    }
  }, [autoZoneId, pinCode]);

  return (
    <Modal
      open={isOpen}
      onClose={() => onModalClose()}
      className={s['finalize-po-modal']}
      closeByClickAway={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <View
          direction="row"
          justify="space-between"
          padding={6}
          className={s['finalize-po-modal__header']}
        >
          <View direction="column">
            <Text as="h2" size="175" weight="bold" color="primary">
              {t('PODashboard.FinalizePOModal.FinalizePurchaseOrder')}
            </Text>
          </View>
          <Button
            variant="ghost"
            onClick={onModalClose}
            className={classNames(s['close-icon'], s['finalize-po-modal__header__close-button'])}
          >
            <Icon svg={Close} />
          </Button>
          <Text
            weight="medium"
            size="100"
            className={s['finalize-po-modal__body__form-field-label']}
          >
            {t('PODashboard.FinalizePOModal.FinalizeDescription')}
          </Text>
        </View>

        <View direction="column" className={s['finalize-po-modal__body']}>
          <View.Item>
            <View direction="column" className={s['finalize-po-modal__body__section']}>
              <View.Item>
                <Text
                  weight="bold"
                  color="500"
                  size="075"
                  className={s['finalize-po-modal__body__section__title']}
                >
                  {t('PODashboard.PurchaseOrder')}
                </Text>
              </View.Item>
              <View.Item>
                <Text size="125" weight="bold" color="primary">
                  {trailerOrder.inboundOrder.vendorLocale?.vendorName}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[4, 0, 1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.FinalizePOModal.ReceivalNumber')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.receiptId}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.FinalizePOModal.TotalPiecesOrdered')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.inboundOrder.totalOrderedQty}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.FinalizePOModal.TotalPiecesReceived')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="error">
                  {trailerOrder.inboundOrder.totalReceivedQty}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.FinalizePOModal.TotalLinesOrdered')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.inboundOrder.totalLineCount}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.FinalizePOModal.TotalLinesReceived')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="error">
                  {trailerOrder.inboundOrder.receivedLineCount}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.ArrivalTime')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {generateDateString(
                    new Date(trailerOrder.trailerArrival.trailerArrivalTs),
                    t('DateFormat.ShortTime')
                  ) || EMPTY_VALUE}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.Shift')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  1st
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.GoalHours')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  5
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.Vendor')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.Carrier')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.trailerArrival.trailerCarrierName || EMPTY_VALUE}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.Trailer')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.trailerArrival.trailerNbr || EMPTY_VALUE}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.FacilityLocation')}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="100" color="500">
                  {trailerOrder.trailerArrival.trailerLocationTypeCd &&
                  trailerOrder.trailerArrival.trailerLocationId
                    ? `${trailerOrder.trailerArrival.trailerLocationTypeCd} ${trailerOrder.trailerArrival.trailerLocationId}`
                    : EMPTY_VALUE}
                </Text>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.Item.Commodity')}
                </Text>
              </View.Item>
              <View.Item grow>
                <View>
                  {trailerOrder.commodityTypeCd ? (
                    <Tag variant="order" text={trailerOrder.commodityTypeCd} />
                  ) : (
                    EMPTY_VALUE
                  )}
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View direction="row" padding={[1, 0]}>
              <View.Item grow>
                <Text size="100" color="primary">
                  {t('PODashboard.HighPriority')}
                </Text>
              </View.Item>
              <View.Item grow>
                <View>
                  <PriorityToggle
                    name="priority"
                    checked={trailerOrder?.inboundOrder.priority > 0}
                    disabled={true}
                  />
                </View>
              </View.Item>
            </View>
          </View.Item>

          <View.Item>
            <View
              direction="column"
              padding={[5, 0]}
              className={s['finalize-po-modal__body__section']}
            >
              {isLoadingUser ? (
                <View.Item>
                  <View padding={10} borderColor="disabled">
                    <Loader color="primary" variant="inline" />
                  </View>
                </View.Item>
              ) : showSignature ? (
                <>
                  <View.Item>
                    <Text size="125" weight="bold">
                      {t('PODashboard.FinalizePOModal.Advisor')}
                    </Text>
                  </View.Item>
                  <View.Item>
                    <UserSignature userName="Christina Lu" />
                  </View.Item>
                </>
              ) : (
                <>
                  <View.Item>
                    <View
                      direction="column"
                      padding={[0, 0, 2, 0]}
                      className={s['finalize-po-modal__body__section__item']}
                    >
                      <Text
                        weight="medium"
                        size="100"
                        className={s['finalize-po-modal__body__form-field-label--required']}
                      >
                        {t('Auth.AutoZoneID')}
                      </Text>
                      <FormControl hasError={!!errors.autoZoneId || !isValidAutoZoneId}>
                        <TextField
                          defaultValue=""
                          inputAttributes={{
                            placeholder: t('Auth.EnterYourAutoZoneID'),
                            ...register('autoZoneId'),
                          }}
                        />
                        {errors.autoZoneId && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['finalize-po-modal__body__form-field-error-messages']}
                            >
                              {getFormNumericInputError(errors.autoZoneId.type)}
                            </FormControl.Error>
                          </View>
                        )}
                        {!errors.autoZoneId && !isValidAutoZoneId && (
                          <View direction="row" justify="space-between">
                            <View
                              className={s['finalize-po-modal__body__form-field-error-messages']}
                            >
                              <Text size="087" lineHeight="140" color="error">
                                {t('Auth.AutoZoneIDDoesNotExists')}
                              </Text>
                            </View>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                  <View.Item>
                    <View
                      direction="column"
                      className={s['finalize-po-modal__body__section__item']}
                    >
                      <Text
                        weight="medium"
                        size="100"
                        className={s['finalize-po-modal__body__form-field-label--required']}
                      >
                        {t('Auth.PinCode')}
                      </Text>
                      <FormControl hasError={!!errors.pincode || !isValidPincode}>
                        <TextField
                          defaultValue=""
                          type={pincodeFieldType}
                          inputAttributes={{
                            placeholder: t('Auth.EnterYourPinCode'),
                            ...register('pincode'),
                          }}
                          endElement={
                            <View direction="row" gap={4}>
                              <Actionable onClick={() => togglePincodeFieldType()}>
                                <Icon
                                  key={pincodeFieldType}
                                  svg={
                                    pincodeFieldType === INPUT_TYPES.PASSWORD
                                      ? PasswordShow
                                      : PasswordHidden
                                  }
                                  size={6}
                                  color="on-secondary"
                                />
                              </Actionable>
                            </View>
                          }
                        />
                        {errors.pincode && (
                          <View direction="row" justify="space-between">
                            <FormControl.Error
                              className={s['finalize-po-modal__body__form-field-error-messages']}
                            >
                              {getFormNumericInputError(errors.pincode.type)}
                            </FormControl.Error>
                          </View>
                        )}
                        {!errors.pincode && !isValidPincode && (
                          <View direction="row" justify="space-between">
                            <View
                              className={s['finalize-po-modal__body__form-field-error-messages']}
                            >
                              <Text size="087" lineHeight="140" color="error">
                                {t('Auth.PincodeIsIncorrect')}
                              </Text>
                            </View>
                          </View>
                        )}
                      </FormControl>
                    </View>
                  </View.Item>
                </>
              )}
            </View>
          </View.Item>

          <View.Item>
            <Text color="500" size="100">
              {t('PODashboard.FinalizePOModal.FooterDescription')}
            </Text>
          </View.Item>
        </View>

        <View padding={[2, 6, 6, 0]} className={s['finalize-po-modal__footer']}>
          <View
            width="100%"
            direction="row"
            justify="end"
            className={s['finalize-po-modal__footer__actions']}
          >
            <Button
              variant="secondary"
              attributes={{ style: { width: 'fit-content' } }}
              onClick={onModalClose}
              disabled={isLoading}
            >
              <Text>{t('Cancel')}</Text>
            </Button>

            <Button
              variant="primary"
              attributes={{ style: { width: 'fit-content' } }}
              type="submit"
              disabled={isButtonDisabled(watch('autoZoneId')) || isButtonDisabled(watch('pincode'))}
              loading={isLoading}
            >
              <Text> {t('PODashboard.Finalize')}</Text>
            </Button>
          </View>
        </View>
      </form>
    </Modal>
  );
};
