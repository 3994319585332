/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Divider,
  Icon,
  Link,
  Loader,
  Modal,
  SearchBar,
  Text,
  View,
  classNames,
} from '@az/starc-ui';
import { ActionDelete, Close } from '@az/starc-ui-icons';

import { Avatar } from '@shared/components/Avatar/Avatar';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions';
import {
  DEPARTMENTS,
  FIRST_NAME,
  MAX_PAGE_SIZE,
  MAX_USER_SEARCH_FIELD_LENGTH,
  NOTIFICATION_TYPES,
  USER_STATUS_CD,
} from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { UserSearchSchemaType } from '@shared/types/schema.type';

import { RecommendedAssignees } from '@inbound/components/RecommendedAssignees/RecommendedAssignees';
import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';
import { TrailerUserType } from '@inbound/types/types';
import { ASC, DEFAULT_PAGE } from '@inbound/constants/constants';

import { AssigneesSuggestion } from './AssigneesSuggestion/AssigneesSuggestion';

import * as T from './AddEditAssigneesModal.types';
import s from './AddEditAssigneesModal.module.scss';

export const AddEditAssigneesModal = ({
  isOpen,
  soureOrderNumber,
  receiptId,
  existingAssignees,
  recommendedAssignees,
  trailerArrivalData,
  onClose,
  onSubmit,
}: T.Props) => {
  /* State variables */
  const [, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [assignedUsers, setAssignedUsers] = useState<TrailerUserType[]>();
  const [matchingUsers, setMatchingUsers] = useState<UserSearchSchemaType[]>([]);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const [hideSuggestions, setHideSuggestions] = useState(false);

  const searchBar = useRef(null);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  /* Queries */
  const { usersSearchData: assigneesData } = useUsersSearch({
    searchPage: {
      page: DEFAULT_PAGE,
      size: MAX_PAGE_SIZE,
      sortBy: FIRST_NAME,
      direction: ASC,
    },
    searchCriteria: {
      deptId: DEPARTMENTS.INBOUND,
    },
  });

  const { isLoading: isLoadingUpdate, mutateUpdateTrailerArrival } = useUpdateTrailerArrival();

  /* Functions */
  const isSelected = (userId: string) => {
    if (assignedUsers) {
      return (
        assignedUsers.filter(
          (item) => item.assignedUserId === userId && item.userStatusCd !== USER_STATUS_CD.CANCEL
        ).length > 0
      );
    }

    return false;
  };

  const updateAssignedUsers = (user: TrailerUserType, status: string) => {
    let assignedUsersData = assignedUsers;

    if (assignedUsersData) {
      const i = assignedUsersData.findIndex(
        (assignee) => assignee.assignedUserId === user.assignedUserId
      );
      if (i === -1) {
        assignedUsersData?.push(user);
      } else {
        assignedUsersData[i].userStatusCd = status;
      }
    } else {
      assignedUsersData = [user];
    }

    setAssignedUsers(assignedUsersData);
  };

  const handleItemSelect = (user: TrailerUserType, isSelected: boolean) => {
    if (isSelected) {
      updateAssignedUsers(user, USER_STATUS_CD.ASSIGNED);
    } else {
      if (existingAssignees?.length === 0) {
        removeAssignee(user.assignedUserId);
      } else {
        updateAssignedUsers(user, USER_STATUS_CD.CANCEL);
      }
    }
  };

  const handleSearchSuggestions = () => {
    if (hasNoResults) {
      return <EmptySuggestions />;
    } else if (matchingUsers && matchingUsers.length > 0) {
      return (
        <AssigneesSuggestion
          data={matchingUsers}
          selectedAssignees={assignedUsers}
          recommendedAssignees={recommendedAssignees}
          onItemClick={handleItemSelect}
        />
      );
    } else {
      return <EmptySuggestions />;
    }
  };

  const onAssigneeSelect = (user: UserSearchSchemaType) => {
    setIsLoading(true);

    handleItemSelect(
      {
        assignedUserId: user.userId,
        assignedUserName: `${user.firstName.trim()} ${user.lastName.trim()}`,
        userStatusCd: USER_STATUS_CD.ASSIGNED,
      },
      true
    );

    setTimeout(() => {
      setIsLoading(false); // fake the delay to reset state
    }, 1);
  };

  const removeAssignee = (userId: string) => {
    setIsLoading(true);

    if (existingAssignees?.length === 0) {
      setAssignedUsers(assignedUsers?.filter((assignee) => assignee.assignedUserId !== userId));
    } else if (assignedUsers) {
      const assignedUsersData = assignedUsers;

      if (assignedUsersData) {
        const i = assignedUsersData.findIndex((assignee) => assignee.assignedUserId === userId);
        if (i > -1) {
          assignedUsersData[i].userStatusCd = USER_STATUS_CD.CANCEL;
        }
      }

      setAssignedUsers(assignedUsersData);
    }

    setTimeout(() => {
      setIsLoading(false); // fake the delay to reset state
    }, 1);
  };

  const handleOnClose = () => {
    setSearchValue('');
    onClose();
  };

  const handleOnSubmit = () => {
    if (assignedUsers) {
      const payload = {
        ...trailerArrivalData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalData.trailerOrders.trailerOrderKey,
            commodityTypeCd: trailerArrivalData.trailerOrders.commodityTypeCd,
            inboundOrder: {
              users: assignedUsers,
            },
          },
        ],
      };

      mutateUpdateTrailerArrival(
        { receiptId: receiptId, trailerArrivalUpdateRequest: payload },
        {
          onSuccess: () => {
            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('PODashboard.Notification.UpdateTrilerArrival.Success', {
                poNumber: soureOrderNumber,
              })
            );

            setSearchValue('');
            onSubmit();
          },
        }
      );
    } else {
      handleOnClose();
    }
  };

  /* Hooks */
  useEffect(() => {
    if (existingAssignees) {
      setAssignedUsers(structuredClone(existingAssignees));
    }
  }, [existingAssignees, isOpen]);

  useEffect(() => {
    // Filter assignees data according to search value in UI level
    if (assigneesData) {
      const assignees = assigneesData?.content;

      if (searchValue) {
        const usersToShow = assignees.filter(
          (userItem: UserSearchSchemaType) =>
            userItem.userId === searchValue ||
            userItem.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            userItem.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
            userItem.firstName
              .concat(' ', userItem.lastName.toLowerCase())
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        );

        if (usersToShow.length) {
          setMatchingUsers(usersToShow);
          setHasNoResults(false);
        } else {
          setMatchingUsers([]);
          setHasNoResults(true);
        }
      } else {
        setMatchingUsers(assignees);
        setHasNoResults(false);
      }
    }
  }, [assigneesData, searchValue]);

  // useEffect added to close suggestion after click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchBar.current && !(searchBar.current as HTMLElement).contains(event.target as Node)) {
        setHideSuggestions(true);
      } else {
        setHideSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBar]);

  return (
    <Modal
      open={isOpen}
      onClose={() => handleOnClose()}
      className={s['add-edit-assignee-modal']}
      closeByClickAway={false}
    >
      <View className={s['add-edit-assignee-modal__header']}>
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {existingAssignees?.filter((assignee) => isSelected(assignee.assignedUserId)).length ===
            0
              ? t('PODashboard.Assignees.AddAssignee')
              : t('PODashboard.Assignees.EditAssignee')}
          </Text>
        </View>
        <Button
          variant="ghost"
          onClick={handleOnClose}
          className={classNames(
            s['close-icon'],
            s['add-edit-assignee-modal__header__close-button']
          )}
        >
          <Icon svg={Close} />
        </Button>
      </View>

      <View direction="column" className={s['add-edit-assignee-modal__body']}>
        <View.Item attributes={{ ref: searchBar }}>
          <SearchBar
            value={searchValue}
            onValueChange={setSearchValue}
            suggestions={handleSearchSuggestions()}
            className={s['add-edit-assignee-modal__body__search-bar']}
            label={t('PODashboard.Assignees.SearchPlaceholder')}
            maxMenuHeight={300}
            hideSuggestions={hideSuggestions}
            inputAttributes={{ maxLength: MAX_USER_SEARCH_FIELD_LENGTH }}
          />
        </View.Item>

        <View.Item>
          <View padding={[6, 0]}>
            <Divider color="300" />
          </View>
        </View.Item>

        <View.Item>
          {isLoadingUpdate && <Loader color="primary" variant="inline" />}

          {!isLoadingUpdate &&
            assignedUsers?.filter((assignee) => isSelected(assignee.assignedUserId)).length ===
              0 && (
              <RecommendedAssignees
                assignees={recommendedAssignees}
                className={s['add-edit-assignee-recommended-assignee']}
                onButtonClick={(user) => onAssigneeSelect(user)}
              />
            )}

          {!isLoadingUpdate &&
            assignedUsers &&
            assignedUsers?.filter((assignee) => isSelected(assignee.assignedUserId)).length > 0 && (
              <View
                direction="column"
                className={s['add-edit-assignee-modal__body__assignees_items_wrapper']}
              >
                {assignedUsers
                  ?.filter((assignee) => isSelected(assignee.assignedUserId))
                  .map((user, index) => (
                    <View.Item key={t('PODashboard.ItemKey', { item: 'assignees', key: index })}>
                      <View direction="row" align="center">
                        <View.Item grow>
                          <Avatar
                            name={user.assignedUserName}
                            size="large"
                            variant="assignee"
                            showText={true}
                          />
                        </View.Item>

                        <View.Item>
                          <Link onClick={() => removeAssignee(user.assignedUserId)}>
                            <Icon svg={ActionDelete} color="error" />
                          </Link>
                        </View.Item>
                      </View>
                    </View.Item>
                  ))}
              </View>
            )}
        </View.Item>
      </View>

      <View className={s['add-edit-assignee-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={s['add-edit-assignee-modal__footer__actions']}
        >
          <Button
            variant="secondary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnClose}
            disabled={isLoadingUpdate}
          >
            <Text>{t('Cancel')}</Text>
          </Button>

          <Button
            variant="primary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnSubmit}
            loading={isLoadingUpdate}
          >
            <Text>{t('Update')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
