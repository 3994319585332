/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';

import { inboundOrderService } from '@inbound/services/inboundOrderService';

export const useGetInboundOrdersCountByTime = (isEnabled = true) => {
  const {
    data: inboundOrderDataCountByTime,
    isLoading,
    isError,
    isFetching: isFetchingCountByTime,
    refetch,
  } = useQuery({
    queryKey: ['inboundOrderCOuntByTime'],
    queryFn: () => inboundOrderService.getInboundOrderCountByTimeRange(),
    enabled: isEnabled,
  });

  return { inboundOrderDataCountByTime, isLoading, isFetchingCountByTime, isError, refetch };
};
