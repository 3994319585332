/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { View, Text, classNames } from '@az/starc-ui';

import { Avatar } from '@shared/components/Avatar/Avatar';
import { Tag } from '@shared/components/Tag/Tag';
import { EMPTY_VALUE } from '@shared/constants/constants';

import { generateDateString } from '@shared/utils/commonUtils';

import { PriorityToggle } from '@inbound/components/PriorityToggle/PriorityToggle';
import { TRAILER_ORDER_STATUS } from '@inbound/constants/constants';

import * as T from './PODetailsSection.types';
import styles from './PODetailsSection.module.scss';

export const PODetailsSection = ({
  inboundOrderData,
  trailerOrder,
  isHighPriority,
  priorityEnabled,
  onChangePriority,
}: T.PODetailsSectionProps) => {
  const { t } = useTranslation();
  const rows: T.PODetailRow[] = [
    {
      label: t('PODashboard.Item.ArrivalTime'),
      text:
        generateDateString(
          new Date(trailerOrder.trailerArrival.trailerArrivalTs),
          t('DateFormat.ShortTime')
        ) || EMPTY_VALUE,
    },
    { label: t('PODashboard.Item.Shift'), text: '1st' },
    {
      label: t('PODashboard.Item.GoalHours'),
      text: '5',
    },
    {
      label: t('PODashboard.Item.ReceivingDoor'),
      text: inboundOrderData.trailerOrder.inboundOrder.orderLocationId || EMPTY_VALUE,
    },
    {
      label: t('PODashboard.Item.Vendor'),
      text: inboundOrderData.trailerOrder.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE,
    },
    {
      label: t('PODashboard.Item.Carrier'),
      text: trailerOrder.trailerArrival.trailerCarrierName || EMPTY_VALUE,
    },
    {
      label: t('PODashboard.Item.Trailer'),
      text: trailerOrder.trailerArrival.trailerNbr || EMPTY_VALUE,
    },
    {
      label: t('PODashboard.Item.Location'),
      text:
        trailerOrder.trailerArrival.trailerLocationTypeCd &&
        trailerOrder.trailerArrival.trailerLocationId
          ? `${trailerOrder.trailerArrival.trailerLocationTypeCd} ${trailerOrder.trailerArrival.trailerLocationId}`
          : EMPTY_VALUE,
    },
    {
      label: t('PODashboard.Item.Commodity'),
      text: inboundOrderData.trailerOrder.commodityTypeCd ? '' : EMPTY_VALUE,
      children: inboundOrderData.trailerOrder.commodityTypeCd && (
        <Tag
          variant="order"
          text={inboundOrderData.trailerOrder.commodityTypeCd}
          className={styles['po-detail__master-title__sub-title__tag']}
        />
      ),
    },
    {
      label: t('PODashboard.Priority'),
      text: '',
      children: (
        <PriorityToggle
          name="priority"
          checked={isHighPriority}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangePriority(event.target.checked)
          }
          disabled={priorityEnabled}
        />
      ),
    },
    {
      label: t('PODashboard.Item.Finalization'),
      text:
        inboundOrderData.trailerOrder.inboundOrder.statusCd === TRAILER_ORDER_STATUS.FINALIZED &&
        inboundOrderData.trailerOrder.inboundOrder.finalizedTs
          ? generateDateString(
              new Date(inboundOrderData.trailerOrder.inboundOrder.finalizedTs),
              t('DateFormat.ShortTime')
            )
          : EMPTY_VALUE,
    },
    {
      label: t('PODashboard.Item.FinalizedBy'),
      text: '',
      children:
        inboundOrderData.trailerOrder.inboundOrder.statusCd === TRAILER_ORDER_STATUS.FINALIZED &&
        inboundOrderData.trailerOrder.inboundOrder.finalizedByUserName ? (
          <Avatar
            name={inboundOrderData.trailerOrder.inboundOrder.finalizedByUserName}
            size="large"
            variant="assignee"
            showText={true}
          />
        ) : (
          EMPTY_VALUE
        ),
    },
  ];
  return (
    <View direction="column" padding={[6, 0, 0, 0]} className={styles['po-details-section']}>
      {rows.map((row) => (
        <View.Item key={row.label}>
          <View
            className={styles['po-details-section__item']}
            direction="row"
            width="100%"
            key={row.label}
          >
            <View.Item grow columns={6}>
              <Text
                color="primary"
                size="087"
                weight="medium"
                className={classNames(styles['po-details-section__text'])}
              >
                {row.label}
              </Text>
            </View.Item>
            <View.Item grow columns={6}>
              {row.text && (
                <Text
                  color="primary"
                  size="087"
                  weight="regular"
                  className={styles['po-details-section__text']}
                >
                  {row.text}
                </Text>
              )}

              {row.children && row.children}
            </View.Item>
          </View>
        </View.Item>
      ))}
    </View>
  );
};
