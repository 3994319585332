/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { replaceAll } from '@inbound/utils/utils';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { EMPTY_VALUE } from '@shared/constants/constants';

type POHistoryType = {
  data: string[][];
};
const styles = StyleSheet.create({
  contentRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#d6d6d6',
    fontSize: '8px',
    padding: '5px',
  },
  headerRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    fontStyle: 'bold',
    fontSize: '10px',
    color: '#b30000',
  },

  arrivalTime: {
    width: '120px',
    textAlign: 'center',
  },
  vendorName: {
    width: '220px',
    textAlign: 'center',
  },
  po: {
    width: '90px',
    textAlign: 'center',
  },
  door: {
    width: '90px',
    textAlign: 'center',
  },
  loc: {
    width: '100px',
    textAlign: 'center',
  },
  lines: {
    width: '80px',
    textAlign: 'center',
  },
  pieces: {
    width: '80px',
    textAlign: 'center',
  },
  commodity: {
    width: '100px',
    textAlign: 'center',
  },
  potype: {
    width: '72px',
    textAlign: 'center',
  },
  fps: {
    width: '100px',
    textAlign: 'center',
  },
  status: {
    width: '217px',
    textAlign: 'center',
    padding: '2px 4px',
    borderRadius: '3px',
    letterSpacing: '1.2px',
    verticalAlign: 'sub',
  },
  notStarted: {
    border: '1px solid #6e6e6e',
    color: '#6e6e6e',
    backgroundColor: '#ffffff',
  },
  inProgress: {
    border: '1px solid #907512',
    color: '#907512',
    backgroundColor: '#fff9e0',
  },
  readyForAction: {
    border: '1px solid #014798',
    color: '#014798',
    backgroundColor: '#d6e9ff',
  },
  completed: {
    border: '1px solid #206009',
    color: '#206009',
    backgroundColor: '#e3eee0',
  },

  cancelled: {
    border: '1px solid #8f0909',
    color: '#8f0909',
    backgroundColor: '#f6e0e0',
  },
  finalizedTime: {
    width: '120px',
    textAlign: 'center',
  },
  finalizedShift: {
    width: '120px',
    textAlign: 'center',
  },
});

export const POHistoryExport = ({ data }: POHistoryType) => {
  /* Constants */
  const statusToBadgeVariant = (status: string) => {
    switch (status) {
      case 'NOT_STARTED':
      case 'NOT STARTED':
      case 'CLOSED':
      default:
        return 'notStarted';
      case 'RECEIVING_IN_PROGRESS':
      case 'RECEIVING IN PROGRESS':
      case 'IN_IC_REVIEW':
      case 'IN IC REVIEW':
        return 'inProgress';
      case 'READY_FOR_FINALIZATION':
      case 'READY FOR FINALIZATION':
        return 'readyForAction';
      case 'COMPLETED':
      case 'READY_FOR_SIGNATURE':
      case 'READY FOR SIGNATURE':
        return 'completed';
      case 'HOLD':
      case 'REMOVED':
      case 'FINALIZED':
        return 'cancelled';
    }
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={[{ backgroundColor: 'white', padding: 16 }]}>
          <View style={styles.headerRow}>
            <Text style={styles.arrivalTime}>Arrival Time</Text>
            <Text style={styles.vendorName}>Vendor</Text>
            <Text style={styles.po}>PO #</Text>
            <Text style={styles.door}>Door</Text>
            <Text style={styles.loc}>Loc</Text>
            <Text style={styles.lines}>Lines</Text>
            <Text style={styles.pieces}>Pieces</Text>
            <Text style={styles.commodity}>Commodity</Text>
            <Text style={styles.potype}>PO Type</Text>
            <Text style={styles.fps}>FPS SKUs</Text>
            <Text style={styles.fps}>Finalized Time </Text>
            <Text style={styles.fps}>Finalized by shift</Text>
            <Text style={styles.status}>Status</Text>
          </View>

          {data.slice(1).map((row) => {
            return (
              <View style={styles.contentRow}>
                <Text style={styles.arrivalTime}>{row[0]}</Text>
                <Text style={styles.vendorName}>{row[1]}</Text>
                <Text style={styles.po}>{row[2]}</Text>
                <Text style={styles.door}>{row[3]}</Text>
                <Text style={styles.loc}>{row[4]}</Text>
                <Text style={styles.lines}>{row[5]}</Text>
                <Text style={styles.pieces}>{row[6]}</Text>
                <Text style={styles.commodity}>{row[7]}</Text>
                <Text style={styles.potype}>{row[8]}</Text>
                <Text style={styles.fps}>{row[9]}</Text>
                <Text style={styles.finalizedTime}>{row[10]}</Text>
                <Text style={styles.finalizedShift}>
                  {row[11] == undefined ? EMPTY_VALUE : row[11]}
                </Text>
                <Text
                  style={{
                    ...styles[statusToBadgeVariant(row[12])],
                    ...styles.status,
                  }}
                >
                  {replaceAll(row[12], '_', ' ')}
                </Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
