/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useGetStores } from '@ofm/services/hooks/useGetStores';
import { GetStoresParams } from '@ofm/types/types';
import { useEffect, useState } from 'react';

export const useStoreSearch = (options: GetStoresParams) => {
  const [invalidId, setInvalidId] = useState<string>('');
  const [stores, setStores] = useState<string[]>([]);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const {
    storesData,
    isLoading,
    isError,
    refetch: refetchStores,
    isInitialLoading: isStoreInitialLoading,
  } = useGetStores(options, false);

  useEffect(() => {
    if (storesData?.content && storesData?.content.length > 0) {
      setStores(storesData.content.map((result) => result.storeId.toString()));
      setHasNoResults(false);
      setInvalidId('');
    } else {
      if (options.storeId) {
        setStores([]);
        setInvalidId(options.storeId);
        setHasNoResults(true);
      }
    }
    if (isError) {
      setStores([]);
      setHasNoResults(true);
      setInvalidId(options.storeId);
    }
  }, [storesData, isError]);

  useEffect(() => {
    if (options.storeId && options.storeId.trim().length > 0) {
      refetchStores();
    } else {
      setStores([]);
      setHasNoResults(options.storeId && options.storeId.length > 0 ? true : false);
      setInvalidId(options.storeId);
    }
  }, [options.storeId, options.currentPage, options.pageSize]);

  return {
    stores,
    hasNoResults,
    isLoading,
    isError,
    storesData,
    invalidId,
    isStoreInitialLoading,
  };
};
