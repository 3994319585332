/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { apiService } from '@shared/services/apiService.ts';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';
import { TopVariancesParams } from '@inventory/types/service.types';
import { DashboardVarianceSchema } from '@inventory/schemas/topVarianceSchema';
import { TOP_VARIANCE_ENDPOINTS } from '@shared/constants/routes';

export const topVarianceService = {
  getVariances: async (payload: TopVariancesParams) => {
    const response = await apiService.post(TOP_VARIANCE_ENDPOINTS.GET_VARIANCE, payload);
    return paginatedParseAndLog(DashboardVarianceSchema, response.data);
  },
};
