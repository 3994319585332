/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { waveStatus } from '../OrderTabs/data';

export const releaseToPaperOrders = [
  {
    id: '154826',
    pallets: 36,
    pcs: 16000,
    routeNo: '0321',
    dispatchTime: new Date('10/16/23 12:30:00'),
    closeByTime: new Date('10/20/23 11:30:00'),

    subzones: [
      {
        subzone: 'BAB2',
        sku: 10,
        pallets: 2,
        pieces: 400,
        status: '',
        containerId: 'C0907833253',
      },
      {
        subzone: 'CRAC',
        pallets: 4,
        sku: 15,
        pieces: 400,
        status: '',
        containerId: 'C0907833253',
      },
      {
        subzone: 'HBP1',
        pallets: 8,
        sku: 20,
        pieces: 800,
        status: '',
        containerId: 'C0907833253',
      },
      {
        subzone: 'HDP1',
        pallets: 5,
        sku: 12,
        pieces: 400,
        status: '',
        containerId: 'C0907833253',
      },
      {
        subzone: 'HFP1',
        pallets: 5,
        sku: 10,
        pieces: 800,
        status: '',
        containerId: 'C0907833253',
      },
      {
        subzone: 'AR01',
        pallets: 5,
        sku: 13,
        pieces: 800,
        status: '',
        containerId: 'C0907833253',
      },
    ],
  },
];
export const subzoneSku = [
  {
    sku: '000991131',
    part: 'TMK-2.5',
    locationId: 'AMA0101A,',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'RSRV-AMA0101A', quantity: 600 },
      { locationId: 'RSRV-AMA0101B', quantity: 600 },
      { locationId: 'RSRV-AMA0101V', quantity: 600 },
      { locationId: 'RSRV-AMA0101X', quantity: 600 },
      { locationId: 'RSRV-AMA0102Y', quantity: 600 },
    ],
  },
  {
    sku: '000991132',
    part: 'TMK-2.5',
    locationId: 'AMA0101B,',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
    ],
  },
  {
    sku: '000991133',
    part: 'TMK-2.5',
    locationId: 'AMA0101C,',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
      { locationId: 'AMA0101X', quantity: 600 },
      { locationId: 'AMA0102Y', quantity: 600 },
    ],
  },
  {
    sku: '000991134',
    part: 'TMK-2.5',
    locationId: 'AMA0101D,',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
      { locationId: 'AMA0101X', quantity: 600 },
      { locationId: 'AMA0102Y', quantity: 600 },
    ],
  },
  {
    sku: '000991135',
    part: 'TMK-2.5',
    locationId: 'AMA0101E,',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
      { locationId: 'AMA0101X', quantity: 600 },
      { locationId: 'AMA0102Y', quantity: 600 },
    ],
  },
];
export const headerItems = [
  { width: '120px', text: 'SKU' },
  { width: '100px', text: 'Part #' },
  { width: '240px', text: 'Location ID' },
  { width: '220px', text: 'Description' },
  { width: '100px', text: 'Pack' },
  { width: '100px', text: 'QOH' },
  { width: '100px', text: 'Quantity' },
];

export const closePaperSubzoneData = [
  {
    label: '',
    sku: '000991131',
    part: 'TMK-2.5',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
      { locationId: 'AMA0101X', quantity: 600 },
      { locationId: 'AMA0102Y', quantity: 600 },
    ],
  },
  {
    label: '',
    sku: '000991132',
    part: 'TMK-2.5',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
    ],
  },
  {
    label: '',
    sku: '000991133',
    part: 'TMK-2.5',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
      { locationId: 'AMA0101X', quantity: 600 },
      { locationId: 'AMA0102Y', quantity: 600 },
    ],
  },
  {
    label: '',
    sku: '000991134',
    part: 'TMK-2.5',
    description: 'POLYURETHANE WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    locations: [
      { locationId: 'AMA0101A', quantity: 600 },
      { locationId: 'AMA0101B', quantity: 600 },
      { locationId: 'AMA0101V', quantity: 600 },
      { locationId: 'AMA0101X', quantity: 600 },
      { locationId: 'AMA0102Y', quantity: 600 },
    ],
  },
];

export const closePaperOrders = [
  {
    id: '005352',
    pallets: 30,
    pcs: 2000,
    routeNo: '0321',
    dispatchTime: new Date('10/16/23 12:30:00'),
    closeByTime: new Date('10/20/23 11:30:00'),

    subzones: [
      {
        subzone: 'HBP1',
        sku: 4,
        pallets: 10,
        pieces: 400,
        status: waveStatus.replenishmentNotRun,
      },
    ],
  },
];
