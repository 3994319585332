/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Icon, View, Text, Modal, Close } from '@az/starc-ui';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../CycleCountsModal.module.scss';
import { CycleCountsModalProps } from '../CycleCountsModal.types';

export const ViewGlobalCycleCountReportModal: FC<CycleCountsModalProps> = ({
  showModal,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={showModal} onClose={onClose} closeByClickAway={true} size="medium">
      <View
        direction="row"
        justify="space-between"
        padding={6}
        className={styles['cycle-counts-modal__header']}
      >
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {t('CycleCount.ViewGlobalCycleCountReportModal.Title')}
          </Text>
        </View>
        <Button variant="ghost" onClick={onClose}>
          <Icon svg={Close} />
        </Button>
      </View>
    </Modal>
  );
};
