/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { storeService } from '../storeService';

export const useGetStoreActivity = (params: { storeId: string }, isEnabled = false) => {
  const {
    data: storeActivity,
    isLoading: activityLoading,
    isError,
    refetch: refetchStoreActivity,
  } = useQuery({
    queryKey: ['storeActivity', params.storeId],
    queryFn: () => storeService.getStoreActivityById(params.storeId),
    enabled: isEnabled,
  });

  return { storeActivity, refetchStoreActivity, activityLoading, isError };
};
