/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';

import { inboundOrderService } from '@inbound/services/inboundOrderService';
import { GetInboundOrderParams } from '@inbound/types/service.type';

export const useGetInboundOrder = (options: GetInboundOrderParams, isEnabled = true) => {
  const {
    data: inboundOrderData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['inboundOrder', JSON.stringify(options)],
    queryFn: () => inboundOrderService.getInboundOrder(options),
    enabled: isEnabled,
  });

  return { inboundOrderData, isLoading, isFetching, isError, refetch };
};
