/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';

import { AssetRequest, ErrorResponse } from '@mdm/types/service.type.ts';
import { assetService } from '@mdm/services/assetService.ts';

export const useMutateVehicle = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  let message = t('Errors.Action.Vehicle');

  const {
    mutate: mutateVehicle,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { assetKey?: string; assetRequest: AssetRequest }) => {
      if (params.assetKey) {
        message = t('Errors.Action.UpdateVehicle');
        const payload = { ...params.assetRequest };
        return assetService.updateAsset(params.assetKey, payload);
      } else {
        return assetService.createAsset(params.assetRequest);
      }
    },
    onError: (error: ErrorResponse) => {
      if (error?.message) {
        message = error.message;
      }
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateVehicle, isLoading, isError };
};
