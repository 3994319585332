/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { SearchAtomType } from '@ofm/atoms/search/searchInputAtom';

import { UserSearchSchemaType } from '@shared/types/schema.type';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { DEPARTMENTS, FIRST_NAME, SORT_ORDER } from '@shared/constants/constants';
import { MAX_SIZE, PAGE } from '@taskManagement/constants/constants';

export const useUserSearch = (searchTerm: SearchAtomType) => {
  const [invalidUser, setInvalidUser] = useState<string>('');
  const [searchData, setSearchData] = useState<UserSearchSchemaType[]>([]);
  const [matchSearchValue, setMatchSearchValue] = useState<UserSearchSchemaType[]>([]);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const [isLoadingSearchValue, setIsLoadingSearchValue] = useState<boolean>(true);

  const { usersSearchData: userSearchAPIData, isLoading: isEnabled } = useUsersSearch({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: FIRST_NAME,
      direction: SORT_ORDER.ASC,
    },
    searchCriteria: {
      deptId: DEPARTMENTS.INBOUND,
    },
  });
  // @todo : replace this while working with backend integration

  useEffect(() => {
    if (userSearchAPIData?.content && userSearchAPIData?.content.length > 0) {
      setSearchData(userSearchAPIData.content);
    }
  }, [userSearchAPIData, isEnabled]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingSearchValue(false); // fake the api delay
    }, 2500);
  }, []);

  useEffect(() => {
    switch (searchTerm) {
      case undefined:
        setMatchSearchValue([]);
        break;
      case '':
        setMatchSearchValue(searchData);
        setHasNoResults(false);
        break;
      default: {
        if (searchData.length) {
          const userToShow = searchData.filter(
            (userItem) =>
              userItem.userId.toString().includes(searchTerm) ||
              userItem.firstName.toLowerCase().includes(searchTerm.toLowerCase())
          );
          if (userToShow.length) {
            setMatchSearchValue(userToShow);
            setHasNoResults(false);
          } else {
            setInvalidUser(searchTerm);
            setMatchSearchValue([]);
            setHasNoResults(true);
          }
        }
        break;
      }
    }
  }, [searchTerm, searchData]);

  return { matchSearchValue, hasNoResults, invalidUser, isLoadingSearchValue };
};
