/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateLocationCountSyncParams } from '@inventory/types/service.types';
import { createLocationCountService } from '../createLocationCountService';
import { ErrorResponse } from 'react-router-dom';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';

// using location-counts/perform-action-sync API
export const useMutateCreateLocationCount = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const queryClient = useQueryClient();
  let message = t('Errors.Action.CreateLocationCount');

  const {
    mutate: mutateLocationCount,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { CreateLocationCountRequest: CreateLocationCountSyncParams }) => {
      const payload = params.CreateLocationCountRequest;
      return createLocationCountService.createLocationCount(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['subzoneList']);
      queryClient.invalidateQueries(['wallToWall']); //refetch the Active phase data
    },
    onError: (error: ErrorResponse) => {
      if (error?.data) {
        message = error.data;
      }
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateLocationCount, isLoading, isError };
};
