/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const ActivitySchema = z.object({
  id: z.string(),
  commentDetail: z.string(),
  commentDate: z.string(),
  commentUserId: z.string(),
  commentSourceId: z.string(),
  commentSource: z.string(),
  commentCreationTs: z.string().nullable(),
});

export const ActivityInfoSchema = z.array(ActivitySchema);

export const NewPaginatedResponseSchema = <T extends z.ZodTypeAny>(dataSchema: T) => {
  return z.object({
    content: z.array(dataSchema),
    empty: z.boolean(),
    first: z.boolean(),
    last: z.boolean(),
    number: z.number(),
    numberOfElements: z.number(),
    pageable: z.object({
      offset: z.number(),
      pageNumber: z.number(),
      pageSize: z.number(),
      paged: z.boolean(),
      sort: z.object({
        empty: z.boolean(),
        sorted: z.boolean(),
        unsorted: z.boolean(),
      }),
      unpaged: z.boolean(),
    }),
    sort: z.object({
      empty: z.boolean(),
      sorted: z.boolean(),
      unsorted: z.boolean(),
    }),
    size: z.number(),
    totalElements: z.number(),
    totalPages: z.number(),
  });
};

export const StoreSchema = z.object({
  storeId: z.string(),
  primaryDc: z.string(),
  storeType: z.string(),
  averagePieces: z.number().nullable(),
  averageLinesNumber: z.number().nullable(),
  customerNumber: z.string(),
  phoneNumber: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  country: z.string(),
  orderSchedule: z.array(
    z.object({
      day: z.string(),
      time: z.string(),
      wave: z.string(),
    })
  ),
  deliverySchedule: z.array(
    z.object({
      day: z.string(),
      time: z.string(),
      wave: z.string(),
    })
  ),
  status: z.string().nullable(),
  onHoldEndDate: z.string().nullable(),
  comment: z.array(ActivitySchema).optional(),
});
