/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import i18next, { t } from 'i18next';

import {
  Chat,
  Dropdown,
  Link,
  View,
  classNames,
  Text,
  Icon,
  TextArea,
  FormControl,
} from '@az/starc-ui';

import { asyncUserAtom } from '@shared/atoms/user/userAtom';
import { CommentCard } from '@shared/components/CommentCard/CommentCard';
import { TextAreaCounter } from '@shared/components/TextAreaCounter/TextAreaCounter';
import { MAX_TEXTAREA_LENGTH } from '@shared/constants/constants';

import { MAX_VISIBLE_COMMENTS } from '@ofm/constants/dataConstants';

import * as T from './ActivityLog.types';
import styles from './ActivityLog.module.scss';

export const ActivityLog = ({
  comments,
  handleCommentSubmit,
  placementPosition = 'bottom-end',
  className,
}: T.Props) => {
  /* Atoms */
  const [user] = useAtom(asyncUserAtom);

  /* State variables */
  const [open, setOpen] = useState<boolean>(false);
  const [commentCount, setCommentCount] = useState(MAX_VISIBLE_COMMENTS);
  const {
    watch,
    register,
    resetField,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const handleDropdownButtonClick = () => {
    setOpen(!open);
  };

  const handleCommentsCount = () => {
    if (commentCount === comments.length) {
      setCommentCount(MAX_VISIBLE_COMMENTS);
    } else {
      setCommentCount(comments.length);
    }
  };

  const handleMoreComments = () => {
    return comments.length
      ? comments.length > commentCount
        ? `${t('CommentDetails.ShowOlderActivities')} (${comments.length - commentCount})`
        : comments.length - MAX_VISIBLE_COMMENTS > 0
        ? `${t('CommentDetails.HideOlderActivities')} (${comments.length - MAX_VISIBLE_COMMENTS})`
        : ''
      : '';
  };

  return (
    <>
      <View className={styles['dropdown']}>
        <Dropdown
          open={open}
          contentGap={0}
          variant="ghost"
          width={400}
          placement={placementPosition}
          className={className}
        >
          <Dropdown.Button
            className={styles['dropdown__button']}
            onClick={handleDropdownButtonClick}
          >
            <View direction="row" gap={2}>
              <Icon svg={Chat} />
              <Text decoration="underline">Activity</Text>
              <View className={styles['badge']} justify="center">
                <Text size="087" color="secondary">
                  {comments.length}
                </Text>
              </View>
            </View>
          </Dropdown.Button>

          <Dropdown.Content className={styles['dropdown__content']}>
            <View
              attributes={{ style: { padding: '15px', overflow: 'auto', maxHeight: '438px' } }}
              gap={2}
            >
              <View padding={[3, 0]}>
                <Text weight="bold">Activity</Text>
              </View>
              <View>
                <form>
                  <FormControl hasError={!!errors.comment}>
                    <TextArea
                      id="comment"
                      variant="floating"
                      value={watch('comment')}
                      label={t('CommentDetails.NewComment')}
                      inputAttributes={{
                        ...register('comment'),
                        maxLength: MAX_TEXTAREA_LENGTH,

                        onKeyDown: (event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            const comment = watch('comment');
                            if (comment.trim() !== '') {
                              // TODO: implement the user's username once Auth is completed
                              handleCommentSubmit?.({
                                comment: comment,
                                username: `${user?.name} ${user?.lastName}`,
                                timestamp: new Date(Date.now()),
                                locale: i18next.language,
                              });
                              resetField('comment');
                            }
                          }
                        },
                      }}
                    />
                    <View direction="row" justify={errors.comment ? 'space-between' : 'end'}>
                      <TextAreaCounter
                        count={watch('comment')?.length}
                        maxCount={MAX_TEXTAREA_LENGTH}
                      />
                    </View>
                  </FormControl>
                </form>
              </View>
              <View direction="column" className={classNames(styles['root__comment-details'])}>
                {comments.slice(0, commentCount).map((comment, key) => {
                  return (
                    <CommentCard
                      key={'comment' + key}
                      comment={comment.comment}
                      username={comment.username}
                      timestamp={comment.timestamp}
                    />
                  );
                })}
              </View>
              <View gap={5} direction="column">
                <Link
                  variant="underline"
                  className={classNames(styles['root__link-details'])}
                  onClick={() => handleCommentsCount()}
                  preventHoverOpacityChange={false}
                >
                  {handleMoreComments()}
                </Link>
              </View>
            </View>
          </Dropdown.Content>
        </Dropdown>
      </View>
    </>
  );
};
