/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { PRODUCT_SEARCH_ENDPOINTS, WAREHOUSE_PRODUCT_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@shared/services/apiService.ts';
import { WarehouseProductSchema } from '@shared/schemas/warehouseProductSchema.ts';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';
import { GetProductParams } from '@shared/types/service.type.ts';
import { WarehouseRequestType } from '@mdm/types/service.type.ts';
import { RESPONSE_TYPE } from '@shared/constants/constants.ts';
import { LogError } from '@ofm/classes/LogError.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';
import { downloadFile } from '@shared/utils/commonUtils.ts';

export const productService = {
  getWarehouseProducts: async (options: GetProductParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        ...(options?.productIdType && { productIdTypes: [options.productIdType] }),
        ...(options?.products && options.products.length > 0 && { products: options.products }),
        ...(options?.entityAssociations &&
          options.entityAssociations.length > 0 && {
            entityAssociations: options.entityAssociations,
          }),
      },
    };
    const response = await apiService.post(
      PRODUCT_SEARCH_ENDPOINTS.GET_WAREHOUSE_PRODUCT_SEARCH_DATA,
      payload
    );
    return paginatedParseAndLog(WarehouseProductSchema, response.data);
  },
  updateWarehouseProduct: async (productId: string, warehouseRequestType: WarehouseRequestType) => {
    const payload = { ...warehouseRequestType };
    const resp = await apiService.put(
      WAREHOUSE_PRODUCT_ENDPOINTS.UPDATE_PRODUCT(productId),
      payload
    );
    return resp.data;
  },
  downloadWarehouseProduct: async (options: GetProductParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        ...(options?.productIdType && { productIdTypes: [options.productIdType] }),
        ...(options?.products && options.products.length > 0 && { products: options.products }),
        ...(options?.entityAssociations &&
          options.entityAssociations.length > 0 && {
            entityAssociations: options.entityAssociations,
          }),
      },
    };

    try {
      const response = await apiService.post(
        PRODUCT_SEARCH_ENDPOINTS.GET_WAREHOUSE_PRODUCT_DOWNLOAD,
        payload,
        {
          responseType: RESPONSE_TYPE,
          headers: {
            Accept: options.acceptType,
          },
        }
      );

      downloadFile(
        response.data,
        'products.csv',
        response.headers['content-type'],
        response.headers['content-disposition']
      );

      return response.data;
    } catch (error) {
      throw new LogError(formatErrorLog(ErrorLogType.AXIOS, error));
    }
  },
};
