/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  View,
  Text,
  Select,
  Button,
  SelectOptionProps,
  Divider,
  Checkmark,
  Icon,
  ActionViewAll,
  Skeleton,
  Actionable,
} from '@az/starc-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { searchAtom } from '@ofm/atoms/search/searchInputAtom';
import { locationManager } from '@ofm/services/locationManager';
import { TaskManagerCard } from '@taskManagement/components/TaskManagerCard/TaskManagerCard';
import {
  SHIFT_LIST,
  PROGRESS_LIMIT,
  TRANSACTION_TIME_MIN,
  PAGE,
  MAX_SIZE,
  USER_STATUS,
  Default_PO_Types,
  REFETCH_INTERVAL_1_MIN,
  NOT_ASSIGNED,
  OTHER_DEPT,
  INBOUND_TASK_SORT_BY_OPTIONS,
} from '@taskManagement/constants/constants';
import {
  DEPARTMENTS,
  ENTITY_ASSOCIATIONS,
  FIRST_NAME,
  INBOUND,
  NOTIFICATION_TYPES,
  REFETCH_INTERVAL,
  SORT_BY,
  SORT_ORDER,
  TASK_ASSIGNMENT_STATUS,
  TASK_ATTRIBUTES,
  USER_DEPT_STATUS,
  TASK_STATUS,
} from '@shared/constants/constants';
import { useUsersSearch } from '@shared/services/hooks/useUsersSearch';
import { UserSearchSchemaType } from '@shared/types/schema.type';
import { AssignTaskDrawer } from '@taskManagement/components/AssignTaskDrawer/AssignTaskDrawer';
import { UserSearch } from '@taskManagement/components/UserSearch/UserSearch';
import { useUserSearch } from '@taskManagement/services/useUserSearch';
import { SEARCH_PROPERTIES } from '@taskManagement/constants/dataConstants';
import { useGetTaskSearchData } from '@shared/services/hooks/useGetTaskSearchData';
import {
  FilterShiftUserDataProps,
  taskAssignmentsObj,
  userAssignmentType,
} from '@taskManagement/components/TaskManagerCard/TaskManagerCard.types';
import styles from './TaskManager.module.scss';
import { ShiftGoalModal } from '@taskManagement/components/Modals/ShiftGoalModal/ShiftGoalModal';
import { useNavigate } from 'react-router-dom';
import { WidgetWithChildren } from '@taskManagement/components/WidgetWithChildren/WidgetWithChildren';
import { PAGE_URLS } from '@shared/constants/routes';
import { SingleValue } from 'react-select';
import { InboundShiftGoalOptions, InboundShiftGoalPayloadType } from '@taskManagement/types/types';
import { generateDateString } from '@ofm/utils/utils';
import { useMutateInboundShiftGoal } from '@taskManagement/services/hooks/useMutateInboundShiftGoal';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useShiftSearch } from '@shared/services/hooks/useShiftsSearch';
import { padZerotoTime } from '@taskManagement/utils/utils';
import { MoveToAnotherDeptModal } from '@taskManagement/components/MoveToAnotherDeptModal/MoveToAnotherDeptModal';
import { capitalizeFirstLetter } from '@shared/utils/commonUtils';
import { PieChart } from '@shared/components/Chart/PieChart';
import { Stat } from '@shared/components/Stat/Stat';
import { ResponsiveBar } from '@nivo/bar';
import { useGetInboundOrdersCountByTime } from '@inbound/services/hooks/useGetInboundOrdersCountByTime';
import { useRetriveDashboards } from '@inbound/services/hooks/useRetriveDashboards';
import { POCountBYTime } from '@inbound/types/types';
import { TRAILER_ORDER_STATUS } from '@inbound/constants/constants';
import { statusToChartColor } from '@inbound/utils/utils';

type BarGraphProps = {
  graphData: POCountBYTime | undefined;
};

const BarGraph = ({ graphData }: BarGraphProps) => {
  const { t } = useTranslation();

  const [poData, setPoData] = useState([
    {
      field: 'MORE_THAN_72_HOURS',
      label: t(`POsNotReceived.72HR`),
      count: 0,
    },
    {
      field: 'BETWEEN_48_TO_72_HOURS',
      label: t(`POsNotReceived.48HR`),
      count: 0,
    },
    {
      field: 'BETWEEN_24_TO_48_HOURS',
      label: t(`POsNotReceived.24HR`),
      count: 0,
    },
    {
      field: 'IN_LAST_24_HOURS',
      label: t(`POsNotReceived.0HR`),
      count: 0,
    },
  ]);

  /* Data for PO graph */
  useEffect(() => {
    if (graphData) {
      for (const property in graphData) {
        const objIndex = poData.findIndex((obj) => obj.field === property);
        if (objIndex !== -1) {
          const objToUpdate = poData[objIndex];
          objToUpdate.count = Number(graphData[property as keyof typeof graphData]);
          const newPOData = [...poData];
          newPOData[objIndex] = objToUpdate;
          setPoData(newPOData);
        }
      }
    }
  }, [graphData]);

  return (
    <div style={{ minWidth: 0, height: '410px' }}>
      <ResponsiveBar
        data={poData}
        keys={['count']}
        indexBy="label"
        margin={{ top: 20, right: 20, bottom: 25, left: 25 }}
        padding={0.4}
        valueScale={{ type: 'linear' }}
        colors="#3182CE"
        animate={true}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        enableTotals={true}
      />
    </div>
  );
};

const sortByFirstNameAsc = (a: FilterShiftUserDataProps, b: FilterShiftUserDataProps) =>
  a?.userAPIData.firstName.localeCompare(b?.userAPIData.firstName);
const sortByFirstNameDesc = (a: FilterShiftUserDataProps, b: FilterShiftUserDataProps) =>
  b?.userAPIData.firstName.localeCompare(a?.userAPIData.firstName);
const sortByTaskTypeCd = (a: FilterShiftUserDataProps, b: FilterShiftUserDataProps) =>
  (a.taskTypeCd ?? '').localeCompare(b.taskTypeCd ?? '');

export const TaskManager = () => {
  /* Atoms */
  const [searchValue, setSearchValue] = useAtom(searchAtom);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();
  const [IsAssignTaskList, setIsAssignTaskList] = useState(false);
  const [UserSearchData, setUserSearchData] = useState<UserSearchSchemaType>();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [openAssignTaskDrawer, setOpenAssignTaskDrawer] = useState(false);
  const [openActionMenu, setOpenActionMenu] = useState<boolean | null>();
  const [userId, setUserId] = useState<string>();
  const [assignTaskUser, setAssignTaskUser] = useState<FilterShiftUserDataProps[]>([]);
  const [isShiftGoalsModalOpen, setIsShiftGoalsModalOpen] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState<string>(SHIFT_LIST[0].value);
  const [selectedSortById, setSelectedSortById] = useState<string>();
  const [shiftUserData, setShiftUserData] = useState<UserSearchSchemaType[]>([]);
  const [finalSwimlanesData, setFinalSwimlanesData] = useState<FilterShiftUserDataProps[]>([]);
  const [isShiftGoal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [lastUpdatedInMin, setLastUpdatedInMin] = useState<string | number>('--');
  const [searchUserStatus, setSearchUserStatus] = useState<string>();
  const [openMoveToAnotherDeptModal, setOpenMoveToAnotherDeptModal] = useState(true);
  const [moveToUserDetails, setMoveToUserDetails] = useState<UserSearchSchemaType>();
  const [poStatusChartSum, setPOstatusChartSum] = useState<number>(0);
  const [isMoveUserBackToDept, setIsMoveUserBackToDept] = useState(false);
  const [POStatusChartData, setPOStatusChartData] = useState([
    {
      id: 'Not Received',
      label: t('POStatusPieChart.NotReceived'),
      value: '0',
      color: statusToChartColor(TRAILER_ORDER_STATUS.NOT_STARTED),
    },
    {
      id: 'Receiving In Progress',
      label: t('POStatusPieChart.ReceivingInProgress'),
      value: '0',
      color: statusToChartColor(TRAILER_ORDER_STATUS.RECEIVING_IN_PROGRESS),
    },
    {
      id: 'Ready for Finalization',
      label: t('POStatusPieChart.ReadyForFinalization'),
      value: '0',
      color: statusToChartColor(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION),
    },
  ]);
  const onClickMoveUserToAnotherDept = (userId: string) => {
    setOpenActionMenu(false);
    setOpenMoveToAnotherDeptModal(true);
    setMoveToUserDetails(userSearchAPIData?.content.filter((user) => user.userId === userId)[0]);
    setIsMoveUserBackToDept(false);
  };
  const onClickMoveUserBackToDept = (userId: string) => {
    setOpenActionMenu(false);
    setOpenMoveToAnotherDeptModal(true);
    setMoveToUserDetails(userSearchAPIData?.content.filter((user) => user.userId === userId)[0]);
    setIsMoveUserBackToDept(true);
  };
  const onShowMoveBackUserDeptNotification = (
    selectedDept: string,
    firstName: string,
    lastName: string
  ) => {
    setOpenMoveToAnotherDeptModal(false);
    handleNotification(
      NOTIFICATION_TYPES.SUCCESS,
      t(`MoveUserToAnotherDept.OnSuccess`, {
        userName: `${firstName} ${lastName}`,
        dept: `${capitalizeFirstLetter(selectedDept)}`,
      })
    );
    taskSearchRefetch();
  };

  const onClickAssignTask = (userId: string) => {
    setOpenActionMenu(false);
    setOpenAssignTaskDrawer(true);
    setIsAssignTaskList(true);
    setUserId(userId);
    setAssignTaskUser(finalSwimlanesData.filter((user) => user.userId == userId));
  };
  const [currentTime] = useState<string>(
    padZerotoTime(new Date().getHours().toString()) +
      ':' +
      padZerotoTime(new Date().getMinutes().toString())
  );
  const onMoveToAnotherDeptClose = () => {
    setOpenMoveToAnotherDeptModal(false);
  };

  /* Queries */
  const { matchSearchValue, isLoadingSearchValue } = useUserSearch(searchValue);
  /* While displaying task assignee on drawer screen in task card required below API call.*/
  const { usersSearchData: allUserSearchAPIData } = useUsersSearch({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: FIRST_NAME,
      direction: SORT_ORDER.ASC,
    },
    searchCriteria: {},
  });

  const {
    usersSearchData: userSearchAPIData,
    isLoading: isEnabled,
    refetch: userSearchRefetch,
    isFetching: usersFetching,
    isError: usersError,
  } = useUsersSearch({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: FIRST_NAME,
      direction: SORT_ORDER.ASC,
    },
    searchCriteria: {
      deptId: DEPARTMENTS.INBOUND,
    },
  });

  const {
    taskSearchData,
    isFetching: isLoading,
    refetch: taskSearchRefetch,
    isFetching: tasksFetching,
    isError: tasksError,
  } = useGetTaskSearchData({
    searchPage: {
      page: PAGE,
      size: MAX_SIZE,
      sortBy: SORT_BY.PRIORITY,
      direction: SORT_ORDER.ASC,
    },
    searchCriteria: {
      departmentId: DEPARTMENTS.INBOUND,
      taskStatusCd: [TASK_STATUS.ASSIGNED, TASK_STATUS.INPROGRESS, TASK_STATUS.INACTIVE],
      entityAssociations: [
        ENTITY_ASSOCIATIONS.ATTRIBUTES,
        ENTITY_ASSOCIATIONS.ASSIGNMENTS,
        ENTITY_ASSOCIATIONS.ACTIVITIES,
      ],
    },
  });

  const { isLoading: isSetShiftGoalLoading, mutateSetShiftGoal } = useMutateInboundShiftGoal();

  const { shiftsSearchData, isFetching: isShiftFetching } = useShiftSearch({
    shiftDate: generateDateString(new Date(), 'YYYY-MM-DD'),
    shiftTime: currentTime,
  });

  const { inboundOrderDataCountByTime, isFetchingCountByTime } = useGetInboundOrdersCountByTime();

  const { retriveDashboardsData } = useRetriveDashboards({
    reportType: 'countByPOStatus',
    orderTypeCds: ['DSD', 'PO', 'LTL', 'LTD'],
    statusCds: [
      TRAILER_ORDER_STATUS.NOT_STARTED,
      TRAILER_ORDER_STATUS.RECEIVING_IN_PROGRESS,
      TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION,
      TRAILER_ORDER_STATUS.IN_IC_REVIEW,
      TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE,
    ],
  });
  /* Functions */

  const onDrawerClose = () => {
    setOpenAssignTaskDrawer(false);
    setOpenActionMenu(null);
  };
  const onSearchDrawerClose = () => {
    setOpenUserDrawer(false);
  };

  const onClickViewShiftGoals = () => {
    setIsShiftGoalsModalOpen(true);
  };

  const onShiftGoalModalClose = () => {
    setIsShiftGoalsModalOpen(false);
  };

  const handleItemSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSearchItemClick = (item: UserSearchSchemaType | string) => {
    if (typeof item === 'string') {
      locationManager.setQueryParameter(SEARCH_PROPERTIES.USER.queryParam, item);
    } else {
      finalSwimlanesData.map((user) => {
        if (user.userId === item.userId) {
          setSearchUserStatus(user.userStatus);
        }
      });
      setUserSearchData(item);
      setAssignTaskUser(finalSwimlanesData.filter((user) => user.userId == item.userId));
      setIsAssignTaskList(false);
      setOpenUserDrawer(true);
    }
  };

  const NotActiveDataCount = finalSwimlanesData?.filter(
    (userItem) =>
      userItem.userStatus == USER_STATUS.NOT_ACTIVE && userItem.shiftId === selectedShiftId
  ).length;

  const activeTaskCount = finalSwimlanesData?.filter(
    (userItem) => userItem.userStatus == USER_STATUS.ACTIVE && userItem.shiftId === selectedShiftId
  ).length;

  const almostDoneCount = finalSwimlanesData?.filter(
    (userItem) =>
      userItem.userStatus == USER_STATUS.ALMOST_DONE && userItem.shiftId === selectedShiftId
  ).length;

  const handleShiftChange = (option: SingleValue<SelectOptionProps>) => {
    if (option) {
      setSelectedShiftId(option.value);
    }
  };

  const sortedData = useMemo(() => {
    switch (selectedSortById) {
      case INBOUND_TASK_SORT_BY_OPTIONS[0].value:
        finalSwimlanesData.sort(sortByFirstNameAsc);
        break;
      case INBOUND_TASK_SORT_BY_OPTIONS[1].value:
        finalSwimlanesData.sort(sortByFirstNameDesc);
        break;
      case INBOUND_TASK_SORT_BY_OPTIONS[2].value:
        finalSwimlanesData.sort(sortByTaskTypeCd);
        break;
    }

    return finalSwimlanesData;
  }, [selectedSortById, finalSwimlanesData]);

  const handleSortByChange = (option: SingleValue<SelectOptionProps>) => {
    if (option) {
      setSelectedSortById(option.value);
      setFinalSwimlanesData(sortedData);
    }
  };

  const handleShiftGoalSubmit = (data: InboundShiftGoalOptions) => {
    const payload: InboundShiftGoalPayloadType = [
      {
        shiftId: (shiftsSearchData && shiftsSearchData[0].shiftId) || '',
        shiftIndex: (shiftsSearchData && shiftsSearchData[0].shiftIndex) || 0,
        date: generateDateString(new Date(), 'DD-MM-YYYY'),
        activity: 'PO_RECEIVING',
        metrics: 'PO',
        goalDefined: Number(data.regularPO),
      },
      {
        shiftId: (shiftsSearchData && shiftsSearchData[0].shiftId) || '',
        shiftIndex: (shiftsSearchData && shiftsSearchData[0].shiftIndex) || 0,
        date: generateDateString(new Date(), 'DD-MM-YYYY'),
        activity: 'PO_RECEIVING',
        metrics: 'LTD',
        goalDefined: Number(data.LTD),
      },
      {
        shiftId: (shiftsSearchData && shiftsSearchData[0].shiftId) || '',
        shiftIndex: (shiftsSearchData && shiftsSearchData[0].shiftIndex) || 0,
        date: generateDateString(new Date(), 'DD-MM-YYYY'),
        activity: 'PO_RECEIVING',
        metrics: 'DSD',
        goalDefined: Number(data.DSD),
      },
    ];
    mutateSetShiftGoal(payload, {
      onSuccess: () => {
        setIsShiftGoalsModalOpen(false);
        handleNotification(NOTIFICATION_TYPES.SUCCESS, t('ShiftGoalHistory.Success'));
      },
      onError: () => {
        handleNotification(NOTIFICATION_TYPES.ERROR, t('ShiftGoalHistory.Error'));
      },
    });
  };

  /* Start Swimlanes final Logic  */
  useEffect(() => {
    const filterSwimlanesData: FilterShiftUserDataProps[] = [];
    if (selectedShiftId && taskSearchData) {
      shiftUserData.map((userSearchData) => {
        /* If user is working with other dept set userStatus => NotActive */
        /* Step - 1 */
        const filterDept = userSearchData.facilities[0]?.departments?.filter(
          (department) => department.deptId === INBOUND
        );
        const FilterOtherDept = userSearchData?.facilities[0]?.departments?.filter(
          (department) =>
            department.statusCd === USER_DEPT_STATUS.ACTIVE && department.deptId !== INBOUND
        );

        if (
          FilterOtherDept &&
          FilterOtherDept.length > 0 &&
          FilterOtherDept[0].deptId !== INBOUND &&
          FilterOtherDept[0].primaryFl === false &&
          FilterOtherDept[0].statusCd === USER_STATUS.ACTIVE &&
          userSearchData.userId &&
          taskSearchData
        ) {
          filterSwimlanesData.push({
            userId: userSearchData.userId,
            deptId: FilterOtherDept[0].deptId,
            shiftId: userSearchData.facilities[0].shifts?.[0]?.shiftId || '',
            userStatus: USER_STATUS.NOT_ACTIVE,
            taskAssignments: null,
            taskCompletedPoints: null,
            taskTotalPoints: null,
            totalTaskPercentage: 0,
            assignmentStatus: OTHER_DEPT,
            lastActivityHourMin: null,
            userAPIData: userSearchData,
          });
        } else if (
          filterDept &&
          filterDept.length > 0 &&
          filterDept[0].deptId === INBOUND &&
          filterDept[0].primaryFl === true &&
          filterDept[0]?.statusCd === USER_STATUS.ACTIVE &&
          userSearchData?.userId &&
          taskSearchData
        ) {
          const currentTime = new Date();

          /* If user is working with other dept set userStatus => NotActive */
          taskSearchData.content.map((taskSearchItem) => {
            taskSearchItem?.assignments
              ?.filter((item) => item.userId === userSearchData.userId)
              .map((taskAssignmentItem) => {
                if (
                  taskAssignmentItem.status !== TASK_ASSIGNMENT_STATUS.COMPLETED &&
                  taskAssignmentItem.status !== TASK_ASSIGNMENT_STATUS.REJECTED
                ) {
                  let lastActivityTs = new Date();
                  if (taskAssignmentItem?.activities) {
                    // Sort activities based on endTs or startTs and get the latest one
                    const latestActivity = taskAssignmentItem?.activities?.sort((a, b) => {
                      const aTime = new Date(a.endTs ?? a.startTs ?? 0).getTime();
                      const bTime = new Date(b.endTs ?? b.startTs ?? 0).getTime();
                      return bTime - aTime; // Descending order
                    })[0];

                    // Determine the last modified timestamp
                    if ((latestActivity && latestActivity.endTs) || latestActivity?.startTs) {
                      lastActivityTs = new Date(
                        latestActivity.endTs ?? latestActivity.startTs ?? 0
                      );
                    }
                  }

                  const lastActivityTime = new Date(lastActivityTs);
                  const timeDifference = currentTime.getTime() - lastActivityTime.getTime();
                  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                  const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
                  const totalMinutes = hoursDifference * 60 + minutesDifference;
                  const transactionHourMinutes = `${hoursDifference}:${minutesDifference}`;

                  /* Step 2 - Merging Data */
                  const userStatus = USER_STATUS.NOT_ACTIVE;
                  let totalTaskPercentage = 0;

                  //Check the latest activity < 10 and task completed points after calculate percentage and
                  // if should be less 80 then it goes in Active else Almost done bucket.

                  if (taskSearchItem.completedPoints && taskSearchItem.totalPoints) {
                    totalTaskPercentage =
                      (taskSearchItem.completedPoints / taskSearchItem.totalPoints) * 100;
                  }

                  const taskAssignments: taskAssignmentsObj = {
                    taskNo: taskSearchItem?.taskNo,
                    lastActivityMin: timeDifference !== 0 && totalMinutes === 0 ? 1 : totalMinutes,
                    latestActivityLog: totalMinutes ?? lastActivityTime,
                    attributes: taskSearchItem?.attributes,
                    completedPoints: taskAssignmentItem.completedPoints ?? 0,
                    priority: taskSearchItem.priority,
                    executionPriority: taskAssignmentItem.executionPriority || 0,
                  };

                  filterSwimlanesData.push({
                    userId: userSearchData.userId,
                    deptId: filterDept[0].deptId,
                    shiftId: userSearchData.facilities[0].shifts?.[0]?.shiftId || '',
                    userStatus: userStatus,
                    taskAssignments: taskAssignments,
                    taskCompletedPoints: taskSearchItem.completedPoints || null,
                    taskTotalPoints: taskSearchItem.totalPoints,
                    totalTaskPercentage: totalTaskPercentage,
                    assignmentStatus: taskAssignmentItem.status,
                    lastActivityHourMin: transactionHourMinutes,
                    userAPIData: userSearchData,
                    taskTypeCd: taskSearchItem.taskTypeCd,
                  });
                }
              }); /* end assignment map  */
          }); /* end if  */
        }

        /* Step 3,4,5 - Finding Tasks Assignments and groupByUsers assignments => filterSwimlanesData */
        const groupByUserId = (array: FilterShiftUserDataProps[]) => {
          return array.reduce(
            (
              result: { [userId: string]: FilterShiftUserDataProps[] },
              currentItem: FilterShiftUserDataProps
            ) => {
              const userId = currentItem.userId;
              if (!result[userId]) {
                result[userId] = [];
              }
              result[userId].push(currentItem);
              return result;
            },
            {}
          );
        };

        /* GroupedBy User-wise Assignments */
        const FinalGroupedByData = groupByUserId(filterSwimlanesData);

        const FinalFilterData: FilterShiftUserDataProps[] = [];
        /* Filter latest Activity and highest completed points from all tasks */
        for (const key in FinalGroupedByData) {
          const groupByUserAssignment: userAssignmentType[] = [];

          for (const item of FinalGroupedByData[key]) {
            if (
              item.taskAssignments &&
              item.taskAssignments.lastActivityMin &&
              item.taskAssignments.lastActivityMin >= 0
            ) {
              const lastActivityMin =
                item.taskAssignments.lastActivityMin !== undefined
                  ? item.taskAssignments.lastActivityMin
                  : Infinity;
              const completedPoints =
                item.taskCompletedPoints !== undefined ? item.taskCompletedPoints : Infinity;
              groupByUserAssignment.push({ lastActivityMin, completedPoints, ...item });
            }
          }
          /* Sort the assignments using latest LasActivity and CompletedPoints  */
          groupByUserAssignment.sort((a, b) => {
            if (a.lastActivityMin && b.lastActivityMin) {
              if (a.lastActivityMin !== b.lastActivityMin) {
                return a.lastActivityMin - b.lastActivityMin;
              }
            }
            return (b.completedPoints ?? 0) - (a.completedPoints ?? 0);
          });

          /* step 6 - checking each user assignments with last activity and completed points < 10  */
          if (groupByUserAssignment.length > 0) {
            const completedPointsAlmostDone: number[] = [];
            const completedPointsActive: number[] = [];
            const lastActivityMins: number[] = [];

            const allTaskCount = groupByUserAssignment.length;
            let anchorAssignmentStatus = USER_STATUS.NOT_ACTIVE;

            //Single task user flow
            if (groupByUserAssignment.length == 1) {
              groupByUserAssignment.map((item) => {
                if (
                  item.lastActivityMin &&
                  item.totalTaskPercentage &&
                  item.lastActivityMin <= TRANSACTION_TIME_MIN
                ) {
                  if (item.totalTaskPercentage < PROGRESS_LIMIT) {
                    anchorAssignmentStatus = USER_STATUS.ACTIVE;
                  } else if (item.totalTaskPercentage > PROGRESS_LIMIT) {
                    anchorAssignmentStatus = USER_STATUS.ALMOST_DONE;
                  }
                }
                groupByUserAssignment[0].userStatus = anchorAssignmentStatus;
              });
            } else {
              // multiple group of tasks check this part
              groupByUserAssignment.map((item) => {
                if (item.lastActivityMin && item.totalTaskPercentage) {
                  if (item.totalTaskPercentage < PROGRESS_LIMIT) {
                    completedPointsActive.push(item.totalTaskPercentage);
                  } else if (item.totalTaskPercentage > PROGRESS_LIMIT) {
                    completedPointsAlmostDone.push(item.totalTaskPercentage);
                  }

                  if (item.lastActivityMin <= TRANSACTION_TIME_MIN) {
                    lastActivityMins.push(item.lastActivityMin);
                  }
                }
              });

              if (lastActivityMins.length > 0) {
                if (completedPointsActive.length > 0) {
                  anchorAssignmentStatus = USER_STATUS.ACTIVE;
                } else if (
                  completedPointsAlmostDone.length > 0 &&
                  allTaskCount === completedPointsAlmostDone.length
                ) {
                  anchorAssignmentStatus = USER_STATUS.ALMOST_DONE;
                }
              } else {
                anchorAssignmentStatus = USER_STATUS.NOT_ACTIVE;
              }

              groupByUserAssignment[0].userStatus = anchorAssignmentStatus;
            }
            FinalFilterData.push(groupByUserAssignment[0]);
          } else {
            FinalFilterData.push(FinalGroupedByData[key][0]);
          }
        }
        const assignedUserIds: string[] = FinalFilterData?.map((item) => item.userId);
        /* checking Not Assigned Tasks or assignments and show into Not active bucket */
        shiftUserData?.forEach((userSearchItem) => {
          if (!assignedUserIds?.includes(userSearchItem?.userId)) {
            const notAssignedUsersObj = {
              userId: userSearchItem.userId,
              deptId: (filterDept && filterDept[0].deptId) || null,
              shiftId: userSearchItem.facilities[0].shifts?.[0]?.shiftId || '',
              userStatus: USER_STATUS.NOT_ACTIVE,
              assignmentStatus: NOT_ASSIGNED,
              taskAssignments: null,
              taskCompletedPoints: null,
              taskTotalPoints: null,
              totalTaskPercentage: null,
              lastActivityHourMin: null,
              userAPIData: userSearchItem,
            };
            FinalFilterData.push(notAssignedUsersObj);
          }
        });
        setFinalSwimlanesData(FinalFilterData);
      });
    }
  }, [shiftUserData, taskSearchData, isEnabled, isLoading, selectedShiftId, lastUpdated]);
  /*  End Swimlanes final Logic */

  /* Filter User's depends on the selected ShiftId */
  useEffect(() => {
    if (userSearchAPIData?.content) {
      const filteredUsers = userSearchAPIData?.content.filter(
        (userSearchItem: UserSearchSchemaType) =>
          userSearchItem.facilities.some((facility) =>
            facility?.shifts?.some((shift) => shift.shiftId === selectedShiftId)
          )
      );
      setShiftUserData(filteredUsers);
    }
  }, [selectedShiftId, userSearchAPIData?.content]);

  /* Refetch and calculate last updated time in mins */
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!isShiftGoalsModalOpen) {
      intervalId = setInterval(() => {
        const currentTime = new Date();
        const timeDifferenceMs = currentTime.getTime() - lastUpdated.getTime();
        const timeDifferenceMinutes = Math.floor(timeDifferenceMs / (1000 * 60));
        setLastUpdatedInMin(timeDifferenceMinutes);
        if (timeDifferenceMs >= REFETCH_INTERVAL) {
          taskSearchRefetch();
          userSearchRefetch();
        }
      }, REFETCH_INTERVAL_1_MIN);
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval when component unmounts
    };
  }, [lastUpdated, taskSearchRefetch, userSearchRefetch, isShiftGoalsModalOpen]);

  /* Reset last updated time */
  useEffect(() => {
    if (!tasksFetching && !usersFetching && !usersError && !tasksError) {
      setLastUpdated(new Date());
      setLastUpdatedInMin(0);
    }
  }, [tasksError, tasksFetching, usersError, usersFetching]);

  /* Set PO status chart Data */
  useEffect(() => {
    if (retriveDashboardsData) {
      const notReceived = Number(retriveDashboardsData.NOT_STARTED ?? '0');
      const receivingInProgress = Number(retriveDashboardsData.RECEIVING_IN_PROGRESS ?? '0');
      const readyForFinalization = Number(retriveDashboardsData.READY_FOR_FINALIZATION ?? '0');
      const inIcReview = Number(retriveDashboardsData.IN_IC_REVIEW ?? '0');
      const readyForSignature = Number(retriveDashboardsData.READY_FOR_SIGNATURE ?? '0');
      const totalPOs =
        notReceived + receivingInProgress + readyForFinalization + inIcReview + readyForSignature;

      setPOstatusChartSum(totalPOs);

      setPOStatusChartData((data) => [
        {
          ...data[0],
          value: totalPOs ? ((notReceived / totalPOs) * 100).toFixed(1) : '0',
          color: statusToChartColor(TRAILER_ORDER_STATUS.NOT_STARTED),
        },
        {
          ...data[1],
          value: totalPOs ? ((receivingInProgress / totalPOs) * 100).toFixed(1) : '0',
          color: statusToChartColor(TRAILER_ORDER_STATUS.RECEIVING_IN_PROGRESS),
        },
        {
          ...data[2],
          value: totalPOs
            ? (((readyForFinalization + inIcReview + readyForSignature) / totalPOs) * 100).toFixed(
                1
              )
            : '0',
          color: statusToChartColor(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION),
        },
      ]);
    }
  }, [retriveDashboardsData]);

  return (
    <>
      {UserSearchData && searchUserStatus && (
        <AssignTaskDrawer
          userId={UserSearchData.userId}
          onDrawerClose={onSearchDrawerClose}
          firstName={UserSearchData.firstName}
          deptId={assignTaskUser[0].deptId ?? ''}
          OpenDrawer={openUserDrawer}
          IsAssignTaskList={IsAssignTaskList}
          lastName={UserSearchData.lastName}
          usersData={allUserSearchAPIData?.content}
          userDetailsData={UserSearchData}
          userStatus={searchUserStatus}
          onClickMoveUserToAnotherDept={onClickMoveUserToAnotherDept}
          onClickMoveUserBackToDept={onClickMoveUserBackToDept}
        />
      )}

      {shiftsSearchData && shiftsSearchData.length && (
        <ShiftGoalModal
          isOpen={isShiftGoalsModalOpen}
          onClose={onShiftGoalModalClose}
          submitInProgress={isSetShiftGoalLoading}
          onSave={(data) => handleShiftGoalSubmit(data)}
          currentShift={shiftsSearchData}
          usersCount={userSearchAPIData?.content.length || 0}
        />
      )}

      <View direction="row">
        <MasterTitle
          title={t(`Sidenav.InboundTaskManager`)}
          subtitle={
            isNaN(Number(lastUpdatedInMin))
              ? t(`InventoryTaskManager.Subtitle_others`)
              : t(`InventoryTaskManager.Subtitle`, { count: Number(lastUpdatedInMin) })
          }
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          titleActionProps={{ label: 'Favorite', handleClick: () => {} }}
        >
          <View direction="row" justify="end">
            <View.Item columns={4}>
              <UserSearch
                options={matchSearchValue}
                isSearchLoading={isLoadingSearchValue}
                label={t('InboundTaskManager.Search.Placeholder')}
                onItemSearch={handleItemSearch}
                onUserItemClick={handleSearchItemClick}
              />
            </View.Item>
          </View>
        </MasterTitle>
      </View>
      <View
        gap={6}
        direction="row"
        backgroundColor="secondary"
        padding={[6, 6]}
        attributes={{ style: { margin: '0px' } }}
      >
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle={t(`ShiftProgress.Title`)}
              widgetSubtitle={t(`ShiftProgress.Subtitle`, {
                currentShift: '2nd',
                AutoZonerCount: 10,
              })}
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                // Add your logic to handle the click event of the icon button
              }}
            />
            <WidgetWithChildren.Body className={styles['shift-progress-body']}>
              {isShiftFetching ? (
                <View gap={4}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </View>
              ) : shiftsSearchData && shiftsSearchData.length ? (
                <View padding={[4, 4]} className={styles['shift-widget']}>
                  <View direction="row" padding={[0, 0, 2, 0]}>
                    <Text size="100" variant="subtitle-bold">
                      {t(`ShiftProgress.NewShiftStarted`)}
                    </Text>
                  </View>
                  <View direction="row" align="center" justify="space-between" gap={2}>
                    <View.Item grow>
                      <Text size="087" color="500" className={styles['widget_subtext']}>
                        {t(`ShiftProgress.ReceivedText`)}&nbsp;
                        <u>
                          {t(`ShiftProgress.ReceivedPoCountTotal`, {
                            count: 10,
                            total: 100,
                          })}
                        </u>
                        &nbsp;
                        {t(`ShiftProgress.LastShiftText`)}
                        {t(`ShiftProgress.PendingPO`, {
                          PendingPO: 10,
                        })}
                        {t(`ShiftProgress.PoGoalText`)}
                      </Text>
                    </View.Item>
                    <View.Item>
                      <Button
                        size="small"
                        onClick={onClickViewShiftGoals}
                        disabled={isShiftFetching}
                      >
                        {t(`ShiftProgress.SetShiftGoalBtn`)}
                      </Button>
                    </View.Item>
                  </View>

                  <Divider color="300" className={styles['shift-widget__divider']} />

                  <View padding={[4, 0]} direction="row" align="center" justify="space-between">
                    <View direction="row" align="baseline" gap={1}>
                      <View>
                        <Text variant="display-3">{0}</Text>
                      </View>
                      <View>
                        <Text variant="subtitle-bold">{t(`ShiftProgress.Pos`)}</Text>
                      </View>
                    </View>
                    {!isShiftGoal && (
                      <View align="center" className={styles['shift-widget__no_shift_goal']}>
                        <Text
                          size="087"
                          variant="subtitle-regular"
                          className={styles['widget_no_shift']}
                        >
                          {t(`ShiftProgress.NoShiftGoal`)}
                        </Text>
                      </View>
                    )}
                  </View>

                  <View direction="row" align="center" width="100%" padding={[2, 0]}>
                    <Text
                      size="075"
                      variant="subtitle-bold"
                      textCase="uppercase"
                      className={styles['widget_breakdown_text']}
                    >
                      {t(`ShiftProgress.PoTypeBreakDown`)}
                    </Text>
                  </View>
                  {Default_PO_Types?.map((poTypes, index) => (
                    <View>
                      <View
                        padding={[2, 0]}
                        direction="row"
                        align="center"
                        justify="space-between"
                        width="100%"
                      >
                        <View gap={0} direction="row" align="center">
                          <Text>
                            <Icon svg={Checkmark} />
                          </Text>

                          <Text
                            size="087"
                            variant="subtitle-regular"
                            className={styles['widget_regular_po']}
                          >
                            {t(poTypes?.label)}
                          </Text>
                        </View>
                        <View>
                          <Text size="087" variant="subtitle-regular">
                            {poTypes?.value}
                          </Text>
                        </View>
                      </View>
                      {index < Default_PO_Types.length - 1 && (
                        <View>
                          <Divider color="500" />
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              ) : (
                <View gap={2} align="center" justify="center">
                  <Text>Current shift details unavailable</Text>
                </View>
              )}
            </WidgetWithChildren.Body>
          </WidgetWithChildren>
        </View.Item>
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle={t(`POsNotReceived.Title`)}
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                navigate(PAGE_URLS.PO_DASHBOARD);
              }}
            />
            <WidgetWithChildren.Body className={styles['pocount-widget-body']}>
              {!isFetchingCountByTime ? (
                <View gap={4}>
                  <View>
                    <Stat
                      title={t(`POsNotReceived.Subtitle`)}
                      primaryText={(inboundOrderDataCountByTime
                        ? Object.values(inboundOrderDataCountByTime).reduce(
                            (a, b) => Number(a) + Number(b),
                            0
                          )
                        : 0
                      ).toString()}
                      width="full-width"
                    />
                  </View>
                  <View>
                    <BarGraph graphData={inboundOrderDataCountByTime} />
                  </View>
                </View>
              ) : (
                <View gap={2}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </View>
              )}
            </WidgetWithChildren.Body>
          </WidgetWithChildren>
        </View.Item>
        <View.Item grow>
          <WidgetWithChildren>
            <WidgetWithChildren.Header
              widgetTitle={t('POStatusPieChart.Title')}
              endIcon={<Icon svg={ActionViewAll} />}
              iconButtonHandler={() => {
                navigate(PAGE_URLS.PO_DASHBOARD);
              }}
            />
            <WidgetWithChildren.Body>
              <View direction="row" align="center" justify="space-between" width="100%" padding={6}>
                <View.Item>
                  <View width="120px" height="120px" align="center">
                    <PieChart widgetData={POStatusChartData} variant="donut" />
                  </View>
                </View.Item>
                <View.Item grow>
                  <View direction="column" width="100%" className={styles['donut-chart__legend']}>
                    <View
                      direction="row"
                      align="center"
                      width="100%"
                      className={styles['donut-chart__legand__item']}
                    >
                      <View.Item grow>
                        <Text variant="main-body" size="100" weight="bold">
                          {t('POStatusPieChart.Subtitle')}
                        </Text>
                      </View.Item>
                      <View.Item>
                        <Text variant="main-body" size="100" weight="bold">
                          {poStatusChartSum}
                        </Text>
                      </View.Item>
                    </View>

                    {POStatusChartData.map((widgetDataItem) => {
                      return (
                        <View
                          direction="column"
                          className={styles['donut-chart__legend__item-wrapper']}
                        >
                          <Divider color="300" />
                          <View
                            direction="row"
                            align="center"
                            width="100%"
                            className={styles['donut-chart__legend__item']}
                          >
                            <View.Item>
                              <View
                                width="16px"
                                height="16px"
                                attributes={{
                                  style: { backgroundColor: widgetDataItem.color },
                                }}
                              />
                            </View.Item>
                            <View.Item grow>
                              <Text variant="main-body">{widgetDataItem.label}</Text>
                            </View.Item>
                            <View.Item>
                              <Text variant="main-body" weight="bold">
                                {widgetDataItem.value}%
                              </Text>
                            </View.Item>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View.Item>
              </View>
              <View align="center">
                <Button variant="secondary" onClick={() => navigate(PAGE_URLS.PO_DASHBOARD)}>
                  {t('POStatusPieChart.PreviewAllPurchaseOrder')}
                </Button>
              </View>
            </WidgetWithChildren.Body>
          </WidgetWithChildren>
        </View.Item>
      </View>
      <View
        direction="row"
        padding={[4, 8]}
        justify="space-between"
        backgroundColor="secondary"
        align="center"
      >
        <View direction="row" align="center" gap={4}>
          <Button variant="secondary" size="large">
            Today
          </Button>
        </View>
        <View direction="row" align="center" justify="end" gap={4}>
          <Select
            label="sortBy"
            name="sortBy"
            variant="no-label"
            minWidth="200px"
            placeholder={t('InboundTaskManager.SortBy')}
            options={INBOUND_TASK_SORT_BY_OPTIONS.map((sortByOption) => ({
              label: t(sortByOption.label),
              value: sortByOption.value,
            }))}
            onValueChange={(value) => handleSortByChange(value)}
          />
          <Select
            label="Shift"
            name="ShiftList"
            variant="no-label"
            placeholder={t('InboundTaskManager.SelectShift')}
            options={SHIFT_LIST}
            defaultValue={SHIFT_LIST[0]}
            className={styles['task-manager_shift']}
            onValueChange={(value) => handleShiftChange(value)}
          />
        </View>
      </View>
      <View gap={4} direction="row" backgroundColor="secondary" padding={[0, 6]}>
        <View.Item columns={{ s: 12, m: 12, l: 6, xl: 4 }}>
          <View gap={2} direction="row" padding={[4, 0]} align="center" backgroundColor="secondary">
            <View
              align="center"
              justify="center"
              backgroundColor="primary"
              padding={[0.5, 2]}
              borderRadius="round"
              width="var(--st-unit-7)"
              height="var(--st-unit-7)"
            >
              <Text size="087" color="secondary">
                {NotActiveDataCount}
              </Text>
            </View>
            <View.Item>
              <Text weight="bold" size="125">
                {t(`InboundTaskManager.UserStatus.NotActive`)}
              </Text>
            </View.Item>
          </View>
          <View
            direction="row"
            gap={2}
            padding={[4, 4]}
            className={styles['task-manager_list-view']}
          >
            {finalSwimlanesData
              .filter(
                (item) =>
                  item.userStatus === USER_STATUS.NOT_ACTIVE && item.shiftId === selectedShiftId
              )
              .map((users) => (
                <View width="100%">
                  <Actionable
                    fullWidth={true}
                    className={styles['task-manager__UserCard']}
                    onClick={() => handleSearchItemClick(users.userAPIData)}
                  >
                    <TaskManagerCard
                      userId={users.userId}
                      firstName={users.userAPIData?.firstName}
                      lastName={users.userAPIData?.lastName}
                      taskStatus={users.userStatus}
                      lastTransactionTs={users?.taskAssignments?.lastActivityMin}
                      assignmentStatus={users.assignmentStatus}
                      clockedInFl={
                        users.userAPIData?.clockedInFl === null
                          ? true
                          : users.userAPIData?.clockedInFl
                      }
                      tempDeptId={users.deptId}
                      onClickMoveUserToAnotherDept={onClickMoveUserToAnotherDept}
                      onClickMoveUserBackToDept={onClickMoveUserBackToDept}
                      onClickAssignTask={onClickAssignTask}
                      setOpenActionMenu={openActionMenu}
                    />
                  </Actionable>
                </View>
              ))}
          </View>
        </View.Item>

        <View.Item columns={{ s: 12, m: 12, l: 6, xl: 4 }}>
          <View gap={2} direction="row" padding={[4, 0]} align="center" backgroundColor="secondary">
            <View
              align="center"
              justify="center"
              backgroundColor="primary"
              padding={[0.5, 2]}
              borderRadius="round"
              width="var(--st-unit-7)"
              height="var(--st-unit-7)"
            >
              <Text size="087" color="secondary">
                {activeTaskCount}
              </Text>
            </View>
            <View.Item>
              <Text weight="bold" size="125">
                {t(`InboundTaskManager.UserStatus.Active`)}
              </Text>
            </View.Item>
          </View>

          <View
            direction="row"
            gap={2}
            padding={[4, 4]}
            className={styles['task-manager_active-view']}
          >
            {finalSwimlanesData
              .filter(
                (item) => item.userStatus === USER_STATUS.ACTIVE && item.shiftId === selectedShiftId
              )
              .map((users) => (
                <View width="100%">
                  <Actionable
                    fullWidth={true}
                    className={styles['task-manager__UserCard']}
                    onClick={() => handleSearchItemClick(users.userAPIData)}
                  >
                    <TaskManagerCard
                      userId={users.userId}
                      firstName={users.userAPIData?.firstName}
                      lastName={users.userAPIData?.lastName}
                      vendorName={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.VENDOR_NAME
                        )[0]?.value || ''
                      }
                      taskStatus={users.userStatus}
                      clockedInFl={
                        users.userAPIData?.clockedInFl === null
                          ? true
                          : users.userAPIData?.clockedInFl
                      }
                      poNumber={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.SOURCE_ORDER_NBR
                        )[0]?.value || ''
                      }
                      subZone={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.SUB_ZONE
                        )[0]?.value || ''
                      }
                      orderTypeCd={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.ORDER_TYPE_CD
                        )[0]?.value || ''
                      }
                      commodityTypeCd={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.COMMODITY_TYPE_CD
                        )[0]?.value || ''
                      }
                      totalReceivedQty={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.TOTAL_RECEIVED_QTY
                        )[0]?.value || ''
                      }
                      totalOrderedQty={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.TOTAL_ORDERED_QTY
                        )[0]?.value || ''
                      }
                      priority={users.taskAssignments?.priority}
                      executionPriority={users.taskAssignments?.executionPriority}
                      completedPoints={users.taskCompletedPoints}
                      totalPoints={users.taskTotalPoints}
                      totalTaskPercentage={users.totalTaskPercentage}
                      onClickAssignTask={onClickAssignTask}
                      setOpenActionMenu={openActionMenu}
                    />
                  </Actionable>
                </View>
              ))}
          </View>
        </View.Item>

        <View.Item columns={{ s: 12, m: 12, l: 6, xl: 4 }}>
          <View gap={2} direction="row" padding={[4, 0]} align="center" backgroundColor="secondary">
            <View
              align="center"
              justify="center"
              backgroundColor="primary"
              padding={[0.5, 2]}
              borderRadius="round"
              width="var(--st-unit-7)"
              height="var(--st-unit-7)"
            >
              <Text size="087" color="secondary">
                {almostDoneCount}
              </Text>
            </View>
            <View.Item>
              <Text weight="bold" size="125">
                {t(`InboundTaskManager.UserStatus.AlmostDone`)}
              </Text>
            </View.Item>
          </View>

          <View
            direction="row"
            gap={2}
            padding={[4, 4]}
            className={styles['task-manager_almost-view']}
          >
            {finalSwimlanesData
              .filter(
                (item) =>
                  item.userStatus === USER_STATUS.ALMOST_DONE && item.shiftId === selectedShiftId
              )
              .map((users) => (
                <View width="100%">
                  <Actionable
                    fullWidth={true}
                    className={styles['task-manager__UserCard']}
                    onClick={() => handleSearchItemClick(users.userAPIData)}
                  >
                    <TaskManagerCard
                      userId={users.userId}
                      firstName={users.userAPIData.firstName}
                      lastName={users.userAPIData.lastName}
                      vendorName={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.VENDOR_NAME
                        )[0]?.value || ''
                      }
                      taskStatus={users.userStatus}
                      clockedInFl={
                        users.userAPIData?.clockedInFl === null
                          ? true
                          : users.userAPIData?.clockedInFl
                      }
                      poNumber={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.SOURCE_ORDER_NBR
                        )[0]?.value || ''
                      }
                      totalReceivedQty={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.TOTAL_RECEIVED_QTY
                        )[0]?.value || ''
                      }
                      totalOrderedQty={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.TOTAL_ORDERED_QTY
                        )[0]?.value || ''
                      }
                      subZone={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.SUB_ZONE
                        )[0]?.value || ''
                      }
                      orderTypeCd={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.ORDER_TYPE_CD
                        )[0]?.value || ''
                      }
                      commodityTypeCd={
                        users.taskAssignments?.attributes?.filter(
                          (item) => item.key == TASK_ATTRIBUTES.COMMODITY_TYPE_CD
                        )[0]?.value || ''
                      }
                      priority={users.taskAssignments?.priority}
                      completedPoints={users.taskCompletedPoints}
                      totalPoints={users.taskTotalPoints}
                      totalTaskPercentage={users.totalTaskPercentage}
                      onClickAssignTask={onClickAssignTask}
                      setOpenActionMenu={openActionMenu}
                    />
                  </Actionable>
                </View>
              ))}
          </View>
        </View.Item>
        {/* Assign task to the User and open side Drawer for select Recommended, PO tasks */}
        {userId && assignTaskUser && (
          <AssignTaskDrawer
            OpenDrawer={openAssignTaskDrawer}
            userId={assignTaskUser[0].userId}
            firstName={assignTaskUser[0].userAPIData.firstName}
            lastName={assignTaskUser[0].userAPIData.lastName}
            deptId={assignTaskUser[0].deptId ?? ''}
            shiftId={assignTaskUser[0]?.shiftId}
            onDrawerClose={onDrawerClose}
            IsAssignTaskList={IsAssignTaskList}
            userStatus={assignTaskUser[0].userStatus}
            usersData={allUserSearchAPIData?.content}
            userDetailsData={UserSearchData}
          />
        )}
      </View>

      {moveToUserDetails && (
        <MoveToAnotherDeptModal
          open={openMoveToAnotherDeptModal}
          isMoveUserBackToDept={isMoveUserBackToDept}
          onClose={onMoveToAnotherDeptClose}
          userId={moveToUserDetails.userId}
          userAPIData={moveToUserDetails}
          firstName={moveToUserDetails.firstName}
          lastName={moveToUserDetails.lastName}
          deptId={moveToUserDetails?.facilities[0]?.departments?.[0]?.deptId}
          onDrawerClose={onSearchDrawerClose}
          onSuccess={(selectedDept) =>
            onShowMoveBackUserDeptNotification(
              selectedDept || '',
              moveToUserDetails.firstName,
              moveToUserDetails.lastName
            )
          }
        />
      )}
    </>
  );
};
