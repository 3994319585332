/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { layoutService } from '@mdm/services/layoutService.ts';

export const useDeleteConsolidationLocation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { handleNotification } = useNotificationHandler();

  const {
    mutate: mutateDeleteLocation,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { layoutKey: string; invalidateCache?: boolean }) =>
      layoutService.deleteLayout(params.layoutKey),
    onSuccess: async (_response, params) => {
      const { invalidateCache = true } = params;

      if (invalidateCache) {
        await queryClient.invalidateQueries(['consolidationLocations']);
      }

      handleNotification(
        NOTIFICATION_TYPES.SUCCESS,
        t('Success.Action.ConsolidationLocation.Deleted')
      );
    },
    onError: () => {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.Action.DeleteConsolidationLocation'));
    },
  });

  return { mutateDeleteLocation, isLoading, isError };
};
