/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Text, classNames, Link } from '@az/starc-ui';

import { t } from 'i18next';

import { cell, DisplayOptionsType } from './MatrixAccordion.types';
import { stateTextColor } from './data';

import styles from './MatrixAccordion.module.scss';
import { MatrixCellStatus } from '@outbound/constants/constants';
import { useAtom } from 'jotai';
import { closingPaperAtom } from '@outbound/atoms/releaseToPaper/releaseToPaperAtom';

type CellProps = {
  cell: cell;
  displayOptions?: DisplayOptionsType;
};

export const Cell = ({ cell, displayOptions }: CellProps) => {
  /*Atoms*/
  const [, setClosingPaper] = useAtom(closingPaperAtom);

  const onPaperClose = () => {
    setClosingPaper(true);
  };

  return (
    <>
      <View
        align="center"
        padding={2}
        attributes={{ 'data-testid': 'matrix-cell' }}
        className={classNames(styles[`cell`], styles[`cell--${cell.state}`])}
      >
        {cell.state !== MatrixCellStatus.EMPTY && (
          <>
            <Text
              size="087"
              weight="medium"
              className={styles[`line-height22`]}
              color={stateTextColor[cell.state]}
            >
              {cell?.count}
            </Text>
            <Text
              size="075"
              weight="medium"
              className={styles[`line-height20`]}
              color={stateTextColor[cell.state]}
            >
              {cell?.assigned && t('OutboundMatrix.Assigned', { count: cell.assigned })}
            </Text>
            {displayOptions?.vehicleCode && (
              <Text
                size="075"
                weight="medium"
                className={styles[`line-height20`]}
                color={stateTextColor[cell.state]}
              >
                {cell?.vehicle}
              </Text>
            )}
            {displayOptions?.orderSelector && (
              <Text
                size="075"
                weight="medium"
                className={styles[`line-height20`]}
                color={stateTextColor[cell.state]}
              >
                {cell?.selector}
              </Text>
            )}
          </>
        )}
        <>
          {(cell.state === MatrixCellStatus.CLOSE || cell.state === MatrixCellStatus.PAPER) && (
            <Text size="075">{t('OutboundMatrix.ReleaseToPaper.Paper')}</Text>
          )}
          {cell.state === MatrixCellStatus.CLOSE && (
            <Link
              onClick={() => {
                onPaperClose();
              }}
            >
              <Text size="075" color="500">
                {t('OutboundMatrix.ReleaseToPaper.Close')}
              </Text>
            </Link>
          )}
        </>
      </View>
    </>
  );
};
