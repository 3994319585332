/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

const addressSchema = z.object({
  addressTypeCode: z.string(),
  address1: z.string(),
  address2: z.string(),
  postalCode: z.string(),
  state: z.string(),
  city: z.string(),
  countryCode: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  timezoneCode: z.string(),
  phoneNbr: z.string(),
  faxNbr: z.string(),
});

const vendorSchema = z.object({
  vendorId: z.string(),
  name: z.string(),
  statusCode: z.string(),
  effectiveDate: z.string(),
  expiryDate: z.string(),
  backdoorVendordSubAcctId: z.string(),
  dunsNbr: z.string(),
  ediVendorAccntNbr: z.string(),
  addresses: z.array(addressSchema),
});

export const VendorListSchema = z.array(vendorSchema);
