/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';
import { vehicleService } from '@mdm/services/vehicleService.ts';

export const useGetVehicleById = (params: { assetCd: string }, isEnabled = true) => {
  const {
    data: vehicleData,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['vehicle', params],
    queryFn: () => vehicleService.getVehicleById(params.assetCd),
    enabled: isEnabled,
    cacheTime: 0,
  });

  return { vehicleData, isLoading, isFetching, isError };
};
