/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { USER_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@shared/services/apiService.ts';
import { parseAndLog } from '@shared/utils/service/serviceUtils';
import { ShiftsSearchSchema } from '@shared/schemas/ShiftsSearchSchema';
import { shiftSearchParams } from '@shared/types/service.type';

export const shiftService = {
  shiftsSearch: async (options?: shiftSearchParams) => {
    const payload = {
      ...(options?.shiftDate && { shiftDate: options.shiftDate }),
      ...(options?.shiftTime && { shiftTime: options.shiftTime }),
      ...(options?.shiftId && { shiftId: options.shiftId }),
    };

    const response = await apiService.post(USER_ENDPOINTS.SHIFTS_SEARCH, payload);

    return parseAndLog(ShiftsSearchSchema, response.data);
  },
};
