/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button, FormControl, Icon, Modal, Text, TextArea, View, classNames } from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import { TextAreaCounter } from '@shared/components/TextAreaCounter/TextAreaCounter';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';

import { formatErrorLog, getFormInputError, isButtonDisabled } from '@ofm/utils/utils';
import { endCountReasonType } from '@inventory/types/types';
import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';

import { useEndCount } from '@inventory/services/hooks/useEndCount';

import {
  CYCLE_COUNT_STATUS_CD,
  MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND,
} from '@inventory/constants/constants';
import { ReasonSchema } from '@inventory/schemas/sendToInbountModalSchema';

import * as T from './EndCountModal.types';
import s from './EndCountModal.module.scss';
import { checkCountTypeCd } from '@inventory/utils/utils';

export const EndCountModal = ({
  isOpen,
  onClose,
  progressValue,
  countTypeCdValue,
  taskId,
  year,
  phase,
}: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();
  const countTypeCdConstValue = checkCountTypeCd(countTypeCdValue);

  const {
    reset,
    watch,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<endCountReasonType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(ReasonSchema),
  });

  /* Queries */
  const { isLoading: endCountLoading, mutateEndCount } = useEndCount();

  /* Functions */
  const onCloseModal = () => {
    reset();
    clearErrors();
    onClose();
  };

  const onSubmit = (data: endCountReasonType) => {
    const endCountResult = ReasonSchema.safeParse(data);

    if (!endCountResult.success) {
      throw new LogError(formatErrorLog(ErrorLogType.ZOD, endCountResult.error));
    } else {
      const currentTimestamp = new Date().toISOString();

      mutateEndCount(
        {
          countRequest: {
            taskId: taskId,
            countTypeCd: countTypeCdValue,
            statusCd: CYCLE_COUNT_STATUS_CD.COMPLETE,
            endReason: watch('reason'),
            endTs: currentTimestamp,
          },
        },
        {
          onSuccess: () => {
            onCloseModal();
            navigate(PAGE_URLS.CYCLE_COUNTS);
            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              t('CycleCount.EndCountModal.successNotification', {
                countTypeCd: `${countTypeCdConstValue}`,
                year: `${year}`,
                phase: `${phase}`,
              })
            );
          },
        }
      );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onCloseModal()}
      className={s['end-count-modal']}
      closeByClickAway={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <View direction="column" className={s['end-count-modal__form-wrapper']}>
          <View className={s['end-count-modal__header']}>
            <View direction="column">
              <Text as="h2" size="175" weight="bold" color="primary">
                {t('CycleCount.EndCountModal.Confirmation')}
              </Text>
            </View>
            <Button
              variant="ghost"
              onClick={onCloseModal}
              className={classNames(s['close-icon'], s['end-count-modal__header__close-button'])}
            >
              <Icon svg={Close} />
            </Button>
          </View>

          <View direction="column" className={s['end-count-modal__body']}>
            <View.Item>
              <View direction="column" className={s['end-count-modal__body__section']}>
                <View.Item>
                  <View direction="column" className={s['end-count-modal__body__section__item']}>
                    <Text weight="medium" size="087">
                      {t('CycleCount.EndCountModal.NotesforEndCount', {
                        percentage: progressValue,
                      })}
                    </Text>
                    <Text weight="bold" size="087">
                      {t('CycleCount.EndCountModal.ReasonForEndingCount')}
                    </Text>
                    <FormControl hasError={!!errors.reason}>
                      <TextArea
                        resize="vertical"
                        id="remove-reason"
                        label=""
                        defaultValue=""
                        inputAttributes={{
                          ...register('reason'),
                          maxLength: MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND,
                          placeholder: t('CycleCount.EndCountModal.NotesPlaceholder'),
                        }}
                      />

                      <View direction="row" justify={errors.reason ? 'space-between' : 'end'}>
                        {errors.reason && (
                          <FormControl.Error
                            className={s['end-count-modal__body__form-field-error-messages']}
                          >
                            {getFormInputError(
                              errors.reason?.type,
                              MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND
                            )}
                          </FormControl.Error>
                        )}

                        <TextAreaCounter
                          count={watch('reason')?.length}
                          maxCount={MAX_TEXTAREA_LENGTH_NOTES_FOR_INBOUND}
                        />
                      </View>
                    </FormControl>
                  </View>
                </View.Item>
              </View>
            </View.Item>
          </View>

          <View className={s['end-count-modal__footer']}>
            <View
              width="100%"
              direction="row"
              justify="end"
              className={s['end-count-modal__footer__actions']}
            >
              <Button
                variant="secondary"
                attributes={{ style: { width: 'fit-content' } }}
                disabled={endCountLoading}
                onClick={onCloseModal}
              >
                <Text>{t('Cancel')}</Text>
              </Button>

              <Button
                variant="primary"
                attributes={{ style: { width: 'fit-content' } }}
                type="submit"
                loading={endCountLoading}
                disabled={isButtonDisabled(watch('reason'))}
              >
                {t('CycleCount.EndCountModal.EndButtonLabel')}
              </Button>
            </View>
          </View>
        </View>
      </form>
    </Modal>
  );
};
