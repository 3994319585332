/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export type UserShiftRowDataType = {
  shiftId: string;
  shiftLabel: string;
  userId: string;
  MON: boolean;
  TUE: boolean;
  WED: boolean;
  THU: boolean;
  FRI: boolean;
  SAT: boolean;
  SUN: boolean;
};

export enum ShiftType {
  Weekday = 'Weekday',
  Weekend = 'Weekend',
}
