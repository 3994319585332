/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Modal,
  View,
  Text,
  Actionable,
  Icon,
  Close,
  Tabs,
  Select,
  SearchBar,
  Button,
  Divider,
  ChevronDown,
  Tag,
  Add,
} from '@az/starc-ui';
import * as T from './LocationSearchModal.types.ts';
import styles from './LocationSearchModal.module.scss';
import { t } from 'i18next';
import { Tabs as ModalTabs } from '@mdm/components/Tabs/Tabs.tsx';
import { General } from './General.tsx';
import { LocationHistory } from './LocationHistory.tsx';

export const LocationSearchModal = ({ isOpen, onClose, location }: T.LocationSearchModalProps) => {
  /* State variables */
  const tabs = [
    {
      value: t('LocationSearchModal.Tabs.General'),
      name: t('LocationSearchModal.Tabs.General'),
      content: <General locationDetails={location} />,
    },
    {
      value: t('LocationSearchModal.Tabs.LocationHistory'),
      name: t('LocationSearchModal.Tabs.LocationHistory'),
      content: <LocationHistory />,
    },
  ];
  return (
    <Modal
      open={isOpen}
      size="medium"
      onClose={onClose}
      closeByClickAway={false}
      className={styles['modal']}
    >
      <View className={styles['modal__close-wrapper']}>
        <Actionable onClick={() => onClose()}>
          <Icon svg={Close} size={6} color="on-primary" />
        </Actionable>
      </View>
      <View className={styles['modal__search-tabs-top']}>
        <Tabs className={styles['search-tabs']}>
          <Tabs.Item
            value={t('LocationSearchModal.Warehouse')}
            content={
              <View gap={0} direction="row" align="center" className={styles['search-tab']}>
                <View direction="column" align="start" className={styles['search-info']}>
                  <Actionable onClick={() => undefined}>
                    {' '}
                    <View.Item>
                      <Tag attributes={{ style: { marginLeft: '0' } }}>
                        {t('LocationSearchModal.Warehouse')}
                      </Tag>
                    </View.Item>
                    <View.Item>
                      <Text weight="medium" className={styles['tab-label']}>
                        {location.layoutName}
                      </Text>
                    </View.Item>
                  </Actionable>
                </View>

                <View className={styles['close-tab']}>
                  <Actionable onClick={() => undefined}>
                    {' '}
                    <Icon
                      svg={Close}
                      size={5}
                      color="on-secondary"
                      className={styles['close-tab-icon']}
                    />
                  </Actionable>
                </View>
              </View>
            }
          >
            <View direction="column" gap={0}>
              <View
                padding={[4, 6]}
                direction="row"
                align="center"
                className={styles['search-bar']}
              >
                <View
                  align="center"
                  justify="center"
                  direction="row"
                  className={styles['search-input-variant']}
                >
                  <Select
                    variant="no-label"
                    className={styles['select']}
                    options={[]}
                    label=""
                    placeholder="Warehouse"
                    name="category"
                    size="small"
                  />

                  <Divider className={styles['divider']} vertical />

                  <Select
                    className={styles['select']}
                    label=""
                    variant="no-label"
                    name="type"
                    placeholder="Location"
                    size="small"
                    options={[]}
                  />

                  <Divider className={styles['divider']} vertical />

                  <View.Item grow>
                    <SearchBar
                      className={styles['search-bar-search-input']}
                      onValueChange={() => undefined}
                      suggestions={[]}
                      label={location.layoutName ?? ''}
                    />
                  </View.Item>
                </View>
                <View direction="row" gap={2} align="center">
                  <Text textCase="uppercase" weight="bold">
                    {t('LocationSearchModal.AdvancedSearch')}
                  </Text>
                  <View>
                    <Icon svg={ChevronDown} />
                  </View>
                </View>

                <View>
                  <Button variant="primary">{t('LocationSearchModal.Search')}</Button>
                </View>
              </View>
              <View className={styles['modal__body']}>
                <View
                  direction="column"
                  attributes={{ style: { width: '100%', minWidth: 'fit-content' } }}
                >
                  <View className={styles['modal__search-modal-tabs']}>
                    <ModalTabs tabs={tabs} />
                  </View>
                </View>
              </View>
            </View>
          </Tabs.Item>

          <Tabs.Item
            value="newSearch"
            content={
              <View className={styles['search-tab']}>
                <Actionable>
                  <View direction="row" className={styles['new-search-tab']}>
                    <Icon
                      size={5}
                      svg={Add}
                      color="on-primary"
                      className={styles['add-tab-icon']}
                    />
                    <Text decoration="underline" color="on-primary" className={styles['tab-label']}>
                      {t('LocationSearchModal.NewSearch')}
                    </Text>
                  </View>
                </Actionable>
              </View>
            }
          >
            <View className={styles['modal__body']} />
          </Tabs.Item>
        </Tabs>
      </View>
    </Modal>
  );
};
