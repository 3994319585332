/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { View } from '@az/starc-ui';
import { DownloadProvider } from '@shared/hooks/useDownload';

import { CycleCountDetailsHeader } from './CycleCountDetailsComponents/CycleCountDetailsHeader/CycleCountDetailsHeader';
import { CycleCountDetailsTabs } from './CycleCountDetailsComponents/CycleCountDetailsTabs/CycleCountDetailsTabs';

export const CycleCountDetails = () => {
  const [progressValue, setProgressValue] = useState(0);
  const paramData = useParams();
  const countTypeCdValue = paramData.countTypeCd || '';
  const taskId = paramData.id || '';

  return (
    <DownloadProvider>
      <View direction="column" height="100%">
        <CycleCountDetailsHeader
          progressValue={progressValue}
          countTypeCdValue={countTypeCdValue}
          taskId={taskId}
        />
        <CycleCountDetailsTabs countTypeCdValue={countTypeCdValue ?? ''} taskId={taskId} />
        <Outlet context={[progressValue, setProgressValue]} />
      </View>
    </DownloadProvider>
  );
};
