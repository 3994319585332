/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ASC, DEFAULT_PAGE } from '@inbound/constants/constants';
import { LAYOUT_SEARCH_ENDPOINTS } from './../../shared/constants/routes';
import { GetLayoutSearchParams } from '@inventory/types/service.types';
import { PAGE_SIZE, SORT_BY } from '@shared/constants/constants';

import { apiService } from '@shared/services/apiService';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';
import { LayoutSearchSchema } from '@inventory/schemas/LayoutSearchSchema';

export const layoutSearchService = {
  getLayoutSearchData: async (options?: GetLayoutSearchParams) => {
    const payload = {
      searchCriteria: {
        ...options?.searchCriteria,
      },
      searchPage: {
        page: options?.searchPage?.page ? options?.searchPage?.page : DEFAULT_PAGE,
        size: options?.searchPage?.size ? options?.searchPage?.size : PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.LAYOUT_NAME,
            direction: ASC,
          },
        ],
      },
    };

    const response = await apiService.post(LAYOUT_SEARCH_ENDPOINTS.GET_LAYOUT_SEARCH_DATA, payload);

    return paginatedParseAndLog(LayoutSearchSchema, response.data);
  },
};
