/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { Button, Icon, SearchBar, Skeleton, Text, useDebounce, View } from '@az/starc-ui';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  ORDER_TYPE_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';

import {
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';

import { useTranslation } from 'react-i18next';
import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import styles from '@mdm/pages/RDM/LocationManager/Location/LocationList.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { SEARCH_MENU_MAX_HEIGHT } from '@mdm/constants/constants.ts';
import { Download } from '@shared/assets/icons';
import { Add } from '@az/starc-ui-icons';
import { mapOrderTypeTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useGetOrderTypes } from '@mdm/services/hooks/useGetOrderTypes.tsx';
import { OrderTypeListType } from '@mdm/types/schema.type.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { DIRECTION, TableSorting } from '@shared/components/Table/Table.types.ts';

export const OrderTypeList = () => {
  /* State */
  const [value, setValue] = useState<string>('');
  const [normalizeOrderTypeData, setNormalizeOrderTypeData] = useState<OrderTypeListType[]>([]);
  const breadcrumbs = useBreadcrumb();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();

  const { orderTypesData: orderTypesCountingData, isFetching: isOrderTypeCountLoading } =
    useGetOrderTypes({
      orderTypeCodes: '',
    });

  const { orderTypesData, isFetching: isLoading } = useGetOrderTypes({
    orderTypeCodes: debouncedSearchQueryValue || '',
  });

  /* Function */
  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);

    if (foundColumn) {
      const sortedOrderTypesData = [...orderTypesData].sort(
        (firstItem: OrderTypeListType, secondItem: OrderTypeListType) => {
          const firstValue = firstItem[foundColumn.id as keyof OrderTypeListType];
          const secondValue = secondItem[foundColumn.id as keyof OrderTypeListType];

          if (firstValue && secondValue) {
            return (
              (firstValue < secondValue ? -1 : 1) *
              (foundColumn.direction === DIRECTION.ASCENDING ? 1 : -1)
            );
          } else if (firstValue) {
            return 1;
          } else if (secondValue) {
            return 1;
          } else {
            return -1;
          }
        }
      );

      setNormalizeOrderTypeData(sortedOrderTypesData);
    }
  };

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onViewDetails = (orderType: OrderTypeListType) => {
    navigate(PAGE_URLS.ORDER_TYPE_DETAILS(String(orderType.orderTypeCode)));
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      setSearchParam(inputValue);
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
    setValue('');
  };

  /* Hooks */
  useEffect(() => {
    if (orderTypesData) {
      if (orderTypesData.length === 0) {
        setNormalizeOrderTypeData([]);
        handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
      } else {
        setNormalizeOrderTypeData(orderTypesData);
      }
    }
  }, [handleNotification, t, orderTypesData]);

  const SubTitle = () => {
    return (
      <View direction="row" align="center" gap={4}>
        <View.Item>
          <Text size="100" color="600">
            {`${t('TotalOrderType')}: `}
            {isOrderTypeCountLoading ? (
              <Skeleton className={styles['count-wrapper']} width="100px" height="24px" />
            ) : (
              (orderTypesCountingData || []).length
            )}
          </Text>
        </View.Item>
      </View>
    );
  };

  return (
    <View>
      <View className={styles['order-type-list']}>
        <MasterTitle
          title={t('MasterTitle.OrderType')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
          subtitle={<SubTitle />}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={6}>
              <SearchBar
                value={value}
                onValueChange={setValue}
                suggestions={[]}
                className={styles['search-bar']}
                label={t('Search.OrderType')}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={SEARCH_MENU_MAX_HEIGHT}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text>{t('Download')}</Text>
                    </View>
                  </Button>
                </View.Item>

                <View.Item>
                  <Button size="large" onClick={() => navigate(PAGE_URLS.ORDER_TYPE_CREATE)}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Add} color="secondary" />
                      <Text>{t('Create')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={[4, 6]}>
          <Table
            columns={ORDER_TYPE_TABLE_COLUMNS}
            rows={mapOrderTypeTableRows(normalizeOrderTypeData || [], onViewDetails)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isLoading}
            styleVariant={TableStylingVariants.DETAILS}
            totalPages={Math.ceil(
              ((normalizeOrderTypeData || []).length || DEFAULT_PAGE - 1) / PAGE_SIZE
            )}
            onSort={onSort}
          />
        </View>
      </View>
    </View>
  );
};
