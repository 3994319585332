/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { endCountService } from '@inventory/services/endCountService';

export const useEndCount = () => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateEndCount,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: {
      countRequest: {
        taskId: string;
        countTypeCd: string;
        statusCd: string;
        endReason?: string;
        endTs: string;
      };
    }) => {
      const payload = [params];
      return endCountService.endCount(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['wallToWall']);
    },
  });

  return { mutateEndCount, isLoading, isError };
};
