/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { GetFacilityConfigParams } from '@shared/types/service.type';
import { facilityConfigService } from '@shared/services/facilityConfigService';

export const useGetFacilityConfig = (options?: GetFacilityConfigParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    data: configsData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['facilityConfig', options],
    queryFn: () => facilityConfigService.getFacilityConfig(options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Notification.GetFacilityConfig'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { configsData, isLoading, isError, refetch };
};
