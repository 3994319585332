/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const EndCountRequestSchema = z.array(
  z.object({
    countRequest: z.object({
      taskId: z.string(),
      countTypeCd: z.string(),
      statusCd: z.string(),
      endReason: z.string().optional(),
      endTs: z.string(),
    }),
  })
);
