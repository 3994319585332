/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Skeleton, View } from '@az/starc-ui';
import { Box } from '@az/starc-ui-icons';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '@shared/components/EmptyState/EmptyState';
import { SubzoneChecked } from './SubzoneCheck';
import { DiscrepancyTabsProps } from './Discripancy.types';

import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { getInboundOrderDataBySubzone } from '@inventory/utils/utils';

export const CheckedDiscrepancy = ({
  inboundOrderData,
  isOrderLoading,
  isInboundOrderFeatching,
  setIsEditOrderLinesCount,
}: DiscrepancyTabsProps) => {
  /* Constants */
  const { t } = useTranslation();
  const { subzone } = useParams();

  /* Hooks */

  const inboundOrderDataBySubzone = useMemo(() => {
    return subzone
      ? getInboundOrderDataBySubzone(inboundOrderData ?? [], subzone)
      : inboundOrderData;
  }, [inboundOrderData, subzone]);

  return !inboundOrderDataBySubzone || inboundOrderDataBySubzone.length === 0 ? (
    <View width="100%" padding={6} className={styles['subzone-discrepancy']}>
      {isOrderLoading ? (
        <View direction="row" gap={2}>
          <Skeleton width="100%" height="20vh" />
          <Skeleton width="100%" height="20vh" />
          <Skeleton width="100%" height="20vh" />
        </View>
      ) : (
        <View
          justify="center"
          align="center"
          className={styles['subzone-discrepancy__empty']}
          height="60vh"
        >
          <View width="360px">
            <EmptyState
              svg={Box}
              subtitle={t('FinalizationDashboard.Empty.Checked.Subtitle')}
              text={t('FinalizationDashboard.Empty.Checked.Text')}
            />
          </View>
        </View>
      )}
    </View>
  ) : (
    <SubzoneChecked
      inboundOrderData={inboundOrderData}
      isOrderLoading={isOrderLoading || false}
      isInboundOrderFeatching={isInboundOrderFeatching || false}
      setIsEditOrderLinesCount={setIsEditOrderLinesCount}
      inboundOrderDataBySubzone={inboundOrderDataBySubzone}
    />
  );
};
