/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Checkbox, Divider, Text, View } from '@az/starc-ui';

import styles from './VehiclesSuggestion.module.scss';
import { Props } from './VehiclesSuggestion.types.ts';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { VEHICLE_FIELD } from '@mdm/constants/constants.ts';
import { LayoutAssetType } from '@mdm/types/schema.type.ts';
import { useEffect, useState } from 'react';
import style from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/AddVehicleSubzoneModal.module.scss';
import { useTranslation } from 'react-i18next';

export const VehiclesSuggestion = ({
  data,
  existingVehicles,
  onItemClick,
  onAttributeClick,
  configData,
}: Props) => {
  /* State */
  const [selectedVehicles, setSelectedVehicles] = useState<LayoutAssetType[]>([]);

  /* Constant */
  const { t } = useTranslation();

  /* Functions */
  const isSelected = (assetCd: string) => {
    if (existingVehicles) {
      return existingVehicles.filter((item) => item.assetCd === assetCd).length > 0;
    }

    return false;
  };

  const isAttributeSelected = (assetCd: string, attributeName: string) => {
    if (selectedVehicles) {
      const foundLayout = selectedVehicles.find((item) => item.assetCd === assetCd);
      if (foundLayout) {
        const foundAttribute = foundLayout.assetLayoutMappingAttrib?.find(
          (item) => item.name === attributeName
        );

        return foundAttribute?.value === 'true';
      }
    }

    return false;
  };

  const handleSuggestionClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    subzone: LayoutAssetType
  ) => {
    onItemClick?.(subzone, e.target.checked);
  };

  const handleAttributeClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    asset: LayoutAssetType,
    attributeName: string
  ) => {
    onAttributeClick?.(asset, e.target.checked, attributeName);
  };

  /* Hooks */
  useEffect(() => {
    setSelectedVehicles(existingVehicles || []);
  }, [existingVehicles]);

  return (
    <View direction="column" padding={[2, 0]} className={styles['vehicles-suggestion__container']}>
      <View.Item>
        <View direction="column" className={styles['vehicles-suggestion__section']}>
          <View.Item>
            <View className={styles['vehicles-suggestion__section__title']}>
              <Text>
                {t('VehicleDetails.AddSubzoneModal.Subzones.Selected')} ({selectedVehicles.length})
              </Text>
            </View>
          </View.Item>
          <View.Item>
            <View className={styles['vehicles-suggestion__section__items']} direction="column">
              {data?.map((vehicle, index) => {
                return (
                  <View
                    key={vehicle.assetCd}
                    direction="column"
                    className={styles['vehicles-suggestion__item']}
                  >
                    <View direction="row" justify="center" align="center">
                      <View.Item grow>
                        <View direction="row">
                          <Checkbox
                            name="selected-vehicles"
                            label={
                              <>
                                <View.Item>
                                  <Text weight="bold">{vehicle.assetCd}</Text>
                                </View.Item>
                                <Divider
                                  vertical
                                  color="600"
                                  attributes={{
                                    style: {
                                      height: 'var(--st-unit-5)',
                                      margin: '0 var(--st-unit-2)',
                                    },
                                  }}
                                />
                                <View.Item>
                                  <Text>
                                    {configData.vehicleTypeOptions.find(
                                      (option) =>
                                        option.value ===
                                        (vehicle.assetAttributes
                                          ? vehicle.assetAttributes[
                                              `${getFormFieldIndexByKey(
                                                vehicle.assetAttributes,
                                                VEHICLE_FIELD.VEHICLE_TYPE
                                              )}`
                                            ]?.value
                                          : '')
                                    )?.label || '-'}
                                  </Text>
                                </View.Item>
                              </>
                            }
                            value="all"
                            defaultChecked={isSelected(vehicle.assetCd)}
                            onChange={(e) => handleSuggestionClick(e, vehicle)}
                            className={styles[`vehicles-suggestion__item__checkbox`]}
                          />
                        </View>
                        <View>
                          <View.Item
                            attributes={{
                              style: {
                                marginTop: 'var(--st-unit-2)',
                                marginLeft: 'var(--st-unit-9)',
                              },
                            }}
                          >
                            <Text color="500">{vehicle.assetDesc}</Text>
                          </View.Item>
                        </View>
                      </View.Item>
                      <View.Item>
                        <View direction="row" align="center">
                          <View.Item
                            attributes={{
                              style: {
                                marginRight: 'var(--st-unit-2)',
                              },
                            }}
                          >
                            <Checkbox
                              className={style['checkbox-field']}
                              name={`assetLayoutMappingAttrib.${getFormFieldIndexByKey(
                                vehicle.assetLayoutMappingAttrib || [],
                                VEHICLE_FIELD.PICKING_ALLOWED
                              )}.value`}
                              label={t('Table.VehicleList.PickingAllowed')}
                              value="false"
                              defaultChecked={isAttributeSelected(
                                vehicle.assetCd,
                                VEHICLE_FIELD.PICKING_ALLOWED
                              )}
                              onChange={(e) =>
                                handleAttributeClick(e, vehicle, VEHICLE_FIELD.PICKING_ALLOWED)
                              }
                            />
                          </View.Item>
                          <View.Item>
                            <Checkbox
                              className={style['checkbox-field']}
                              name={`assetLayoutMappingAttrib.${getFormFieldIndexByKey(
                                vehicle.assetLayoutMappingAttrib || [],
                                VEHICLE_FIELD.PUTAWAY_ALLOWED
                              )}.value`}
                              label={t('Table.VehicleList.PutawayAllowed')}
                              value="false"
                              defaultChecked={isAttributeSelected(
                                vehicle.assetCd,
                                VEHICLE_FIELD.PUTAWAY_ALLOWED
                              )}
                              onChange={(e) =>
                                handleAttributeClick(e, vehicle, VEHICLE_FIELD.PUTAWAY_ALLOWED)
                              }
                            />
                          </View.Item>
                        </View>
                      </View.Item>
                    </View>
                    {index !== data.length - 1 && (
                      <Divider
                        className={styles['vehicles-suggestion__item__separator']}
                        color="300"
                      />
                    )}
                  </View>
                );
              })}
            </View>
          </View.Item>
        </View>
      </View.Item>
    </View>
  );
};
