/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@shared/constants/routes';

import { PODashboard } from '@inbound/pages/PODashboard/PODashboard';
import { PODashboardSearch } from '@inbound/pages/PODashboard/Search/PODashboardSearch';
import { PODetail } from '@inbound/pages/PODashboard/Detail/PODetail';
import { POFinalizeDiscrepancies } from '@inbound/pages/PODashboard/Detail/POFinalizeDiscrepancies';
import { ReplenishmentDashboard } from '@inbound/pages/ReplenishmentDashboard';

import { TaskManager } from '@taskManagement/pages/Inbound/TaskManager';
import { Inventory } from '@taskManagement/pages/Inventory/Inventory';
import { POHistory } from '@inbound/pages/PODashboard/POHistory';

export const inboundRoutes: RouteObject = {
  children: [
    {
      path: ROUTES.PO_DASHBOARD,
      children: [
        {
          path: '',
          element: <PODashboard />,
        },
        {
          path: ROUTES.SEARCH,
          element: <PODashboardSearch />,
        },
        {
          path: ':sourceOrderId/:receiptId',
          element: <PODetail />,
          children: [
            {
              path: ROUTES.EDIT,
            },
            {
              path: ROUTES.PUT_ON_HOLD,
            },
            {
              path: ROUTES.REMOVE,
            },
          ],
        },
        {
          path: `:sourceOrderId/:receiptId/${ROUTES.PO_FINALIZATION}`,
          element: <POFinalizeDiscrepancies />,
        },
        {
          path: ROUTES.PO_HISTORY,
          element: <POHistory />,
        },
      ],
    },
    {
      path: ROUTES.REPLENISHMENT_DASHBOARD,
      element: <ReplenishmentDashboard />,
    },
    {
      path: ROUTES.TASK_MANAGER,
      element: <TaskManager />,
    },
    {
      path: ROUTES.INVENTORY_MANAGER,
      element: <Inventory />,
    },
  ],
};
