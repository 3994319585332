/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useSessionStorage } from '@shared/hooks/useStorage.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';
import { z } from 'zod';
import { ConsolidationLocationSchema } from '@mdm/schemas/consolidationLocationSchema.ts';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import { FormControl, Loader, Select, Text, TextField, View } from '@az/starc-ui';
import styles from '@mdm/pages/RDM/LocationManager/ConsolidationLocation/Detail/ConsolidationLocationDetail.module.scss';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { getFormInputError } from '@ofm/utils/utils.ts';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils.tsx';
import {
  DEFAULT_DIMENSION_UNIT,
  CONSOLIDATION_LOCATION_FIELD,
  FIELD_EVENT,
  MAX_LENGTH_FIELD,
} from '@mdm/constants/constants.ts';
import { useGetZones } from '@mdm/services/hooks/useGetZones.tsx';
import { DEFAULT_PAGE, LAYOUT, MAX_PAGE_SIZE, RDM } from '@shared/constants/constants.ts';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';

type Props = {
  locationId?: string;
};

export const ConsolidationLocationDetailForm = ({ locationId }: Props) => {
  /* State variables */
  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);
  const [locationTypeOptions, setLocationTypeOptions] = useState<SelectOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);

  /* Constants */
  type FormData = z.infer<typeof ConsolidationLocationSchema>;
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useFormContext<FormData>();

  const selectedZoneId = watch('zoneName');

  const { zonesData, isFetching: isZonesLoading } = useGetZones({
    currentPage: DEFAULT_PAGE - 1,
    entityAssociations: [],
    pageSize: MAX_PAGE_SIZE,
  });

  const { subzonesData, isFetching: isSubzonesLoading } = useGetSubzones(
    {
      currentPage: DEFAULT_PAGE - 1,
      entityAssociations: [],
      parentLayoutDistinctName: selectedZoneId || '',
    },
    !!selectedZoneId
  );

  const { configsData, isLoading: isConfigDataLoading } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  /* Functions */
  const revalidateAttributeField = (attributeName: string) => {
    const attributeIndex = attributeFields.findIndex((field) => field.name === attributeName);
    if (attributeIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trigger(`attributes[attribute-${attributeIndex}]`);
    }
  };

  /* Hooks */
  const populateDropdownOptions = useCallback(() => {
    const locationTypes = (configsData || [])
      .filter((config) => config.configCd === CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setLocationTypeOptions(locationTypes);

    const statuses = (configsData || [])
      .filter((config) => config.configCd === CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setStatusOptions(statuses);
  }, [configsData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // reset subzone dropdown value if zone dropdown is changed
      if (
        name === CONSOLIDATION_LOCATION_FIELD.ZONE_NAME &&
        type === FIELD_EVENT.CHANGE &&
        value.parentLayoutDistinctName
      ) {
        setValue('parentLayoutDistinctName', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  useEffect(() => {
    populateDropdownOptions();
  }, [configsData, populateDropdownOptions]);

  return (
    <View>
      <form>
        <View direction="row" gap={4}>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('Warehouse')} isRequired={true} />
              <FormControl>
                <Select
                  attributes={{
                    'data-testid': 'warehouse-id',
                  }}
                  label={t('Warehouse')}
                  name="warehouse"
                  variant="no-label"
                  defaultValue={{
                    label: 'DC ' + selectedDC?.toString(),
                    value: 'DC ' + selectedDC?.toString(),
                  }}
                  options={[]}
                  disabled
                />
              </FormControl>
              <Text weight="regular" color="600" size="087">
                {t('ConsolidationLocationDetails.WarehouseInstruction')}
              </Text>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'zone-name',
              }}
              gap={2}
            >
              <FormLabel
                text={t('ConsolidationLocationDetails.ZoneName.Label')}
                isRequired={true}
              />
              <FormControl hasError={!!errors.zoneName}>
                <Controller
                  control={control}
                  name="zoneName"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isZonesLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('ConsolidationLocationDetails.ZoneName.Placeholder')}
                          placeholder={t('ConsolidationLocationDetails.ZoneName.Placeholder')}
                          name="zoneName"
                          variant="no-label"
                          options={(zonesData?.content || []).map((row) => ({
                            label: row.layout.layoutName,
                            value: row.layout.layoutDistinctName.toString(),
                          }))}
                          value={{
                            label:
                              (zonesData?.content || []).find(
                                (option) => option.layout.layoutDistinctName === value
                              )?.layout.layoutName || '',
                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(zoneName) => onChange(zoneName?.value)}
                        />
                      )}
                    </>
                  )}
                />
                {errors.zoneName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>{getFormInputError(errors.zoneName.type)}</FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'subzone-name',
              }}
              gap={2}
            >
              <FormLabel
                text={t('ConsolidationLocationDetails.SubzoneName.Label')}
                isRequired={true}
              />
              <FormControl hasError={!!errors.parentLayoutDistinctName}>
                <Controller
                  control={control}
                  name="parentLayoutDistinctName"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isSubzonesLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('ConsolidationLocationDetails.SubzoneName.Placeholder')}
                          name="subzoneName"
                          variant="no-label"
                          disabled={!selectedZoneId}
                          options={(subzonesData?.content || []).map((row) => ({
                            label: row.layout.layoutName,
                            value: row.layout.layoutDistinctName.toString(),
                          }))}
                          value={{
                            label:
                              (subzonesData?.content || []).find(
                                (option) => option.layout.layoutDistinctName === value
                              )?.layout.layoutName || '',
                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(subzone) => onChange(subzone?.value)}
                        />
                      )}
                    </>
                  )}
                />
                {errors.parentLayoutDistinctName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>
                      {getFormInputError(errors.parentLayoutDistinctName.type)}
                    </FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>
        </View>

        <View
          direction="row"
          gap={4}
          className={styles['consolidation-location-detail__form-field-section']}
        >
          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'location-type',
              }}
              gap={2}
            >
              <FormLabel
                text={t('ConsolidationLocationDetails.LocationType.Label')}
                isRequired={true}
              />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE
                  )}.value`}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isConfigDataLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('ConsolidationLocationDetails.LocationType.Placeholder')}
                          variant="no-label"
                          options={locationTypeOptions}
                          value={{
                            label:
                              locationTypeOptions.find((option) => option.value === value)?.label ||
                              '',
                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(LOCATION_TYPE) => {
                            onChange(LOCATION_TYPE?.value);
                            revalidateAttributeField(CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE);
                          }}
                        />
                      )}
                    </>
                  )}
                />

                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.LOCATION_TYPE
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel
                text={t('ConsolidationLocationDetails.ConsolidationLocationId.Label')}
                isRequired={true}
              />
              <FormControl hasError={!!errors.layoutName}>
                <TextField
                  attributes={{
                    'data-testid': 'name',
                  }}
                  inputAttributes={{
                    placeholder: t(
                      'ConsolidationLocationDetails.ConsolidationLocationId.Placeholder'
                    ),
                    ...register('layoutName'),
                    maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_NAME,
                  }}
                  defaultValue=""
                  disabled={!!locationId}
                />
                {errors.layoutName && (
                  <View direction="row" justify="space-between">
                    <FormControl.Error>{errors.layoutName.message}</FormControl.Error>
                  </View>
                )}
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View
              attributes={{
                'data-testid': 'status',
              }}
              gap={2}
            >
              <FormLabel text={t('ConsolidationLocationDetails.Status.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS
                      )
                    )
                  )
                }
              >
                <Controller
                  control={control}
                  name={`attributes.${getFormFieldIndexByKey(
                    getValues()['attributes'],
                    CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS
                  )}.value`}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      {isConfigDataLoading ? (
                        <Loader variant="spinner" color="primary" size="small" />
                      ) : (
                        <Select
                          label={t('ConsolidationLocationDetails.Status.Placeholder')}
                          variant="no-label"
                          options={statusOptions}
                          value={{
                            label:
                              statusOptions.find((option) => option.value === value)?.label || '',
                            value: value || '',
                          }}
                          inputAttributes={{ onBlur }}
                          onValueChange={(status) => {
                            onChange(status?.value);
                            revalidateAttributeField(
                              CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS
                            );
                          }}
                        />
                      )}
                    </>
                  )}
                />

                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.CONS_LOCATION_STATUS
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('ConsolidationLocationDetails.Order.Label')} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.ORDER
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'order',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.ORDER
                      )}.value`
                    ),
                    maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_ORDER,
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          CONSOLIDATION_LOCATION_FIELD.ORDER
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(CONSOLIDATION_LOCATION_FIELD.ORDER);
                    },
                  }}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.ORDER
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>
        <View
          direction="row"
          gap={4}
          className={styles['consolidation-location-detail__form-field-section']}
        >
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('ConsolidationLocationDetails.Height.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.HEIGHT
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'height',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.HEIGHT
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          CONSOLIDATION_LOCATION_FIELD.HEIGHT
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(CONSOLIDATION_LOCATION_FIELD.HEIGHT);
                    },
                    maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_HEIGHT,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.HEIGHT
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('ConsolidationLocationDetails.Width.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.WIDTH
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'width',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.WIDTH
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          CONSOLIDATION_LOCATION_FIELD.WIDTH
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(CONSOLIDATION_LOCATION_FIELD.WIDTH);
                    },
                    maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_WIDTH,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.WIDTH
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 3 }}>
            <View gap={2}>
              <FormLabel text={t('ConsolidationLocationDetails.Depth.Label')} isRequired={true} />
              <FormControl
                hasError={
                  !!(
                    errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.DEPTH
                      )
                    )
                  )
                }
              >
                <TextField
                  attributes={{
                    'data-testid': 'depth',
                  }}
                  defaultValue=""
                  inputAttributes={{
                    ...register(
                      `attributes.${getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.DEPTH
                      )}.value`
                    ),
                    onChange: (e) => {
                      setValue(
                        `attributes.${getFormFieldIndexByKey(
                          getValues()['attributes'],
                          CONSOLIDATION_LOCATION_FIELD.DEPTH
                        )}.value`,
                        e.target.value
                      );
                      revalidateAttributeField(CONSOLIDATION_LOCATION_FIELD.DEPTH);
                    },
                    maxLength: MAX_LENGTH_FIELD.CONSOLIDATION_LOCATION_DEPTH,
                  }}
                  endElement={<Text color="400">{DEFAULT_DIMENSION_UNIT}</Text>}
                />
                <FormControl.Error>
                  {errors.attributes &&
                    displayAttributeFieldValidationMessage(
                      errors,
                      getFormFieldIndexByKey(
                        getValues()['attributes'],
                        CONSOLIDATION_LOCATION_FIELD.DEPTH
                      )
                    )}
                </FormControl.Error>
              </FormControl>
            </View>
          </View.Item>
        </View>
      </form>
    </View>
  );
};
