/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import * as T from './Chart.types';
import { ResponsivePie } from '@nivo/pie';

export const PieChart = (props: T.PieChartProps) => {
  const { widgetData, variant } = props;
  return (
    <>
      <ResponsivePie
        data={widgetData}
        margin={{ top: 10, right: 10 }}
        innerRadius={variant === 'pie' ? 0 : 0.75}
        padAngle={1}
        cornerRadius={0}
        startAngle={360}
        endAngle={-360}
        activeOuterRadiusOffset={8}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={({ data }: { data: T.WidgetData }) => data.color}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
      />
    </>
  );
};
