/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { classNames, Text, Tooltip, View } from '@az/starc-ui';

import { AvatarGroup } from '@shared/components/AvatarGroup/AvatarGroup';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge';
import { Tag } from '@shared/components/Tag/Tag';
import { Props as TagItemProps } from '@shared/components/Tag/Tag.types';

import { ProgressBar } from '@inbound/components/ProgressBar/ProgressBar';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';

import * as T from './MasterCard.types';
import styles from './MasterCard.module.scss';
import { MAX_VISIBLE_AVATARS, MAX_VISIBLE_AVATARS_HOVER } from '@shared/constants/constants';

export const MasterCard = ({
  title,
  replenPlanMoveKey,
  priority,
  tagItems,
  statusBadgeProps,
  label,
  detailsData,
  avatarGroupData,
  piecesData,
  linesData,
  className,
  titleClassName,
  actionMenu,
  topSectionDirection = 'row',
  showUsersOnHover,
}: T.Props) => {
  const { t } = useTranslation();

  return (
    <View
      direction="column"
      wrap={false}
      padding={4}
      backgroundColor="secondary"
      width="100%"
      className={classNames(styles['master-card'], className)}
      attributes={{
        'data-testid': 'st-master-card',
        id: `replenCard-${replenPlanMoveKey ? replenPlanMoveKey : 'id'}`,
      }}
    >
      <View
        direction={topSectionDirection}
        {...(topSectionDirection === 'row' ? { align: 'center', justify: 'space-between' } : {})}
        className={styles['master-card__top-section']}
      >
        <View.Item columns={12}>
          <View direction="row" align="center" className={styles['master-card__title-wrapper']}>
            <Text
              size="100"
              weight="bold"
              className={
                tagItems
                  ? classNames(styles['master-card__task_title'], titleClassName)
                  : classNames(styles['master-card__title'], titleClassName)
              }
            >
              {title}
            </Text>

            {priority ? <PriorityTag /> : null}

            {tagItems && (
              <View className={styles['master-card__tags-wrapper']}>
                {tagItems.map(
                  (tagItem: TagItemProps) =>
                    tagItem.text !== '' && <Tag text={tagItem.text} variant={tagItem.variant} />
                )}
              </View>
            )}

            {statusBadgeProps && <StatusBadge {...statusBadgeProps} />}
          </View>
        </View.Item>

        {label && (
          <View direction="row" align="center" gap={2}>
            {Array.isArray(label) ? (
              label.map((item, index) => (
                <Text
                  size="087"
                  color="500"
                  className={classNames(styles['master-card__label'], titleClassName)}
                  key={index}
                >
                  {item}
                </Text>
              ))
            ) : (
              <Text
                size="087"
                color="500"
                className={classNames(styles['master-card__label'], titleClassName)}
              >
                {label}
              </Text>
            )}
          </View>
        )}
        {actionMenu}
      </View>

      <View direction="column" className={styles['master-card__bottom-section']}>
        {(detailsData || avatarGroupData) && (
          <View direction="row" className={styles['master-card__details-wrapper']}>
            {detailsData && (
              <View.Item grow>
                <View direction="row" className={styles['master-card__detail-item']}>
                  {detailsData.data.map(({ label, color }: T.DetailProps) => (
                    <View.Item>
                      <Text
                        size="087"
                        color={color ? color : '600'}
                        className={styles['master-card__detail-item__text']}
                      >
                        {label}
                      </Text>
                    </View.Item>
                  ))}
                </View>
              </View.Item>
            )}

            {avatarGroupData &&
              (avatarGroupData.users.length > 0 ? (
                showUsersOnHover &&
                avatarGroupData.users.length >
                  (avatarGroupData.maxDisplay ?? MAX_VISIBLE_AVATARS) ? (
                  <Tooltip
                    variant="alt"
                    placement="bottom"
                    triggerType="hover"
                    buttonTitle=""
                    svg={<AvatarGroup {...avatarGroupData} />}
                  >
                    <View padding={[1, 4, 4, 4]}>
                      <AvatarGroup {...avatarGroupData} maxDisplay={MAX_VISIBLE_AVATARS_HOVER} />
                    </View>
                  </Tooltip>
                ) : (
                  <AvatarGroup {...avatarGroupData} />
                )
              ) : (
                <Text size="087">{t('PODashboard.NoSelectorsAssigned')}</Text>
              ))}
          </View>
        )}

        {(piecesData || linesData) && (
          <View direction="row" className={styles['master-card__statistics-wrapper']}>
            {piecesData && (
              <View.Item grow>
                <ProgressBar
                  label={t('PODashboard.PicesDataLabel', {
                    count: piecesData.count,
                    total: piecesData.total,
                    label: piecesData.label,
                  })}
                  value={piecesData.count}
                  max={piecesData.total}
                />
              </View.Item>
            )}
            {linesData && (
              <View.Item>
                <Text size="087" className={styles['master-card__lines']}>
                  {t('PODashboard.LinesData', {
                    count: linesData.count,
                    total: linesData.total,
                  })}
                  &nbsp;
                  {t('PODashboard.Item.Lines')}
                </Text>
              </View.Item>
            )}
          </View>
        )}
      </View>
    </View>
  );
};
