/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { apiService } from '@shared/services/apiService';
import { TASK_SEARCH_ENDPOINTS, TASK_UPDATE_ENDPOINTS } from '@shared/constants/routes.ts';
import {
  InboundShiftGoalPayloadType,
  getInboundShiftGoalOptions,
} from '@taskManagement/types/types';
import { paginatedParseAndLog } from '@taskManagement/utils/utils';
import { InboundShiftGoalDetailsSchema } from '@taskManagement/schemas/InboundShiftGoalPayloadSchema';

export const setInboundShiftGoalService = {
  setGoals: async (goalsRequest: InboundShiftGoalPayloadType) => {
    const payload = goalsRequest;

    const resp = await apiService.post(TASK_UPDATE_ENDPOINTS.SET_INBOUND_SHIFT_GOAL, payload);
    return resp.data;
  },

  getGoals: async (goalsRequest: getInboundShiftGoalOptions) => {
    const payload = {
      searchPage: {
        page: 0,
        size: goalsRequest?.pageSize ? goalsRequest?.pageSize : 50,
        direction: 'ASC',
      },
      searchCriteria: {
        ...(goalsRequest?.shiftId && { shiftId: goalsRequest.shiftId }),
        ...(goalsRequest?.activity && { activity: goalsRequest.activity }),
        ...(goalsRequest?.metrics && { metrics: goalsRequest.metrics }),
        ...(goalsRequest?.fromDate && { fromDate: goalsRequest.fromDate }),
        ...(goalsRequest?.toDate && { toDate: goalsRequest.toDate }),
      },
    };
    const resp = await apiService.post(TASK_SEARCH_ENDPOINTS.GET_GOALS_SEARCH_DATA, payload);
    return paginatedParseAndLog(InboundShiftGoalDetailsSchema, resp.data);
  },
};
