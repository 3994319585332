/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { InboundOrderLineSchema } from '@inbound/schemas/inboundOrderLineSchema';
import { InboundTaskSchema } from '@inbound/schemas/inboundTaskSchema';
import { InboundUserSchema } from '@inbound/schemas/userSchema';
import { VendorLocaleSchema } from '@inbound/schemas/vendorLocalSchema';

const InboundOrderSchema = z.object({
  sourceOrderNbr: z.string(),
  vendorId: z.string().optional(),
  domainTypeCd: z.string(),
  orderTypeCd: z.string().optional().nullable(),
  statusCd: z.string(),
  statusRevertReason: z.string().optional().nullable(),
  subStatusCd: z.string().optional().nullable(),
  prevStatusCd: z.string().optional().nullable(),
  sourceSystemStatusCd: z.string(),
  priority: z.number(),
  orderLocationTypeCd: z.string().optional().nullable(),
  orderLocationId: z.string().optional().nullable(),
  totalOrderedQty: z.number(),
  totalReceivedQty: z.number(),
  totalReceivedQtyAdj: z.number().optional().nullable(),
  totalWeight: z.number().optional().nullable(),
  weightUomCd: z.string().optional().nullable(),
  totalLineCount: z.number(),
  receivedLineCount: z.number(),
  totalFpsLineCount: z.number().optional().nullable(),
  shipToOrgId: z.string().optional().nullable(),
  shipToFacilityId: z.string().optional().nullable(),
  sourceCreateTs: z.string().optional().nullable(),
  sourceSystemUpdateTs: z.string().optional().nullable(),
  finalizedByUserName: z.string().optional().nullable(),
  finalizedTs: z.string().optional().nullable(),
  vendorLocale: VendorLocaleSchema.optional().nullable(),
  tasks: InboundTaskSchema.optional().nullable(),
  inboundOrderLines: z.array(InboundOrderLineSchema).optional().nullable(),
  users: z.array(InboundUserSchema),
});

export const InboundTrailerOrderSchema = z.object({
  trailerOrder: z.object({
    trailerOrderKey: z.string(),
    sourceOrderNbr: z.string(),
    receiptId: z.string(),
    statusCd: z.string(),
    commodityTypeCd: z.string(),
    inboundOrder: InboundOrderSchema,
  }),
});

export const InboundOrderICReviewSchema = z.object({
  inboundOrder: InboundOrderSchema,
});
