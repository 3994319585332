/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Actionable } from '@az/starc-ui';
import { MasterCard } from '@inbound/components/MasterCard/MasterCard';
import { commonTabCardProps } from './CombinedPillTabs.types';
import { User } from '@shared/components/AvatarGroup/AvatarGroup.types';
import styles from './CombinedPillTabs.module.scss';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';
import { useGetTaskTypeCardHook } from './useGetTaskTypeCardHook';
import { Props as TagItemProps } from '@shared/components/Tag/Tag.types';

export const CommonTabCard = ({
  onClickAssignCurrentTask,
  poData,
  usersData = [],
  taskType,
}: commonTabCardProps) => {
  const masterCardData = useGetTaskTypeCardHook(poData, usersData, taskType);
  if (!masterCardData) {
    return null;
  }
  const {
    title,
    priority,
    statusBadge,
    label,
    detailsData,
    avatarGroupData,
    tagItems,
    piecesData,
    linesData,
  } = masterCardData;
  return (
    <>
      <Actionable
        key={poData.taskNo}
        onClick={() => onClickAssignCurrentTask(poData)}
        className={styles['combined-tabs__actionable-card']}
      >
        <MasterCard
          title={title}
          priority={priority}
          statusBadgeProps={
            statusBadge && {
              variant: statusBadge.variant as StatusVariants,
              text: statusBadge.text?.toString() ?? '',
            }
          }
          label={label?.data}
          detailsData={detailsData}
          {...(poData?.assignments && avatarGroupData
            ? {
                avatarGroupData: {
                  users: avatarGroupData.users?.filter((user): user is User => user !== null) ?? [],
                  size: avatarGroupData.size === 'large' ? 'large' : 'small',
                  variant: 'assignee',
                  maxDisplay: avatarGroupData.maxDisplay,
                },
              }
            : {})}
          tagItems={tagItems?.map((item) => ({
            ...item,
            variant: item.variant as TagItemProps['variant'],
          }))}
          piecesData={piecesData}
          linesData={linesData}
        />
      </Actionable>
    </>
  );
};
