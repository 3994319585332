/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { MatrixSortOptions } from '@outbound/constants/constants';

export const replenishmentMatrixRouteData = [
  {
    location: 'Aerosol',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
      ],
      [
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 4, state: 'empty' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'not-started' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 4, state: 'complete' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Battery',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 2, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'not-started' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Hard Parts',
    tiles: [
      [
        { count: '4', assigned: null, state: 'paper' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '4', vehicle: null, selector: null, state: 'close' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4', vehicle: null, selector: null, state: 'close' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '4', vehicle: null, selector: null, state: 'close' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '4', vehicle: null, selector: null, state: 'close' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Mezzanine',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 4, state: 'empty' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'not-started' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 4, state: 'complete' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Oil',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Reserves',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 2, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'not-started' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Pick to Cart',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'LTD',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
];

export const replenishmentMatrixWaveData = [
  {
    location: 'Aerosol',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'not-started' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'not-started' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 1, state: 'not-started' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Battery',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 2, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '1/4', assigned: 4, state: 'not-started' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Hard Parts',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'error' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'completed' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Mezzanine',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'not-started' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 1, state: 'not-started' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Oil',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Reserves',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 2, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
      ],
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'error' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Pick to Cart',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'LTD',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
      ],
      [
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
          [
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
          ],
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
          ],
        ],
      },
    ],
  },
];

export const replenishmentMatrixReleaseData = [
  {
    location: 'Aerosol',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 4, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'not-started' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 4, state: 'complete' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Battery',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 2, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Hard Parts',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'not-started' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Mezzanine',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '4/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 1, state: 'not-started' },
        { count: '4/4', assigned: 4, state: 'complete' },
        { count: '0/4', assigned: 0, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 0, state: 'empty' },
        { count: '1/4', assigned: 4, state: 'complete' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Oil',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'empty' },
        { count: '1/4', assigned: null, state: 'empty' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'empty' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Reserves',
    tiles: [
      [
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '0/4', assigned: 2, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'complete' },
        { count: '4/4', assigned: 4, state: 'un-assigned' },
        { count: '0/4', assigned: 1, state: 'un-assigned' },
        { count: '4/4', assigned: 4, state: 'not-started' },
        { count: '0/4', assigned: 1, state: 'in-progress' },
        { count: '4/4', assigned: 1, state: 'empty' },
        { count: '0/4', assigned: 1, state: 'error' },
        { count: '0/4', assigned: 1, state: 'empty' },
        { count: '1/4', assigned: 1, state: 'un-assigned' },
        { count: '1/4', assigned: 4, state: 'un-assigned' },
      ],
    ],

    subzones: [
      {
        location: 'Subzone1',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
      {
        location: 'Subzone2',
        tiles: [
          [
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'un-assigned' },
          ],
        ],
      },
    ],
  },
  {
    location: 'Pick to Cart',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'error' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'error' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'error' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
  {
    location: 'LTD',
    tiles: [
      [
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '0/4', assigned: null, state: 'un-assigned' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'not-started' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'complete' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
        { count: '1/4', assigned: 1, state: 'in-progress' },
      ],
    ],

    subzones: [
      {
        location: 'HBP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HDP1',
        tiles: [
          [
            { count: '4/4', vehicle: null, selector: null, state: 'completed' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'error' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HFP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'APAL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HHP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
          ],
        ],
      },
      {
        location: 'HJP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HKP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'in-progress' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
      {
        location: 'HLP1',
        tiles: [
          [
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: null, selector: null, state: 'un-assigned' },
            { count: '0/4', vehicle: 'APAL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'not-started' },
            { count: '4/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '0/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'empty' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
            { count: '1/4', vehicle: 'HAPL', selector: 'kwheeler', state: 'complete' },
          ],
        ],
      },
    ],
  },
];

export const heatMapData = [
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'un-assigned' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'complete' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'in-progress' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'un-assigned' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'complete' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'un-assigned' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'complete' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'un-assigned' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'un-assigned' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'un-assigned' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'complete' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'un-assigned' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'un-assigned' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'un-assigned' },
    { state: 'complete' },
  ],
  [
    { state: 'un-assigned' },
    { state: 'un-assigned' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'complete' },
  ],
  [
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
  ],
  [
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'not-started' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'not-started' },
    { state: 'not-started' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'complete' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'complete' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'empty' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'complete' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
  ],
  [
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
    { state: 'not-started' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
    { state: 'empty' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
  ],
  [
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'complete' },
    { state: 'complete' },
    { state: 'complete' },
  ],
  [
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'not-started' },
    { state: 'complete' },
    { state: 'in-progress' },
    { state: 'in-progress' },
    { state: 'empty' },
    { state: 'in-progress' },
  ],
];

export const defaultDisplayOptions = {
  completedStores: true,
  waveDetails: true,
  vehicleCode: true,
  orderSelector: true,
  tileInfo: true,
  sortBy: MatrixSortOptions.ROUTE,
};
