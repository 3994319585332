/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS } from '@shared/constants/routes.ts';
import { apiService } from '@shared/services/apiService.ts';
import { parseAndLog } from '@shared/utils/service/serviceUtils';

import { EndCountRequestSchema } from '@inventory/schemas/endCountModalSchema.ts';
import { endCountRequestType } from '@inventory/types/types.ts';

export const endCountService = {
  endCount: async (endCountRequest: endCountRequestType) => {
    const resp = await apiService.post(
      LOCATION_COUNT_PERFORM_ACTION_SYNC_ENDPOINTS.PERFORM_ACTION_SYNC,
      endCountRequest
    );
    return parseAndLog(EndCountRequestSchema, resp.data);
  },
};
