/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, FormControl, Select, Text, View } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';

import styles from './Permission.module.scss';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionTab } from '@mdm/pages/RDM/User/Permission/PermissionTab.tsx';
import { useEffect, useState } from 'react';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import {
  TableStylingVariants,
  USER_PERMISSION_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import { mapUserPermissionTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { DEFAULT_PAGE, NOTIFICATION_TYPES, PAGE_SIZE } from '@shared/constants/constants.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import { useUserRoleSearch } from '@shared/services/hooks/useUserRoleSearch.ts';
import { useGetCapabilityGroups } from '@shared/services/hooks/useGetCapabilityGroups.tsx';
import { RoleType } from '@shared/types/schema.type.ts';
import {
  CapabilityGroupType,
  CapabilityType,
} from '@mdm/pages/RDM/User/Permission/PermissionList.types.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { CAPABILITY_PERMISSION_STATUS, PERMISSION } from '@mdm/constants/constants.ts';
import { useMutateCapabilityGroup } from '@shared/services/hooks/useMutateCapabilityGroup.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';

export const Permission = () => {
  /* State */
  const [selectedCapabilityGroup, setSelectedCapabilityGroup] = useState<SelectOption | null>(null);
  const [roleFilter, setRoleFilter] = useState<string>('');
  const [userPermissionRows, setUserPermissionRows] = useState<CapabilityType[]>([]);
  const [showRoleIdErrorMessage, setShowRoleIdErrorMessage] = useState<boolean>(false);

  /* Constants */
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumb(PAGE_URLS.USER_PERMISSION);
  const navigate = useNavigate();
  const { roleId } = useParams();
  const { mutateCapabilityGroup, isLoading: isCapabilityGroupUpdating } =
    useMutateCapabilityGroup();

  const { userRolesData } = useUserRoleSearch({});
  const { capabilityGroupsData } = useGetCapabilityGroups({});

  /* Functions */
  const onSubmit = () => {
    if (!roleFilter) {
      setShowRoleIdErrorMessage(true);
      return;
    }

    if (selectedCapabilityGroup?.value) {
      const foundCapabilityGroupType = (capabilityGroupsData || []).find(
        (r: CapabilityGroupType) => {
          return r.groupId === selectedCapabilityGroup.value;
        }
      );

      if (foundCapabilityGroupType) {
        mutateCapabilityGroup(
          {
            capabilityGroupPermissionUpdateRequestType: {
              capabilityGroupId: foundCapabilityGroupType.groupId,
              capabilities: userPermissionRows,
            },
          },
          {
            onSuccess: () => {
              const successMessage = t('Success.Action.UserRolePermission.Updated', {
                roleId: roleId || roleFilter,
              });
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            },
          }
        );
      }
    }
  };

  const onTabChange = (tabValue: string, tabLabel: string) => {
    setSelectedCapabilityGroup({
      value: tabValue || '',
      label: tabLabel,
    });
  };

  const onPermissionChange = (row: CapabilityType, event: React.ChangeEvent<HTMLInputElement>) => {
    const foundCapabilityGroup = (capabilityGroupsData ? [...capabilityGroupsData] : []).find(
      (r: CapabilityGroupType) => r.groupId === selectedCapabilityGroup?.value
    );

    if (row && row.capabilityCode) {
      const foundCapability = (foundCapabilityGroup?.capabilities || []).find(
        (r: CapabilityType) => r.capabilityCode === row.capabilityCode
      );

      if (foundCapability) {
        let foundRole = null;
        if (!roleId && roleFilter) {
          foundRole = (foundCapability?.permissions || []).find(
            (r: RoleType) => r.roleId === roleFilter
          );
        } else if (roleId) {
          foundRole = (foundCapability?.permissions || []).find(
            (r: RoleType) => r.roleId === roleId
          );
        }

        if (foundRole) {
          // if permission for the given role doesn't exist update it
          if (event.target.value === PERMISSION.EDIT) {
            foundRole.canAdd = CAPABILITY_PERMISSION_STATUS.CHECKED;
            foundRole.canDelete = CAPABILITY_PERMISSION_STATUS.CHECKED;
            foundRole.canEdit = CAPABILITY_PERMISSION_STATUS.CHECKED;
            foundRole.canView = CAPABILITY_PERMISSION_STATUS.CHECKED;
          } else {
            foundRole.canAdd = CAPABILITY_PERMISSION_STATUS.UNCHECKED;
            foundRole.canDelete = CAPABILITY_PERMISSION_STATUS.UNCHECKED;
            foundRole.canEdit = CAPABILITY_PERMISSION_STATUS.UNCHECKED;
            foundRole.canView = CAPABILITY_PERMISSION_STATUS.CHECKED;
          }
        } else if (foundCapability?.permissions) {
          // if permission for the given role doesn't exist create it
          foundCapability.permissions.push({
            roleId: roleId || roleFilter,
            canAdd:
              event.target.value === PERMISSION.EDIT
                ? CAPABILITY_PERMISSION_STATUS.CHECKED
                : CAPABILITY_PERMISSION_STATUS.UNCHECKED,
            canDelete:
              event.target.value === PERMISSION.EDIT
                ? CAPABILITY_PERMISSION_STATUS.CHECKED
                : CAPABILITY_PERMISSION_STATUS.UNCHECKED,
            canEdit:
              event.target.value === PERMISSION.EDIT
                ? CAPABILITY_PERMISSION_STATUS.CHECKED
                : CAPABILITY_PERMISSION_STATUS.UNCHECKED,
            canView: [PERMISSION.EDIT, PERMISSION.VIEW].includes(event.target.value)
              ? CAPABILITY_PERMISSION_STATUS.CHECKED
              : CAPABILITY_PERMISSION_STATUS.UNCHECKED,
          });
        }
      }
    }

    if (foundCapabilityGroup) {
      setUserPermissionRows([...(foundCapabilityGroup?.capabilities || [])]);
    } else {
      setUserPermissionRows([]);
    }
  };

  /* Hooks */
  useEffect(() => {
    if ((capabilityGroupsData || []).length > 0) {
      const foundCapabilityGroup = (capabilityGroupsData || []).find(
        (capabilityGroup: CapabilityGroupType) => {
          return capabilityGroup.groupId === (capabilityGroupsData || [])[0]?.groupId || '';
        }
      );

      setSelectedCapabilityGroup({
        value: (capabilityGroupsData || [])[0]?.groupId || '',
        label: (capabilityGroupsData || [])[0]?.groupDescription || '',
      });

      setUserPermissionRows([...(foundCapabilityGroup?.capabilities || [])]);
    }
  }, [capabilityGroupsData]);

  useEffect(() => {
    if ((capabilityGroupsData || []).length > 0) {
      const foundCapabilityGroup = (capabilityGroupsData || []).find((r: CapabilityGroupType) => {
        return r.groupId === selectedCapabilityGroup?.value;
      });

      setUserPermissionRows([...(foundCapabilityGroup?.capabilities || [])]);
    }
  }, [capabilityGroupsData, selectedCapabilityGroup]);

  useEffect(() => {
    if (roleId) {
      setRoleFilter(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['capabilityGroups']);
    };
  }, [queryClient]);

  return (
    <>
      <View className={styles['permission-view']}>
        <MasterTitle
          title={t('MasterTitle.UserRolesAndPermissions')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large" onClick={() => navigate(-1)}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Cancel')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button size="large" loading={isCapabilityGroupUpdating} onClick={onSubmit}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('Save')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View
          backgroundColor="secondary"
          direction="column"
          width="100%"
          height="100%"
          wrap={false}
          className={styles['permission-view__content']}
        >
          <View.Item columns={{ s: 12, l: 3 }} className={styles['permission-view__left-column']}>
            <View height="100%" className={styles['permission__tabs']}>
              {(capabilityGroupsData || []).map((tab: CapabilityGroupType) => {
                return (
                  <PermissionTab
                    value={tab.groupId}
                    label={tab.groupDescription}
                    isActive={
                      !!(selectedCapabilityGroup && selectedCapabilityGroup.value === tab.groupId)
                    }
                    onTabChangeHandler={onTabChange}
                  />
                );
              })}
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, l: 9 }} className={styles['permission-view__right-column']}>
            <View height="100%" direction="column" padding={6} gap={4}>
              <View>
                <Text size="125" weight="bold">
                  {selectedCapabilityGroup?.label}
                </Text>
              </View>
              <View direction="row" padding={[4, 0]} gap={4} align="center">
                <View.Item columns={{ s: 12, l: 4 }}>
                  <FormLabel text={t('UserDetails.GroupOrRole')} />
                  <View attributes={{ style: { marginTop: 'var(--st-unit-2)' } }}>
                    <FormControl hasError={showRoleIdErrorMessage}>
                      <Select
                        label=""
                        variant="no-label"
                        name="roleFiler"
                        options={(userRolesData || []).map((role: RoleType) => ({
                          label: role.roleDesc,
                          value: role.roleId,
                        }))}
                        disabled={!!roleId}
                        value={{
                          label:
                            (userRolesData || []).find(
                              (role: RoleType) => role.roleId === (roleId || roleFilter)
                            )?.roleDesc || '',
                          value: roleId || '',
                        }}
                        onValueChange={(value) => {
                          setRoleFilter(value?.value || '');
                          setShowRoleIdErrorMessage(!value?.value);
                        }}
                      />
                      {showRoleIdErrorMessage && (
                        <FormControl.Error>{t('Form.RequiredField')}</FormControl.Error>
                      )}
                    </FormControl>
                  </View>
                </View.Item>
              </View>
              <View direction="row" gap={4} align="center">
                <Table
                  columns={USER_PERMISSION_TABLE_COLUMNS}
                  rows={mapUserPermissionTableRows(
                    userPermissionRows,
                    roleId || roleFilter,
                    onPermissionChange
                  )}
                  isPaginated={true}
                  isCheckboxDisabled={false}
                  pageSize={PAGE_SIZE}
                  defaultPage={DEFAULT_PAGE}
                  isCreditItem={false}
                  isCheckboxTable={false}
                  styleVariant={TableStylingVariants.DETAILS}
                  totalPages={Math.ceil([].length / PAGE_SIZE)}
                  onSort={() => ({})}
                />
              </View>
            </View>
          </View.Item>
        </View>
      </View>
    </>
  );
};
