/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, classNames, Text, Button, ProgressBar, Icon, Divider } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { useTranslation } from 'react-i18next';
import styles from './QualityControlServices.module.scss';
import { ActionCard } from '@shared/components/ActionCard/ActionCard';
import {
  QUALITY_CONTROL_SERVICES,
  myProgressDetails,
  shiftGoalDetails,
} from '@inventory/constants/data';
import { useState } from 'react';
import { QCSButtons } from '@inventory/constants/constants';
import { QualityControlServiceButtons } from '@inventory/types/types';
import { Checkmark } from '@az/starc-ui-icons';

export const QualityControlServices = () => {
  /* State variables */
  const [activeButton, setActiveButton] = useState<QualityControlServiceButtons>(
    QCSButtons.SHIFT_PROGRESS
  );

  /* Constants */
  const { t } = useTranslation();
  return (
    <View height="100%" direction="row">
      <View.Item grow className={styles['layout']}>
        <MasterTitle
          title={t('Sidenav.QCS')}
          // TODO: Add functionality to save page to favorites column
          titleActionProps={{
            label: 'Favorite',
            handleClick: () => {
              return;
            },
          }}
        />
        <View gap={4} padding={6}>
          <View>
            <Text variant="display-3">{t('QCS.selectTitle')}</Text>
          </View>
          <View gap={4}>
            {QUALITY_CONTROL_SERVICES.map((service, index) => (
              <ActionCard
                key={index}
                title={t(service.title)}
                description={t(service.description)}
                searchValue={service.title}
                onClickHandler={service.onClickHandler}
                fullWidth={true}
                hasHoverEffect={false}
              />
            ))}
          </View>
        </View>
      </View.Item>
      <View.Item
        className={classNames(styles['layout'], styles['layout_shift_progress'])}
        columns={3}
      >
        <View padding={4}>
          <View padding={2}>
            <Text variant="display-3">{t('QCS.ShiftProgress')}</Text>
          </View>

          <View padding={2} direction="row" align="center" gap={2}>
            <Button
              variant={activeButton === QCSButtons.MY_PROGRESS ? 'pill' : 'pill-secondary'}
              attributes={{ style: { width: 'fit-content' } }}
              onClick={() => setActiveButton(QCSButtons.MY_PROGRESS)}
            >
              {t('QCS.MyProgressButton')}
            </Button>

            <Button
              variant={activeButton === QCSButtons.SHIFT_PROGRESS ? 'pill' : 'pill-secondary'}
              attributes={{ style: { width: 'fit-content' } }}
              onClick={() => setActiveButton(QCSButtons.SHIFT_PROGRESS)}
            >
              {t('QCS.ShiftGoalButton')}
            </Button>
          </View>

          {activeButton == QCSButtons.SHIFT_PROGRESS && (
            <View gap={4} padding={2}>
              <View>
                <Text variant="display-5" color="500">
                  {t('QCS.ShiftGoalSubtitle')}
                </Text>
              </View>

              <View>
                <View direction="row" justify="space-between" align="center">
                  <View>
                    <Text variant="display-2">55%</Text>
                  </View>
                  <View>
                    <Text variant="small-body" color="500">
                      2,848/5,240 {t('QCS.Pieces')}
                    </Text>
                  </View>
                </View>
                <View>
                  <ProgressBar
                    value={2848}
                    max={5240}
                    label=""
                    attributes={{ style: { width: '100%' } }}
                  />
                </View>
              </View>

              <View>
                <View>
                  <Text variant="display-5" color="500">
                    {t('QCS.PieceGoal')}
                  </Text>
                </View>
                <View>
                  {shiftGoalDetails?.map((piecesTypes) => (
                    <View>
                      <View padding={[2, 2]} direction="row" align="center">
                        <View.Item grow>
                          <View direction="row" gap={2} align="center">
                            <Text>
                              <Icon svg={Checkmark} />
                            </Text>

                            <Text
                              size="087"
                              variant="subtitle-regular"
                              className={styles['widget_regular_po']}
                            >
                              {t(piecesTypes?.title)}
                            </Text>
                          </View>
                        </View.Item>
                        <View.Item grow>
                          <View direction="row" gap={2} justify="space-between" align="center">
                            <View>
                              <ProgressBar
                                value={piecesTypes?.progress}
                                max={piecesTypes?.target}
                                label=""
                                valueColor="primary"
                                attributes={{ style: { height: '8px' } }}
                              />
                            </View>

                            <View>
                              <Text size="087" variant="subtitle-regular" color="500">
                                {piecesTypes?.progress} / {piecesTypes?.target}
                              </Text>
                            </View>
                          </View>
                        </View.Item>
                      </View>

                      <View>
                        <Divider color="300" />
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}

          {activeButton == QCSButtons.MY_PROGRESS && (
            <View gap={4} padding={2}>
              <View>
                <Text variant="display-5" color="500">
                  {t('QCS.MyProgressSubtitle')}
                </Text>
              </View>

              <View>
                <View direction="row" justify="space-between" align="center">
                  <View>
                    <Text variant="display-2">55%</Text>
                  </View>
                  <View>
                    <Text variant="small-body" color="500">
                      2,848/5,240 {t('QCS.MyProgressSubtitle')}
                    </Text>
                  </View>
                </View>
                <View>
                  <ProgressBar
                    value={2848}
                    max={5240}
                    label=""
                    attributes={{ style: { width: '100%' } }}
                  />
                </View>
              </View>

              <View>
                <View>
                  <Text variant="display-5" color="500">
                    {t('QCS.PiecesChecked')}
                  </Text>
                </View>
                <View>
                  {myProgressDetails?.map((piecesTypes) => (
                    <View>
                      <View padding={[2, 2]} direction="row" align="center" justify="space-between">
                        <View direction="row" gap={2} align="center">
                          <Text>
                            <Icon svg={Checkmark} />
                          </Text>

                          <Text
                            size="087"
                            variant="subtitle-regular"
                            className={styles['widget_regular_po']}
                          >
                            {piecesTypes?.title}
                          </Text>
                        </View>

                        <View direction="row" gap={2} align="center">
                          <View>
                            <Text size="087" variant="subtitle-regular" color="500">
                              {piecesTypes?.progress} {t('QCS.Pieces')}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View>
                        <Divider color="300" />
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}
        </View>
      </View.Item>
    </View>
  );
};
