/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { Text, View } from '@az/starc-ui';
import { UserSearchSchemaType } from '@shared/types/schema.type.ts';
import styles from './UserShiftList.module.scss';
import { Table } from '@shared/components/Table/Table.tsx';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';
import {
  TableStylingVariants,
  USER_SHIFT_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import { useCallback, useEffect, useState } from 'react';
import { DAY_OF_WEEK, USER_SHIFT_OPTIONS } from '@mdm/constants/constants.ts';
import { UserShiftRowDataType } from '@mdm/pages/RDM/User/Detail/UserShift.types';
import { mapUserShiftTableRows } from '@mdm/utils/table/tableUtils.tsx';

type Props = {
  userData?: UserSearchSchemaType;
  onShiftClick: (row: UserShiftRowDataType, dayOfWeek: string, isChecked: boolean) => void;
};

export const UserShiftList = ({ userData, onShiftClick }: Props) => {
  /* State */
  const [rows, setRows] = useState<UserShiftRowDataType[]>([]);

  /* Constants */
  const { t } = useTranslation();

  /* Hooks */
  const setRowData = useCallback(() => {
    const primaryFacility = userData?.facilities.find((facility) => facility.primaryFl);
    const shifts = primaryFacility && primaryFacility.shifts ? primaryFacility.shifts : [];

    const userShiftMatrix = USER_SHIFT_OPTIONS.map((shiftOption) => ({
      shiftId: shiftOption.value,
      shiftLabel: shiftOption.label,
      userId: userData?.userId || '',
      MON: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.MONDAY
      ),
      TUE: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.TUESDAY
      ),
      WED: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.WEDNESDAY
      ),
      THU: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.THURSDAY
      ),
      FRI: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.FRIDAY
      ),
      SAT: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.SATURDAY
      ),
      SUN: !!shifts.find(
        (shift) => shift.shiftId === shiftOption.value && shift.dayOfWeek === DAY_OF_WEEK.SUNDAY
      ),
    }));

    setRows(userShiftMatrix);
  }, [userData]);

  useEffect(() => {
    setRowData();
  }, [setRowData, userData]);

  return (
    <View className={styles['shift__list']}>
      <View className={styles['shift__list__title']} direction="row">
        <Text size="125" weight="bold">
          {t('Shift')}
        </Text>
        <View padding={[0, 0, 0, 1]}>
          <Text color="error">*</Text>
        </View>
      </View>

      <View>
        <Table
          columns={USER_SHIFT_TABLE_COLUMNS}
          rows={mapUserShiftTableRows(rows, onShiftClick)}
          isPaginated={false}
          isCheckboxDisabled={false}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          styleVariant={TableStylingVariants.DEFAULT}
          onSort={() => ({})}
          totalPages={0}
        />
      </View>
    </View>
  );
};
