/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

import { ASSET_TYPE_CODE, MAX_LENGTH_FIELD, VEHICLE_FIELD } from '@mdm/constants/constants.ts';
import { t } from 'i18next';
import { isAlphaNumeric } from '@shared/utils/validationUtils.ts';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';
import {
  addCustomMaxLengthAttributeFieldValidation,
  addCustomNumericAttributeFieldValidation,
  addCustomRequiredAttributeFieldValidation,
} from '@mdm/utils/form/validationUtils.tsx';
import { AssetLayoutMappingSchema } from '@mdm/schemas/assetSchema.ts';

export const defaultAttributeValues = [
  {
    name: VEHICLE_FIELD.VEHICLE_TYPE,
    value: '',
  },
  {
    name: VEHICLE_FIELD.FULL_PALLET,
    value: 'false',
  },
  {
    name: VEHICLE_FIELD.PART_PALLET,
    value: 'false',
  },
  {
    name: VEHICLE_FIELD.BINS,
    value: '',
  },
  {
    name: VEHICLE_FIELD.MAX_VEHICLE_TRANSITION,
    value: '',
  },
  {
    name: VEHICLE_FIELD.PICK_METHOD,
    value: '',
  },
  {
    name: VEHICLE_FIELD.PICK_ROUTE,
    value: '',
  },
  {
    name: VEHICLE_FIELD.SCAN_PICK_CONTAINER,
    value: 'false',
  },
  {
    name: VEHICLE_FIELD.SCAN_PICK_LOCATION,
    value: 'false',
  },
  {
    name: VEHICLE_FIELD.SCAN_PICK_SKU,
    value: 'false',
  },
];

export const VehicleSchema = z.object({
  assetKey: z.string().optional(),
  assetCd: z.string().superRefine((val, ctx) => {
    if (val == '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (val.length > MAX_LENGTH_FIELD.VEHICLE_NAME) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: MAX_LENGTH_FIELD.VEHICLE_NAME,
        type: 'string',
        inclusive: true,
        message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.VEHICLE_NAME }),
      });
    }

    if (!isAlphaNumeric(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }
  }),
  assetDesc: z.string().superRefine((val, ctx) => {
    if (val == '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.RequiredField'),
      });
    }

    if (val && val.length > MAX_LENGTH_FIELD.VEHICLE_DESCRIPTION) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: MAX_LENGTH_FIELD.VEHICLE_DESCRIPTION,
        type: 'string',
        inclusive: true,
        message: t('Form.MaxCharacterAmount', { count: MAX_LENGTH_FIELD.VEHICLE_DESCRIPTION }),
      });
    }

    if (val && !isAlphaNumeric(val, true)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('Form.AlphaNumericError'),
      });
    }
  }),
  assetTypeCd: z.string().default(ASSET_TYPE_CODE.VEHICLE),
  attributes: z
    .array(AttributeSchema)
    .superRefine((data, ctx) => {
      const requiredFields = [
        VEHICLE_FIELD.VEHICLE_TYPE,
        VEHICLE_FIELD.FULL_PALLET,
        VEHICLE_FIELD.PART_PALLET,
        VEHICLE_FIELD.BINS,
        VEHICLE_FIELD.MAX_VEHICLE_TRANSITION,
        VEHICLE_FIELD.PICK_METHOD,
        VEHICLE_FIELD.PICK_ROUTE,
      ];
      addCustomRequiredAttributeFieldValidation(data, requiredFields, ctx);

      const numericFields = [
        { key: VEHICLE_FIELD.BINS, allowOnlyPositiveNumber: true },
        { key: VEHICLE_FIELD.MAX_VEHICLE_TRANSITION, allowOnlyPositiveNumber: true },
      ];
      addCustomNumericAttributeFieldValidation(data, numericFields, ctx);

      const maxLengthFields = [
        { name: VEHICLE_FIELD.BINS, maxLength: MAX_LENGTH_FIELD.VEHICLE_BINS },
        {
          name: VEHICLE_FIELD.MAX_VEHICLE_TRANSITION,
          maxLength: MAX_LENGTH_FIELD.VEHICLE_MAX_VEHICLE_TRANSITION,
        },
      ];
      addCustomMaxLengthAttributeFieldValidation(data, maxLengthFields, ctx);
    })
    .nullable()
    .default(defaultAttributeValues),
  layouts: z.array(AssetLayoutMappingSchema).nullable().default([]),
});
