/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { useParams } from 'react-router-dom';
import {
  DOLLAR_VARIANCE_THRESHOLD,
  MATRICS,
  QTY_VARIANCE_THRESHOLD,
} from '@inventory/constants/constants';
import { useGetPhaseProgress } from '@inventory/services/hooks/useCycleCounts';
import { useDownload } from '@shared/hooks/useDownload';
import { getThisWeekMondayToSunday } from '@shared/utils/commonUtils';
import { ProgressCard } from './CycleCountDetailsCards/ProgressCard';
import { AccuracyMeasurementCard } from './CycleCountDetailsCards/AccuracyMeasurementsCard';
import { TopVariancesCard } from './CycleCountDetailsCards/TopVariancesCard';

export const CycleCountDetailsOverview = () => {
  const paramData = useParams();
  const countTypeCdValue = paramData.countTypeCd || '';
  const taskId = paramData.id || '';
  const { fromTs, toTs } = getThisWeekMondayToSunday();

  const { contentRef } = useDownload();

  // Query
  const { data } = useGetPhaseProgress({
    countTypeCd: countTypeCdValue,
    taskId: taskId || '',
    fromTs,
    toTs,
    dollarVarianceThershold: DOLLAR_VARIANCE_THRESHOLD,
    qtyVarianceThershold: QTY_VARIANCE_THRESHOLD,
    metrics: MATRICS,
  });

  const {
    variancesCounted,
    dollarVarianceOverThreshold,
    pieceAdjustmentsOverThreshold,
    locationCounts,
    accuracyMeasurements,
  } = data || {};

  const phaseData = {
    taskId: taskId,
    startTs: fromTs,
    endTs: toTs,
    countTypeCd: countTypeCdValue,
    dollarVarianceThershold: DOLLAR_VARIANCE_THRESHOLD,
    qtyVarianceThershold: QTY_VARIANCE_THRESHOLD,
    variancesCounted: {
      varianceCount: variancesCounted?.varianceCount ?? null,
    },
    dollarVarianceOverThreshold: {
      varianceCount: dollarVarianceOverThreshold?.varianceCount ?? null,
    },
    pieceAdjustmentsOverThreshold: {
      varianceCount: pieceAdjustmentsOverThreshold?.varianceCount ?? null,
    },
    locationCounts: {
      totalFpsLayoutCountExpected: locationCounts?.totalFpsLayoutCountExpected ?? null,
      totalFpsLayoutCountCompleted: locationCounts?.totalFpsLayoutCountCompleted ?? null,
      totalRsrvLayoutCountExpected: locationCounts?.totalRsrvLayoutCountExpected ?? null,
      totalRsrvLayoutCountCompleted: locationCounts?.totalRsrvLayoutCountCompleted ?? null,
    },
    accuracyMeasurements: {
      totalInvAmtExpected: accuracyMeasurements?.totalInvAmtExpected ?? null,
      totalInvAmtCounted: accuracyMeasurements?.totalInvAmtCounted ?? null,
      totalInvQtyExpected: accuracyMeasurements?.totalInvQtyExpected ?? null,
      totalInvAmtAdjCalc: accuracyMeasurements?.totalInvAmtAdjCalc ?? null,
      totalInvQtyCounted: accuracyMeasurements?.totalInvQtyCounted ?? null,
      totalInvQtyAdjustedCalc: accuracyMeasurements?.totalInvQtyAdjustedCalc ?? null,
    },
  };

  return (
    <div ref={contentRef}>
      <View gap={6} padding={[6, 6]} attributes={{ style: { margin: '0px' } }} width="100%">
        <View direction="row" gap={4}>
          <View.Item grow>
            <ProgressCard data={phaseData} />
          </View.Item>
          <View.Item grow>
            <AccuracyMeasurementCard data={phaseData} />
          </View.Item>
        </View>
        <View gap={4}>
          <View.Item grow>
            <TopVariancesCard data={phaseData} />
          </View.Item>
        </View>
      </View>
    </div>
  );
};
