/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Modal, View, Text, Icon, Close, Notification } from '@az/starc-ui';
import { Warning } from '@az/starc-ui-icons';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  InboundTrailerLineType,
  TrailerArrivalUpdateType,
  TrailerOrderType,
} from '@inbound/types/types';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes';
import { useGetInboundOrderBySourceAndReceiptId } from '@inbound/services/hooks/useGetInboundOrderBySourceOrderAndReceiptId';
import {
  ASC,
  DEFAULT_PAGE,
  DOMAIN_TYPE_CD,
  INBOUND_ORDER_STATUS,
  NOT_STARTED,
  PAGE_SIZE,
  SOURCE_ORDER_NUMBER,
  TRAILER_ORDER_STATUS,
} from '@inbound/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { useTrailerArrivalUpdater } from '@inbound/hooks/useTrailerArrivalUpdater';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import * as T from './IcReviewModal.types';
import s from './IcReviewModal.module.scss';
import styles from './../../pages/PODashboard/Detail/PODetail.module.scss';

export const IcReviewModal = ({ isOpen, onClose, data, count }: T.Props) => {
  /* State variables */
  const [quantityUnmatchedData, setQuantityUnmatchedData] =
    useState<InboundTrailerLineType[]>(data);
  const [trailerArrivalEditData, setTrailerArrivalEditData] = useState<TrailerArrivalUpdateType>();
  const [trailerOrderData, setTrailerOrderData] = useState<TrailerOrderType>();
  const [orderStatus, setOrderStatus] = useState<string>();
  const [acceptedArray, setAcceptedArray] = useState<InboundTrailerLineType[]>([]);
  const [unacceptedArray, setUnacceptedArray] = useState<InboundTrailerLineType[]>([]);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleNotification } = useNotificationHandler();
  const { sourceOrderId: sourceOrderId, receiptId: receiptId } = useParams();

  /* Queries */
  const { isLoadingUpdate: isLoading, updateTrailerArrival } = useTrailerArrivalUpdater();

  const { inboundOrderData } = useGetInboundOrderBySourceAndReceiptId(
    {
      sourceOrderNumber: sourceOrderId || '',
      receiptId: receiptId || '',
    },
    true
  );
  const { trailerOrdersData } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE,
        pageSize: PAGE_SIZE * 20,
        sortBy: SOURCE_ORDER_NUMBER,
        direction: ASC,
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: sourceOrderId,
          statusCds: [orderStatus ? orderStatus : NOT_STARTED],
        },
      },
    },
    !!orderStatus
  );

  /* Functions */

  const onSendToIcReview = () => {
    if (
      receiptId &&
      trailerArrivalEditData &&
      inboundOrderData?.trailerOrder.inboundOrder.inboundOrderLines
    ) {
      const updatedUnacceptedOrderLines = unacceptedArray.map((line) => ({
        inboundOrderLineKey: line.inboundOrderLineKey,
        statusCd: INBOUND_ORDER_STATUS.IC_REVIEW_IN_PROGRESS,
      }));

      const updatedAcceptedOrderLines = acceptedArray.map((line) => ({
        inboundOrderLineKey: line.inboundOrderLineKey,
        statusCd: INBOUND_ORDER_STATUS.ACCEPTED,
      }));

      const updatedInboundOrderLines = [
        ...updatedUnacceptedOrderLines,
        ...updatedAcceptedOrderLines,
      ];

      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd || '',
            comment: 'Discreps sent to IC for Review',
            inboundOrder: {
              statusCd: TRAILER_ORDER_STATUS.IN_IC_REVIEW,
              inboundOrderLines: updatedInboundOrderLines,
            },
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
      });

      handleNotification(NOTIFICATION_TYPES.SUCCESS, t('PODashboard.Notification.IcReviewSuccess'));
      navigate(PAGE_URLS.PO_DETAILS(sourceOrderId ?? '', receiptId));
    }
  };
  useEffect(() => {
    if (inboundOrderData) {
      setOrderStatus(inboundOrderData.trailerOrder.inboundOrder.statusCd);

      if (inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines) {
        setQuantityUnmatchedData(
          inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.filter(
            (inboundOrderLine) =>
              inboundOrderLine.totalOrderedQty !== inboundOrderLine.totalReceivedQty
          )
        );
      }
    }
  }, [inboundOrderData]);
  useEffect(() => {
    if (trailerOrdersData) {
      const trailerOrder = trailerOrdersData.content.filter(
        (orderItem: { trailerOrder: { receiptId: string | undefined } }) =>
          orderItem.trailerOrder.receiptId === receiptId
      );

      if (trailerOrder.length > 0) {
        setTrailerOrderData(trailerOrder[0].trailerOrder);
      }
    }
  }, [receiptId, trailerOrdersData]);
  useEffect(() => {
    if (inboundOrderData && trailerOrderData) {
      setTrailerArrivalEditData({
        trailerArrivalTs: trailerOrderData.trailerArrival.trailerArrivalTs,
        trailerCarrierName: trailerOrderData.trailerArrival.trailerCarrierName || '',
        trailerNbr: trailerOrderData.trailerArrival.trailerNbr || '',
        trailerLocationTypeCd: trailerOrderData.trailerArrival.trailerLocationTypeCd || '',
        trailerLocationId: trailerOrderData.trailerArrival.trailerLocationId || '',
        trailerOrders: {
          trailerOrderKey: inboundOrderData.trailerOrder.trailerOrderKey,
          statusCd: inboundOrderData.trailerOrder.statusCd,
          commodityTypeCd: inboundOrderData.trailerOrder.commodityTypeCd,
          inboundOrder: {
            priority: inboundOrderData.trailerOrder.inboundOrder.priority,
            orderLocationTypeCd: inboundOrderData.trailerOrder.inboundOrder.orderLocationTypeCd,
            orderLocationId: inboundOrderData.trailerOrder.inboundOrder.orderLocationId,
            users: inboundOrderData.trailerOrder.inboundOrder.users,
            statusCd: inboundOrderData.trailerOrder.inboundOrder.statusCd,
          },
        },
        priority: inboundOrderData.trailerOrder.inboundOrder.priority ? true : false,
        contactName: trailerOrderData.trailerArrival.contactName ?? null,
        contactPhone: trailerOrderData.trailerArrival.contactPhone ?? null,
        contactEmail: trailerOrderData.trailerArrival.contactEmail ?? null,
      });
    }
  }, [inboundOrderData, trailerOrderData]);

  useEffect(() => {
    const accepted = quantityUnmatchedData.filter((item) => count[item.productId]);
    const unaccepted = quantityUnmatchedData.filter((item) => !count[item.productId]);
    setAcceptedArray(accepted);
    setUnacceptedArray(unaccepted);
  }, [count, quantityUnmatchedData]);

  return (
    <Modal
      open={isOpen}
      className={s['icreview-po-modal']}
      onClose={() => onClose()}
      closeByClickAway={false}
    >
      <View padding={6} direction="column" gap={4}>
        <View direction="row" justify="space-between" className={s['icreview-po-modal__header']}>
          <View direction="column">
            <Text as="h2" size="175" weight="bold" color="primary">
              {t('PODashboard.ICReviewPOModal.ICReviewHeader')}
            </Text>
            <Text>{t('PODashboard.ICReviewPOModal.ICReviewSubHeader')}</Text>
          </View>
          <Button
            variant="ghost"
            onClick={() => onClose()}
            className={classNames(s['close-icon'], s['icreview-po-modal__header__close-button'])}
          >
            <Icon svg={Close} />
          </Button>
        </View>
        <View gap={2} className={s['icreview-po-modal__body']}>
          <View direction="row">
            <Text weight="bold" color="primary">
              {t('PODashboard.ICReviewPOModal.SendToIcReview', {
                count: unacceptedArray.length,
              })}
            </Text>
            <Text color="500">{t('PODashboard.ICReviewPOModal.SendToIcReviewText')}</Text>
          </View>

          {unacceptedArray.map((productItem) => (
            <View
              direction="column"
              padding={2}
              gap={2}
              attributes={{ style: { border: '1px solid #d6d6d6' } }}
            >
              <View.Item>
                <Text weight="bold" color="primary">
                  {productItem.product?.productLocaleDesc}
                </Text>
              </View.Item>
              <View direction="row" gap={2}>
                <Text color="600" size="087">
                  FPS:{productItem?.inboundOrderLineLocations?.[0]?.layoutAttribNameType ?? '--'}{' '}
                  SKU:
                  {productItem.productId} Part:
                  {productItem.partNbrId ? productItem.partNbrId : '--'}
                </Text>
              </View>
            </View>
          ))}

          <View direction="column" gap={2}>
            <Text weight="bold" color="primary">
              {t('PODashboard.ICReviewPOModal.Accepted', {
                count: acceptedArray.length,
              })}
            </Text>

            <Notification
              className={styles['po-detail__notification_warning']}
              type="custom"
              customNotification={{
                svg: Warning,
                barColor: 'warning',
                iconColor: 'warning',
              }}
              text={t('PODashboard.ICReviewPOModal.Notification')}
            />
          </View>
          {acceptedArray.length > 0 &&
            acceptedArray.map((productItem) => (
              <View
                direction="column"
                padding={2}
                gap={2}
                attributes={{ style: { border: '1px solid #d6d6d6' } }}
              >
                <View.Item>
                  <Text weight="bold" color="primary">
                    {productItem.product?.productLocaleDesc}
                  </Text>
                </View.Item>
                <View direction="row" gap={2}>
                  <Text color="600" size="087">
                    FPS:{productItem?.inboundOrderLineLocations?.[0]?.layoutAttribNameType ?? '--'}{' '}
                    SKU:
                    {productItem.productId} Part:
                    {productItem.partNbrId ? productItem.partNbrId : '--'}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </View>
      <View className={s['icreview-po-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={s['icreview-po-modal__footer__actions']}
        >
          <Button
            variant="secondary"
            onClick={() => onClose()}
            attributes={{ style: { width: 'fit-content' } }}
          >
            <Text>{t('Cancel')}</Text>
          </Button>

          <Button
            variant="primary"
            onClick={() => onSendToIcReview()}
            attributes={{ style: { width: 'fit-content' } }}
            type="submit"
            loading={isLoading}
          >
            <Text> {t('PODashboard.SendToIcReview')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
