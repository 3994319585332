/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const PerformActionSchema = z.object({
  userId: z.string().optional(),
  layoutName: z.string().optional(),
  layoutDistinctName: z.string().optional(),
  layoutType: z.string().optional(),
  parentLayoutDistinctName: z.string().nullable().optional(),
  parentLayoutName: z.string().optional(),
  adjustmentCd: z.number().optional(),
  counterUserId: z.string().optional(),
  recounterUserId: z.string().optional(),
  countTs: z.string().optional(),
  productId: z.string().optional(),
  partNbr: z.string().optional(),
  productLongDesc: z.string().optional(),
  productCategoryName: z.string().optional(),
  currentBaseCostAmt: z.number().optional(),
  receiptLabel: z.string().optional(),
  totalInvAmtExpected: z.number().optional(),
  totalInvAmtCounted: z.number().optional(),
  totalInvQtyExpected: z.number().optional(),
  totalInvQtyCounted: z.number().optional(),
  productStatusCd: z.string().optional(),
  comment: z.string().optional(),
});

export const LocationSchema = z.object({
  assignedUserId: z.string().optional().nullable(),
  assignedTs: z.string().optional().nullable(),
  startTs: z.string().optional().nullable(),
  endTs: z.string().optional().nullable(),
  layoutDistinctName: z.string().optional(),
  layoutName: z.string().optional().nullable(),
  layoutType: z.string().optional().nullable(),
  parentLayoutDistinctName: z.string().nullable().optional(),
  parentLayoutName: z.string().optional().nullable(),
  productId: z.string().optional().nullable(),
  countStatusCd: z.string().optional().nullable(),
  levelSortDirection: z.string().optional().nullable(),
  performActions: z.array(PerformActionSchema).optional(),
});

export const AssignedUserSchema = z.object({
  parentLayoutDistinctName: z.string().nullable().optional(),
  userId: z.string().optional(),
  firstName: z.string().optional().nullable(),
  lastName: z.string().optional().nullable(),
  assignedTs: z.string().optional().nullable(),
  layoutLocationsAssigned: z.number().nullable().optional(),
  layoutLocationsCompleted: z.number().nullable().optional(),
  userStatusCd: z.string().optional(),
  performActions: z.array(PerformActionSchema).optional(),
});

export const SubzoneSchema = z.object({
  startTs: z.string().optional().nullable(),
  endTs: z.string().nullable().optional(),
  statusCd: z.string().optional(),
  parentLayoutDistinctName: z.string().nullable().optional(),
  parentLayoutName: z.string().nullable().optional(),
  parentMapSequence: z.number().nullable().optional(),
  totalInvAmtExpected: z.number().optional().nullable(),
  totalInvAmtCounted: z.number().optional().nullable(),
  totalInvAmtAdjCalc: z.number().nullable().optional(),
  totalInvAmtExceedsVariance: z.number().nullable().optional(),
  totalInvQtyExpected: z.number().optional().nullable(),
  totalInvQtyCounted: z.number().optional().nullable(),
  totalFpsLayoutCountExpected: z.number().optional().nullable(),
  totalFpsLayoutCountCompleted: z.number().optional().nullable(),
  totalRsrvLayoutCountExpected: z.number().optional().nullable(),
  totalRsrvLayoutCountCompleted: z.number().optional().nullable(),
  layoutLocationsAssigend: z.number().nullable().optional(),
  pageIndex: z.number().nullable().optional(),
  pageSortDir: z.string().nullable().optional(),
  layoutLocationsAssigned: z.number().nullable(),
  locations: z.array(LocationSchema).optional().optional(),
  assignedUsers: z.array(AssignedUserSchema).optional(),
});

export const LocationCountSearchSchema = z.object({
  taskId: z.string().optional(),
  year: z.number().nullable().optional(),
  week: z.number().nullable().optional(),
  phase: z.number().nullable().optional(),
  startTs: z.string().nullable().optional(),
  endTs: z.string().nullable().optional(),
  endReason: z.string().nullable().optional(),
  countTypeCd: z.string(),
  statusCd: z.string(),
  reasonCd: z.string().nullable().optional(),
  totalInvAmtExpected: z.number().optional().nullable(),
  totalInvAmtCounted: z.number().optional().nullable(),
  totalInvQtyExpected: z.number().optional().nullable(),
  totalInvAmtAdjCalc: z.number().nullable().nullable(),
  totalInvQtyCounted: z.number().optional().nullable(),
  totalInvQtyAdjustedCalc: z.number().optional().nullable(),
  totalInvAmtExceedsVariance: z.number().nullable().nullable(),
  totalFpsLayoutCountExpected: z.number().optional().nullable(),
  totalFpsLayoutCountCompleted: z.number().optional().nullable(),
  totalRsrvLayoutCountExpected: z.number().optional().nullable(),
  totalRsrvLayoutCountCompleted: z.number().optional().nullable(),
  subzones: z.array(SubzoneSchema).optional(),
});
