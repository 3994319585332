/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Divider, Text, Toggle, View, Modal, Actionable, Close, Icon, Button } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Tag } from '@shared/components/Tag/Tag';
import { PriorityTag } from '@inbound/components/PriorityTag/PriorityTag';
import * as T from './AssignTaskModal.types';
import styles from './AssignTaskModal.module.scss';
import { generateDateString } from '@ofm/utils/utils';
import { useMutateUpdateTask } from '@taskManagement/services/hooks/useMutateUpdateTask';
import { updateTaskAttributes } from '@taskManagement/utils/utils';
import {
  attributeKeys,
  TASK_ASSIGNMENT_STATUS,
  TASK_ATTRIBUTES,
} from '@shared/constants/constants';

export const AssignTaskModal = ({
  isOpen,
  onClose,
  POModalData,
  onSuccess,
  userId,
  firstName,
  lastName,
  shiftId,
}: T.Props) => {
  /* Constant */
  const { t } = useTranslation();
  const commodityValue =
    (POModalData &&
      POModalData.attributes?.filter((item) => item.key == TASK_ATTRIBUTES.COMMODITY_TYPE_CD)[0]
        ?.value) ||
    '';
  const orderTypeValue =
    (POModalData &&
      POModalData.attributes?.filter((item) => item.key == TASK_ATTRIBUTES.ORDER_TYPE_CD)[0]
        ?.value) ||
    '';

  const [urgentTaskChecked, setUrgentTaskChecked] = useState(false);
  const handleValueChange = (urgentTaskChecked: boolean) => {
    setUrgentTaskChecked(urgentTaskChecked);
  };

  /* Query */
  const { isLoading, mutateUpdateTask } = useMutateUpdateTask();

  /* Function */
  const OnClickAssignTaskSuccess = (taskNo: string, userId: string) => {
    if (POModalData) {
      const attributesPayload = updateTaskAttributes(POModalData.attributes, attributeKeys);
      const userAssignment = POModalData?.assignments?.find(
        (assignment) => assignment.userId === userId
      );
      const isUrgentTask = urgentTaskChecked ? 999 : 0;

      mutateUpdateTask(
        {
          updateTaskRequest: [
            {
              taskNo: taskNo,
              departmentId: POModalData.departmentId,
              domainType: POModalData.domainType || null,
              statusCd: POModalData?.statusCd || null,
              taskTypeCd: POModalData.taskTypeCd,
              startTs: POModalData?.startTs || null,
              endTs: POModalData?.endTs || null,
              priority: POModalData?.priority || null,
              totalPoints: POModalData?.totalPoints || 0,
              completedPoints: POModalData?.completedPoints || 0,
              nextTask: POModalData?.nextTask || null,
              attributes: attributesPayload,
              assignments: [
                {
                  userId: userId,
                  status: TASK_ASSIGNMENT_STATUS.ASSIGNED,
                  startTs: userAssignment?.startTs || null,
                  endTs: userAssignment?.endTs || null,
                  progress: userAssignment?.completedPoints || null,
                  executionPriority: userAssignment?.executionPriority || null,
                  typeCd: isUrgentTask,
                },
              ],
            },
          ],
        },
        {
          onSuccess: () => {
            if (onSuccess) {
              onSuccess();
            }
          },
        }
      );
    }

    // setUrgentTaskChecked(false); // FYI, its required in future flow
  };
  const OnModalClose = () => {
    setUrgentTaskChecked(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={OnModalClose} className={styles['assign-task-modal']}>
      <View padding={6} direction="row" justify="space-between" className={styles['modal__header']}>
        <View.Item columns={11}>
          <Text size="175" weight="bold">
            {firstName && lastName
              ? t(`AssignTaskConfirmationModal.TitleText`, {
                  userName: `${firstName} ${lastName}`,
                })
              : ''}
          </Text>
          <Text size="087" variant="large-body" color="primary">
            {t(`AssignTaskConfirmationModal.SubtitleText`)}
          </Text>
        </View.Item>
        <Actionable onClick={onClose}>
          <Icon svg={Close} />
        </Actionable>
      </View>

      <View padding={[0, 6, 6, 6]} className={styles['modal__body']}>
        {POModalData && userId && (
          <>
            <View gap={2} direction="row">
              <Text size="075" weight="bold" color="500" textCase="uppercase">
                {t(`AssignTaskConfirmationModal.PurchaseOrders`)}
              </Text>
            </View>
            <View gap={4} direction="row">
              <Text size="125" weight="bold" color="primary">
                {POModalData.attributes?.filter(
                  (item) => item.key == TASK_ATTRIBUTES.VENDOR_NAME
                )[0]?.value || '--'}
                &nbsp;
                {POModalData.priority ? <PriorityTag /> : ''}
              </Text>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.CompatibleVehicles`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  -
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.PO#`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t('PoTask.PO', {
                    poNumber:
                      POModalData.attributes?.filter((item) => item.key == 'sourceOrderNbr')[0]
                        ?.value || '',
                  })}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Assignees`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t(`AssignTaskConfirmationModal.NoReceiversAssigned`)}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Pieces#`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t(`AssignTaskConfirmationModal.Pieces`, {
                    total:
                      POModalData.attributes?.filter(
                        (item) => item.key == TASK_ATTRIBUTES.TOTAL_LINE_COUNT
                      )[0]?.value || '--',
                  })}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.ArrivalTime`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {generateDateString(
                    new Date(
                      POModalData.attributes?.filter(
                        (item) => item.key == TASK_ATTRIBUTES.TRAILER_ARRIVAL_TS
                      )[0]?.value || 0
                    ),
                    t('DateFormat.ShortTime')
                  )}
                </Text>
              </View.Item>
            </View>
            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Shift`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {shiftId}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.GoalHours`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  -
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Vendor`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {(POModalData &&
                    POModalData.attributes?.filter(
                      (item) => item.key == TASK_ATTRIBUTES.VENDOR_NAME
                    )[0]?.value) ||
                    '--'}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Carrier`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  -
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Trailer`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  -
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Location`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {t('AssignTaskConfirmationModal.Loc', {
                    locationId:
                      POModalData.attributes?.filter(
                        (item) => item.key == TASK_ATTRIBUTES.TRAILER_LOCATION_TYPE_CD
                      )[0]?.value || '--',
                  })}
                  &nbsp;,&nbsp;
                  {t('AssignTaskConfirmationModal.Loc', {
                    locationId:
                      POModalData.attributes?.filter(
                        (item) => item.key == TASK_ATTRIBUTES.TRAILER_LOCATION_ID
                      )[0]?.value || '--',
                  })}
                </Text>
              </View.Item>
            </View>

            <View gap={6} direction="row">
              <View.Item grow>
                <Text size="087" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.Commodity`)}
                </Text>
              </View.Item>
              <View.Item grow>
                <Text size="087" variant="large-body" color="primary">
                  {orderTypeValue && <Tag text={orderTypeValue} variant="order" />}
                  {commodityValue && <Tag text={commodityValue} variant="order" />}
                </Text>
              </View.Item>
            </View>

            <Divider className={styles['assign-task-modal__bottom-divider']} />

            <View gap={6} direction="row">
              <View.Item columns={10}>
                <Text size="100" variant="display-5" color="primary">
                  {t(`AssignTaskConfirmationModal.UrgentTask`)}
                </Text>
                <Text size="087" variant="large-body" color="primary">
                  {t(`AssignTaskConfirmationModal.UrgentTaskSubText`)}
                </Text>
              </View.Item>
              <View.Item columns={2}>
                <Toggle
                  name="controlled"
                  checked={urgentTaskChecked}
                  onValueChange={handleValueChange}
                  className={styles['assign-task-modal__priority-toggle']}
                />
              </View.Item>
            </View>
          </>
        )}
      </View>

      <View gap={3} direction="row" padding={6} justify="end" className={styles['modal__footer']}>
        <View.Item columns={3}>
          <Button variant="secondary" size="large" onClick={OnModalClose}>
            {t(`AssignTaskConfirmationModal.Cancel`)}
          </Button>
        </View.Item>
        <View.Item columns={5}>
          {POModalData && userId && (
            <Button
              size="large"
              onClick={() => OnClickAssignTaskSuccess(POModalData.taskNo, userId)}
              loading={isLoading}
            >
              {t(`AssignTaskConfirmationModal.YesAssignTask`)}
            </Button>
          )}
        </View.Item>
      </View>
    </Modal>
  );
};
