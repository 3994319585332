/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { LocationCountSearchSchema } from '@inventory/schemas/CycleCountSchema';
import { LOCATION_COUNT_SEARCH_ENDPOINTS } from '@shared/constants/routes';
import { apiService } from '@shared/services/apiService';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';
import {
  DownloadLocationCountSubzoneParams,
  GetLocationCountSearchParams,
  GetPhaseProgressParams,
} from '@inventory/types/service.types';
import { formatErrorLog } from '@ofm/utils/utils';
import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';
import { DEFAULT_PAGE, DESC } from '@inbound/constants/constants';
import { SINGLE_PAGE_SIZE } from '@inventory/constants/constants';
import { RESPONSE_TYPE, SORT_BY } from '@shared/constants/constants';

export const cycleCountsService = {
  getWallToWallData: async (options: GetLocationCountSearchParams) => {
    const payload = {
      searchPage: {
        page: options?.searchPage.page,
        size: options?.searchPage.size,
        ...(options?.searchPage.sort && { sortBy: options.searchPage.sort }),
      },
      searchCriteria: {
        ...options?.searchCriteria,
      },
    };
    const resp = await apiService.post(
      LOCATION_COUNT_SEARCH_ENDPOINTS.GET_LOCATION_COUNT_SEARCH_DATA,
      payload
    );
    return paginatedParseAndLog(LocationCountSearchSchema, resp.data);
  },

  getPhaseProgress: async (options: GetPhaseProgressParams) => {
    const payload = options;
    const resp = await apiService.post(
      LOCATION_COUNT_SEARCH_ENDPOINTS.GET_PHASE_PROGRESS_DATA,
      payload
    );
    return resp.data;
  },

  downloadSubzones: async (options?: DownloadLocationCountSubzoneParams) => {
    const payload = {
      searchCriteria: {
        ...options?.searchCriteria,
      },
      searchPage: {
        page: options?.searchPage?.page || DEFAULT_PAGE,
        size: options?.searchPage?.size || SINGLE_PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.END_TS,
            direction: DESC,
          },
        ],
      },
    };

    try {
      const response = await apiService.post(
        LOCATION_COUNT_SEARCH_ENDPOINTS.DOWNLOAD_LOCATION_COUNT_SUBZONES,
        payload,
        {
          responseType: RESPONSE_TYPE,
          headers: {
            Accept: options?.type,
          },
        }
      );
      const fileType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: fileType });

      const contentDisposition = response.headers['content-disposition'];
      let fileName = null;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch && fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      throw new LogError(formatErrorLog(ErrorLogType.AXIOS, error));
    }
  },
};
