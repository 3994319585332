/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useAtom } from 'jotai';

import { AccordionGroup, View } from '@az/starc-ui';
import { MatrixAccordion } from '@outbound/components/MatrixAccordion/MatrixAccordion';
import { MatrixHeader } from '@outbound/components/MatrixAccordion/MatrixHeader';
import { displayOptionsAtom } from '@outbound/atoms/outboundMatrix/outboundMatrixAtoms';
import { routes, waves, release } from '@outbound/components/MatrixAccordion/data';
import { MatrixSortOptions } from '@outbound/constants/constants';
import * as T from '@outbound/components/MatrixAccordion/MatrixAccordion.types';

import {
  replenishmentMatrixRouteData,
  replenishmentMatrixWaveData,
  replenishmentMatrixReleaseData,
} from '../data';
import styles from '../ControlDesk.module.scss';

function reorderLocations(locationsList: T.MatrixRow[], fromPosition: number, toPosition: number) {
  const clonedLocationsList = [...locationsList];

  if (fromPosition < toPosition) {
    clonedLocationsList.splice(toPosition + 1, 0, clonedLocationsList[fromPosition]);
    clonedLocationsList.splice(fromPosition, 1);
  } else if (toPosition < fromPosition) {
    clonedLocationsList.splice(toPosition, 0, clonedLocationsList[fromPosition]);
    clonedLocationsList.splice(fromPosition + 1, 1);
  }

  return clonedLocationsList;
}

export const ReplenishmentMatrix = () => {
  /* Atoms */
  const [displayOptions] = useAtom(displayOptionsAtom);

  /* State variables */
  const [controlDeskData, setControlDeskData] = useState<T.MatrixRow[]>([]);
  const [headerData, setHeaderData] = useState<T.MatrixHeader[]>([]);
  const [draggedItemIdentifier, setDraggedItemIdentifier] = useState('');
  const [draggedOverContainerIdentifier, setDraggedOverContainerIdentifier] = useState('');
  const [open, setOpen] = useState<string[]>([]);

  /* Functions */
  const onExpand = () => {
    setOpen(controlDeskData.map((row) => row.location));
  };

  const onCollapse = () => {
    setOpen([]);
  };

  const onDragStart = (value: string) => setDraggedItemIdentifier(value);
  const onDragEnd = () => clearState();
  const onDragEnter = (value: string) => setDraggedOverContainerIdentifier(value);
  const onDragExit = () => setDraggedOverContainerIdentifier('');
  const onDrop = () => {
    if (!draggedItemIdentifier || !draggedOverContainerIdentifier) {
      clearState();
      return;
    }

    const fromIndex = controlDeskData.findIndex((item) => item.location === draggedItemIdentifier);
    const toIndex = controlDeskData.findIndex(
      (item) => item.location === draggedOverContainerIdentifier
    );
    setControlDeskData((item) => reorderLocations(item, fromIndex, toIndex));

    clearState();
  };

  const clearState = () => {
    setDraggedItemIdentifier('');
    setDraggedOverContainerIdentifier('');
  };

  /* Hooks */
  useEffect(() => {
    if (displayOptions.sortBy === MatrixSortOptions.WAVE) {
      setControlDeskData(replenishmentMatrixWaveData);
      setHeaderData(waves);
    } else if (displayOptions.sortBy === MatrixSortOptions.RELESASE) {
      setControlDeskData(replenishmentMatrixReleaseData);
      setHeaderData(release);
    } else {
      setControlDeskData(replenishmentMatrixRouteData);
      setHeaderData(routes);
    }
  }, [displayOptions.sortBy]);

  return (
    <View direction="column" height="100%">
      <View padding={[0, 1, 0, 0]} className={styles['outbound-matrix__container']}>
        <MatrixHeader
          data={headerData}
          displayOptions={displayOptions}
          onExpand={onExpand}
          onCollapse={onCollapse}
        />
        <AccordionGroup
          openAccordionNames={open}
          onGroupChange={(updatedNames) => setOpen(updatedNames)}
        >
          {controlDeskData.map((row, index) => {
            return (
              <div
                key={index}
                className={`${styles['outbound-matrix__row--drag-item']} ${
                  row.location === draggedOverContainerIdentifier
                    ? styles['outbound-matrix__row--dragging']
                    : ''
                }`}
                onDrop={onDrop}
                onDragEnter={() => onDragEnter(row.location)}
                onDragExit={onDragExit}
                onDragOver={(e) => e.preventDefault()}
                onDragStart={() => onDragStart(row.location)}
                onDragEnd={onDragEnd}
                draggable
              >
                <MatrixAccordion row={row} open={open} displayOptions={displayOptions} />
              </div>
            );
          })}
        </AccordionGroup>
      </View>
      <Outlet />
    </View>
  );
};
