/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FinalizationDashboardDividerRowType } from '@inventory/types/types';

const FINALIZATIONDASHBOARD_DIVIDER_LABELS: { [key: string]: FinalizationDashboardDividerRowType } =
  {
    '24 Hours': {
      receiptId: 'c69a2a6bc85745168e213a91067f2141',
      dividerLabel: '24 Hours',
      priority: false,
      arrivalTime: new Date(),
      vendorName: '',
      poNumber: '240000000000000000',
      shippedPcs: 0,
      location: '',
      linesWithError: 0,
      sentFromInbound: new Date(),
      commodity: '',
      poType: '',
    },
    '48 Hours': {
      dividerLabel: '48 Hours',
      receiptId: 'c69a2a6bc85745168e213a91067f2141',
      priority: false,
      arrivalTime: new Date(),
      vendorName: '',
      poNumber: '480000000000000000',
      shippedPcs: 0,
      location: '',
      linesWithError: 0,
      sentFromInbound: new Date(),
      commodity: '',
      poType: '',
    },
    '72 Hours': {
      dividerLabel: '72 Hours',
      receiptId: 'c69a2a6bc85745168e213a91067f2141',
      priority: false,
      arrivalTime: new Date(),
      vendorName: '',
      poNumber: '720000000000000000',
      shippedPcs: 0,
      location: '',
      linesWithError: 0,
      sentFromInbound: new Date(),
      commodity: '',
      poType: '',
    },
    '72+ Hours': {
      dividerLabel: '72+ Hours',
      receiptId: 'c69a2a6bc85745168e213a91067f2141',
      priority: false,
      arrivalTime: new Date(),
      vendorName: '',
      poNumber: '721000000000000000',
      shippedPcs: 0,
      location: '',
      linesWithError: 0,
      sentFromInbound: new Date(),
      commodity: '',
      poType: '',
    },
  };

const LOCATION_TYPES = [
  {
    label: 'All Location Types',
    value: 'all',
  },
  {
    label: 'RESERVE',
    value: 'Reserve',
  },
  {
    label: 'SLOT',
    value: 'slot',
  },
];

export { FINALIZATIONDASHBOARD_DIVIDER_LABELS, LOCATION_TYPES };
