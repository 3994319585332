/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { USER_ENDPOINTS, USER_SEARCH_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@shared/services/apiService.ts';
import { UserSearchSchema } from '@shared/schemas/usersSearchSchema';
import { UsersSearchParams } from '@shared/types/service.type';
import { paginatedParseAndLog } from '@shared/utils/service/serviceUtils';
import { UserRequestType } from '@shared/types/schema.type.ts';
import { GetUserParams } from '@mdm/types/service.type.ts';
import { getSelectedDC } from '@mdm/utils/utils.ts';

export const userService = {
  usersSearch: async (options?: UsersSearchParams) => {
    const payload = {
      searchPage: {
        page: options?.searchPage.page,
        size: options?.searchPage.size,
        ...(options?.searchPage.sortBy && { sortBy: options.searchPage.sortBy }),
        ...(options?.searchPage.direction && {
          direction: options.searchPage.direction.toUpperCase(),
        }),
      },
      searchCriteria: {
        ...options?.searchCriteria,
      },
    };

    const response = await apiService.post(USER_ENDPOINTS.USERS_SEARCH, payload, {
      headers: {
        'Facility-Id': getSelectedDC(),
      },
    });
    return paginatedParseAndLog(UserSearchSchema, response.data);
  },
  getUsers: async (options: GetUserParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        ...(options?.userId && { userId: options.userId }),
        ...(options?.firstName && { firstName: options.firstName }),
        ...(options?.lastName && { lastName: options.lastName }),
        ...(options?.deptId && { deptId: options.deptId }),
        ...(options?.primaryDeptId && { primaryDeptId: options.primaryDeptId }),
        ...(options?.fromDate && { fromDate: options.fromDate }),
        ...(options?.toDate && { toDate: options.toDate }),
        ...(options?.roleId && { roleId: options.roleId }),
        ...(options?.shiftId && { shiftId: options.shiftId }),
        ...(options?.userType && options.userType.length > 0 && { userType: options.userType }),
      },
    };

    const response = await apiService.post(USER_SEARCH_ENDPOINTS.GET_USER_SEARCH_DATA, payload, {
      headers: {
        'Facility-Id': getSelectedDC(),
      },
    });
    return paginatedParseAndLog(UserSearchSchema, response.data);
  },
  getUserByUserId: async (userId: string) => {
    const payload = {
      searchCriteria: { userId },
      searchPage: {},
    };

    const response = await apiService.post(USER_SEARCH_ENDPOINTS.GET_USER_SEARCH_DATA, payload, {
      headers: {
        'Facility-Id': getSelectedDC(),
      },
    });
    return paginatedParseAndLog(UserSearchSchema, response.data);
  },
  updateUser: async (userId: string, userRequestType: UserRequestType) => {
    const resp = await apiService.put(USER_ENDPOINTS.UPDATE_USER(userId), userRequestType, {
      headers: {
        'Facility-Id': getSelectedDC(),
      },
    });
    return resp.data;
  },
};
