/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { View, Text, Button, TextField, Icon, Actionable } from '@az/starc-ui';

import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { EMPTY_VALUE, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { CheckmarkCircleFilled } from '@shared/assets/icons';

import { POActions } from '@inbound/constants/constants';
import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';

import {
  DISCREPANCY_ACTION_STATE,
  DISCREPANCY_STATUS,
  MAX_PCS_INPUT_LENGTH,
  NUMERIC_REGEX,
  PODiscrepancyAdjResons,
} from '@inventory/constants/constants';

import { PODiscrepancyLocationRowProps } from './PODiscripancy.types';

import styles from './PODiscrepancy.module.scss';
import { generateLayoutNameFromDistinctName } from '@shared/utils/commonUtils';
import { LocationSearchModal } from './LocationSearchModal';

export const LocationRow = ({
  location,
  trailer,
  orderLines,
  setIsAllDiscrepancyResolved,
  autoResolvedLocations,
  status,
  isOrderLineEdit,
  setSavedDiscrepanciesCount,
}: PODiscrepancyLocationRowProps) => {
  /* State variables */
  const [receivedQty, setReceivedQty] = useState(location.receivedQtyAdj ?? null);
  const [adjReason, setAdjReason] = useState<{ label: string; code: string } | null>(null);
  const [actionState, setActionState] = useState(
    location.receivedQtyAdj !== null ? DISCREPANCY_ACTION_STATE.EDIT : DISCREPANCY_ACTION_STATE.SAVE
  );
  const [locationSearchModal, setLocationSearchModal] = useState(false);

  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const autoResolved = autoResolvedLocations?.includes(location.inboundOrderLineLocationKey);

  /* Queries */
  const { isLoading: trailerArrivalLoading, mutateUpdateTrailerArrival } =
    useUpdateTrailerArrival();

  const onUpdateTrailerOrder = () => {
    mutateUpdateTrailerArrival(
      {
        receiptId: trailer?.receiptId || '',
        operation: POActions.EDIT_DETAILS,
        trailerArrivalUpdateRequest: {
          trailerLocationId: trailer?.trailerArrival?.trailerLocationId || '',
          trailerLocationTypeCd: trailer?.trailerArrival?.trailerLocationTypeCd || '',
          trailerOrders: [
            {
              trailerOrderKey: trailer?.trailerOrderKey || '',
              commodityTypeCd: trailer?.commodityTypeCd || '',
              inboundOrder: {
                inboundOrderLines: [
                  {
                    inboundOrderLineKey: orderLines.inboundOrderLineKey,
                    productId: orderLines.productId,
                    inboundOrderLineLocations: [
                      {
                        inboundOrderLineLocationKey: location.inboundOrderLineLocationKey,
                        layoutDistinctName: location.layoutDistinctName,
                        receivedQtyAdj: receivedQty ?? null,
                        adjReasonCd: adjReason?.code ?? '',
                      },
                    ],
                  },
                ],
              },
            },
          ],
          contactName: null,
          contactPhone: null,
          contactEmail: null,
        },
      },
      {
        onSuccess: () => {
          handleNotification(
            NOTIFICATION_TYPES.SUCCESS,
            t('FinalizationDashboard.Notification.DiscepancyUpdated')
          );
          setActionState(DISCREPANCY_ACTION_STATE.EDIT);
        },
      }
    );
  };

  /*Functions */
  const onInputQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NUMERIC_REGEX.test(e.target.value)) {
      const inputQty = e.target.value;
      inputQty === '' ? setReceivedQty(null) : setReceivedQty(Number(inputQty));
    }
  };

  const onEdit = () => {
    setActionState(DISCREPANCY_ACTION_STATE.SAVE);
    setIsAllDiscrepancyResolved(false);
  };

  const onSave = () => {
    onUpdateTrailerOrder();
    status === DISCREPANCY_STATUS.CHECKED &&
      setSavedDiscrepanciesCount((count: number) => count + 1);
  };

  const handleAdjReasonSet = (difference: number) => {
    difference === 0
      ? setAdjReason(PODiscrepancyAdjResons.RECOUNT)
      : difference > 0
      ? setAdjReason(PODiscrepancyAdjResons.RECEIVE)
      : setAdjReason(PODiscrepancyAdjResons.REBOX);
  };

  /*Hooks */
  const difference = useMemo(() => {
    if (location.receivedQtyAdj && receivedQty !== null) {
      const difference = receivedQty - location.totalReceivedQty;
      handleAdjReasonSet(difference);
      return difference;
    } else {
      const difference = receivedQty !== null ? receivedQty - location.totalReceivedQty : 0;
      handleAdjReasonSet(difference);
      return difference;
    }
  }, [location, receivedQty]);

  // UseEffect added to change the action state according to order line edit
  useEffect(() => {
    if (status === DISCREPANCY_STATUS.CHECKED) {
      isOrderLineEdit
        ? setActionState(DISCREPANCY_ACTION_STATE.SAVE)
        : setActionState(DISCREPANCY_ACTION_STATE.EDIT);
    }
  }, [isOrderLineEdit, status]);

  return (
    <View
      key={location.inboundOrderLineLocationKey}
      direction="row"
      align="center"
      padding={[2, 0]}
      wrap={false}
      className={styles['po-discrepancy__card__details__row']}
    >
      <View width="39.5%">
        <View direction="row" gap={2} align="center">
          <Actionable
            onClick={() => {
              setLocationSearchModal(true);
            }}
          >
            <Text weight="bold" decoration="underline" variant="text-link">
              {`${location.layoutAttribNameType} - ${
                location.layoutName ||
                generateLayoutNameFromDistinctName(location.layoutDistinctName)
              }`}
            </Text>
          </Actionable>

          {location.receivedQtyAdj !== null && actionState === DISCREPANCY_ACTION_STATE.EDIT && (
            <Icon svg={CheckmarkCircleFilled} />
          )}
          {autoResolved && <Text>{t('FinalizationDashboard.AutoResolveLabel')}</Text>}
        </View>
      </View>
      <View width="9.2%">
        <Text>{location.totalReceivedQty}</Text>
      </View>
      <View padding={[0, 4, 0, 0]} width="10.2%">
        <TextField
          size="small"
          value={
            autoResolved
              ? String(location.totalReceivedQty)
              : receivedQty === null
              ? ''
              : String(receivedQty)
          }
          inputAttributes={{ maxLength: MAX_PCS_INPUT_LENGTH, placeholder: '--' }}
          onChange={onInputQtyChange}
          disabled={actionState === DISCREPANCY_ACTION_STATE.EDIT || autoResolved}
          className={styles['po-discrepancy__card__details__row__pcs-input']}
          endElement={
            <View>
              <Text color="400">{t('FinalizationDashboard.Pices')}</Text>
            </View>
          }
        />
      </View>
      <View width="8.2%">
        <Text>{difference > 0 ? `+${difference}` : difference}</Text>
      </View>
      <View direction="row" width="33%" justify="end" align="center" gap={2}>
        <View width="21%">
          <TextField
            size="small"
            value={autoResolved || receivedQty === null ? '' : t(adjReason?.label || '')}
            inputAttributes={{ readOnly: true, placeholder: EMPTY_VALUE }}
            disabled
          />
        </View>
        <View width="12%">
          {actionState === DISCREPANCY_ACTION_STATE.SAVE && !autoResolved ? (
            <Button
              variant="secondary"
              loading={trailerArrivalLoading}
              disabled={receivedQty === null}
              onClick={onSave}
            >
              {t('FinalizationDashboard.Save')}
            </Button>
          ) : (
            !isOrderLineEdit &&
            status !== DISCREPANCY_STATUS.CHECKED && (
              <Button
                variant="secondary"
                disabled={trailerArrivalLoading || autoResolved}
                className={styles['po-discrepancy__card__details__row__button']}
                onClick={onEdit}
              >
                {t('FinalizationDashboard.Edit')}
              </Button>
            )
          )}
        </View>
      </View>
      {locationSearchModal && (
        <LocationSearchModal
          isOpen={locationSearchModal}
          onClose={() => setLocationSearchModal(false)}
          location={location}
        />
      )}
    </View>
  );
};
