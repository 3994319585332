/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { GetTrailerOrdersParams } from '@inbound/types/service.type';
import { trailerOrderService } from '@inbound/services/trailerOrderService';
import { useDebounce } from '@az/starc-ui';
import { DEBOUNCE_TIME, TRAILER_ORDER_STATUS } from '@inbound/constants/constants';

export const useGetTrailerOrders = (
  options: GetTrailerOrdersParams,

  isEnabled = true,
  isDelay = false,
  downloadWithFilters = true,
  isPoHistory = false
) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  let stringOptions = '';
  if (downloadWithFilters) {
    stringOptions = JSON.stringify(options);
  } else {
    const nonFilterOptions = {
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: 'PO',
          orderTypeCds: [],
          ...(isPoHistory && { statusCds: [TRAILER_ORDER_STATUS.FINALIZED] }),
        },
        trailerOrder: {
          arrivalTimeStamps: [],
          commodityTypeCds: [],
          statusCds: [],
          user: { userStatusCds: ['BOTH'] },
        },
      },
      searchPage: {
        currentPage: 0,
        pageSize: 10000,
        sortBy: 'trailerArrival.trailerArrivalTs',
        direction: 'ASC',
      },
    };
    stringOptions = JSON.stringify(nonFilterOptions);
  }

  const debouncedOptions = useDebounce(stringOptions, DEBOUNCE_TIME);

  const {
    data: trailerOrdersData,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['trailerOrders', isDelay ? debouncedOptions : JSON.parse(stringOptions)],
    queryFn: () =>
      trailerOrderService.getTrailerOrders(
        isDelay ? JSON.parse(debouncedOptions) : JSON.parse(stringOptions)
      ),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('PODashboard.Notification.GetTrailerOrders'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { trailerOrdersData, isLoading, isError, refetch, isFetching };
};
