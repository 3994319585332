/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';
import { DatePicker } from '@az/starc-ui';

import { KEY } from '@shared/constants/keyConstants';

import * as T from './DatePicker.types';
import s from './DatePicker.module.scss';

export const DatePickerWrapper = ({
  id,
  value,
  label,
  dateFormat,
  dateRange,
  yearRange,
  size,
  required,
  disabled,
  onValueChange,
}: T.Props) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEY.BACKSPACE) {
      event.preventDefault();
      const newValue = currentValue.slice(0, -1);
      setCurrentValue(newValue);
      onValueChange(newValue);
    }
  };

  const handleDateChange = (newValue: string) => {
    setCurrentValue(newValue);
    onValueChange(newValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={s['date-picker-wrapper']} onKeyDown={handleKeyDown}>
      <DatePicker
        className={s['date-picker-wrapper__input']}
        id={id}
        value={currentValue}
        label={label}
        dateFormat={dateFormat}
        dateRange={dateRange}
        yearRange={yearRange}
        size={size}
        required={required}
        disabled={disabled}
        onValueChange={handleDateChange}
      />
    </div>
  );
};
