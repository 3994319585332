/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { KEY, KEY_CODE } from '@shared/constants/keyConstants.ts';
import { useTranslation } from 'react-i18next';
import { Button, Icon, SearchBar, Skeleton, Text, useDebounce, View } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { Download } from '@shared/assets/icons';
import { Add } from '@az/starc-ui-icons';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import {
  ASSET_DEFAULT_SORT_FIELD,
  SEARCH_MENU_MAX_HEIGHT,
  VEHICLE_FIELD,
} from '@mdm/constants/constants.ts';

import styles from './VehicleList.module.scss';
import { Table } from '@shared/components/Table/Table.tsx';
import {
  TableStylingVariants,
  VEHICLE_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import { mapVehicleTableRows } from '@mdm/utils/table/tableUtils.tsx';
import {
  ASSET,
  RDM,
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
} from '@shared/constants/constants.ts';
import { useGetVehicles } from '@mdm/services/hooks/useGetVehicles.tsx';
import {
  DIRECTION as TABLE_SORT_DIRECTION,
  TableSorting,
} from '@shared/components/Table/Table.types.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { AssetType } from '@mdm/types/schema.type.ts';
import { useDeleteVehicle } from '@mdm/services/hooks/useDeleteVehicle.ts';
import { useQueryClient } from '@tanstack/react-query';

export const VehicleList = () => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<string>(TABLE_SORT_DIRECTION.ASCENDING);
  const [sortColumnId, setSortColumnId] = useState<string>('assetCd');
  const [searchQuery, setSearchQuery] = useState('');
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<SelectOption[]>([]);
  const [pickMethodOptions, setPickMethodOptions] = useState<SelectOption[]>([]);
  const [pickRouteOptions, setPickRouteOptions] = useState<SelectOption[]>([]);

  const [value, setValue] = useState<string>('');

  /* Constants */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const debouncedSearchQueryValue = useDebounce(searchQuery, DEBOUNCE_TIMER);
  const { mutateDeleteVehicle, isLoading: isDeleting } = useDeleteVehicle();

  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const breadcrumbs = useBreadcrumb();
  const { vehiclesData: vehicleCountData, isFetching: isVehicleCountLoading } = useGetVehicles({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: PAGE_SIZE,
    sortBy: ASSET_DEFAULT_SORT_FIELD,
  });

  const { vehiclesData, isFetching: isLoading } = useGetVehicles({
    currentPage: currentPage - 1,
    pageSize: PAGE_SIZE,
    assetCd: debouncedSearchQueryValue || '',
    sortBy: sortColumnId,
    direction: sortDirection,
  });

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: ASSET,
  });

  /* Functions */
  const onDeleteVehicle = (asset: AssetType) => {
    if (asset.assetKey) {
      mutateDeleteVehicle(
        {
          assetKey: asset.assetKey,
          invalidateCache: false,
        },
        {
          onSuccess: () => {
            onClearSearch();
            queryClient.invalidateQueries(['vehicles']);
          },
        }
      );
    }
  };

  const SubTitle = () => {
    return (
      <View direction="row" align="center" gap={4}>
        <View.Item>
          <Text size="100" color="600">
            {`${t('TotalVehicles')}: `}
            {isVehicleCountLoading ? (
              <Skeleton className={styles['vehicle__count-wrapper']} width="100px" height="24px" />
            ) : (
              vehicleCountData?.totalElements || 0
            )}
          </Text>
        </View.Item>
      </View>
    );
  };

  const onSort = (mappedSorting: TableSorting[], columnID: string) => {
    const foundColumn = mappedSorting.find((column) => column.id === columnID);
    if (foundColumn) {
      setSortDirection(foundColumn.direction);
      setSortColumnId(foundColumn.id);
    }
  };

  const onViewDetails = (vehicle: AssetType) => {
    navigate(PAGE_URLS.VEHICLE_DETAILS(vehicle.assetCd));
  };

  const setSearchParam = (param: string) => {
    setSearchQuery(param);
  };

  const onKeyDown = (e: {
    key: string;
    keyCode: number;
    preventDefault: () => void;
    currentTarget: { value: string };
  }) => {
    if (e.key === KEY.ENTER || e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
      const inputValue = (e.currentTarget as HTMLInputElement).value;
      if (inputValue) {
        setSearchParam(inputValue);
      }
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      onClearSearch();
    }
  };

  const onClearSearch = () => {
    setSearchParam('');
    setValue('');
  };

  /* Hooks */
  useEffect(() => {
    const pickMethods = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.PICK_METHOD)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setPickMethodOptions(pickMethods);

    const pickRoutes = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.PICK_ROUTE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setPickRouteOptions(pickRoutes);

    const vehicleTypes = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.VEHICLE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setVehicleTypeOptions(vehicleTypes);
  }, [configsData]);

  useEffect(() => {
    if (vehiclesData && vehiclesData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Empty.Search.NoRecordsFound'));
    }
  }, [handleNotification, t, vehiclesData]);

  return (
    <>
      <View className={styles['vehicle-list']}>
        <MasterTitle
          title={t('MasterTitle.Vehicles')}
          breadcrumbProps={breadcrumbs}
          titleActionProps={{ label: t('Favorite'), handleClick: () => ({}) }}
          subtitle={<SubTitle />}
        >
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item columns={6}>
              <SearchBar
                value={value}
                onValueChange={setValue}
                suggestions={[]}
                className={styles['search-bar']}
                label={t('Search.Vehicle')}
                onValueClear={onClearSearch}
                inputAttributes={{ onKeyDown: onKeyDown, onChange: onChange }}
                maxMenuHeight={SEARCH_MENU_MAX_HEIGHT}
              />
            </View.Item>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button variant="secondary" size="large">
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Download} />
                      <Text>{t('Download')}</Text>
                    </View>
                  </Button>
                </View.Item>

                <View.Item>
                  <Button size="large" onClick={() => navigate(PAGE_URLS.VEHICLE_CREATE)}>
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Icon svg={Add} color="secondary" />
                      <Text>{t('Create')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={[4, 6]}>
          <View className={styles['vehicle-list__table-wrapper']}>
            <Table
              columns={VEHICLE_TABLE_COLUMNS}
              rows={mapVehicleTableRows(
                vehiclesData?.content || [],
                onViewDetails,
                onDeleteVehicle,
                isDeleting,
                {
                  vehicleTypeOptions,
                  pickMethodOptions,
                  pickRouteOptions,
                }
              )}
              isPaginated={true}
              isCheckboxDisabled={false}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              isLoading={isLoading}
              isApiLoadedData={true}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={vehiclesData?.totalPages || 0}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              onSort={onSort}
            />
          </View>
        </View>
      </View>
    </>
  );
};
