/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useOutletContext } from 'react-router-dom';

import { View, Text } from '@az/starc-ui';

import { releaseOrders, allLaneOptions, difOrders } from '../data';
import { OrderReleaseAccordion } from '@outbound/components/OrderReleaseAccordion/OrderReleaseAccordion';

import styles from '../OrderTabs.module.scss';
import { useAtom } from 'jotai';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import { OrderSection } from '@outbound/components/OrderSection/OrderSection';

export const DifOrders = () => {
  /*Atoms*/
  const [laneConfirmationItem] = useAtom(laneConfirmationItemAtom);
  const [inLaneConfirmation] = useAtom(inLaneConfirmationAtom);

  /* Constants */
  const [getSelectionStatusLabel]: [() => string] = useOutletContext();

  return (
    <View direction="column" padding={6} className={styles['order-release']}>
      {!inLaneConfirmation && <Text>{getSelectionStatusLabel()}</Text>}
      <View direction="column" className={styles['order-release__content']}>
        <OrderSection>
          {(!inLaneConfirmation || laneConfirmationItem) &&
            difOrders.map((wave) => (
              <OrderReleaseAccordion
                item={wave}
                order={releaseOrders.transferOrders}
                laneOptions={allLaneOptions}
              />
            ))}
        </OrderSection>
      </View>
    </View>
  );
};
