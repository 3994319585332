/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  Loader,
  Radio,
  Select,
  Text,
  TextField,
  View,
} from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '@shared/hooks/useStorage';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants';
import { z } from 'zod';

import styles from './VehicleDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults, getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';

import { MAX_LENGTH_FIELD, VEHICLE_FIELD } from '@mdm/constants/constants.ts';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useEffect, useState } from 'react';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { defaultAttributeValues, VehicleSchema } from '@mdm/schemas/vehicleSchema.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { ASSET, NOTIFICATION_TYPES, RDM } from '@shared/constants/constants.ts';
import { displayAttributeFieldValidationMessage } from '@mdm/utils/form/validationUtils.tsx';
import { useMutateVehicle } from '@mdm/services/hooks/useMutateVehicle.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useGetVehicleById } from '@mdm/services/hooks/useGetVehicleById.tsx';
import { VehicleAssociatedSubzoneList } from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/VehicleAssociatedSubzoneList.tsx';
import { useDeleteVehicle } from '@mdm/services/hooks/useDeleteVehicle.ts';

export const VehicleDetail = () => {
  /* State */
  const { vehicleId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!vehicleId);

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<SelectOption[]>([]);
  const [pickMethodOptions, setPickMethodOptions] = useState<SelectOption[]>([]);
  const [pickRouteOptions, setPickRouteOptions] = useState<SelectOption[]>([]);

  /* Constants */
  type FormData = z.infer<typeof VehicleSchema>;

  const [selectedDC] = useSessionStorage<string>(SESSION_DC_ID_KEY);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading: isVehicleUpdating, mutateVehicle } = useMutateVehicle();
  const { mutateDeleteVehicle, isLoading: isDeleting } = useDeleteVehicle();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    register,
    reset: vehicleReset,
    watch,
    trigger,
  } = useForm<FormData>({
    defaultValues: getFormDefaults(VehicleSchema),
    resolver: zodResolver(VehicleSchema),
  });

  const assetCd = watch('assetCd');

  const breadcrumbs = useBreadcrumb(vehicleId ? PAGE_URLS.VEHICLE_CREATE : '');

  const { configsData, isLoading: isConfigDataLoading } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: ASSET,
  });

  const { vehicleData, isFetching: isVehicleLoading } = useGetVehicleById(
    {
      assetCd: vehicleId || '',
    },
    !!vehicleId
  );

  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  /* Functions */
  const revalidateAttributeField = (attributeName: string) => {
    const attributeIndex = attributeFields.findIndex((field) => field.name === attributeName);
    if (attributeIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trigger(`attributes[attribute-${attributeIndex}]`);
    }
  };

  const onUpdateVehicle = (context = 'vehicle', updatedFieldName: string | null = '') => {
    const payload = getValues();
    if (payload.attributes) {
      payload.attributes = payload.attributes
        .filter((attribute) => attribute.value !== '')
        .map((attribute) => {
          return {
            ...attribute,
            value: attribute.value.toString(),
          };
        });
    }

    mutateVehicle(
      {
        assetKey: payload.assetKey || '',
        assetRequest: {
          ...payload,
          isActive: true,
        },
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(['vehicles']);
            queryClient.invalidateQueries(['vehicle']);
            let successMessage = t('Success.Action.Vehicle.Created');
            if (payload.assetKey) {
              successMessage = t('Success.Action.Vehicle.Updated', {
                vehicleName: payload.assetCd,
              });
            }

            if (context === 'subzone') {
              successMessage = t('Success.Action.Subzone.Updated', {
                subzoneName: updatedFieldName,
              });
            }

            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            if (!vehicleId && data.assetCd) {
              navigate(PAGE_URLS.VEHICLE_DETAILS(data.assetCd));
            }
          }
        },
      }
    );
  };

  const onSubmit = () => {
    onUpdateVehicle();
  };

  const onLayoutAttributeChange = (
    layoutDistinctName: string,
    attributeName: string,
    value: string
  ) => {
    const layouts = getValues().layouts;
    const foundLayoutIndex = layouts?.findIndex(
      (layout) => layout.layoutDistinctName === layoutDistinctName
    );

    if (layouts && foundLayoutIndex !== undefined && foundLayoutIndex !== -1) {
      const layout = layouts[foundLayoutIndex];
      const foundAttributeIndex = layout.assetLayoutMappingAttributes?.findIndex((attribute) => {
        return attribute.name === attributeName;
      });

      if (
        foundAttributeIndex !== undefined &&
        foundAttributeIndex !== -1 &&
        layout.assetLayoutMappingAttributes
      ) {
        layout.assetLayoutMappingAttributes[foundAttributeIndex].value = value;
      }
    }

    const vehicleViewData = vehicleData?.content[0];

    if (vehicleViewData) {
      const vehicleViewDataLayoutIndex = (vehicleViewData.layouts || [])?.findIndex(
        (layout) => layout.layoutDistinctName === layoutDistinctName
      );

      if (vehicleViewDataLayoutIndex !== -1) {
        const foundAttributeIndex = (
          (vehicleViewData.layouts || [])[vehicleViewDataLayoutIndex]
            .assetLayoutMappingAttributes || []
        ).findIndex((attribute) => {
          return attribute.name === attributeName;
        });

        if (
          foundAttributeIndex !== -1 &&
          (vehicleViewData.layouts || [])[vehicleViewDataLayoutIndex].assetLayoutMappingAttributes
        ) {
          ((vehicleViewData.layouts || [])[vehicleViewDataLayoutIndex]
            .assetLayoutMappingAttributes || [])[foundAttributeIndex].value = value;
        }
      }
    }
  };

  const onDeleteVehicle = () => {
    if (vehicleId && !isDeleting) {
      const payload = getValues();
      mutateDeleteVehicle(
        {
          assetKey: payload.assetKey || '',
        },
        {
          onSuccess: async () => {
            navigate(PAGE_URLS.VEHICLE_LIST);
          },
        }
      );
    }
  };

  const onCancel = () => {
    navigate(PAGE_URLS.VEHICLE_LIST);
  };

  /* Hooks */
  useEffect(() => {
    const pickMethods = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.PICK_METHOD)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setPickMethodOptions(pickMethods);

    const pickRoutes = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.PICK_ROUTE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));
    setPickRouteOptions(pickRoutes);

    const vehicleTypes = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.VEHICLE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setVehicleTypeOptions(vehicleTypes);
  }, [configsData]);

  useEffect(() => {
    if (vehicleData && vehicleData.content[0]) {
      const attributes = vehicleData.content[0].attributes || [];
      for (const defaultAttributeValue of defaultAttributeValues) {
        if (!attributes.find((a) => a.name === defaultAttributeValue.name)) {
          attributes.push(defaultAttributeValue);
        }
      }

      vehicleReset({
        ...vehicleData.content[0],
        attributes,
      });

      setIsDataLoaded(true);
    } else if (vehicleId && vehicleData && vehicleData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('VehicleNotFound'));
    }
  }, [handleNotification, t, vehicleData, vehicleId, vehicleReset]);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <>
        <View
          className={styles['vehicle-detail']}
          backgroundColor="secondary"
          direction="column"
          height="100%"
        >
          <View
            attributes={{
              'data-testid': 'vehicle-details-header',
            }}
          >
            <MasterTitle
              title={`${t('Vehicle')}: ${assetCd ? assetCd : t('Untitled')}`}
              breadcrumbProps={breadcrumbs}
            >
              <View
                attributes={{
                  'data-testid': 'vehicle-details-action',
                }}
                direction="row"
                justify="end"
                align="center"
                gap={4}
              >
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button variant="secondary" size="large" onClick={onCancel}>
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('Cancel')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        size="large"
                        onClick={() => handleSubmit(onSubmit)()}
                        loading={isVehicleUpdating}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('SaveEdits')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                {vehicleId ? (
                  <ActionMenu
                    menus={[
                      {
                        label: isDeleting ? <Loader /> : t('RemoveVehicle'),
                        clickHandler: onDeleteVehicle,
                        labelColor: 'error',
                      },
                    ]}
                  />
                ) : null}
              </View>
            </MasterTitle>
          </View>
          <View backgroundColor="secondary" padding={[6, 6, 0, 6]} height="100%">
            <View>
              <View.Item>
                <Text size="125" weight="bold">
                  {t('VehicleDetails.Title')}
                </Text>
              </View.Item>
            </View>
            <View className={styles['vehicle-detail__content-section']}>
              <View>
                <form
                  key={btoa(JSON.stringify(vehicleData?.content[0] || ''))}
                  className="vehicle-detail__form"
                >
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('Warehouse')} isRequired={true} />
                        <FormControl>
                          <Select
                            attributes={{
                              'data-testid': 'warehouse-id',
                              style: { width: 'var(--st-unit-5)' },
                            }}
                            label={t('Warehouse')}
                            name="warehouse"
                            variant="no-label"
                            multiSelect={false}
                            defaultValue={{
                              label: 'DC ' + selectedDC?.toString(),
                              value: 'DC ' + selectedDC?.toString(),
                            }}
                            options={[]}
                            disabled
                          />
                        </FormControl>
                        <Text weight="regular" color="600" size="087">
                          {t('VehicleDetails.WarehouseInstruction')}
                        </Text>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('VehicleDetails.Name.Label')} isRequired={true} />
                        <FormControl hasError={!!errors?.assetCd}>
                          <TextField
                            attributes={{
                              'data-testid': 'name',
                            }}
                            name="assetCd"
                            inputAttributes={{
                              placeholder: t('VehicleDetails.Name.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.VEHICLE_NAME,
                              ...register('assetCd'),
                            }}
                            disabled={!!vehicleId}
                            defaultValue=""
                          />
                          {errors?.assetCd && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors?.assetCd.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'vehicle-type',
                        }}
                        gap={2}
                      >
                        <FormLabel text={t('VehicleDetails.VehicleType.Label')} isRequired={true} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.VEHICLE_TYPE
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.VEHICLE_TYPE
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('VehicleDetails.VehicleType.Placeholder')}
                                    variant="no-label"
                                    options={vehicleTypeOptions}
                                    value={{
                                      label:
                                        vehicleTypeOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(VEHICLE_TYPE) => {
                                      onChange(VEHICLE_TYPE?.value);
                                      revalidateAttributeField(VEHICLE_FIELD.VEHICLE_TYPE);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.VEHICLE_TYPE
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                  <View
                    direction="row"
                    gap={4}
                    className={styles['vehicle-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('VehicleDetails.Description.Label')} isRequired={true} />
                        <FormControl hasError={!!errors?.assetDesc}>
                          <TextField
                            attributes={{
                              'data-testid': 'description',
                            }}
                            name="assetDesc"
                            inputAttributes={{
                              placeholder: t('VehicleDetails.Description.Placeholder'),
                              maxLength: MAX_LENGTH_FIELD.VEHICLE_DESCRIPTION,
                              ...register('assetDesc'),
                            }}
                            defaultValue=""
                          />
                          {errors?.assetDesc && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>{errors?.assetDesc.message}</FormControl.Error>
                            </View>
                          )}
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'full-pallet',
                        }}
                        gap={2}
                      >
                        <FormLabel text={t('VehicleDetails.FullPallet.Label')} isRequired />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.FULL_PALLET
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.FULL_PALLET
                            )}.value`}
                            render={({ field: { onChange, value } }) => (
                              <View direction="row" gap={4}>
                                <Radio
                                  value="true"
                                  label={t('Yes')}
                                  checked={value && value.toLowerCase() === 'true'}
                                  onChange={(isFullPallet) => onChange(isFullPallet)}
                                />
                                <Radio
                                  value="false"
                                  label={t('No')}
                                  checked={value && value.toLowerCase() === 'false'}
                                  onChange={(isFullPallet) => onChange(isFullPallet)}
                                />
                              </View>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.FULL_PALLET
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'part-pallet',
                        }}
                        gap={2}
                      >
                        <FormLabel text={t('VehicleDetails.PartPallet.Label')} isRequired />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.PART_PALLET
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.PART_PALLET
                            )}.value`}
                            render={({ field: { onChange, value } }) => (
                              <View direction="row" gap={4}>
                                <Radio
                                  value="true"
                                  label={t('Yes')}
                                  checked={value && value.toLowerCase() === 'true'}
                                  onChange={(isPartPallet) => onChange(isPartPallet)}
                                />
                                <Radio
                                  value="false"
                                  label={t('No')}
                                  checked={value && value.toLowerCase() === 'false'}
                                  onChange={(isPartPallet) => onChange(isPartPallet)}
                                />
                              </View>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.PART_PALLET
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                  <View
                    direction="row"
                    gap={4}
                    className={styles['vehicle-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'pick-method',
                        }}
                        gap={2}
                      >
                        <FormLabel text={t('VehicleDetails.PickMethod.Label')} isRequired={true} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.PICK_METHOD
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.PICK_METHOD
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('VehicleDetails.PickMethod.Placeholder')}
                                    variant="no-label"
                                    options={pickMethodOptions}
                                    value={{
                                      label:
                                        pickMethodOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(PICK_METHOD) => {
                                      onChange(PICK_METHOD?.value);
                                      revalidateAttributeField(VEHICLE_FIELD.PICK_METHOD);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.PICK_METHOD
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View
                        attributes={{
                          'data-testid': 'pick-route',
                        }}
                        gap={2}
                      >
                        <FormLabel text={t('VehicleDetails.PickRoute.Label')} isRequired={true} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.PICK_ROUTE
                                )
                              )
                            )
                          }
                        >
                          <Controller
                            control={control}
                            name={`attributes.${getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.PICK_ROUTE
                            )}.value`}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <>
                                {isConfigDataLoading ? (
                                  <Loader variant="spinner" color="primary" size="small" />
                                ) : (
                                  <Select
                                    label={t('VehicleDetails.PickMethod.Placeholder')}
                                    variant="no-label"
                                    options={pickRouteOptions}
                                    value={{
                                      label:
                                        pickRouteOptions.find((option) => option.value === value)
                                          ?.label || '',
                                      value: value || '',
                                    }}
                                    inputAttributes={{ onBlur }}
                                    onValueChange={(PICK_ROUTE) => {
                                      onChange(PICK_ROUTE?.value);
                                      revalidateAttributeField(VEHICLE_FIELD.PICK_ROUTE);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          />

                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.PICK_ROUTE
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel text={t('VehicleDetails.Bins.Label')} isRequired={true} />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.BINS
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'bins',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.BINS
                                )}.value`
                              ),
                              onBlur: () => revalidateAttributeField(VEHICLE_FIELD.BINS),
                              maxLength: MAX_LENGTH_FIELD.VEHICLE_BINS,
                            }}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.BINS
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <View gap={2}>
                        <FormLabel
                          text={t('VehicleDetails.MaxVehicleTransaction.Label')}
                          isRequired={true}
                        />
                        <FormControl
                          hasError={
                            !!(
                              errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.MAX_VEHICLE_TRANSITION
                                )
                              )
                            )
                          }
                        >
                          <TextField
                            attributes={{
                              'data-testid': 'max-vehicle-transition',
                            }}
                            defaultValue=""
                            inputAttributes={{
                              ...register(
                                `attributes.${getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.MAX_VEHICLE_TRANSITION
                                )}.value`
                              ),
                              onBlur: () =>
                                revalidateAttributeField(VEHICLE_FIELD.MAX_VEHICLE_TRANSITION),
                              maxLength: MAX_LENGTH_FIELD.VEHICLE_MAX_VEHICLE_TRANSITION,
                            }}
                          />
                          <FormControl.Error>
                            {errors.attributes &&
                              displayAttributeFieldValidationMessage(
                                errors,
                                getFormFieldIndexByKey(
                                  getValues()['attributes'],
                                  VEHICLE_FIELD.MAX_VEHICLE_TRANSITION
                                )
                              )}
                          </FormControl.Error>
                        </FormControl>
                      </View>
                    </View.Item>
                  </View>
                  <View
                    direction="row"
                    gap={4}
                    className={styles['vehicle-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12 }}>
                      <View
                        attributes={{
                          'data-testid': 'scan-options',
                        }}
                        gap={2}
                      >
                        <FormLabel
                          text={`${t('VehicleDetails.ScanOptions.Label')} (${t('Optional')})`}
                        />
                      </View>
                    </View.Item>
                  </View>
                  <View
                    direction="row"
                    gap={4}
                    className={styles['vehicle-detail__form-field-section']}
                  >
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                VEHICLE_FIELD.SCAN_PICK_CONTAINER
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            VEHICLE_FIELD.SCAN_PICK_CONTAINER
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                VEHICLE_FIELD.SCAN_PICK_CONTAINER
                              )}.value`}
                              value={value}
                              checked={value.toString().toLowerCase() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('VehicleDetails.ScanPickContainer.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.SCAN_PICK_CONTAINER
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      VEHICLE_FIELD.SCAN_PICK_CONTAINER
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                VEHICLE_FIELD.SCAN_PICK_LOCATION
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            VEHICLE_FIELD.SCAN_PICK_LOCATION
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                VEHICLE_FIELD.SCAN_PICK_LOCATION
                              )}.value`}
                              value={value}
                              checked={value.toString().toLowerCase().toLowerCase() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('VehicleDetails.ScanPickLocation.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.SCAN_PICK_LOCATION
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      VEHICLE_FIELD.SCAN_PICK_LOCATION
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                    <View.Item columns={{ s: 12, l: 3 }}>
                      <FormControl
                        hasError={
                          !!(
                            errors.attributes &&
                            errors.attributes[
                              getFormFieldIndexByKey(
                                getValues()['attributes'],
                                VEHICLE_FIELD.SCAN_PICK_SKU
                              )
                            ]
                          )
                        }
                      >
                        <Controller
                          control={control}
                          name={`attributes.${getFormFieldIndexByKey(
                            getValues()['attributes'],
                            VEHICLE_FIELD.SCAN_PICK_SKU
                          )}.value`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              name={`attributes.${getFormFieldIndexByKey(
                                getValues()['attributes'],
                                VEHICLE_FIELD.SCAN_PICK_SKU
                              )}.value`}
                              value={value}
                              checked={value.toString().toLowerCase() === 'true'}
                              inputAttributes={{ ref }}
                              label={t('VehicleDetails.ScanPickSku.Label')}
                              onChange={(event) => onChange(event)}
                            />
                          )}
                        />
                        {errors.attributes &&
                          errors.attributes[
                            getFormFieldIndexByKey(
                              getValues()['attributes'],
                              VEHICLE_FIELD.SCAN_PICK_SKU
                            )
                          ] && (
                            <View direction="row" justify="space-between">
                              <FormControl.Error>
                                {
                                  errors.attributes[
                                    getFormFieldIndexByKey(
                                      getValues()['attributes'],
                                      VEHICLE_FIELD.SCAN_PICK_SKU
                                    )
                                  ]?.message
                                }
                              </FormControl.Error>
                            </View>
                          )}
                      </FormControl>
                    </View.Item>
                  </View>
                  <View direction="row" gap={4}>
                    <View.Item columns={{ s: 12 }}>
                      <View
                        attributes={{
                          'data-testid': 'scan-instructions',
                        }}
                        gap={2}
                      >
                        <Text size="087" color="600">
                          {t('VehicleDetails.ScanOptions.Instruction')}
                        </Text>
                      </View>
                    </View.Item>
                  </View>
                </form>
              </View>
            </View>
          </View>
          <View backgroundColor="secondary" padding={6}>
            <Divider color="300" className={styles['vehicle-detail__divider']} />
            <VehicleAssociatedSubzoneList
              vehicleId={vehicleId}
              isVehicleLoading={isVehicleLoading}
              vehicleData={vehicleData?.content[0]}
              onLayoutAttributeChange={onLayoutAttributeChange}
            />
          </View>
        </View>
      </>
    );
  }
};
