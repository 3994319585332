/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { DAY_OF_WEEK } from '@mdm/constants/constants.ts';

const STEPPER_MAX_VALUE = 5000;

const ERROR_TABLE_COLUMNS = [
  {
    label: 'ErrorTables.StoreNumber',
    id: 'storeId',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  {
    label: 'ErrorTables.ErrorStatus',
    id: 'errorStatus',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 4)',
    isSorted: true,
  },
  {
    label: 'ErrorTables.Attempts',
    id: 'attempts',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  {
    label: 'ErrorTables.CreatedAt',
    id: 'created',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  {
    label: 'ErrorTables.LastAttemptedAt',
    id: 'lastAttempted',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  { label: '', id: 'empty', textAlign: 'end' as const, width: 'calc(var(--st-unit-25) * 2)' },
];

const ORDER_DETAILS_TABLE_COLUMNS = [
  {
    label: '',
    id: 'checkbox',
    textAlign: 'start' as const,
    width: 'var(--st-unit-8)',
    isCheckbox: true,
  },
  {
    label: 'Table.OrderDetails.SKU',
    id: 'sku',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-32) + var(--st-unit-3))',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PartNumber',
    id: 'partNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2 - 1px)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PlanogramId',
    id: 'planogramId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2 - 1px)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Description',
    id: 'description',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderDetails.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.QOH',
    id: 'qoh',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  { label: '', id: 'credited', width: 'var(--st-unit-14)' },
];
const ORDER_DETAILS_DRAWER_TABLE_COLUMNS = [
  {
    label: 'Table.OrderDetails.SKU',
    id: 'sku',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-32) + var(--st-unit-3))',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PartNumber',
    id: 'partNumber',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PlanogramId',
    id: 'planogramId',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'Table.OrderDetails.Subzone',
    id: 'subzone',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Description',
    id: 'description',
    textAlign: 'start' as const,
    isSorted: false,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderDetails.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'Table.OrderDetails.QOH',
    id: 'qoh',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'Table.OrderDetails.Selectors',
    id: 'selector',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-19) * 2) - var(--st-unit-1))',
    isSorted: false,
  },
];

const ORDER_DETAILS_CREDITED_TABLE_COLUMNS = [
  {
    label: 'Table.OrderDetails.SKU',
    id: 'sku',
    textAlign: 'center' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PartNumber',
    id: 'partNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2 - 1px)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PlanogramId',
    id: 'planogramId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2 - 1px)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Description',
    id: 'description',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderDetails.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.QOH',
    id: 'qoh',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'var(--st-unit-26)',
    isSorted: true,
  },
  { label: '', id: 'credited', width: 'var(--st-unit-14)' },
];

const ORDER_DETAILS_QUANTITY_ANOMALY_TABLE_COLUMNS = [
  {
    label: '',
    id: 'checkbox',
    textAlign: 'start' as const,
    width: 'var(--st-unit-8)',
    isCheckbox: true,
  },
  {
    label: 'Table.OrderDetails.SKU',
    id: 'sku',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-32) + var(--st-unit-3))',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PartNumber',
    id: 'partNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2 - 1px)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.PlanogramId',
    id: 'planogramId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2 - 2px)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Description',
    id: 'description',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderDetails.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.QOH',
    id: 'qoh',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: true,
  },
  {
    label: 'Table.OrderDetails.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-22) * 2)',
    isSorted: true,
  },
  { label: '', id: 'credited', width: 'var(--st-unit-14)' },
];

const STORE_DETAILS_TABLE_COLUMNS = [
  {
    label: 'Table.StoreDetails.InvoiceId',
    id: 'invoiceId',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.RequestBy',
    id: 'requestBy',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.OrderType',
    id: 'orderType',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-17) + 1px) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.Status',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2 - 4px)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.RequestedAt',
    id: 'requestedAt',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-21) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.BilledAt',
    id: 'billedAt',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-21) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.Lines',
    id: 'lines',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-23) - 10px)',
    isSorted: true,
  },
  {
    label: 'Table.StoreDetails.Pieces',
    id: 'pieces',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-22) - 10px)',
    isSorted: true,
  },
  {
    label: '',
    id: 'comments',
    textAlign: 'start' as const,
    width: 'var(--st-unit-14)',
  },
];

const WILL_CALL_TABLE_COLUMNS = [
  {
    label: '',
    id: 'checkbox',
    textAlign: 'start' as const,
    isCheckbox: true,
    width: 'var(--st-unit-10)',
  },
  {
    label: 'WillCall.Item.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-32) + var(--st-unit-3))',
  },
  {
    label: 'WillCall.Item.Status',
    id: 'part',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'var(--st-unit-32)',
  },
  {
    label: 'WillCall.Item.PartNumber',
    id: 'status',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'var(--st-unit-25)',
  },
  {
    label: 'WillCall.Item.Description',
    id: 'description',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-16) * 2)',
  },
  {
    label: 'WillCall.Item.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'var(--st-unit-18)',
  },
  {
    label: 'WillCall.Item.NumberOfPacks',
    id: 'numOfPacks',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
  },
  {
    label: 'WillCall.Item.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    isSorted: true,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  {
    label: 'WillCall.Item.DCSource',
    id: 'dc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-28) * 2)',
  },
];

const WILL_CALL_TABLE_MODAL_COLUMNS = [
  {
    label: 'WillCall.Item.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-32) + var(--st-unit-3))',
  },
  {
    label: 'WillCall.Item.Status',
    id: 'part',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-19) - 1px) * 2)',
  },
  {
    label: 'WillCall.Item.PartNumber',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-19) - 1px) * 2)',
  },
  {
    label: 'WillCall.Item.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 5)',
  },
  {
    label: 'WillCall.Item.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) - 1px)',
  },
  {
    label: 'WillCall.Item.NumberOfPacks',
    id: 'numOfPacks',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'WillCall.Item.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'var(--st-unit-20)',
  },
  {
    label: '',
    id: 'optionsMenu',
    textAlign: 'end' as const,
    width: 'var(--st-unit-20)',
  },
];

const WILL_CALL_TABLE_MODAL_EDIT_COLUMNS = [
  {
    label: 'WillCall.Item.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-32) + var(--st-unit-3))',
  },
  {
    label: 'WillCall.Item.Status',
    id: 'part',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-19) - 1px) * 2)',
  },
  {
    label: 'WillCall.Item.PartNumber',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-19) - 1px) * 2)',
  },
  {
    label: 'WillCall.Item.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 5)',
  },
  {
    label: 'WillCall.Item.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) - 1px)',
  },
  {
    label: 'WillCall.Item.NumberOfPacks',
    id: 'numOfPacks',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-15) * 2)',
  },
  {
    label: 'WillCall.Item.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  {
    label: '',
    id: 'optionsMenu',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-15) * 2 + 2px)',
  },
];

const WAREHOUSE_CONFIGURATION_TABLE_COLUMNS = [
  {
    label: 'Table.WarehouseConfiguration.DCNumber',
    id: 'dcNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.WarehouseConfiguration.Address',
    id: 'address',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 3)',
    isSorted: false,
  },
  {
    label: 'Table.WarehouseConfiguration.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 4)',
    isSorted: false,
  },
  {
    label: 'Table.WarehouseConfiguration.ContactNumber',
    id: 'contactNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 5)',
    isSorted: false,
  },
];

const ZONE_TABLE_COLUMNS = [
  {
    label: 'Table.ZoneList.ZoneName',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-34))',
    isSorted: true,
  },
  {
    label: 'Table.ZoneList.MapSequence',
    id: 'mapSequence',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.ZoneList.Subzones',
    id: 'subzones',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-15))',
    isSorted: false,
  },
  {
    label: 'Table.ZoneList.Description',
    id: 'layoutNameDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-40))',
    isSorted: true,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const ZONE_ASSOCIATED_SUBZONE_TABLE_COLUMNS = [
  {
    label: 'Table.SubzoneList.SubzoneName',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.SubzoneList.Description',
    id: 'layoutNameDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.SubzoneList.SubzoneType',
    id: 'subzoneType',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.Locations',
    id: 'locations',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.Vehicles',
    id: 'vehicles',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const VEHICLE_ASSOCIATED_SUBZONE_TABLE_COLUMNS = [
  {
    label: 'Table.SubzoneList.SubzoneName',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.Description',
    id: 'layoutNameDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.SubzoneType',
    id: 'subzoneType',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'pickingAllowed',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'putawayAllowed',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const LOCATION_ASSOCIATED_SKU_TABLE_COLUMNS = [
  {
    label: 'Table.LocationAssociatedSkuList.Sku',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationAssociatedSkuList.PartNumber',
    id: 'partNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationAssociatedSkuList.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationAssociatedSkuList.Qty',
    id: 'qty',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const ORDER_RELEASE_STORE_ORDERS_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.StoreNumber',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.RouteGroup',
    id: 'route',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.DispatchTime',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Activity',
    id: 'activity',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
];

const ORDER_RELEASE_LANE_ASSIGNMENT_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.StoreNumber',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: '',
    id: 'route',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.DispatchTime',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Activity',
    id: 'activity',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
];

const ORDER_RELEASE_NEW_STORE_ORDERS_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.Subzone',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
  {
    label: '',
    id: 'optionsMenu',
    textAlign: 'end' as const,
    width: 'var(--st-unit-12)',
  },
];

const ORDER_RELEASE_WILL_CALL_SCHEDULED_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.StoreNumber',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: '',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.Route',
    id: 'route',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.DispatchTime',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderRelease.Activity',
    id: 'activity',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
];

const ORDER_RELEASE_WILL_CALL_UNSCHEDULED_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.StoreNumber',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: '',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: '',
    id: 'route',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.DispatchTime',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderRelease.Activity',
    id: 'activity',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
];

const ORDER_RELEASE_LTD_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.StoreNumber',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'route',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: '',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'activity',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
];

const ORDER_RELEASE_TRANSFER_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.OrderNumber',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'route',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: '',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'activity',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
];

const ORDER_RELEASE_CROSSDOCK_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.StoreNumber',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.OrderNumber',
    id: 'orderNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: '',
    id: 'route',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: '',
    id: 'dispatchTime',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'activity',
    textAlign: 'start' as const,
    width: '0',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-27) * 4)',
  },
];

const RELEASE_TO_PAPER_STORE_ORDERS_TABLE_COLUMNS = [
  {
    label: 'Table.OrderRelease.Subzone',
    id: 'storeNumber',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: 'Table.OrderRelease.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallets',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: '100%',
  },
  {
    label: '',
    id: 'lane',
    textAlign: 'start' as const,
    width: '0',
  },
];

const CLOSE_PAPER_ORDERS_TABLE_COLUMNS = [
  {
    label: '',
    id: 'label',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20)*2)',
  },
  {
    label: 'Table.OrderRelease.Sku',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
  },
  {
    label: 'Table.OrderRelease.Part',
    id: 'part',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
  },
  {
    label: 'Table.OrderRelease.PickLocation',
    id: 'locations',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
  },
  {
    label: 'Table.OrderRelease.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
  },
  {
    label: 'Table.OrderRelease.Pack',
    id: 'pack',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
  },
  {
    label: 'Table.OrderRelease.Qoh',
    id: 'qoh',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
  },
  {
    label: 'Table.OrderRelease.Quantity',
    id: 'quantity',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
  },
];

const SUBZONE_TABLE_COLUMNS = [
  {
    label: 'Table.SubzoneList.SubzoneName',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.SubzoneList.ZoneName',
    id: 'zoneName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.Description',
    id: 'layoutNameDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.SubzoneList.SubzoneType',
    id: 'subzoneType',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.PickMethod',
    id: 'pickMethod',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-22) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.Locations',
    id: 'locations',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-14) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneList.Vehicles',
    id: 'vehicles',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const SUBZONE_ASSOCIATED_LOCATION_TABLE_COLUMNS = [
  {
    label: 'Table.LocationList.LocationId',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.LocationList.Type',
    id: 'type',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.QuantityUnits',
    id: 'quantityUnits',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-19) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.QuantityReserved',
    id: 'quantityReserved',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.Minimum',
    id: 'minimum',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-15) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.Maximum',
    id: 'maximum',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const SUBZONE_ASSOCIATED_VEHICLE_TABLE_COLUMNS = [
  {
    label: 'Table.VehicleList.VehicleId',
    id: 'vehicleId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.VehicleType',
    id: 'type',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
  },
  {
    label: 'Table.VehicleList.FullPallet',
    id: 'fullPallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.PartPallet',
    id: 'partPallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'pickingAllowed',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
  },
  {
    label: '',
    id: 'putAwayAllowed',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const LOCATION_TABLE_COLUMNS = [
  {
    label: 'Table.LocationList.LocationId',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.LocationList.Zone',
    id: 'zone',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.Subzone',
    id: 'subzone',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.LocationList.LocationType',
    id: 'type',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const CONSOLIDATION_LOCATION_TABLE_COLUMNS = [
  {
    label: 'Table.ConsolidationLocationList.LocationId',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.ConsolidationLocationList.Zone',
    id: 'zone',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.ConsolidationLocationList.Subzone',
    id: 'subzone',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.ConsolidationLocationList.LocationType',
    id: 'type',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const VEHICLE_TABLE_COLUMNS = [
  {
    label: 'Table.VehicleList.VehicleId',
    id: 'assetCd',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.VehicleList.VehicleType',
    id: 'type',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.Description',
    id: 'assetDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-32) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.VehicleList.FullPallet',
    id: 'fullPallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-16) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.PartPallet',
    id: 'partPallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.PickMethod',
    id: 'pickMethod',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.VehicleList.PickRoute',
    id: 'pickRoute',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-17) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-11)' },
];

const SUBZONE_RESERVE_PRIORITY_TABLE_COLUMNS = [
  {
    label: 'Table.SubzoneReservePriorityList.SubzoneName',
    id: 'layoutName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneReservePriorityList.ReserveSubzone',
    id: 'zoneName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneReservePriorityList.ReservePriority',
    id: 'layoutNameDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const SUBZONE_MIN_MAX_TABLE_COLUMNS = [
  {
    label: 'Table.SubzoneMinMaxList.Sku',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneMinMaxList.SubzoneName',
    id: 'subzoneName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneMinMaxList.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 3)',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneMinMaxList.Min',
    id: 'min',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.SubzoneMinMaxList.Max',
    id: 'max',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const ORDER_TYPE_TABLE_COLUMNS = [
  {
    label: 'Table.OrderTypeList.Type',
    id: 'orderTypeCode',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.OrderTypeList.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderTypeList.Priority',
    id: 'priority',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderTypeList.ShuttlePriority',
    id: 'shuttlePriority',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderTypeList.PalletThreshold',
    id: 'palletThreshold',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'Table.OrderTypeList.AllowMerge',
    id: 'allowMerge',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const All_USER_TABLE_COLUMNS = [
  {
    label: 'Table.AllUserList.LastName',
    id: 'lastName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: true,
  },
  {
    label: 'Table.AllUserList.FirstName',
    id: 'firstName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: true,
  },
  {
    label: 'Table.AllUserList.EmployeeId',
    id: 'employeeId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.RimsId',
    id: 'rimsId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.Department',
    id: 'department',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.Group',
    id: 'group',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.WageCode',
    id: 'wageCode',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.DateJoined',
    id: 'dateJoined',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const NEW_USER_TABLE_COLUMNS = [
  {
    label: 'Table.AllUserList.LastName',
    id: 'lastName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: true,
  },
  {
    label: 'Table.AllUserList.FirstName',
    id: 'firstName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: true,
  },
  {
    label: 'Table.AllUserList.EmployeeId',
    id: 'employeeId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.RimsId',
    id: 'rimsId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.Department',
    id: 'department',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.Group',
    id: 'group',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.WageCode',
    id: 'wageCode',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.DateJoined',
    id: 'dateJoined',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'dcStatus',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'managePermission',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const DC_USER_TABLE_COLUMNS = [
  {
    label: 'Table.AllUserList.LastName',
    id: 'lastName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: true,
  },
  {
    label: 'Table.AllUserList.FirstName',
    id: 'firstName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: true,
  },
  {
    label: 'Table.AllUserList.EmployeeId',
    id: 'employeeId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.RimsId',
    id: 'rimsId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.Department',
    id: 'department',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.Group',
    id: 'group',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.WageCode',
    id: 'wageCode',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.AllUserList.DateJoined',
    id: 'dateJoined',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'dcStatus',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: '',
    id: 'managePermission',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const USER_PERMISSION_TABLE_COLUMNS = [
  {
    label: 'Table.UserPermissionList.Permission',
    id: 'permission',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 3)',
    isSorted: false,
  },
  {
    label: 'Table.UserPermissionList.Privilege',
    id: 'privilege',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
];

const ROLE_TABLE_COLUMNS = [
  {
    label: 'Table.RoleList.Name',
    id: 'roleId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.RoleList.Description',
    id: 'roleDesc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

const USER_SHIFT_TABLE_COLUMNS = [
  {
    label: '',
    id: 'shiftId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'User',
    id: 'userId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Monday',
    id: DAY_OF_WEEK.MONDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Tuesday',
    id: DAY_OF_WEEK.TUESDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Wednesday',
    id: DAY_OF_WEEK.WEDNESDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Thursday',
    id: DAY_OF_WEEK.THURSDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Friday',
    id: DAY_OF_WEEK.FRIDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Saturday',
    id: DAY_OF_WEEK.SATURDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
  {
    label: 'WeekDay.Sunday',
    id: DAY_OF_WEEK.SUNDAY,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25))',
    isSorted: false,
  },
];

enum TableVariants {
  ERROR = 'error',
  ORDER_DETAILS = 'order-details',
  STORE_DETAILS = 'store-details',
  WILL_CALL = 'will-call',
}

enum TableStylingVariants {
  ERROR = 'error',
  DETAILS = 'details',
  MODAL = 'modal',
  DEFAULT = 'default',
  FILLED = 'filled',
  SIMPLE = 'simple',
}

const PAST_SHIFTS_TABLE_COLUMNS = [
  {
    label: 'ShiftGoalHistory.Columns.Shift',
    id: 'shift',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-25) * 2) + var(--st-unit-6))',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.Columns.ShiftProgress',
    id: 'shiftProgress',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-25) * 3) + var(--st-unit-12))',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.Columns.RegularPO',
    id: 'regularPO',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.Columns.DSD',
    id: 'dsd',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.Columns.LTD',
    id: 'ltd',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
];

const INVENTORY_PAST_SHIFTS_TABLE_COLUMNS = [
  {
    label: 'ShiftGoalHistory.InventoryColumns.Shift',
    id: 'shift',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-25) * 2) + var(--st-unit-6))',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.TotalPieces',
    id: 'shiftProgress',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-25) * 3) + var(--st-unit-12))',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.Totes',
    id: 'regularPO',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.Pallet',
    id: 'dsd',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.DSDTotes',
    id: 'ltd',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.DSDPallets',
    id: 'assignees',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.Returns',
    id: 'assignees',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.InventoryColumns.Assignees',
    id: 'assignees',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
];

const OUTBOUND_PAST_SHIFTS_TABLE_COLUMNS = [
  {
    label: 'ShiftGoalHistory.OutboundColumns.Shift',
    id: 'shift',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.OutboundColumns.StoreProgress',
    id: 'storeProgress',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-25) * 4) + var(--st-unit-17))',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.OutboundColumns.PiecesProgress',
    id: 'piecesProgress',
    textAlign: 'start' as const,
    width: 'calc((var(--st-unit-25) * 4) + var(--st-unit-17))',
    isSorted: false,
  },
  {
    label: 'ShiftGoalHistory.OutboundColumns.Assignees',
    id: 'assignees',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
];

const PRODUCT_TABLE_COLUMNS = [
  {
    label: 'Table.ProductList.Sku',
    id: 'productId',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
    isSorted: true,
  },
  {
    label: 'Table.ProductList.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 3)',
    isSorted: false,
  },
  {
    label: 'Table.ProductList.PartNumber',
    id: 'partNbr',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'Table.ProductList.UPC',
    id: 'upc',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.ProductList.Major',
    id: 'max',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.ProductList.Minor',
    id: 'max',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-13)' },
];

export {
  TableVariants,
  ERROR_TABLE_COLUMNS,
  ORDER_DETAILS_TABLE_COLUMNS,
  ORDER_DETAILS_CREDITED_TABLE_COLUMNS,
  STEPPER_MAX_VALUE,
  STORE_DETAILS_TABLE_COLUMNS,
  ORDER_DETAILS_QUANTITY_ANOMALY_TABLE_COLUMNS,
  ORDER_DETAILS_DRAWER_TABLE_COLUMNS,
  TableStylingVariants,
  WILL_CALL_TABLE_COLUMNS,
  WILL_CALL_TABLE_MODAL_COLUMNS,
  WILL_CALL_TABLE_MODAL_EDIT_COLUMNS,
  WAREHOUSE_CONFIGURATION_TABLE_COLUMNS,
  ZONE_TABLE_COLUMNS,
  ORDER_RELEASE_STORE_ORDERS_TABLE_COLUMNS,
  ORDER_RELEASE_NEW_STORE_ORDERS_TABLE_COLUMNS,
  ORDER_RELEASE_WILL_CALL_SCHEDULED_TABLE_COLUMNS,
  ORDER_RELEASE_WILL_CALL_UNSCHEDULED_TABLE_COLUMNS,
  ORDER_RELEASE_LTD_TABLE_COLUMNS,
  ORDER_RELEASE_TRANSFER_TABLE_COLUMNS,
  ZONE_ASSOCIATED_SUBZONE_TABLE_COLUMNS,
  SUBZONE_TABLE_COLUMNS,
  SUBZONE_ASSOCIATED_LOCATION_TABLE_COLUMNS,
  SUBZONE_ASSOCIATED_VEHICLE_TABLE_COLUMNS,
  LOCATION_TABLE_COLUMNS,
  CONSOLIDATION_LOCATION_TABLE_COLUMNS,
  ORDER_RELEASE_LANE_ASSIGNMENT_TABLE_COLUMNS,
  ORDER_RELEASE_CROSSDOCK_TABLE_COLUMNS,
  RELEASE_TO_PAPER_STORE_ORDERS_TABLE_COLUMNS,
  CLOSE_PAPER_ORDERS_TABLE_COLUMNS,
  VEHICLE_TABLE_COLUMNS,
  VEHICLE_ASSOCIATED_SUBZONE_TABLE_COLUMNS,
  SUBZONE_RESERVE_PRIORITY_TABLE_COLUMNS,
  All_USER_TABLE_COLUMNS,
  NEW_USER_TABLE_COLUMNS,
  PAST_SHIFTS_TABLE_COLUMNS,
  INVENTORY_PAST_SHIFTS_TABLE_COLUMNS,
  OUTBOUND_PAST_SHIFTS_TABLE_COLUMNS,
  USER_PERMISSION_TABLE_COLUMNS,
  SUBZONE_MIN_MAX_TABLE_COLUMNS,
  ORDER_TYPE_TABLE_COLUMNS,
  LOCATION_ASSOCIATED_SKU_TABLE_COLUMNS,
  ROLE_TABLE_COLUMNS,
  DC_USER_TABLE_COLUMNS,
  PRODUCT_TABLE_COLUMNS,
  USER_SHIFT_TABLE_COLUMNS,
};
