/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

const bucketsSchema = z.object({
  AVAILABLE: z.number(),
  ON_YARD: z.number(),
  RECEIVED: z.number(),
  ALLOCATED: z.number(),
  PICKED: z.number(),
  BLOCKED: z.number(),
  DAMAGED: z.number(),
  LOADED: z.number(),
  SHIPPED: z.number(),
});

const productSchema = z.object({
  productId: z.string(),
  layoutName: z.string(),
  layoutDistinctName: z.string(),
  receiptLabel: z.string(),
  receiptLabelSeqenceId: z.number(),
  scope: z.string(),
  expiryDate: z.string().nullable(),
  receivedDate: z.string().nullable(),
  buckets: bucketsSchema,
  lastModTs: z.string(),
  lastResetTs: z.string().nullable(),
  layoutAttributes: z.array(z.unknown()),
  layoutParent: z.unknown().nullable(),
});

export const InventoryProductSchema = z.array(productSchema);
