/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Modal, View, Text, Icon, Divider, classNames, ProgressBar } from '@az/starc-ui';
import { ShiftGoalOutboundModalProps } from './ShiftGoalOutboundModal.types';
import { Close } from '@az/starc-ui-icons';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

import styles from './ShiftGoalOutboundModal.module.scss';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants';
import {
  OUTBOUND_PAST_SHIFTS_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants';
import { mapOutboundShiftHistoryTableRows } from '@taskManagement/utils/table/tableUtils';
import { OUTBOUND_SHIFT_GOAL_MOCK_DATA } from '@taskManagement/constants/dataConstants';
import { Table } from '@shared/components/Table/Table';

export const ShiftGoalOutboundModal = ({
  isOpen,
  onClose,
  shiftName,
  ValidatorCount,
  timeLeftInHour,
  storesPicked,
  storesCompleted,
  storesPercentage,
  piecesPicked,
  piecesCompleted,
  piecesPercentage,
}: ShiftGoalOutboundModalProps) => {
  /* Constants */
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="large"
      closeByClickAway={false}
      className={styles['outbound-goals__modal']}
    >
      <View
        direction="row"
        justify="space-between"
        padding={3}
        className={styles['outbound-goals__header']}
      >
        <View direction="column">
          <Text as="h2" size="150" weight="bold" color="primary">
            {t('ShiftGoalHistory.Title')}
          </Text>
        </View>
        <Button
          variant="ghost"
          className={classNames(
            styles['close-icon'],
            styles['outbound-goals__header__close-button']
          )}
          onClick={onClose}
        >
          <Icon svg={Close} />
        </Button>
      </View>

      <View className={styles['outbound-goals__body']} padding={4}>
        <View className={styles['outbound-goals__current_shift']} padding={4}>
          <View direction="row" justify="space-between" align="center">
            <View direction="column" gap={1}>
              <Text weight="bold" size="125" color="primary">
                {t('ShiftGoalHistory.CurrentShift')}
              </Text>
              <Text size="087" color="500">
                {shiftName} | {ValidatorCount} Pickers
              </Text>
            </View>
            <StatusBadge
              variant={StatusVariants.COMPLETED}
              text={t('ShiftGoalHistory.TimeBreak', { hour: timeLeftInHour })}
            />
          </View>
        </View>
        <View
          className={styles['outbound-goals__new_shift']}
          padding={4}
          direction="row"
          align="stretch"
          gap={3}
        >
          <View
            className={classNames(
              styles['outbound-goals__new_shift-entires'],
              styles['outbound-goals__new_shift-goal']
            )}
            padding={2}
          >
            <View gap={2}>
              <View gap={2}>
                <View direction="row">
                  <Text size="100" variant="subtitle-bold">
                    {t(`ShiftProgress.NewShiftStarted`)}
                  </Text>
                </View>
                <View direction="row" align="center" justify="space-between" gap={2}>
                  <View.Item grow>
                    <Text size="087" color="500" className={styles['widget_subtext']}>
                      {t('ShiftGoalHistory.PreviousOutboundStatus', {
                        completed: 87,
                        total: 85,
                        difference: 2,
                      })}
                    </Text>
                  </View.Item>
                  <View.Item>
                    <Button size="small">{t(`ShiftProgress.SetShiftGoalBtn`)}</Button>
                  </View.Item>
                </View>

                <Divider color="300" />

                <View gap={2}>
                  <View direction="row" align="center" justify="space-between">
                    <View direction="row" gap={2} align="baseline">
                      <View>
                        <Text variant="display-4">
                          {storesPicked} / {storesCompleted}
                        </Text>
                      </View>
                      <View>
                        <Text variant="subtitle-bold">Stores</Text>
                      </View>
                    </View>
                    <View>
                      <Text color="300">{storesPercentage} %</Text>
                    </View>
                  </View>
                  <View>
                    <ProgressBar
                      label=""
                      value={storesPicked}
                      max={storesCompleted}
                      valueColor="500"
                      attributes={{ style: { width: '100%' } }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View>
            <Divider vertical color="300" />
          </View>

          <View className={styles['outbound-goals__new_shift-entires']}>
            <View gap={2}>
              <Divider color="300" />

              <View direction="row" align="center" justify="space-between">
                <View direction="row" gap={2} align="baseline">
                  <View>
                    <Text variant="display-4">
                      {piecesPicked} / {piecesCompleted}
                    </Text>
                  </View>
                  <View>
                    <Text variant="subtitle-bold">Pieces</Text>
                  </View>
                </View>
                <View>
                  <Text color="300">{piecesPercentage} %</Text>
                </View>
              </View>
              <View>
                <ProgressBar
                  label=""
                  value={piecesPicked}
                  max={piecesCompleted}
                  valueColor="500"
                  attributes={{ style: { width: '100%' } }}
                />
              </View>
            </View>
          </View>
        </View>

        <View className={styles['outbound-goals__past_shifts']} gap={2} padding={[3, 0, 0, 0]}>
          <View>
            <Text weight="bold" size="125" color="primary">
              {t('ShiftGoalHistory.PastShifts')}
            </Text>
          </View>

          <View>
            <Table
              columns={OUTBOUND_PAST_SHIFTS_TABLE_COLUMNS}
              rows={mapOutboundShiftHistoryTableRows(OUTBOUND_SHIFT_GOAL_MOCK_DATA)}
              isPaginated={false}
              pageSize={PAGE_SIZE}
              defaultPage={DEFAULT_PAGE}
              isCreditItem={false}
              isCheckboxTable={false}
              styleVariant={TableStylingVariants.DETAILS}
              totalPages={0}
              onSort={() => ({})}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};
