/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';

import { Button, FormControl, Icon, Modal, Text, TextArea, View, classNames } from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import { ACTIONS, MAX_TEXTAREA_LENGTH, NOTIFICATION_TYPES } from '@shared/constants/constants';
import { TextAreaCounter } from '@shared/components/TextAreaCounter/TextAreaCounter';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { LogError } from '@ofm/classes/LogError';
import { ErrorLogType } from '@ofm/constants/constants';
import { ReasonSchema } from '@ofm/schemas/confirmationModalSchema';
import { ReasonType } from '@ofm/types/types';
import { formatErrorLog, getFormInputError, isButtonDisabled } from '@ofm/utils/utils';

import { POActions, TRAILER_ORDER_STATUS } from '@inbound/constants/constants';
import { useUpdateTrailerArrival } from '@inbound/services/hooks/useUpdateTrailerArrival';

import * as T from './POStatusModal.types';
import s from './POStatusModal.module.scss';

export const POStatusModal = ({
  isOpen,
  previousStatus,
  soureOrderNumber,
  receiptId,
  trailerArrivalData,
  onClose,
}: T.Props) => {
  /* Constants */
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    reset,
    watch,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<ReasonType>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(ReasonSchema),
  });

  /* Queries */
  const { isLoading: trailerArrivalLoading, mutateUpdateTrailerArrival } =
    useUpdateTrailerArrival();

  /* Functions */
  const resetForm = (status: string) => {
    reset();
    clearErrors();
    onClose(status);
  };

  const onCloseModal = () => {
    resetForm(ACTIONS.CLOSE);
  };

  const isPOOnHold = () => {
    if (
      trailerArrivalData &&
      trailerArrivalData.trailerOrders.inboundOrder?.statusCd === TRAILER_ORDER_STATUS.HOLD
    ) {
      return true;
    }

    return false;
  };

  const onSubmit = (data: ReasonType) => {
    const purchaseOrderStatusResult = ReasonSchema.safeParse(data);

    if (!purchaseOrderStatusResult.success) {
      throw new LogError(formatErrorLog(ErrorLogType.ZOD, purchaseOrderStatusResult.error));
    } else {
      const payload = {
        ...trailerArrivalData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalData.trailerOrders.trailerOrderKey,
            commodityTypeCd: trailerArrivalData.trailerOrders.commodityTypeCd,
            inboundOrder: {
              statusCd: isPOOnHold() ? previousStatus : TRAILER_ORDER_STATUS.HOLD,
            },
            comment: data.reason,
          },
        ],
      };

      mutateUpdateTrailerArrival(
        {
          receiptId: receiptId,
          operation: isPOOnHold() ? POActions.REMOVE_HOLD : POActions.PUT_ON_HOLD,
          trailerArrivalUpdateRequest: payload,
        },
        {
          onSuccess: () => {
            resetForm(ACTIONS.SUBMIT);

            handleNotification(
              NOTIFICATION_TYPES.SUCCESS,
              isPOOnHold()
                ? t('PODashboard.Notification.RemoveTrilerArrivalHold.Success')
                : t('PODashboard.Notification.PutTrilerArrivalOnHold.Success')
            );
          },
        }
      );
    }
  };

  return (
    <Modal open={isOpen} onClose={() => onCloseModal()} className={s['po-status-modal']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <View direction="column" className={s['po-status-modal__form-wrapper']}>
          <View className={s['po-status-modal__header']}>
            <View direction="column">
              <Text as="h2" size="175" weight="bold" color="primary">
                {t(
                  `PODashboard.PutOnHold.${
                    isPOOnHold() ? 'RemoveFromHoldTitle' : 'PutOnHoldTitle'
                  }`,
                  {
                    poId: soureOrderNumber,
                  }
                )}
              </Text>
              <View padding={[2, 0, 0, 0]}>
                <Text color="primary" lineHeight="140">
                  {t('PODashboard.PutOnHold.PutPOOnHoldSubTitle')}
                </Text>
              </View>
            </View>
            <Button
              variant="ghost"
              onClick={onCloseModal}
              className={classNames(s['close-icon'], s['po-status-modal__header__close-button'])}
            >
              <Icon svg={Close} />
            </Button>
          </View>

          <View direction="column" className={s['po-status-modal__body']}>
            <View.Item>
              <View direction="column" className={s['po-status-modal__body__section']}>
                <View.Item>
                  <View direction="column" className={s['po-status-modal__body__section__item']}>
                    <Text
                      weight="medium"
                      size="087"
                      className={s['po-status-modal__body__form-field-label--required']}
                    >
                      {t(
                        `PODashboard.PutOnHold.${
                          isPOOnHold() ? 'ReasonRemoveFromHold' : 'ReasonPutOnHold'
                        }`
                      )}
                    </Text>

                    <FormControl hasError={!!errors.reason}>
                      <TextArea
                        variant="alt"
                        resize="vertical"
                        id="remove-reason"
                        label={t('PODashboard.PutOnHold.Placeholder')}
                        defaultValue=""
                        inputAttributes={register('reason')}
                      />

                      <View direction="row" justify={errors.reason ? 'space-between' : 'end'}>
                        {errors.reason && (
                          <FormControl.Error
                            className={s['po-status-modal__body__form-field-error-messages']}
                          >
                            {getFormInputError(errors.reason?.type, MAX_TEXTAREA_LENGTH)}
                          </FormControl.Error>
                        )}

                        <TextAreaCounter
                          count={watch('reason')?.length}
                          maxCount={MAX_TEXTAREA_LENGTH}
                        />
                      </View>
                    </FormControl>
                  </View>
                </View.Item>
              </View>
            </View.Item>
          </View>

          <View className={s['po-status-modal__footer']}>
            <View
              width="100%"
              direction="row"
              justify="end"
              className={s['po-status-modal__footer__actions']}
            >
              <Button
                variant="secondary"
                attributes={{ style: { width: 'fit-content' } }}
                disabled={trailerArrivalLoading}
                onClick={onCloseModal}
              >
                <Text>{t('Cancel')}</Text>
              </Button>

              <Button
                variant="primary"
                attributes={{ style: { width: 'fit-content' } }}
                type="submit"
                loading={trailerArrivalLoading}
                disabled={isButtonDisabled(watch('reason'))}
              >
                <Text>{t(`PODashboard.Actions.${isPOOnHold() ? 'RemoveHold' : 'PutOnHold'}`)}</Text>
              </Button>
            </View>
          </View>
        </View>
      </form>
    </Modal>
  );
};
