/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Icon, Link, Text, View, Warning, Notification } from '@az/starc-ui';
import { IconError } from '@az/starc-ui-icons';

import { Ellipses } from '@shared/assets/icons';
import { CommentCardProps } from '@shared/components/CommentCard/CommentCard';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { EMPTY_VALUE, ENTITY_ASSOCIATIONS, USER_STATUS_CD } from '@shared/constants/constants';

import { IcReviewModal } from '@inbound/components/ICReviewModal/IcReviewModal';
import { PAGE_URLS } from '@shared/constants/routes';
import { useGetTaskSearchData } from '@shared/services/hooks/useGetTaskSearchData';
import { useTrailerArrivalUpdater } from '@inbound/hooks/useTrailerArrivalUpdater';
import { CommentSchemaType } from '@shared/types/schema.type';
import { EmptyPage } from '@shared/components/EmptyPage/EmptyPage';
import { ActivityLog } from '@shared/components/ActivityLog/ActivityLog';

import {
  ASC,
  DEFAULT_PAGE,
  DOMAIN_TYPE_CD,
  INBOUND_ORDER_STATUS,
  NOT_STARTED,
  PAGE_SIZE,
  SORT_BY_PRIORITY,
  SOURCE_ORDER_NUMBER,
  TRAILER_ORDER_STATUS,
} from '@inbound/constants/constants';

import { useGetInboundOrderBySourceAndReceiptId } from '@inbound/services/hooks/useGetInboundOrderBySourceOrderAndReceiptId';
import { useGetTrailerOrders } from '@inbound/services/hooks/useGetTrailerOrders';
import {
  InboundTrailerLineType,
  TrailerArrivalUpdateType,
  TrailerOrderType,
  TrailerUserType,
} from '@inbound/types/types';
import { replaceAll, statusToBadgeVariant } from '@inbound/utils/utils';

import { PODetailSkeleton } from './PODetailSkeleton';

import { PODiscrepancyTable } from './PODiscrepancyTable/PODiscrepancyTable';
import { RenderMaterTitleSubTitle } from './PODetailsSection/PODetailMasterSubTitle';

import styles from './PODetail.module.scss';

export const POFinalizeDiscrepancies = () => {
  /* State variables */
  const [isHighPriority, setIsHighPriority] = useState<boolean | undefined>(false);
  const [trailerOrderData, setTrailerOrderData] = useState<TrailerOrderType>();
  const [orderStatus, setOrderStatus] = useState<string>();
  const [quantityUnmatchedData, setQuantityUnmatchedData] = useState<InboundTrailerLineType[]>([]);

  const [trailerArrivalEditData, setTrailerArrivalEditData] = useState<TrailerArrivalUpdateType>();
  const [comments, setComments] = useState<CommentCardProps[]>([]);
  const [isAcceptAll, setIsAcceptAll] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<TrailerUserType[]>([]);

  const [acceptedRows, setAcceptedRows] = useState<{ [key: string]: boolean }>({});
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [showIcReviewPOModal, setShowIcReviewPOModal] = useState<boolean>(false);

  /* Constants */
  const { t } = useTranslation();
  const { sourceOrderId: sourceOrderId, receiptId: receiptId } = useParams();
  const navigate = useNavigate();

  const { updateTrailerArrival } = useTrailerArrivalUpdater();

  const breadcrumbs = {
    data: [
      {
        label: t('PODashboard.Title'),
        onClick: () => navigate(PAGE_URLS.PO_DASHBOARD),
      },
      {
        label: t('PODashboard.PoWithNumber', {
          poNumber: sourceOrderId,
        }),
        onClick: () => navigate(PAGE_URLS.PO_DETAILS(sourceOrderId || '', receiptId || '')),
      },
      {
        label: t('PODashboard.POFinalization'),
        onClick: () => {
          ('');
        },
      },
    ],
  };

  /* Queries */
  const {
    inboundOrderData,
    isLoading: isLoadingInboundOrder,
    isError: isErrorInboundOrder,
  } = useGetInboundOrderBySourceAndReceiptId(
    {
      sourceOrderNumber: sourceOrderId || '',
      receiptId: receiptId || '',
    },
    true
  );

  const {
    trailerOrdersData,
    isLoading: isLoadingTrailerOrders,
    isError: isErrorTrailerOrders,
  } = useGetTrailerOrders(
    {
      searchPage: {
        currentPage: DEFAULT_PAGE,
        pageSize: PAGE_SIZE * 20,
        sortBy: SOURCE_ORDER_NUMBER,
        direction: ASC,
      },
      searchCriteria: {
        inboundOrder: {
          domainTypeCd: DOMAIN_TYPE_CD,
          sourceOrderNbr: sourceOrderId,
          statusCds: [orderStatus ? orderStatus : NOT_STARTED],
        },
      },
    },
    !!orderStatus
  );

  const { taskSearchData, refetch } = useGetTaskSearchData(
    {
      searchPage: {
        page: DEFAULT_PAGE,
        size: PAGE_SIZE,
        sortBy: SORT_BY_PRIORITY,
        direction: ASC,
      },
      searchCriteria: {
        taskNo: inboundOrderData?.trailerOrder.inboundOrder.tasks?.taskNbr,
        entityAssociations: [ENTITY_ASSOCIATIONS.COMMENTS],
      },
    },
    false
  );

  /* Functions */
  const isPOStatus = useCallback(
    (status: string) => {
      if (inboundOrderData && inboundOrderData.trailerOrder.inboundOrder.statusCd === status) {
        return true;
      } else {
        return false;
      }
    },
    [inboundOrderData]
  );

  const showPOAction = useCallback(() => {
    if (
      isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE) ||
      isPOStatus(TRAILER_ORDER_STATUS.FINALIZED)
    ) {
      return false;
    } else {
      return true;
    }
  }, [isPOStatus]);

  const addActivityComment = (newComment: CommentCardProps) => {
    if (trailerArrivalEditData && receiptId) {
      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            comment: newComment.comment,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
        notificationText: '',
        errorCallBack: () => {
          if (comments !== undefined) {
            const updatedComments = [...comments];
            updatedComments.pop();
            setComments(updatedComments);
          } else {
            setComments([]);
          }
        },
      });
      setComments([newComment, ...comments]);
    }
  };

  const onFinalizeRequestApproval = () => {
    if (
      trailerArrivalEditData &&
      receiptId &&
      inboundOrderData?.trailerOrder.inboundOrder.inboundOrderLines
    ) {
      const updatedInboundOrderLines =
        inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.map((line) => ({
          inboundOrderLineKey: line.inboundOrderLineKey,
          statusCd: INBOUND_ORDER_STATUS.ACCEPTED,
        }));

      const payload = {
        ...trailerArrivalEditData,
        trailerOrders: [
          {
            trailerOrderKey: trailerArrivalEditData.trailerOrders?.trailerOrderKey,
            commodityTypeCd: trailerArrivalEditData.trailerOrders?.commodityTypeCd,
            inboundOrder: {
              statusCd: TRAILER_ORDER_STATUS.READY_FOR_SIGNATURE,
              subStatusCd: INBOUND_ORDER_STATUS.ACCEPTED,
              inboundOrderLines: updatedInboundOrderLines, // Use the array of updated inboundOrderLines here
            },
          },
        ],
      };

      updateTrailerArrival({
        payload,
        receiptId,
        sourceOrderId,
        notificationText: t('PODashboard.Notification.FinalizationCompletedSuccess'),
        successCallBack: () => {
          navigate(PAGE_URLS.PO_DETAILS(sourceOrderId ?? '', receiptId));
        },
      });
    }
  };

  const handleAcceptAllClick = () => {
    const newIsAcceptAll = !isAcceptAll;
    setIsAcceptAll(newIsAcceptAll);

    const updatedAcceptedRows =
      quantityUnmatchedData?.reduce((acc, item) => {
        if (item.productId) {
          acc[item.productId] = newIsAcceptAll;
        }
        return acc;
      }, {} as { [key: string]: boolean }) || {};

    setAcceptedRows(updatedAcceptedRows);
    setAcceptedCount(newIsAcceptAll ? quantityUnmatchedData?.length || 0 : 0);
  };

  const handleIndividualAccept = (productId: string) => {
    const isCurrentlyAccepted = acceptedRows[productId];
    setAcceptedRows((prevAcceptedRows) => ({
      ...prevAcceptedRows,
      [productId]: !isCurrentlyAccepted,
    }));

    const acceptedCountRef = isCurrentlyAccepted ? acceptedCount - 1 : acceptedCount + 1;

    setAcceptedCount(acceptedCountRef);

    if (acceptedCountRef === quantityUnmatchedData?.length) {
      setIsAcceptAll(true);
    } else {
      setIsAcceptAll(false);
    }
  };

  const onCloseIcReviewPOModal = () => {
    setShowIcReviewPOModal(false);
  };

  /* Hooks */
  useEffect(() => {
    if (inboundOrderData) {
      setOrderStatus(inboundOrderData.trailerOrder.inboundOrder.statusCd);
      setAssignedUsers(
        inboundOrderData.trailerOrder?.inboundOrder.users.filter(
          (item) => item.userStatusCd !== USER_STATUS_CD.CANCEL
        )
      );

      if (inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines) {
        setQuantityUnmatchedData(
          inboundOrderData.trailerOrder.inboundOrder.inboundOrderLines.filter(
            (inboundOrderLine) =>
              inboundOrderLine.totalOrderedQty !== inboundOrderLine.totalReceivedQty
          )
        );
      }

      setIsHighPriority(inboundOrderData.trailerOrder.inboundOrder.priority ? true : false);
    }
  }, [inboundOrderData]);

  useEffect(() => {
    if (trailerOrdersData) {
      const trailerOrder = trailerOrdersData.content.filter(
        (orderItem) => orderItem.trailerOrder.receiptId === receiptId
      );

      if (trailerOrder.length > 0) {
        setTrailerOrderData(trailerOrder[0].trailerOrder);
      }
    }
  }, [receiptId, trailerOrdersData]);

  useEffect(() => {
    if (inboundOrderData && trailerOrderData) {
      setTrailerArrivalEditData({
        trailerArrivalTs: trailerOrderData.trailerArrival.trailerArrivalTs,
        trailerCarrierName: trailerOrderData.trailerArrival.trailerCarrierName || '',
        trailerNbr: trailerOrderData.trailerArrival.trailerNbr || '',
        trailerLocationTypeCd: trailerOrderData.trailerArrival.trailerLocationTypeCd || '',
        trailerLocationId: trailerOrderData.trailerArrival.trailerLocationId || '',
        trailerOrders: {
          trailerOrderKey: inboundOrderData.trailerOrder.trailerOrderKey,
          statusCd: inboundOrderData.trailerOrder.statusCd,
          commodityTypeCd: inboundOrderData.trailerOrder.commodityTypeCd,
          inboundOrder: {
            priority: inboundOrderData.trailerOrder.inboundOrder.priority,
            orderLocationTypeCd: inboundOrderData.trailerOrder.inboundOrder.orderLocationTypeCd,
            orderLocationId: inboundOrderData.trailerOrder.inboundOrder.orderLocationId,
            users: inboundOrderData.trailerOrder.inboundOrder.users,
            statusCd: inboundOrderData.trailerOrder.inboundOrder.statusCd,
          },
        },
        priority: inboundOrderData.trailerOrder.inboundOrder.priority ? true : false,
        contactName: trailerOrderData.trailerArrival.contactName ?? null,
        contactPhone: trailerOrderData.trailerArrival.contactPhone ?? null,
        contactEmail: trailerOrderData.trailerArrival.contactEmail ?? null,
      });
    }
  }, [inboundOrderData, trailerOrderData]);

  useEffect(() => {
    if (taskSearchData && taskSearchData.content.length > 0) {
      const userComments = taskSearchData.content[0].comments;
      const taskComments =
        (userComments &&
          userComments
            .filter(
              (comment: CommentSchemaType) => comment.comment !== null && comment.userId !== null
            )
            .map((comment: CommentSchemaType) => ({
              comment: comment.comment || '',
              username: comment.userId || '',
              timestamp: new Date(comment?.createTs || ''),
            }))) ||
        [];
      setComments(taskComments);
    }
  }, [taskSearchData]);

  useEffect(() => {
    if (inboundOrderData?.trailerOrder.inboundOrder.tasks?.taskNbr) {
      refetch();
    }
  }, [inboundOrderData, refetch]);

  if (isLoadingInboundOrder || isLoadingTrailerOrders) {
    return <PODetailSkeleton />;
  } else if (isErrorTrailerOrders || isErrorInboundOrder) {
    return (
      <EmptyPage
        title={t('Errors.Page.Title')}
        description={t('Errors.Page.Description', { service: t('Services.Order') })}
        buttonText={t('404.ButtonText')}
        onClick={() => navigate(PAGE_URLS.PO_DASHBOARD)}
        icon={IconError}
      />
    );
  } else {
    return (
      <View direction="column" height="100%" width="100%" className={styles['po-detail']}>
        {inboundOrderData && trailerOrderData && (
          <>
            <MasterTitle
              title={
                inboundOrderData?.trailerOrder.inboundOrder.vendorLocale?.vendorName || EMPTY_VALUE
              }
              breadcrumbProps={breadcrumbs}
              statusBadgeProps={{
                variant: statusToBadgeVariant(inboundOrderData.trailerOrder.inboundOrder.statusCd),
                text: replaceAll(inboundOrderData.trailerOrder.inboundOrder.statusCd, '_', ' '),
              }}
              subtitle={
                <RenderMaterTitleSubTitle
                  inboundOrderData={inboundOrderData}
                  isHighPriority={isHighPriority}
                  sourceOrderId={sourceOrderId ?? ''}
                />
              }
            >
              <View direction="row" justify="end" align="center" gap={4}>
                <View.Item>
                  <ActivityLog
                    comments={comments}
                    handleCommentSubmit={addActivityComment}
                    isLoading={false}
                  />
                </View.Item>

                <View.Item>
                  {(isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION) ||
                    isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW)) && (
                    <View direction="row" gap={2}>
                      <View.Item>
                        {isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION) && (
                          <Button
                            disabled={isAcceptAll}
                            variant="secondary"
                            size="large"
                            loading={isLoadingInboundOrder}
                            onClick={() => {
                              setShowIcReviewPOModal(true);
                            }}
                          >
                            <View direction="row">
                              <Text>{t('PODashboard.SendToIcReview')}</Text>
                            </View>
                          </Button>
                        )}
                      </View.Item>
                      <View.Item>
                        <Button
                          disabled={!isAcceptAll}
                          loading={isLoadingInboundOrder}
                          size="large"
                          onClick={() => {
                            onFinalizeRequestApproval();
                          }}
                        >
                          <View direction="row">
                            <Text>{t('PODashboard.FinalizeRequestApproval')}</Text>
                          </View>
                        </Button>
                      </View.Item>
                    </View>
                  )}
                </View.Item>

                {showPOAction() && (
                  <View.Item>
                    <View direction="row">
                      <Link
                        onClick={() => {
                          return;
                        }}
                      >
                        <Icon svg={Ellipses} />
                      </Link>
                    </View>
                  </View.Item>
                )}
              </View>
            </MasterTitle>
          </>
        )}

        {inboundOrderData &&
          trailerOrderData &&
          (isPOStatus(TRAILER_ORDER_STATUS.READY_FOR_FINALIZATION) ||
            isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW)) && (
            <View padding={[4, 6]}>
              <View direction="column" height="100%" width="100%">
                <View direction="row" justify="space-between" width="100%" padding={[0, 0, 4, 0]}>
                  <View.Item>
                    {!isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW) && (
                      <Text weight="bold" size="125">
                        {t('PODashboard.DiscrepanciesCount', {
                          count: acceptedCount,
                          total: quantityUnmatchedData?.length || 0,
                        })}
                      </Text>
                    )}
                    {isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW) && (
                      <Text weight="bold" size="125">
                        {t('PODashboard.DiscrepanciesCount', {
                          count:
                            quantityUnmatchedData.filter(
                              (line) => line.statusCd === INBOUND_ORDER_STATUS.ACCEPTED
                            ).length || 0,
                          total: quantityUnmatchedData?.length || 0,
                        })}
                      </Text>
                    )}
                    <Text>{t('PODashboard.DiscrepanciesText')}</Text>
                  </View.Item>
                  {!isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW) && (
                    <View.Item>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleAcceptAllClick();
                        }}
                      >
                        {t('PODashboard.Actions.AcceptAll')}
                      </Button>
                    </View.Item>
                  )}
                </View>

                {isPOStatus(TRAILER_ORDER_STATUS.IN_IC_REVIEW) && (
                  <View direction="row" padding={[0, 0, 4, 0]}>
                    <Notification
                      type="custom"
                      className={styles['po-detail__notification_warning']}
                      customNotification={{
                        svg: Warning,
                        barColor: 'warning',
                        iconColor: 'warning',
                      }}
                      title={t('PODashboard.InIcReview.InlineNotificationTitle')}
                      text={t('PODashboard.InIcReview.InlineNotificationText')}
                    />
                  </View>
                )}

                <View direction="row" padding={[4, 0]} attributes={{ style: { overflow: 'auto' } }}>
                  <PODiscrepancyTable
                    productData={quantityUnmatchedData}
                    acceptedRows={acceptedRows}
                    handleIndividualAccept={handleIndividualAccept}
                    users={assignedUsers}
                    status={inboundOrderData?.trailerOrder.inboundOrder?.statusCd}
                  />
                </View>

                <IcReviewModal
                  data={quantityUnmatchedData}
                  isOpen={showIcReviewPOModal}
                  onClose={() => onCloseIcReviewPOModal()}
                  count={acceptedRows}
                />
              </View>
            </View>
          )}
      </View>
    );
  }
};
