/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Text, TextField, View } from '@az/starc-ui';
import { Table } from '@shared/components/Table/Table.tsx';
import { TableStylingVariants } from '@shared/components/Table/tableConstants.ts';

import { DEFAULT_PAGE, PAGE_SIZE } from '@shared/constants/constants.ts';

import { useTranslation } from 'react-i18next';
import styles from '@mdm/pages/RDM/Role/RoleList.module.scss';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { FormLabel } from '@shared/components/FormLabel/FormLabel.tsx';
import { ColumnParam } from '@shared/components/Table/Table.types.ts';
import { mapGroupPermissionTableRows } from '@mdm/utils/table/tableUtils.tsx';
import {
  CapabilityGroupType,
  CapabilityType,
  PermissionListType,
  PermissionType,
} from '@mdm/pages/RDM/User/Permission/PermissionList.types.ts';
import { MAX_LENGTH_FIELD } from '@mdm/constants/constants.ts';
import { useGetCapabilityGroups } from '@shared/services/hooks/useGetCapabilityGroups.tsx';
import { useUserRoleSearch } from '@shared/services/hooks/useUserRoleSearch.ts';
import { RoleType } from '@shared/types/schema.type.ts';

type RolePermissions = {
  [roleId: string]: {
    [groupId: string]: { permission: PermissionType[]; roleId: string; groupId: string };
  };
};

export const PermissionList = () => {
  /* State */
  const [columns, setColumns] = useState<ColumnParam[]>([]);
  const [rows, setRows] = useState<PermissionListType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userRolesData } = useUserRoleSearch({});
  const { capabilityGroupsData, isFetching: isCapabilityGroupDataLoading } = useGetCapabilityGroups(
    {}
  );

  /* Function */
  const onViewDetails = (permission: PermissionListType) => {
    navigate(PAGE_URLS.PERMISSION_DETAILS(permission.roleId));
  };

  const setRowData = useCallback(() => {
    if (userRolesData && capabilityGroupsData) {
      const groupByRoleId = (userRolesData || []).reduce(
        (
          acc: Record<
            string,
            Record<string, { permission: PermissionType[]; roleId: string; groupId: string }>
          >,
          role: RoleType
        ) => {
          acc[role.roleId] = (capabilityGroupsData || []).reduce(
            (groupAcc: RolePermissions, group: CapabilityGroupType) => {
              groupAcc[group.groupId] = {};
              return groupAcc;
            },
            {}
          );
          return acc;
        },
        {}
      );

      (capabilityGroupsData || []).forEach((group: CapabilityGroupType) => {
        (group.capabilities || []).forEach((capability: CapabilityType) => {
          (capability.permissions || []).forEach((permission: PermissionType) => {
            if (groupByRoleId[permission.roleId]?.[group.groupId]) {
              const groupPermissions =
                groupByRoleId[permission.roleId][group.groupId].permission || [];
              groupPermissions.push(permission);
              groupByRoleId[permission.roleId][group.groupId].permission = groupPermissions;
            } else {
              if (!groupByRoleId[permission.roleId]) {
                groupByRoleId[permission.roleId] = {};
              }
              groupByRoleId[permission.roleId][group.groupId] = {
                roleId: permission.roleId,
                permission: [permission],
                groupId: group.groupId,
              };
            }
          });
        });
      });

      let rowData: PermissionListType[] = (userRolesData || []).map((role: RoleType) => {
        const row: { roleId: string; [key: string]: { permission: PermissionType[] } | string } = {
          roleId: role.roleId,
        };
        (capabilityGroupsData || []).forEach((group: CapabilityGroupType) => {
          row[group.groupId] = {
            permission: groupByRoleId[role.roleId][group.groupId].permission,
          };
        });
        return row;
      });

      if (searchQuery) {
        rowData = rowData.filter((row) =>
          row.roleId.toLowerCase().startsWith(searchQuery.toLowerCase())
        );
      }

      setRows([...rowData]);
    }
  }, [capabilityGroupsData, searchQuery, userRolesData]);

  const setColumnData = useCallback(() => {
    if (capabilityGroupsData) {
      const columnData = [
        {
          label: 'CreateRoleModal.RoleName',
          id: 'roleId',
          textAlign: 'start' as const,
          width: 'calc(var(--st-unit-20) * 2)',
          isSorted: false,
        },
        ...(capabilityGroupsData || []).map((group: CapabilityGroupType) => ({
          label: group.groupDescription,
          id: group.groupId,
          textAlign: 'start' as const,
          width: 'calc(var(--st-unit-20) * 4)',
          isSorted: false,
        })),
        {
          label: 'Action',
          id: 'empty',
          textAlign: 'start' as const,
          width: 'calc(var(--st-unit-20) * 2)',
          isSorted: false,
        },
      ];
      setColumns([...columnData]);
    }
  }, [capabilityGroupsData]);

  /* Hooks */
  useEffect(() => {
    setColumnData();
    setRowData();
  }, [setColumnData, setRowData]);

  return (
    <View>
      <View className={styles['permission__list']}>
        <MasterTitle title={t('Sidenav.Permissions')}>
          <View direction="row" justify="end" align="center" gap={4}>
            <View.Item>
              <View direction="row" gap={4}>
                <View.Item>
                  <Button
                    size="large"
                    onClick={() => navigate(PAGE_URLS.MANAGE_SUBGROUP_PERMISSIONS)}
                  >
                    <View direction="row" align="center" justify="center" gap={2}>
                      <Text>{t('AssignPermission')}</Text>
                    </View>
                  </Button>
                </View.Item>
              </View>
            </View.Item>
          </View>
        </MasterTitle>

        <View padding={6} backgroundColor="secondary">
          <View gap={3}>
            <View.Item columns={{ s: 12, l: 3 }}>
              <FormLabel text={t('CreateRoleModal.RoleName')} />
              <TextField
                attributes={{
                  'data-testid': 'name',
                }}
                inputAttributes={{
                  maxLength: MAX_LENGTH_FIELD.USER_ROLE_ID,
                  placeholder: t('CreateRoleModal.RoleNamePlaceholder'),
                  onChange: (e) => setSearchQuery(e.target.value),
                }}
                defaultValue=""
              />
            </View.Item>
          </View>
        </View>

        <View padding={[4, 6]}>
          <Table
            columns={columns}
            rows={mapGroupPermissionTableRows(rows, capabilityGroupsData || [], onViewDetails)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isCapabilityGroupDataLoading}
            styleVariant={TableStylingVariants.DEFAULT}
            totalPages={Math.ceil((rows.length || DEFAULT_PAGE - 1) / PAGE_SIZE)}
            onSort={() => ({})}
          />
        </View>
      </View>
    </View>
  );
};
