/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ErrorResponse } from '@mdm/types/service.type.ts';
import { assetService } from '@mdm/services/assetService.ts';

export const useDeleteVehicle = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { handleNotification } = useNotificationHandler();

  const {
    mutate: mutateDeleteVehicle,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { assetKey: string; invalidateCache?: boolean }) =>
      assetService.deleteAsset(params.assetKey),
    onSuccess: async (_response, params) => {
      const { invalidateCache = true } = params;

      if (invalidateCache) {
        queryClient.invalidateQueries(['vehicles']);
      }

      handleNotification(NOTIFICATION_TYPES.SUCCESS, t('Success.Action.Vehicle.Deleted'));
    },
    onError: (error: ErrorResponse) => {
      let message = t('Errors.Action.DeleteVehicle');
      if (error?.message) {
        message = error.message;
      }
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateDeleteVehicle, isLoading, isError };
};
