/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { USER_ENDPOINTS } from '@shared/constants/routes.ts';

import { apiService } from '@shared/services/apiService.ts';
import {
  AssignUserToRoleRequest,
  GetUserRoleParams,
  UserRoleRequest,
} from '@mdm/types/service.type.ts';

export const userRoleService = {
  assignUsers: async (payload: AssignUserToRoleRequest[]) => {
    const response = await apiService.post(USER_ENDPOINTS.ASSIGN_USER_TO_USER_ROLE, payload);
    return response.data;
  },
  bulkCreateUserRole: async (userRoleRequest: UserRoleRequest[]) => {
    const resp = await apiService.post(USER_ENDPOINTS.CREATE_BULK_USER_ROLE, userRoleRequest);
    return resp.data;
  },
  getUserRoles: async (options: GetUserRoleParams) => {
    const payload = options.roleIds && options.roleIds.length ? [options.roleIds] : [];

    const response = await apiService.post(USER_ENDPOINTS.GET_USER_ROLES, payload);
    return response.data;
  },
};
