/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

import { trailerArrivalService } from '@inbound/services/trailerArrivalService';
import { ErrorResponse } from '@inbound/types/service.type';
import { TrailerArrivalRequestType } from '@inbound/types/types';

export const useCreateTrailerArrival = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  let message = t('PODashboard.Notification.CreateTrilerArrival.Error');

  const {
    mutate: mutateCreateaTrailerArrival,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { trailerArrivalRequest: TrailerArrivalRequestType }) => {
      return trailerArrivalService.createTrailerArrival(params.trailerArrivalRequest);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['sourceOrderNumber']);
      queryClient.invalidateQueries(['trailerOrders']);
    },
    onError: (error: ErrorResponse) => {
      if (error?.message) {
        message = error.message;
      }

      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateCreateaTrailerArrival, isLoading, isError };
};
