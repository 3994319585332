/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GetLayoutParams } from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { LAYOUT_ENDPOINTS } from '@shared/constants/routes.ts';
import { paginatedParseAndLog } from '@mdm/utils/utils.ts';
import {
  ENTITY_ASSOCIATIONS,
  LAYOUT_ENTITY_CODE,
  LAYOUT_ENTITY_TYPE_CODE,
  SEARCH_TYPE,
} from '@mdm/constants/constants.ts';
import { LayoutListSchema } from '@mdm/schemas/layoutSchema.ts';

export const zoneService = {
  getZones: async (options: GetLayoutParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        entityCd: LAYOUT_ENTITY_CODE.ZONE,
        entityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        entityAssociations: options?.entityAssociations || [
          ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES,
          ENTITY_ASSOCIATIONS.LAYOUT_PARENT,
          ENTITY_ASSOCIATIONS.CHILDREN,
        ],
        ...(options?.searchQuery && {
          layout: { layoutName: options.searchQuery, searchType: SEARCH_TYPE.PARTIAL },
        }),
      },
    };

    const response = await apiService.post(LAYOUT_ENDPOINTS.GET_LAYOUTS, payload);
    return paginatedParseAndLog(LayoutListSchema, response.data);
  },
  getZoneByLayoutKey: async (layoutKey: string) => {
    const payload = {
      searchCriteria: {
        entityCd: LAYOUT_ENTITY_CODE.ZONE,
        entityTypeCd: LAYOUT_ENTITY_TYPE_CODE.DEFAULT,
        entityAssociations: [
          ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES,
          ENTITY_ASSOCIATIONS.LAYOUT_PARENT,
          ENTITY_ASSOCIATIONS.CHILDREN,
        ],
        layoutDistinctNames: [layoutKey],
      },
      searchPage: {},
    };

    const response = await apiService.post(LAYOUT_ENDPOINTS.GET_LAYOUTS, payload);
    return paginatedParseAndLog(LayoutListSchema, response.data);
  },
};
