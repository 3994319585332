/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const RetriveDashboardsSchema = z.object({
  NOT_STARTED: z.string().optional(),
  RECEIVING_IN_PROGRESS: z.string().optional(),
  READY_FOR_FINALIZATION: z.string().optional(),
  IN_IC_REVIEW: z.string().optional(),
  READY_FOR_SIGNATURE: z.string().optional(),
  IN_LAST_24_HOURS: z.string().optional(),
  BETWEEN_24_TO_48_HOURS: z.string().optional(),
  BETWEEN_48_TO_72_HOURS: z.string().optional(),
  MORE_THAN_72_HOURS: z.string().optional(),
});
