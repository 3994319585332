/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { ErrorResponse, OrderTypeRequest } from '@mdm/types/service.type.ts';
import { orderTypeService } from '@mdm/services/orderTypeService.ts';

export const useMutateOrderType = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  let message = t('Errors.Action.CreateOrderType');

  const {
    mutate: mutateOrderType,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { orderTypeCode?: string; orderTypeRequest: OrderTypeRequest }) => {
      if (params.orderTypeCode) {
        message = t('Errors.Action.UpdateOrderType');
        return orderTypeService.updateOrderType(params.orderTypeCode, params.orderTypeRequest);
      } else {
        return orderTypeService.createOrderType(params.orderTypeRequest);
      }
    },
    onError: (error: ErrorResponse) => {
      if (error?.message) {
        message = error.message;
      }
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateOrderType, isLoading, isError };
};
