/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  useEffect,
  useRef,
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';
import { t } from 'i18next';

import { Checkbox, Divider, Dropdown, Radio, Text, Toggle, View } from '@az/starc-ui';

import { DisplayOptionsType } from '@outbound/components/MatrixAccordion/MatrixAccordion.types';
import { MatrixSortOptions } from '@outbound/constants/constants';

import styles from './ControlDesk.module.scss';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@shared/constants/routes';

export type DisplayOptionsProps = {
  options: DisplayOptionsType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DisplayOptions = ({ options, onChange }: DisplayOptionsProps) => {
  /* State variables */
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  /* Constants */
  const location = useLocation();
  const orderType = location.pathname.split('/').at(-1);

  /* Functions */
  const toggleDropdown = () => setOpen(!open);

  const handleDopdownButtonClick = (
    e: ReactKeyboardEvent<HTMLElement> | ReactMouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    toggleDropdown();
  };

  const isSortationDisabled = () => {
    const tab = location.pathname.split('/').at(-1);
    return [ROUTES.REPLENISHMENT, ROUTES.Will_CAll].includes(tab ?? '') ? false : true;
  };

  /* Hooks */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target: Node = ((event as MouseEvent).target ||
        (event as TouchEvent).targetTouches[0]) as Node;
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(target) &&
        !buttonRef.current.contains(target) &&
        open
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [open]);

  return (
    <View>
      <Dropdown open={open} contentGap={0} className={styles['dropdown']}>
        <View attributes={{ ref: buttonRef }}>
          <Dropdown.Button
            label={t('OutboundMatrix.DisplayOptions.Label')}
            className={styles['dropdown__button']}
            onClick={handleDopdownButtonClick}
          />
        </View>
        <Dropdown.Content>
          <View
            attributes={{
              ref: dropdownRef,
            }}
            padding={6}
            gap={6}
          >
            <View.Item>
              <View gap={5}>
                <Text size="087" weight="medium">
                  {t('OutboundMatrix.DisplayOptions.GeneralInformation')}
                </Text>
                <View gap={4}>
                  <Checkbox
                    label={t('OutboundMatrix.DisplayOptions.CompletedStores')}
                    name="completedStores"
                    value="completedStores"
                    checked={options.completedStores}
                    onChange={onChange}
                  />
                  <Checkbox
                    label={t('OutboundMatrix.DisplayOptions.WaveDetails')}
                    name="waveDetails"
                    value="waveDetails"
                    checked={options.waveDetails}
                    onChange={onChange}
                  />
                </View>
              </View>
            </View.Item>
            <View.Item>
              <Divider color="300" />
            </View.Item>
            <View.Item>
              <View gap={5}>
                <Text size="087" weight="medium">
                  {t('OutboundMatrix.DisplayOptions.TileInformation')}
                </Text>
                <View gap={4}>
                  <Checkbox
                    label={t('OutboundMatrix.DisplayOptions.VehicleCode')}
                    name="vehicleCode"
                    value="vehicleCode"
                    checked={options.vehicleCode}
                    onChange={onChange}
                  />
                  <Checkbox
                    label={t('OutboundMatrix.DisplayOptions.OrderSelector')}
                    name="orderSelector"
                    value="orderSelector"
                    checked={options.orderSelector}
                    onChange={onChange}
                  />
                  <View
                    direction="row"
                    align="center"
                    wrap={false}
                    className={styles['dropdown__switch']}
                  >
                    <Toggle name="tileInfo" checked={options.tileInfo} onChange={onChange} />
                    <View className={styles['dropdown__switch--label']}>
                      <Text size="100" weight="medium" color="600">
                        {t('OutboundMatrix.DisplayOptions.ShowTileInformation')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View.Item>
            <View.Item>
              <Divider color="300" />
            </View.Item>
            <View.Item>
              <View gap={5}>
                <Text size="087" weight="medium">
                  {t('OutboundMatrix.DisplayOptions.SortBy')}
                </Text>
                <View gap={4}>
                  <Radio.Group
                    name="sortBy"
                    value={isSortationDisabled() ? MatrixSortOptions.RELESASE : options.sortBy}
                    onChange={onChange}
                    disabled={isSortationDisabled()}
                  >
                    <Radio
                      label={t('OutboundMatrix.DisplayOptions.RouteGroup')}
                      value={MatrixSortOptions.ROUTE}
                    />
                    <Radio
                      label={t('OutboundMatrix.DisplayOptions.WaveGroup')}
                      value={MatrixSortOptions.WAVE}
                      disabled={orderType === ROUTES.Will_CAll}
                    />
                    <Radio
                      label={t('OutboundMatrix.DisplayOptions.ReleaseOrder')}
                      value={MatrixSortOptions.RELESASE}
                    />
                  </Radio.Group>
                </View>
              </View>
            </View.Item>
          </View>
        </Dropdown.Content>
      </Dropdown>
    </View>
  );
};
