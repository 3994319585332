/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import dayjs, { Dayjs } from 'dayjs';

import { DateFilters } from '@inventory/constants/constants';

type DateRange = {
  fromTs: string;
  toTs: string;
};

const SUNDAY = 0;

// Helper function to find the previous Sunday from a given date
const getPreviousSunday = (date: dayjs.Dayjs) => {
  const dayOfWeek = date.day();
  return dayOfWeek === SUNDAY ? date : date.subtract(dayOfWeek, 'day');
};

const getStartOfFiscalYear = (referenceDate: dayjs.Dayjs) => {
  let fiscalYearStart = dayjs(`${referenceDate.year()}-09-01`);
  fiscalYearStart = getPreviousSunday(fiscalYearStart);

  if (referenceDate.isBefore(fiscalYearStart)) {
    fiscalYearStart = dayjs(`${referenceDate.year() - 1}-09-01`);
    fiscalYearStart = getPreviousSunday(fiscalYearStart);
  }

  return fiscalYearStart;
};

/**
 * Represents a date range with start and end timestamps.
 * @typedef {Object} DateRange
 * @property {string} fromTs - The start timestamp in ISO string format.
 * @property {string} toTs - The end timestamp in ISO string format.
 */

/**
 * Maps a date filter to a corresponding date range based on the reference date.
 *
 * @param {string} filter - The filter to apply for the date range.
 * Possible values are:
 * - 'ThisWeek': Returns the current week range (Sunday to Saturday).
 * - 'Last4Weeks': Returns the range of the last 4 weeks.
 * - 'FiscalYear': Returns the fiscal year range based on the reference date.
 * @param {Dayjs} [referenceDate=dayjs()] - The reference date to calculate the range from. Defaults to the current date.
 * @returns {DateRange} The calculated date range with fromTs and toTs as ISO strings.
 *
 * @example
 * // Example usage:
 * const today = dayjs('2024-10-22'); // Example reference date
 *
 * const thisWeekRange = mapDateFilterToRange('ThisWeek', today);
 * Output: { fromTs: '2024-10-20T00:00:00.000Z', toTs: '2024-10-26T23:59:59.999Z' }
 *
 * const last4WeeksRange = mapDateFilterToRange('Last4Weeks', today);
 * Output: { fromTs: '2024-09-29T00:00:00.000Z', toTs: '2024-10-19T23:59:59.999Z' }
 *
 * const fiscalYearRange = mapDateFilterToRange('FiscalYear', today);
 *  Output: { fromTs: '2024-09-01T00:00:00.000Z', toTs: '2025-08-31T23:59:59.999Z' }
 */
export const mapDateFilterToRange = (filter: string, referenceDate: Dayjs = dayjs()): DateRange => {
  let fromTs: Dayjs;
  let toTs: Dayjs;

  switch (filter) {
    case DateFilters.THIS_WEEK:
      fromTs = referenceDate.startOf('week'); // Start of week (Sunday)
      toTs = fromTs.add(6, 'days').endOf('day'); // End of week (Saturday)
      break;

    case DateFilters.LAST_4_WEEKS: {
      // Start from the Sunday 4 weeks ago to the last Saturday
      fromTs = referenceDate.subtract(4, 'weeks').startOf('week'); // Start of the week 4 weeks ago (Sunday)
      toTs = referenceDate.startOf('week').subtract(1, 'day').endOf('day'); // End of last week (Saturday)
      break;
    }

    case DateFilters.FISCAL_YEAR: {
      // First Sunday on or before September 1 of the current year
      const fiscalYearStart = getStartOfFiscalYear(referenceDate); // First Sunday on/before Sept 1

      // Add 380 days to start of the fiscal year and get next fiscal year start date
      let endOfFiscalYear = getStartOfFiscalYear(fiscalYearStart.add(380, 'day'));
      endOfFiscalYear = endOfFiscalYear.subtract(1, 'day'); // Subtract 1 day for end date

      fromTs = fiscalYearStart;
      toTs = endOfFiscalYear;
      break;
    }

    default:
      // Default to "This Week" if no filter is selected
      fromTs = referenceDate.startOf('week'); // Start of week (Sunday)
      toTs = fromTs.add(6, 'days').endOf('day'); // Saturday
      break;
  }

  return {
    fromTs: fromTs.toISOString(),
    toTs: toTs.toISOString(),
  };
};
