/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  CYCLE_COUNT_TABS,
  CYCLE_COUNT_TYPE_CD,
  HEADER_COMPONENT,
} from '@inventory/constants/constants';
import { InboundOrderDataType } from '@inventory/types/types';

export const getSubzone = (input: string) => {
  const match = input.match(/SZ=([^,]*)/);
  const szValue = match ? match[1] : null;
  return szValue;
};

export const getInboundOrderDataBySubzone = (
  inboundOrderData: InboundOrderDataType[],
  subzone: string
) =>
  inboundOrderData?.filter((item) =>
    item.inboundOrder.inboundOrderLines.some(
      (line) =>
        line.inboundOrderLineLocations &&
        line.inboundOrderLineLocations.some((location) => {
          if (getSubzone(location.layoutDistinctName) === subzone) {
            return true;
          } else {
            return false;
          }
        })
    )
  );

export const checkCountTypeCd = (CountTypeCdValue: string) => {
  if (CountTypeCdValue === CYCLE_COUNT_TABS.WALL_TO_WALL) {
    return CYCLE_COUNT_TYPE_CD.WALL_TO_WALL;
  } else if (CountTypeCdValue === CYCLE_COUNT_TABS.RISK_BASED) {
    return CYCLE_COUNT_TYPE_CD.RBCC;
  }
  // check this condition for cycleCount Details - header component
  else if (CountTypeCdValue === CYCLE_COUNT_TYPE_CD.WALL_TO_WALL) {
    return HEADER_COMPONENT.WALL_TO_WALL;
  } else if (CountTypeCdValue === CYCLE_COUNT_TYPE_CD.RBCC) {
    return HEADER_COMPONENT.RISK_BASED;
  }
};
