/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { INBOUND_RECEIVING_ENDPOINTS } from '@shared/constants/routes.ts';
import { apiService } from '@shared/services/apiService.ts';
import { parseAndLog } from '@shared/utils/service/serviceUtils';

import {
  TrailerArrivalRequestSchema,
  TrailerArrivalUpdateRequestSchema,
} from '@inbound/schemas/trailerArrivalSchema';
import { TrailerArrivalRequestType, TrailerArrivalUpdateRequestType } from '@inbound/types/types';

export const trailerArrivalService = {
  createTrailerArrival: async (trailerArrivalRequest: TrailerArrivalRequestType) => {
    const resp = await apiService.post(
      INBOUND_RECEIVING_ENDPOINTS.CREATE_TRAILER_ARRIVALS,
      trailerArrivalRequest
    );

    return parseAndLog(TrailerArrivalRequestSchema, resp.data);
  },

  updateTrailerArrival: async (
    receiptId: string,
    trailerArrivalUpdateRequest: TrailerArrivalUpdateRequestType
  ) => {
    const resp = await apiService.put(
      INBOUND_RECEIVING_ENDPOINTS.UPDATE_TRAILER_ARRIVALS(receiptId),
      trailerArrivalUpdateRequest
    );

    return parseAndLog(TrailerArrivalUpdateRequestSchema, resp.data);
  },
};
