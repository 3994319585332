/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, View, Text } from '@az/starc-ui';
import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle';
import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ViewGlobalCycleCountReportModal } from '@inventory/components/CycleCountsModal/ViewGlobalCycleCountReportModal/ViewGlobalCycleCountReportModal';
import { CycleCountsCreateButton } from '../CycleCountsCreateButton/CycleCountsCreateButton';

export const CycleCountHeader = ({ isEnableCreateBtn }: { isEnableCreateBtn: boolean }) => {
  const { t } = useTranslation();
  const [showViewGlobalCycleCountModal, setViewGlobalCycleCountModalOpen] =
    useState<boolean>(false);

  const toggleViewGlobalCycleCountModal = useCallback(() => {
    setViewGlobalCycleCountModalOpen(!showViewGlobalCycleCountModal);
  }, [showViewGlobalCycleCountModal]);

  return (
    <>
      <MasterTitle
        title={t('CycleCount.Title')}
        // TODO: Add functionality to save page to favorites column
        titleActionProps={{
          label: 'Favorite',
          handleClick: () => {
            return;
          },
        }}
      >
        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Button variant="secondary" size="large" onClick={toggleViewGlobalCycleCountModal}>
              <Text>{t('CycleCount.ViewGlobalCycleCountReport')}</Text>
            </Button>
          </View.Item>
          <View.Item>
            <CycleCountsCreateButton isEnableCreateBtn={isEnableCreateBtn} />
          </View.Item>
        </View>
      </MasterTitle>
      <ViewGlobalCycleCountReportModal
        showModal={showViewGlobalCycleCountModal}
        onClose={toggleViewGlobalCycleCountModal}
      />
    </>
  );
};
