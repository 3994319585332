/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const ShiftSchema = z.object({
  shiftId: z.string(),
  shiftDesc: z.string(),
  shiftIndex: z.number(),
  locale: z.string(),
  fromTime: z.string(),
  toTime: z.string(),
  dayOfWeek: z.string(),
});

export const ShiftsSearchSchema = z.array(ShiftSchema);
