/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';

import { Modal as StarcModal, View, Text, Actionable, Icon, Button } from '@az/starc-ui';
import { Close } from '@az/starc-ui-icons';

import * as T from './Modal.type';

import styles from './Modal.module.scss';

export const ConfirmationModal = ({
  open,
  onClose,
  title,
  subTitle,
  onSuccess,
  primaryBtnText,
  isCancelBtn,
  isPrimaryBtnDisabled,
  children,
  ...restProps
}: T.ModalProps) => {
  /* Constants */
  const { t } = useTranslation();

  return (
    <StarcModal
      open={open}
      onClose={onClose}
      attributes={{ style: { width: 'calc((var(--st-unit-23) * 6))' } }}
      className={styles['confirmation-modal']}
      {...restProps}
    >
      <View gap={6} padding={6}>
        <View
          direction="row"
          justify="space-between"
          className={styles['confirmation-modal__header']}
        >
          <View gap={2}>
            <Text size="175" weight="bold">
              {title}
            </Text>
            {subTitle && <Text>{subTitle}</Text>}
          </View>
          <Actionable onClick={onClose}>
            <Icon svg={Close} />
          </Actionable>
        </View>
        <View padding={[0, 2, 0, 0]}>{children}</View>
        <View direction="row" justify="end" className={styles['modal__footer']}>
          {isCancelBtn && (
            <Button variant="secondary" size="large" onClick={onClose}>
              {t('Cancel')}
            </Button>
          )}
          <Button size="large" onClick={onSuccess} disabled={isPrimaryBtnDisabled}>
            {primaryBtnText ? primaryBtnText : t('Save')}
          </Button>
        </View>
      </View>
    </StarcModal>
  );
};
