/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { STORE_ENDPOINTS } from '@shared/constants/routes';
import { ActivityInfoSchema, StoreSchema } from '@ofm/schemas/storeSchema';
import { apiService } from '@ofm/services/apiService';
import { GetStoresParams, ActivityParams, PostStoreStatus } from '@ofm/types/types';
import { newpaginatedParseAndLog, parseAndLog } from '@ofm/utils/utils';

export const storeService = {
  getStoreById: async (storeId: string) => {
    const response = await apiService.get(STORE_ENDPOINTS.GET_STORE(storeId));
    return parseAndLog(StoreSchema, response.data);
  },
  getStores: async (options: GetStoresParams) => {
    const params = {
      page: options.currentPage,
      size: options.pageSize,
    };

    const response = await apiService.get(STORE_ENDPOINTS.GET_ALL_STORES(options.storeId), {
      params: params,
    });
    return newpaginatedParseAndLog(StoreSchema, response.data);
  },
  postStoreActivity: async (storeId: string, activity: ActivityParams) => {
    const response = await apiService.post(STORE_ENDPOINTS.POST_STORE_ACTIVITY(storeId), activity);
    return response;
  },
  postStoreStatus: async (options: PostStoreStatus) => {
    const response = await apiService.post(STORE_ENDPOINTS.POST_STORE_STATUS(options.storeId), {
      status: options.status,
      message: options.message,
      endDate: options?.endDate,
    });

    return parseAndLog(StoreSchema, response.data);
  },
  getStoreActivityById: async (storeId: string) => {
    const response = await apiService.get(STORE_ENDPOINTS.POST_STORE_ACTIVITY(storeId));

    return parseAndLog(ActivityInfoSchema, response.data);
  },
};
