/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { waveStatus } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/data';

export const ORDER_DETAILS_DRAWER_ROWS = [
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
  {
    sku: '000912',
    part: 'TMK-2.5',
    planogramId: 'Lorem',
    subzone: 'BAB2',
    description: 'POLYURETHANAEL WHEELS',
    pack: 1000,
    qoh: 800,
    quantity: 600,
    users: [
      {
        id: 1,
        firstName: 'ABC',
        lastName: 'BCD',
      },
      {
        id: 2,
        firstName: 'CDE',
        lastName: 'DEF',
      },
    ],
  },
];
export const orders = {
  id: 2009987,
  due: '1/29/23 10:30',
  releaseBy: new Date('10/16/23 12:30:00'),
  status: waveStatus.readyForRelease,
  pcs: 996,
  stores: [
    {
      storeNumber: '005',
      pallets: 4,
      pieces: 121,
      dispatchTime: new Date('10/16/23 12:30:00'),
      activity: '',
    },
  ],
};
