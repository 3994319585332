/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  View,
  Text,
  Accordion,
  Dropdown,
  SingleValue,
  Divider,
  Actionable,
  Toggle,
} from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import styles from './UserDetails.module.scss';
import { INBOUND_TASK_TYPES } from '@taskManagement/constants/dataConstants';
import { DropdownOption } from '@inbound/components/DropdownSelectMenu/DropdownSelectMenu.types';
import { useState } from 'react';
import * as T from '@taskManagement/components/AssignTaskDrawer/UserTabs/Overview/Overview.types';
import { DetailsSection } from '@shared/components/DetailsSection/DetailsSection';
import { useGetUserDetails } from '@inventory/hooks/useGetUserDetails';

export const UserDetails = ({ userDetailsData }: T.UserDetailsProps) => {
  const { t } = useTranslation();

  const [taskType, setTaskType] = useState<SingleValue>(undefined);
  const handleTaskTypeChange = (value: DropdownOption | null) => {
    setTaskType(value);
  };

  const [autoAssignedTask, setAutoAssignedTask] = useState(false);
  const handleValueChange = () => {
    setAutoAssignedTask(!autoAssignedTask);
  };

  return (
    <View>
      <View.Item>
        <Accordion
          className={styles['user-detail__accordion']}
          headerOptions={{
            triggerInnerClassName: styles['user-detail__accordion__trigger-inner'],
            headerElement: (
              <View direction="row">
                <View.Item grow>
                  <Text
                    color="primary"
                    size="125"
                    weight="bold"
                    className={styles['user-detail__accordion__header']}
                  >
                    {t('UserDetailsTab.UserDetails')}
                  </Text>
                </View.Item>
              </View>
            ),
          }}
          defaultOpen={true}
        >
          <DetailsSection rows={useGetUserDetails(userDetailsData)} header="" />

          <View.Item>
            <View direction="row" align="center" gap={2} padding={[3, 0]}>
              <Text size="087">{t('UserDetailsTab.UserDetailItems.SeeWrongInfo')}</Text>
              <Actionable onClick={() => ({})}>
                <Text size="087" className={styles['user-detail__actionable-text']}>
                  {t('UserDetailsTab.UserDetailItems.EditUserDetails')}
                </Text>
              </Actionable>
            </View>
          </View.Item>
        </Accordion>
      </View.Item>

      <View.Item>
        <Divider color="300" />
      </View.Item>

      <View.Item>
        <View>
          <Accordion
            className={styles['user-detail__accordion']}
            headerOptions={{
              triggerInnerClassName: styles['user-detail__accordion__trigger-inner'],
              headerElement: (
                <View direction="row">
                  <View.Item grow>
                    <Text color="primary" size="125" weight="bold">
                      {t('UserDetailsTab.TaskPref')}
                    </Text>
                  </View.Item>
                </View>
              ),
            }}
            defaultOpen={true}
          >
            <View.Item>
              <View padding={[3, 0]}>
                <Text weight="bold" size="087">
                  {t('UserDetailsTab.TaskType')}
                </Text>
              </View>
              <Dropdown>
                <Dropdown.Button label={taskType ? taskType.label : t('UserDetailsTab.Task')} />
                <Dropdown.Content>
                  <View gap={1}>
                    {INBOUND_TASK_TYPES.map((task) => (
                      <Dropdown.Item
                        label={task.value}
                        onClick={() => handleTaskTypeChange(task)}
                      />
                    ))}
                  </View>
                </Dropdown.Content>
              </Dropdown>

              <View
                direction="row"
                justify="space-between"
                padding={4}
                className={styles['user-detail__auto-assign-section']}
              >
                <View width="85%">
                  <View padding={[1, 0]}>
                    <Text size="100" weight="bold">
                      Auto-Assign (On)
                    </Text>
                  </View>
                  <View>
                    <Text size="087">{t('UserDetailsTab.AutoAssignDesc')}</Text>
                  </View>
                </View>
                <View>
                  <Toggle name="tileInfo" checked={autoAssignedTask} onChange={handleValueChange} />
                </View>
              </View>
            </View.Item>
          </Accordion>
        </View>
      </View.Item>

      <View.Item>
        <Divider color="300" />
      </View.Item>

      <View.Item>
        <View>
          <Accordion
            className={styles['user-detail__accordion']}
            headerOptions={{
              triggerInnerClassName: styles['user-detail__accordion__trigger-inner'],
              headerElement: (
                <View direction="row">
                  <View.Item grow>
                    <Text
                      color="primary"
                      size="125"
                      weight="bold"
                      className={styles['user-detail__accordion__header']}
                    >
                      {t('UserDetailsTab.CachingAndSafety')}
                    </Text>
                  </View.Item>
                </View>
              ),
            }}
          >
            <View.Item />
          </Accordion>
        </View>
      </View.Item>
    </View>
  );
};
