/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Table } from '@shared/components/Table/Table.tsx';
import {
  TableStylingVariants,
  VEHICLE_ASSOCIATED_SUBZONE_TABLE_COLUMNS,
} from '@shared/components/Table/tableConstants.ts';
import { Button, Icon, Text, View } from '@az/starc-ui';
import { Props } from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/VehicleAssociatedSubzoneList.types.ts';
import {
  DEFAULT_PAGE,
  LAYOUT,
  NOTIFICATION_TYPES,
  RDM,
  ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE,
} from '@shared/constants/constants.ts';
import { Add, LicensePlateSearch } from '@az/starc-ui-icons';
import { EmptyState } from '@shared/components/EmptyState/EmptyState.tsx';
import { useTranslation } from 'react-i18next';
import { mapVehicleAssociatedSubzoneTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { AssetLayoutMappingType } from '@mdm/types/schema.type.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { SUBZONE_FIELD } from '@mdm/constants/constants.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { AddVehicleSubzoneModal } from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/AddVehicleSubzoneModal.tsx';
import { useMutateVehicle } from '@mdm/services/hooks/useMutateVehicle.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

export const VehicleAssociatedSubzoneList = ({
  vehicleId,
  vehicleData,
  onLayoutAttributeChange,
}: Props) => {
  /* State */
  const [subzoneTypeOptions, setSubzoneTypeOptions] = useState<SelectOption[]>([]);
  const [isAddVehicleSubzoneModalOpen, setIsAddVehicleSubzoneModalOpen] = useState<boolean>(false);

  /* Constants */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateVehicle: mutateDeleteSubzone, isLoading: isDeleting } = useMutateVehicle();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  /* Functions */
  const onDeleteSubzone = async (layoutDistinctName: string) => {
    if (vehicleData && vehicleData.assetKey) {
      const assetLayoutMappingData = vehicleData?.layouts || [];

      const foundLayoutIndex = assetLayoutMappingData.findIndex((subzone) => {
        return subzone.layoutDistinctName === layoutDistinctName;
      });

      if (foundLayoutIndex !== -1) {
        assetLayoutMappingData[foundLayoutIndex].isActive = false;
      }

      const payload = {
        ...vehicleData,
        layouts: assetLayoutMappingData.length > 0 ? assetLayoutMappingData : null,
      };

      mutateDeleteSubzone(
        {
          assetKey: vehicleData.assetKey,
          assetRequest: payload,
        },
        {
          onSuccess: (data) => {
            if (data) {
              const successMessage = t('Success.Action.Subzone.Deleted');

              queryClient.invalidateQueries(['vehicles']);
              queryClient.invalidateQueries(['vehicle']);
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
          },
        }
      );
    }
  };

  const onViewDetails = (assetLayout: AssetLayoutMappingType) => {
    navigate(PAGE_URLS.SUB_ZONE_DETAILS(assetLayout.layoutDistinctName));
  };

  const onAttributeChange = (value: string, attributeKey: string, layoutDistinctName: string) => {
    onLayoutAttributeChange(layoutDistinctName, attributeKey, value);
  };

  const populateDropdownOptions = useCallback(() => {
    const subzoneTypes = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.SUBZONE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setSubzoneTypeOptions(subzoneTypes);
  }, [configsData]);

  /* Hooks */
  useEffect(() => {
    populateDropdownOptions();
  }, [configsData, populateDropdownOptions]);

  return (
    <View
      attributes={{
        'data-testid': 'vehicle-subzone-list',
      }}
      gap={4}
      width="100%"
      height="100%"
    >
      <View direction="row">
        <View.Item grow>
          <Text size="125" weight="bold">
            {t('VehicleDetails.TotalSubzonesInVehicle', {
              count: (vehicleData?.layouts || []).length,
            })}
          </Text>
          <View direction="row" attributes={{ style: { marginTop: 'var(--st-unit-2)' } }}>
            <Text weight="regular">{t('VehicleDetails.VehicleSubzones.Subtitle')}</Text>
          </View>
        </View.Item>
        <View.Item>
          <Button
            variant="secondary"
            size="small"
            disabled={!vehicleId}
            onClick={() => setIsAddVehicleSubzoneModalOpen(true)}
          >
            <View direction="row" align="center" justify="center" gap={2}>
              <Icon svg={Add} />
              <Text>{t('AddSubzone')}</Text>
            </View>
          </Button>
        </View.Item>
      </View>

      {!vehicleId || (vehicleData?.layouts || []).length === 0 ? (
        <View padding={6}>
          <EmptyState
            svg={LicensePlateSearch}
            subtitle={t('Empty.VehicleSubzones.Subtitle')}
            text={t('Empty.VehicleSubzones.Text')}
          />
        </View>
      ) : (
        <View>
          <Table
            columns={VEHICLE_ASSOCIATED_SUBZONE_TABLE_COLUMNS}
            rows={mapVehicleAssociatedSubzoneTableRows(
              vehicleData?.layouts || [],
              onViewDetails,
              onAttributeChange,
              onDeleteSubzone,
              {
                subzoneTypeOptions,
              },
              isDeleting
            )}
            isLoading={isAddVehicleSubzoneModalOpen}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE}
            defaultPage={DEFAULT_PAGE}
            isCreditItem={false}
            isCheckboxTable={false}
            styleVariant={TableStylingVariants.FILLED}
            totalPages={Math.ceil(
              (vehicleData?.layouts || []).length / ZONE_ASSOCIATED_SUBZONES_PAGE_SIZE
            )}
            onSort={() => ({})}
          />
        </View>
      )}

      <AddVehicleSubzoneModal
        isOpen={isAddVehicleSubzoneModalOpen}
        onLayoutAttributeChange={onLayoutAttributeChange}
        onClose={() => {
          setIsAddVehicleSubzoneModalOpen(false);
        }}
        vehicleData={vehicleData}
      />
    </View>
  );
};
