/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@az/starc-ui';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { TaskSearchService } from '@shared/services/TaskSearchService';
import { TaskSearchParams } from '@shared/types/service.type';

import { DEBOUNCE_TIME } from '@inbound/constants/constants';

export const useGetTaskSearchData = (
  options: TaskSearchParams,
  isEnabled = true,
  isDelay = false
) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const debouncedOptions = useDebounce(JSON.stringify(options), DEBOUNCE_TIME);
  const {
    data: taskSearchData,
    isFetching,
    refetch,
    isError,
  } = useQuery({
    queryKey: ['TaskSearchAPI', isDelay ? debouncedOptions : options],
    queryFn: () =>
      TaskSearchService.getTaskSearchData(isDelay ? JSON.parse(debouncedOptions) : options),
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.TaskSearch.Description'));
    }
  }, [isError, handleNotification, t]);

  return { taskSearchData, refetch, isFetching, isError };
};
